import { parseISO } from 'date-fns';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const GeniusSelectDate = ({ date, setDate, SetDateFocus, dateFocus }) => {
  return (
    <div
      style={{
        border: '1px solid #b5b3b3',
      }}
      className="w-fit flex rounded-sm  overflow-hidden "
    >
      <div
        style={{
          padding: '2px',
          borderRight: '1px solid #a6a2a2 ',
        }}
        className="bg-[#f1efeb] overflow-hidden w-full flex  items-center "
      >
        <DatePicker
          className=" bg-transparent flex items-center text-sm w-full p-[7px] outline-0  overflow-hidden  "
          dateFormat={
            window.innerWidth <= 500 ? ' d MMM , yyyy ' : 'd MMMM , yyyy'
          }
          selected={parseISO(moment(date).format('YYYY-MM-DD'))}
          onChange={(e) => {
            setDate(moment(e).format('YYYY-MM-DD'));
            SetDateFocus(false);
          }}
          onFocus={() => {
            SetDateFocus(true);
          }}
          readOnly={true}
          onClickOutside={() => SetDateFocus(false)}
          open={dateFocus}
        />
      </div>
      <div
        style={{
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
        }}
        className="flex items-center  bg-[#f7dda4] rounded-r-sm "
      >
        <i
          className="bi bi-calendar2 py-1 px-2"
          onClick={() => SetDateFocus(true)}
          style={{ fontSize: '14px' }}
        ></i>
      </div>
    </div>
  );
};

export default GeniusSelectDate;
