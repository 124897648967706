import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import axios from 'axios';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import spinner from '../../../utility/json/Spinner.json';

import {
  selectIssuesList,
  selectPaymodeReport,
  setIssuedSummeryList,
  setPaymodeSaleReport,
} from '../../../utility/Redux/list';

import { FormatNumber2 } from '../../../utility/component/tools';
import {
  selectStatus,
  setVocherModalStatus,
  setloading,
  setSessionExpired,
  setAlertMsg,
  setALertStatus,
  selectLoginApprovelModalStatus,
} from '../../../utility/Redux/modal';

import { TotalFooter } from '../../../utility/component/FooterDashboard';
import { useNavigate, useLocation } from 'react-router-dom';
import { selectToken } from '../../../utility/Redux/security';
import {
  selectDefultProfileSetting,
  selectLatitude,
  selectPrefix,
  selectStationId,
  selectTodayIssueStatus,
  selectUserType,
  setBillingAccountDetails,
  setTodayIssueStatus,
  setlatitudeRedux,
  setlongitudeRedux,
} from '../../../utility/Redux/profile';

import moment from 'moment';

import { SelectDate } from '../../../utility/Redux/refresh';

import { clearOrderBillingList } from '../../../utility/Redux/tableOrder';

import ViewBillModal from '../../../utility/component/modals/modBillInvoice';

import { GeniusHeader } from '../../../utility/component/datagrid/geniusHeader';
import {
  DashboardColumns,
  DashboardMobileColumns,
} from '../../../utility/columns/dashboardCol';

import { FromToDateDashBoard } from '../../../utility/component/fromToDateDashboard';
import DashCollectionsButton from '../../../utility/component/dashCollectionsButton';
import { CallAPIPromise } from './biiling/modal/comman';

const height = window.innerHeight;

const isProduction = process.env.NODE_ENV === 'production';

const Dashboard = () => {
  const props = useLocation();
  const { state } = props;
  const defaultProfile = useSelector(selectDefultProfileSetting);

  const stationId = useSelector(selectStationId);

  // const sid = localStorage.getItem("stationId");
  const columns = DashboardColumns(defaultProfile);
  const columnsMobileView = DashboardMobileColumns(defaultProfile);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const location = useLocation();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const checkDateData = useSelector(SelectDate);
  const dispatch = useDispatch();
  const issuedSummeryList = useSelector(selectIssuesList);
  const navigate = useNavigate();
  const [disable, setdisable] = useState(false);
  const currency = location?.state?.currency;

  const paymodeReport = useSelector(selectPaymodeReport);
  const latitude = useSelector(selectLatitude);
  const LoginApproveStatus = useSelector(selectLoginApprovelModalStatus);

  const getIssuedData = () => {
    setdisable(true);
    CallAPIPromise(
      `/api/dashBoradReports/issuedToStation`,
      {
        table_prefix: prefix,
        station_id: stationId ? stationId : state?.sid,
        from_date: checkDateData,
        to_date: checkDateData,
      },
      token,
      dispatch
    )
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];
          for (let index = 0; index < data.length; index++) {
            if (data[index].PRV_BAL > 0 || data[index].IQNTY > 0) {
              rowList.push({
                ...data[index],
                BQNTY:
                  data[index].PRV_BAL + data[index].IQNTY - data[index].SQNTY,
                BAMOUNT:
                  (data[index].PRV_BAL +
                    data[index].IQNTY -
                    data[index].SQNTY) *
                  data[index].SALE_PRICE,

                id: index + 1,
              });
            }
          }

          dispatch(setIssuedSummeryList(rowList));
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
      })
      .finally((e) => {
        getPaymodeDetails();
        setdisable(false);
      });
  };

  const getPaymodeDetails = () => {
    CallAPIPromise(
      `/api/dashBoradReports/payModeSale`,
      {
        table_prefix: prefix,
        sid: stationId ? stationId : state?.sid,
        from_date: checkDateData,
        to_date: checkDateData,
      },
      token,
      dispatch
    )
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          if (res.data.response[0]) {
            dispatch(setPaymodeSaleReport(res.data.response));
          }
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally((e) => {
        dispatch(setloading(false));
        setdisable(false);
      });
  };

  const todayIssuedStatus = useSelector(selectTodayIssueStatus);
  const userType = useSelector(selectUserType);
  const getTodayIssueStatus = () => {
    CallAPIPromise(
      `/api/dashBoradReports/todayIssuedStatus`,
      {
        table_prefix: prefix,
        station_id: stationId ? stationId : state?.sid,
        from_date: moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
      },
      token,
      dispatch
    )
      .then((res) => {
        if (
          res.data.response[0]?.ISSUED_COUNT > 0 ||
          res.data.response[0]?.PRV_BAL_COUNT > 0 ||
          userType === 'STORE'
        ) {
          dispatch(setTodayIssueStatus(true));
        } else {
          dispatch(setTodayIssueStatus(true));
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    if (!todayIssuedStatus && !LoginApproveStatus) {
      getTodayIssueStatus();
    }
  }, [LoginApproveStatus]);

  useEffect(() => {
    dispatch(
      setBillingAccountDetails({
        accNumber: '',
        accMobile: '',
        accName: '',
      })
    );
    dispatch(clearOrderBillingList());

    if (!LoginApproveStatus) {
      getIssuedData();
    }
  }, [checkDateData, LoginApproveStatus]);

  let date = new Date().toLocaleDateString('de-DE');

  const goBilling = () => {
    if (!latitude) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(setlatitudeRedux(position.coords.latitude));
          dispatch(setlongitudeRedux(position.coords.longitude));
          navigate('/billing');
        },
        (error) => {
          if (isProduction) {
            navigator.geolocation.getCurrentPosition((position) => {
              dispatch(setlatitudeRedux(position.coords.latitude));
              dispatch(setlongitudeRedux(position.coords.longitude));
            });
            dispatch(
              setAlertMsg(
                'To continue using this app, please enable location services.'
              )
            );
            dispatch(setALertStatus(true));
          } else {
            navigate('/billing');
          }
          console.error(error);
        }
      );
    } else {
      navigate('/billing');
    }
  };

  const collectCollection = () => {
    if (!latitude) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(setlatitudeRedux(position.coords.latitude));
          dispatch(setlongitudeRedux(position.coords.longitude));
          dispatch(setVocherModalStatus(true));
        },
        (error) => {
          if (isProduction) {
            dispatch(
              setAlertMsg(
                'To continue using this app, please enable location services.'
              )
            );
            dispatch(setALertStatus(true));
          } else {
            dispatch(setVocherModalStatus(true));
          }
          console.error(error);
        }
      );
    } else {
      // dispatch(setVocherModalStatus(true));
      navigate('/Collection_Voucher');
    }
  };

  const totalCash = paymodeReport[0]?.CASH_AMOUNT
    ? paymodeReport[0]?.CASH_AMOUNT
    : Number(0);
  const totalDiscount = paymodeReport[0]?.DISQ_AMT
    ? paymodeReport[0]?.DISQ_AMT
    : Number(0);

  return (
    <div className="p-1  flex flex-col overflow-y-scroll  h-full ">
      <div
        style={{
          height: `calc(100% - 0px)`,
        }}
      >
        <div className="flex flex-col mb-2">
          <FromToDateDashBoard partyName={false} />
        </div>

        {disable ? (
          <div
            style={{ height: `calc(100% - 50px)` }}
            className=" w-full  flex justify-center items-center"
          >
            <div className="w-10 h-10">
              <Lottie
                animationData={spinner}
                loop={true}
                style={{ fontSize: 15 }}
              />
            </div>
          </div>
        ) : (
          <div className="p-1 mb-2" style={{ height: `calc(100% - 50px)` }}>
            <DataGrid
              sx={
                window.innerWidth <= 768
                  ? {
                      backgroundColor: 'white',

                      fontSize: 10,
                      '& .super-app-theme--header': {
                        backgroundColor: '#E6E6E3',
                        display: 'none',
                      },
                    }
                  : {
                      backgroundColor: 'white',

                      fontSize: 13,
                      '& .super-app-theme--header': {
                        backgroundColor: '#E6E6E3',
                        display: 'none',
                      },
                    }
              }
              scrollbarSize={0}
              rowHeight={33}
              headerHeight={0}
              columns={window.innerWidth <= 768 ? columnsMobileView : columns}
              rows={issuedSummeryList ? issuedSummeryList : []}
              pageSize={100}
              getRowId={(i) => i.ICODE}
              rowsPerPageOptions={[100]}
              experimentalFeatures={{ newEditingApi: true }}
              pagination
              showCellRightBorder={true}
              showColumnRightBorder={true}
              disableColumnSelector
              disableColumnReorder
              disableColumnMenu={true}
              components={{
                Pagination: GridPagination,
                Header: () => (
                  <GeniusHeader
                    columns={
                      window.innerWidth <= 768 ? columnsMobileView : columns
                    }
                  />
                ),
                Footer: () => (
                  <TotalFooter
                    rows={issuedSummeryList ? issuedSummeryList : []}
                    columns={
                      window.innerWidth <= 768 ? columnsMobileView : columns
                    }
                  />
                ),
              }}
            />
          </div>
        )}
      </div>

      {!disable ? (
        <div className="mt-3 flex ">
          <>
            <Button
              onClick={goBilling}
              variant="contained"
              style={{
                textTransform: 'none',
                letterSpacing: '1px',
                backgroundColor: '#e0ab5b',
                width: '100%',
              }}
            >
              <div className="text-black font-sans "> Create Bill </div>
            </Button>
          </>

          <>
            <Button
              onClick={collectCollection}
              variant="contained"
              style={{
                marginLeft: 8,
                textTransform: 'none',
                letterSpacing: '1px',
                backgroundColor: '#e0ab5b',
                width: '100%',
              }}
            >
              <div className="text-black font-sans "> Collection Voucher </div>
            </Button>
          </>
        </div>
      ) : null}

      <div className="pt-3 ">
        <div>
          <div className="  grid  grid-cols-2 md:grid  md:grid-cols-7 gap-2">
            <DashCollectionsButton
              mode={'Cash'}
              payment={
                paymodeReport[0]?.CASH_AMOUNT
                  ? FormatNumber2(paymodeReport[0]?.CASH_AMOUNT)
                  : FormatNumber2(0)
              }
              currency={currency}
              bg={'#a8cf45'}
              iconBg={'#bbe06c'}
            />

            <DashCollectionsButton
              mode={'Card'}
              payment={
                paymodeReport[0]?.CARD_AMOUNT
                  ? FormatNumber2(paymodeReport[0]?.CARD_AMOUNT)
                  : FormatNumber2(0)
              }
              currency={currency}
              bg={'#a8cf45'}
              iconBg={'#bbe06c'}
            />

            <DashCollectionsButton
              mode={'Cheque'}
              payment={
                paymodeReport[0]?.CHEQUE_AMOUNT
                  ? FormatNumber2(paymodeReport[0]?.CHEQUE_AMOUNT)
                  : FormatNumber2(0)
              }
              currency={currency}
              bg={'#a8cf45'}
              iconBg={'#bbe06c'}
            />

            <DashCollectionsButton
              mode={'ePayment'}
              payment={
                paymodeReport[0]?.EPAYMENT
                  ? FormatNumber2(paymodeReport[0]?.EPAYMENT)
                  : FormatNumber2(0)
              }
              currency={currency}
              bg={'#a8cf45'}
              iconBg={'#bbe06c'}
            />

            <DashCollectionsButton
              mode={'BTC'}
              payment={
                paymodeReport[0]?.BTC_AMOUNT
                  ? FormatNumber2(paymodeReport[0]?.BTC_AMOUNT)
                  : FormatNumber2(0)
              }
              currency={currency}
              bg={'#a8cf45'}
              iconBg={'#bbe06c'}
            />

            <DashCollectionsButton
              mode={'Discount'}
              payment={
                paymodeReport[0]?.DISQ_AMT
                  ? FormatNumber2(paymodeReport[0]?.DISQ_AMT)
                  : FormatNumber2(0)
              }
              currency={currency}
              bg={'#e97a7a'}
              iconBg={'#eb8d8d'}
            />

            <DashCollectionsButton
              mode={'Total Collection'}
              payment={FormatNumber2(totalCash - totalDiscount)}
              currency={currency}
              bg={'#a8cf45'}
              iconBg={'#bbe06c'}
            />

            <ViewBillModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
