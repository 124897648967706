import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { selectAccountDetails } from '../../../../../utility/Redux/profile';
import {
  selectBillingAccountMasterModalStatus,
  setBillingAccountMasterModalStatus,
} from '../../../../../utility/Redux/modal';

import BillingAccountGrid from './billingAccMaster';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  height: '85%',
  backgroundColor: 'white',
  padding: '2px',
  borderRadius: '8px',
  overflow: 'hidden',
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '80%',
  padding: '1px',
  borderRadius: '8px',
  backgroundColor: 'white',
  overflow: 'hidden',
};

export default function BillingAccountMasterDatagridModal() {
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectBillingAccountMasterModalStatus);

  const accProfile = useSelector(selectAccountDetails);
  const { accNumber } = accProfile;
  const navigate = useNavigate();
  const handleClose = () => {
    if (accNumber) {
      dispatch(setBillingAccountMasterModalStatus(false));
    } else {
      navigate('/');
    }
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={ModalStatus}
        onClose={accNumber ? handleClose : () => navigate('/')}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={'  overflow-hidden '}
        >
          <div className="flex-grow h-full  ">
            <BillingAccountGrid />
          </div>
        </div>
      </Modal>
    </div>
  );
}
