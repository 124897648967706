import moment from 'moment';
import React from 'react';
import { FormatNumber2 } from '../utility/component/tools';

export const paymentrecieptCol = (width) => {
  const columns = [
    {
      field: 'VOUCHER_NO',
      type: 'string',
      headerAlign: 'center',
      width: width < 768 ? 150 : width < 1400 ? 80 : 100,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Voucher No.'}{' '}
        </strong>
      ),
    },
    {
      field: 'PAY_MODE',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'left',

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  pr-4 text-center"
          style={{ fontSize: 13 }}
        >
          Pay Mode
        </strong>
      ),
    },
    {
      field: 'DATE',
      type: 'string',
      headerAlign: 'center',
      width: width < 768 ? 100 : 100,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => moment(params.value).format('DD-MM-YYYY'),
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Date'}{' '}
        </strong>
      ),
    },
    {
      field: 'ACCOUNT_NO',
      type: 'string',
      width: width < 768 ? 100 : 80,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params.value > 0) {
          return params.value;
        }
        return '';
      },

      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Acc. No.'}{' '}
        </strong>
      ),
    },

    {
      field: 'NAME',
      type: 'string',
      width: width < 768 ? 100 : width < 1400 ? 120 : 250,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          Name{' '}
        </strong>
      ),
    },

    {
      field: 'MOBILE',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 130,
      headerAlign: 'center',
      align: 'left',

      renderHeader: (params) => (
        <strong
          className="text-white  whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Mobile
        </strong>
      ),
    },
    {
      field: 'EXPENCE_NAME',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 150 : 180,
      align: 'left',

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  pr-4 text-center"
          style={{ fontSize: 13 }}
        >
          Expense Name
        </strong>
      ),
    },
    {
      field: 'OP_BALANCE',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          Opening
        </strong>
      ),
    },

    {
      field: 'AMOUNT_PAID',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          Amount Paid
        </strong>
      ),
    },

    {
      field: 'AMOUNT_RECEIVED',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 120,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerAlign: 'center',
      // renderCell: (params) => {
      //   if (params.row.SQNTY) {
      //     return params.row.IAMOUNT - params.row.SAMOUNT + params.row.RAMOUNT;
      //   } else {
      //     return "";
      //   }
      // },
      renderHeader: (params) => (
        <strong
          className="text-white  whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          Amt. Recived
        </strong>
      ),
    },
    {
      field: 'balance',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 100 : 150,
      align: 'right',

      headerAlign: 'center',
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          Balance
        </strong>
      ),
    },
    {
      field: 'SID',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 70 : 70,
      align: 'left',

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  pr-4 text-center"
          style={{ fontSize: 13 }}
        >
          SID
        </strong>
      ),
    },
    {
      field: 'SNAME',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 150 : 180,
      align: 'left',

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  pr-4 text-center"
          style={{ fontSize: 13 }}
        >
          Station Name
        </strong>
      ),
    },
    {
      field: 'APPROVED',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width < 768 ? 150 : 100,
      align: 'left',

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  pr-4 text-center"
          style={{ fontSize: 13 }}
        >
          Approved
        </strong>
      ),
    },
  ];
  return columns;
};
