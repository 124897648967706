import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccountDetails,
  selectPrefix,
  selectStationId,
  selectUserType,
  selectWidth,
  setShopUserName,
  setStationID,
  setStationName,
  setshopUserMobile,
} from '../../Redux/profile';
import {
  selectVoucherAccountModalStatus,
  setCollectionAccountDetails,
  setSessionExpired,
  setSpinnerLoading,
  setcollVoucherAccModStatus,
} from '../../Redux/modal';

import MasterHelp from './masterHelp';
import {
  selectShopkeeperToken,
  selectToken,
  setShopkeeperUserID,
} from '../../Redux/security';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  selectAccountDatagridList,
  setAccountDatagridList,
} from '../../Redux/list';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '80%',
  width: 'auto',
  padding: '2px',
  borderRadius: '8px',
  overflow: 'hidden',
  backgroundColor: 'white',
};

export default function ModAccountMasterCollectionVoucher({ id, onClose }) {
  const dispatch = useDispatch();
  const station_id = useSelector(selectStationId);

  const [fullList, setFullList] = useState([]);
  const ModalStatus = useSelector(selectVoucherAccountModalStatus);
  const [searchQuery, setSearchQuery] = useState('');
  const accProfile = useSelector(selectAccountDetails);
  const width = useSelector(selectWidth);
  const shopDetails = JSON.parse(localStorage.getItem('shopDetails'));
  const shopToken = useSelector(selectShopkeeperToken);
  const token = useSelector(selectToken);
  const usertype = useSelector(selectUserType);
  let shopPrefix = '';
  if (shopDetails?.table_prefix) {
    shopPrefix = shopDetails.table_prefix;
  }
  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 70
          : windowWidth <= 1156
          ? 100
          : 130,
      headerAlign: 'center',
      align: 'left',
      field: 'MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 50
          : windowWidth <= 940
          ? 100
          : windowWidth <= 1156
          ? 160
          : 300,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 80
          : windowWidth <= 1156
          ? 120
          : 150,
      headerAlign: 'center',
      align: 'left',
      field: 'ADDRESS',
      renderHeader: (params) => <div>Addrress</div>,
    },

    {
      width: windowWidth <= 940 ? 50 : windowWidth <= 1156 ? 60 : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'ACCOUNT_NO',
      renderHeader: (params) => (
        <div
        // style={{
        //   display: windowWidth <= 940 ? 'none' : 'flex',
        // }}
        >
          Acc.No.
        </div>
      ),
    },
    {
      width: windowWidth <= 940 ? 30 : windowWidth <= 1156 ? 60 : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'SID',
      renderHeader: (params) => <div>SID</div>,
    },
  ];

  if (shopDetails?.table_prefix) {
    shopPrefix = shopDetails.table_prefix;
  }

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token ? token : shopToken}`,
  };
  const location = useLocation();
  const prefix = useSelector(selectPrefix);
  const getAccountData = () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPIPromise(
      `/api/masters/listAccountMaster`,
      {
        // table_prefix: prefix,
        table_prefix: prefix ? prefix : shopPrefix,
        mobile: '',
        name: '',
        sid: shopPrefix
          ? ''
          : usertype === 'MANAGER'
          ? station_id
          : id
          ? id
          : station_id,
      },
      token ? token : shopToken,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }
          dispatch(setAccountDatagridList(rowList));
          setFullList(rowList);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const rows = useSelector(selectAccountDatagridList);

  useEffect(() => {
    getAccountData();
  }, []);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.ACCOUNT_NO.toString().includes(searchQuery);
        } else {
          return item.NAME.toLowerCase().includes(searchQuery.toLowerCase());
        }
      });

      dispatch(setAccountDatagridList(searchItems));
    } else {
      dispatch(setAccountDatagridList(fullList));
    }
  }, [searchQuery]);
  const rowClick = (params) => {
    const name = params.row.NAME;
    const mobile = params.row.MOBILE;
    const accNumber = params.row.ACCOUNT_NO;
    const address = params.row.ADDRESS;
    const stationId = params.row.SID;
    const stationName = params.row.SNAME;
    dispatch(
      setCollectionAccountDetails({
        accName: name,
        accAddress: address,
        accMobile: mobile,
        accNumber: accNumber,
        stationId: stationId,
        sName: stationName,
      })
    );
    dispatch(setShopkeeperUserID(accNumber));
    dispatch(setShopUserName(name));
    dispatch(setshopUserMobile(mobile));

    if (usertype === 'MANAGER') {
      if (!location.pathname === '/Sale_Summary') {
        dispatch(setStationID(stationId));
        dispatch(setStationName(stationName));
      }
    }
    dispatch(setcollVoucherAccModStatus(false));
  };
  return (
    <div className="bg-white">
      <MasterHelp
        status={ModalStatus}
        onClose={() => {
          onClose();
        }}
        style={style}
        title={'Account Master | Help'}
        columns={columns}
        list={rows}
        rowId={(p) => p.ACCOUNT_NO}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={rowClick}
      />
    </div>
  );
}
