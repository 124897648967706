import { useDispatch, useSelector } from 'react-redux';

import { selectAccountDetails } from '../../../utility/Redux/profile';
import FormatPhoneNumber from '../formatPhoneNumber';
import { Search } from '@mui/icons-material';
import { setBillingAccountMasterModalStatus } from '../../Redux/modal';

export const BillingSubHeader = (props) => {
  const dispatch = useDispatch();

  const AccProfile = useSelector(selectAccountDetails);
  const { accName, accMobile, accNumber } = AccProfile;

  return (
    <div
      style={{ borderRadius: '3px', overflow: 'hidden' }}
      className=" flex  "
    >
      <div
        style={{ borderRadius: '3px', overflow: 'hidden' }}
        className="flex border  border-[#bab5ad] grow border-r-0 md:border-r"
      >
        <div className="flex whitespace-normal  w-full   justify-start items-center">
          <div
            style={{
              borderRight: '1px solid #a6a2a2 ',
              paddingRight: '12px',
            }}
            className="flex   items-center  h-full pr-2 "
          >
            <button
              onClick={() => {
                dispatch(setBillingAccountMasterModalStatus(true));
              }}
            >
              <div className="flex   items-center">
                <Search
                  sx={{
                    fontSize: '20px',
                    color: 'orangered',
                    marginLeft: '16px',
                  }}
                />
                <span
                  style={{
                    fontSize: window.innerWidth <= 764 ? 11 : 12,
                    paddingLeft: '8px',
                  }}
                  className=" text-black     flex font-semibold items-center"
                >
                  {' '}
                  Account{' '}
                  <span className="pl-1 text-black print:hidden font-semibold ">
                    {' '}
                    Number
                  </span>
                </span>
              </div>
            </button>

            <div
              style={{
                border: '1px dotted #a6a2a2 ',
                borderRadius: '3px',
                fontSize: window.innerWidth <= 764 ? 11 : 14,
              }}
              className="flex   px-2  ml-2 p-2"
            >
              {accNumber ? accNumber : ''}
            </div>
          </div>

          {/* party Name and mobile */}

          <div
            style={{
              borderRight: '1px dotted #a6a2a2',
            }}
            className="flex w-full md:w-1/3 h-full   "
          >
            <div className="flex grow h-full  ">
              <div className="w-full h-full">
                <div
                  className="w-full h-1/2 print:h-full flex items-center p-1 "
                  style={{
                    fontSize: window.innerWidth <= 764 ? 12 : 14,
                    color: 'red',
                  }}
                >
                  <span
                    style={{
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      color: accNumber ? 'red' : 'gray',
                    }}
                    className={`px-4 pl-2 flex flex-col justify-center font-semibold  ${
                      accNumber ? 'text-gray-400' : 'text-red'
                    } `}
                  >
                    {accName ? accName : 'Party Name'}
                  </span>
                </div>
                <div
                  style={{
                    borderTop: '1px dotted #ccc7c0',
                    color: 'red',
                    fontSize: window.innerWidth <= 764 ? 12 : 14,
                    fontWeight: accNumber ? '500' : '400',
                  }}
                  className="h-1/2 flex  print:hidden p-1 "
                >
                  <span
                    style={{
                      paddingTop: '1px',
                      paddingBottom: '1px',
                      color: accNumber ? 'red' : 'gray',
                    }}
                    className="px-4 pl-2 flex flex-col justify-center font-semibold  "
                  >
                    {accMobile ? FormatPhoneNumber(accMobile) : 'Mobile'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
