import { useEffect, useState } from 'react';
import { FormatNumber2 } from '../../utility/component/tools';

const DiscountFotter = (props) => {
  const [partySearch, setPartySearch] = useState('');
  const { rows, columns, voucherList, setlist, fullList } = props;

  const totalDiscount = voucherList.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT_PAID),
    0
  );
  useEffect(() => {
    if (partySearch) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(partySearch.substring(0, 1))) {
          return item.ACCOUNT_NO.toString().includes(partySearch);
        } else {
          return item.NAME.toLowerCase().includes(partySearch.toLowerCase());
        }
      });

      setlist(searchItems);
    } else {
      setlist(fullList);
    }
  }, [partySearch]);

  return (
    <div className="text-sm mt-2 w-full  ">
      <div
        className=" flex  "
        style={{
          alignItems: 'center',
        }}
      >
        <div className="flex w-full">
          <div
            style={{
              width: columns[0].width + columns[1].width,

              border: '1px solid #f3f3f3',
            }}
            className="rounded-tl-sm"
          >
            <div className="flex w-full justify-end h-full items-center pr-3">
              {' '}
              <input
                placeholder="Search Party"
                value={partySearch}
                className="w-full outline-none pl-2 "
                onChange={(e) => setPartySearch(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              border: '1px solid #f3f3f3',
              borderLeft: '0px solid #f3f3f3',
            }}
            className="flex-1 flex justify-end items-center pr-3"
          >
            <div
              style={{
                width: columns[2].width,
              }}
              className="flex justify-end border-r p-2"
            >
              {' '}
              {'Total'}
            </div>
            <div
              style={{
                width: columns[3].width,
              }}
              className="flex justify-end border-r p-2"
            >
              {' '}
            </div>
            <div
              style={{
                width: columns[4].width,
              }}
              className="flex justify-end border-r p-2"
            >
              {' '}
              {FormatNumber2(totalDiscount)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DiscountFotter;
