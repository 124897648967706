import moment from 'moment';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toProperCase } from './format';
import { FormatNumber2 } from './tools';

import NumberToWords3 from './amountinWords3';
import { selectCompanyAddress, selectPrefix } from '../Redux/profile';
import { selectViewBillInvoiceId } from '../Redux/tableOrder';
import { selectToken } from '../Redux/security';
import axios from 'axios';
import { DownloadFile } from '../functions/firebase';
import '../../routeBilling/issueStock/print.css';
import { CallAPIPromise } from '../../routeBilling/menu/dashboard/biiling/modal/comman';
const ViewBillImage = React.forwardRef((props, ref) => {
  const prefix = useSelector(selectPrefix);
  const billInvoice = useSelector(selectViewBillInvoiceId);
  const token = useSelector(selectToken);
  const { setBillStatus } = props;
  const [date, setDate] = useState();
  const [list, setList] = useState([]);
  const comapnyAddress = useSelector(selectCompanyAddress);
  const { address, city, companyName, companyWebsite, email, mobile, state } =
    comapnyAddress;

  const dispathc = useDispatch();
  const getViewBillDetatils = () => {
    // dispatch(setloading(true));
    CallAPIPromise(
      '/api/reports/viewBill',

      {
        table_prefix: prefix,
        invno: billInvoice,
      },

      token,
      dispathc
    )
      .then((response) => {
        const dataList = response.data.response;
        setList(dataList);
        setDate(dataList[0].DATE);
        setBillStatus(dataList[0].BILL_STATUS);
      })
      .catch((err) => {
        console.log(err);
      });
    // .finally(() => {
    //   dispatch(setloading(false));
    // });
  };

  useEffect(() => {
    getViewBillDetatils();
  }, []);

  return (
    <div
      id={'print-component'}
      className="h-full bg-white print-component  overflow-scroll print:overflow-visible  p-4"
      ref={ref}
    >
      <div className="w-full h-full ">
        <div className="w-full flex  text-[20px] justify-center font-semibold">
          {companyName}
        </div>
        <div style={{ fontSize: 12 }} className="w-full flex justify-center ">
          {address},{city}
        </div>
        <div style={{ fontSize: 12 }} className="w-full flex justify-center ">
          {state} , {mobile}
        </div>
        <div style={{ fontSize: 12 }} className="flex">
          {' '}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {email}
          </div>{' '}
        </div>

        <div className="flex">
          {' '}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {companyWebsite}
          </div>{' '}
        </div>

        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1   border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-black "
        ></div>
        <div
          style={{ fontSize: 16 }}
          className="w-full text-sm mt-2 mb-2 font-semibold flex items-center justify-center"
        >
          {' '}
          <div>TAX INVOICE</div>
        </div>

        <div className="w-full flex justify-between">
          <div className="w-full">
            <div
              style={{ fontSize: 12 }}
              className="w-full  flex justify-between"
            >
              <div>Bill No. : {billInvoice}</div>
              <div style={{ fontSize: 12 }}>
                {' '}
                {date
                  ? `Date: ${moment.utc(date).format('YYYY-MM-DD : hh.mm A')}`
                  : null}
              </div>
            </div>
            <div
              style={{ borderTopWidth: '1px' }}
              className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-black "
            ></div>
            <div
              style={{ fontSize: 14 }}
              className="w-full  flex justify-between mt-2"
            ></div>
            <div style={{ fontSize: 12 }}>Route Name : {list[0]?.SNAME}</div>
            <div style={{ fontSize: 12 }} className={'mt-1'}>
              Bill Status: {list[0]?.BILL_STATUS}
            </div>
            <div style={{ fontSize: 12 }} className={'mt-1'}>
              Customer Name: {list[0]?.NAME}
            </div>
            <div style={{ fontSize: 12 }} className={'mb-2'}>
              Address: {list[0]?.ADDRESS}
            </div>
          </div>
        </div>

        <table className="w-full">
          <thead
            style={{ borderTopWidth: '1px', borderBottomWidth: '1px' }}
            className="w-full  border-black h-6"
          >
            <tr className="w-full   ">
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '40%',
                }}
                className=" whitespace-nowrap  border-black "
              >
                <div>Item Name</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '20%',
                }}
                className="   border-black"
              >
                <div> Rate</div>
              </th>
              <th
                style={{
                  fontSize: 12,
                  borderRightWidth: '1px',
                  width: '15%',
                }}
                className="  border-black "
              >
                <div> Qnty</div>
              </th>
              <th style={{ fontSize: 12, width: '25%' }}>
                <div> Amount</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    className=" border-black border-dotted text-center"
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {item.ITEMNAME}
                  </td>
                  <td
                    className=" pr-2  border-dotted border-black text-right "
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {FormatNumber2(item.SALE_PRICE)}
                  </td>
                  <td
                    className=" pr-2 border-dotted text-right  border-black "
                    style={{ fontSize: 10, borderRightWidth: '1px' }}
                  >
                    {item.QNTY}
                  </td>
                  <td className="text-right pr-2 " style={{ fontSize: 10 }}>
                    {FormatNumber2(item.SALE_PRICE * item.QNTY)}
                  </td>
                </tr>
              );
            })}
            <tr style={{ borderTopWidth: '1px' }} className=" border-black ">
              <td
                colSpan="3"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 11,
                  borderRightWidth: '1px',
                }}
              >
                Total :
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                {FormatNumber2(list[0]?.TOTAL)}
              </td>
            </tr>
            <tr className=" border-black ">
              <td
                colSpan="3"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 11,
                  borderRightWidth: '1px',
                }}
              >
                Discount :
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                {FormatNumber2(list[0]?.DISQ_AMT)}
              </td>
            </tr>

            <tr
              style={{ borderTopWidth: '1px', borderBottomWidth: '1px' }}
              className=" border-black "
            >
              <td
                colSpan="3"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 11,
                  borderRightWidth: '1px',
                }}
              >
                PLEASE PAY
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                {FormatNumber2(list[0]?.NET_AMOUNT)}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            fontSize: 12,
          }}
          className={'w-full flex justify-center mt-1'}
        >
          {toProperCase(NumberToWords3(list[0]?.NET_AMOUNT))}
        </div>
        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-1 border-black "
        ></div>
        <div
          style={{
            fontSize: 12,
          }}
          className={'w-full flex font-semibold justify-center'}
        >
          Amount Received
        </div>

        <div className="flex w-full justify-center items-center ">
          {list[0]?.CASH_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              Cash Amount : {list[0]?.CASH_AMOUNT}
            </div>
          ) : null}

          {list[0]?.CARD_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {list[0]?.CASH_AMOUNT ? <div className="px-2"> | </div> : null}
              Card Amount :{list[0]?.CARD_AMOUNT}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center">
          {list[0]?.BTC_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              BTC Amount : {list[0]?.BTC_AMOUNT}
            </div>
          ) : null}
          {list[0]?.BTC_AMOUNT && list[0]?.EPAYMENT ? (
            <div className="px-2">|</div>
          ) : null}

          {list[0]?.EPAYMENT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              ePayment Amount : {list[0]?.EPAYMENT}{' '}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center ">
          {list[0]?.COUPON_AMOUNT ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              Coupon Amount : {list[0]?.COUPON_AMOUNT}{' '}
            </div>
          ) : null}
          {list[0]?.COUPON_AMOUNT && list[0]?.COUPON_AMOUNT ? (
            <div className="px-2">|</div>
          ) : null}
        </div>

        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-black "
        ></div>

        <div
          style={{
            fontSize: 11,
          }}
          className={' flex text-center  justify-center mt-1'}
        >
          All Disputes Subjected to Jalandhar Jurisdiction.
        </div>

        <div
          style={{
            fontSize: 11,
          }}
          className={' flex justify-center'}
        >
          Powered by : www.geniusoffice.com
        </div>
      </div>
    </div>
  );
});

export default ViewBillImage;
