import moment from 'moment';
import { FormatNumber2 } from '../../utility/component/tools';

const MfgBalReportColumns = (width) => {
  const C = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 140 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'ICODE',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 150 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'ITEMNAME',

      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'MFG_QNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Mfg.Qnty</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'DMG_QNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Damage Qnty.</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'IQNTY_SUM',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Issue Qnty. </div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'RQNTY_SUM',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Return Qnty.</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'BAL_QNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Balance Qnty.</div>,
    },
  ];

  return C;
};

export { MfgBalReportColumns };
