import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfrimSaveVoucher,
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setConfirmSaveVoucher,
  setSessionExpired,
} from '../../Redux/modal';
import { Button, Modal } from '@mui/material';
import axios from 'axios';
import { selectToken } from '../../Redux/security';
import { selectPrefix } from '../../Redux/profile';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

const ModConfirmSaveVoucher = ({ data, clearData }) => {
  const modalStatus = useSelector(selectConfrimSaveVoucher);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const handleClose = () => {
    dispatch(setConfirmSaveVoucher(false));
  };
  const prefix = useSelector(selectPrefix);
  const handleSave = () => {
    const list = {
      table_prefix: prefix,
      cart_item: [data],
    };
    CallAPIPromise(`/api/voucherRoute/saveVoucher`, list, token, dispatch)
      .then(function (response) {
        if (response.status === 200) {
          dispatch(setConfirmSaveVoucher(false));
          dispatch(
            setAlertWithTitle({
              tilte: 'success',
              msg: 'Voucher saved successfully',
            })
          );
        } else {
          dispatch(setConfirmSaveVoucher(false));
          dispatch(setAlertMsg('Something wrong'));
          dispatch(setALertStatus(true));
        }
      })
      .catch(function (error) {
        console.log(error);

        if (error?.response.data?.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        clearData();
      });
  };

  return (
    <div className="bg-white">
      <Modal
        open={modalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="d:w-4/12 ml:w-10/12 ms:w-11/12 l:w-5/12 bg-brown overflow-hidden rounded-lg t:w-4/12 p:w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 pt-5 items-center ">
            <div className="flex  w-full px-5 pb-6  items-center ">
              <img
                src={require('../../../utility/images/ico.png')}
                className="mr-3 w-10 h-10 "
                alt=" "
              />
              <div>
                <div className="text-brown text-md ">
                  Do you want to save this Voucher?
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                <Button
                  size={'small'}
                  // disabled={issaveDisable}
                  onClick={handleSave}
                  style={{
                    backgroundColor: '#A8CF45',
                    color: 'black',
                  }}
                  variant="contained"
                >
                  <div className="px-5">Yes</div>{' '}
                </Button>
              </div>
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                <Button
                  size={'small'}
                  onClick={handleClose}
                  style={{
                    backgroundColor: '#f1f5f9',
                    color: 'black',
                  }}
                  variant="contained"
                >
                  <div className="px-2">No </div>{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModConfirmSaveVoucher;
