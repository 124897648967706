import { FormatNumber2 } from '../tools';
export const TotalFooter = (props) => {
  const { rows, columns } = props;
  const totalNetAmount = rows.reduce(
    (total, row) =>
      Number(total) + Number(row.BILL_STATUS === 'Settled' ? row.TOTAL : 0),
    0
  );

  const TotalCashAmt = rows.reduce(
    (total, row) =>
      Number(total) +
      Number(row.BILL_STATUS === 'Settled' ? row.CASH_AMOUNT : 0),
    0
  );

  const TotalCardAmount = rows.reduce(
    (total, row) =>
      Number(total) +
      Number(row.BILL_STATUS === 'Settled' ? row.CARD_AMOUNT : 0),
    0
  );

  const TotalChequeAmt = rows.reduce(
    (total, row) =>
      Number(total) +
      Number(row.BILL_STATUS === 'Settled' ? row.CHEQUE_AMOUNT : 0),
    0
  );

  const TotalePayment = rows.reduce(
    (total, row) =>
      Number(total) + (row.BILL_STATUS === 'Settled' ? row.EPAYMENT : 0),
    0
  );

  const TotaleDisqtAmt = rows.reduce(
    (total, row) =>
      Number(total) + (row.BILL_STATUS === 'Settled' ? row.DISQ_AMT : 0),
    0
  );

  const TotaleBtcAmt = rows.reduce(
    (total, row) =>
      Number(total) + (row.BILL_STATUS === 'Settled' ? row.BTC_AMOUNT : 0),
    0
  );
  const TotalePayRec = rows.reduce(
    (total, row) =>
      Number(total) + (row.CASH_AMOUNT + row.CARD_AMOUNT + row.EPAYMENT),
    0
  );

  const TotalCollection = rows.reduce(
    (acc, row) => acc + Number(row.TOTAL_COLLECTION),
    0
  );

  return (
    <div className="flex  w-full  ">
      <div className="flex">
        {columns.map((c, i) => {
          return (
            <div
              key={i}
              style={
                i === columns.length - 1
                  ? {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',

                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                    }
                  : {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',

                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                    }
              }
              className="  h-8  text-black   justify-end  flex items-center"
            >
              <div className="p-2 flex  text-right">
                {c.field === 'INVNO' && ''}
                {c.field === 'DATE' && ''}

                {c.field === 'ACCOUNT_NO' && ''}
                {c.field === 'NAME' && ''}
                {c.field === 'MOBILE' && 'Total'}

                {c.field === 'ADDRESS' && ''}
                {c.field === 'CASH_AMOUNT' && FormatNumber2(TotalCashAmt)}

                {c.field === 'CARD_AMOUNT' && FormatNumber2(TotalCardAmount)}

                {c.field === 'CHEQUE_AMOUNT' && FormatNumber2(TotalChequeAmt)}

                {c.field === 'EPAYMENT' && FormatNumber2(TotalePayment)}

                {c.field === 'BTC_AMOUNT' && FormatNumber2(TotaleBtcAmt)}

                {c.field === 'DISQ_AMT' && FormatNumber2(TotaleDisqtAmt)}

                {c.field === 'TOTAL' && FormatNumber2(totalNetAmount)}
                {c.field === 'TotalCash' && FormatNumber2(TotalePayRec)}
                {c.field === 'TOTAL_COLLECTION' &&
                  FormatNumber2(TotalCollection)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
