import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef } from 'react';

const MapWithMarkerWithInfo = (info) => {
  const mapRef = useRef(null);
  const details = info;
  const longitude = Number(details.lng);
  const latitude = Number(details.lat);

  const sid = details.sid;
  const user = details.user;

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_MAP_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      const geocoder = new google.maps.Geocoder();
      const latLng = { lat: latitude, lng: longitude };
      const map = new google.maps.Map(mapRef.current, {
        center: {
          lat: latitude,
          lng: longitude,
        },
        zoom: 13,
      });

      const marker = new google.maps.Marker({
        position: {
          lat: latitude,
          lng: longitude,
        },
        map,
        title: 'My Marker',
      });
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const infowindow = new google.maps.InfoWindow({
              content: `
                          
    <div style="font-size: 16px; width: 340px; ">

    <div style="display: flex; flex-direction: row; justify-content: center; width:300px; margin-bottom:5px" >
    

      <p style="font-size: 16px; font-weight:500; "> SID : ${sid}  </p>
    

      <p style="font-size: 16px; font-weight:500; "> , User : ${user}</p>
    
    </div>

    <div style="display: flex; flex-direction: row;" >
      <p  style="font-size: 16px;">Address : ${results[0].formatted_address} </p>
    </div>
    </div>
  `,
            });
            infowindow.open(map, marker);
          }
        }
      });
    });
  }, [info]);

  return <div ref={mapRef} className="w-full h-full" />;
};

export default MapWithMarkerWithInfo;
