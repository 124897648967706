import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SummaryList = () => {
  const navigate = useNavigate();

  const list = [
    {
      name: 'Sale Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigate('/Sale_Summary');
      },
    },
    {
      name: 'Collection Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigate('/collection_summary');
      },
    },
    {
      name: 'Day End Summary',
      path: '',
      userType: 'MANAGER,USER,ADMIN,STORE',
      onclick: () => {
        navigate('/day_end_summary');
      },
    },

    {
      name: 'Payment Reciept Summary',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/payment_reciept_summary');
      },
    },
  ];

  return (
    <div className="h-full p-4">
      <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-5 ll:grid-cols-6 gap-3  ">
        {list[0] &&
          list.map((e, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  if (e.path) {
                    navigate(e.path);
                  } else {
                    e.onclick();
                  }
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
                }}
              >
                <div className="bg-[#e9e9e5] py-5 w-full text-brown">
                  {e.name}
                </div>
              </Button>
            );
          })}
      </div>
    </div>
  );
};

export default SummaryList;
