import { Button } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectUserType } from '../utility/Redux/profile';

const Updation = () => {
  const navigate = useNavigate();

  const masters = [
    {
      name: 'Expense Voucher',
      path: '',
      userType: 'MANAGER,USER,STORE',
      onclick: () => {
        navigate('/Expense_Voucher');
      },
    },
    {
      name: 'Collection Voucher',
      path: '',
      userType: 'MANAGER,USER,STORE',
      onclick: () => {
        navigate('/Collection_Voucher');
      },
    },
  ];

  const userType = useSelector(selectUserType);

  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-2 t:grid-cols-4 d:grid-cols-6 ll:grid-cols-8 gap-3  ">
        {masters[0] &&
          masters.map((e, i) => {
            if (e.userType.includes(userType)) {
              return (
                <Button
                  key={i}
                  onClick={() => {
                    if (e.path) {
                      navigate(e.path);
                    } else {
                      e.onclick();
                    }
                  }}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#e9e9e5] py-5 w-full text-brown">
                    {e.name}
                  </div>
                </Button>
              );
            }
          })}
      </div>
    </div>
  );
};

export default Updation;
