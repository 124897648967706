import { FormatNumber2 } from '../../../utility/component/tools';

const TotalIssueStockFooter = (props) => {
  const { rows, columns } = props;

  const totalMfgQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.MFG_QNTY),
    0
  );
  const totalDamage = rows.reduce(
    (total, row) => Number(total) + Number(row.DMG_QNTY),
    0
  );
  return (
    <div className="flex w-full ">
      <div
        style={{
          borderBottom: '1px solid black',
          borderTop: '1px solid black',
          borderRight: '1px solid black',
        }}
        className="flex w-full "
      >
        {columns.map((c, i) => {
          return (
            <div
              key={i}
              style={
                i === columns.length - 1
                  ? {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                    }
                  : {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',
                    }
              }
              className="  h-8  text-black   justify-end  flex items-center"
            >
              <div className="p-2 flex  text-right">
                {c.field === 'ITEMNAME' && 'Total'}
                {c.field === 'DMG_QNTY' && FormatNumber2(totalDamage)}
                {c.field === 'MFG_QNTY' && FormatNumber2(totalMfgQnty)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TotalIssueStockFooter;
