import { Button } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';

import { toJpeg, toPng } from 'html-to-image';
import React, { useRef } from 'react';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaWhatsappSquare, FaSms, FaWhatsapp } from 'react-icons/fa';
import { TiArrowBack, TiMessage } from 'react-icons/ti';
import whitSpinner from '../../../../../utility/json/spinerWhite.json';
import {
  selectStatus,
  setAlertMsg,
  setALertStatus,
  setConfirmPrintBillModalStatus,
  setloading,
  setSessionExpired,
  setShareImageModalStatus,
  setSpinnerLoading,
} from '../../../../../utility/Redux/modal';
import jsPDF from 'jspdf';
import spinner from '../../../../../utility/json/Spinner.json';
import {
  clearOrderBillingList,
  SelectBillingDiscount,
  SelectBillSettlementDetails,
  selectCurrentInvoiceBillNumber,
  SelectOrdersList,
  setBillSettlementDetails,
} from '../../../../../utility/Redux/tableOrder';
import WhatsAppBillDesign from './whatsappShare';
import { selectToken } from '../../../../../utility/Redux/security';
import { useFocus } from '../../../../../utility/hooks/hooks';
import { CancelPresentation } from '@mui/icons-material';
import { BiCloudDownload } from 'react-icons/bi';

import {
  selectAccountDetails,
  setBillingAccountDetails,
} from '../../../../../utility/Redux/profile';
import { CallAPIPromise } from './comman';

const ShareBill = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentInvoiceNumber = useSelector(selectCurrentInvoiceBillNumber);
  const billingItemsList = useSelector(SelectOrdersList);
  const billSettelmentDetails = useSelector(SelectBillSettlementDetails);

  const accDetails = useSelector(selectAccountDetails);

  const { accMobile } = accDetails;
  const [numberRef, setNumberFocous] = useFocus();
  const componentRef = useRef(null);
  const [waDisable, setWadisable] = useState(false);
  const [smsDisable, setsmsdisable] = useState(false);

  const selectDiscountedAmount = useSelector(SelectBillingDiscount);

  const loading = useSelector(selectStatus);
  const token = useSelector(selectToken);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const [sentdo, seSendTo] = useState(accMobile);

  const totalAmount = Math.round(
    billingItemsList?.reduce((acc, curr) => acc + curr.AMOUNT, 0) -
      selectDiscountedAmount
  );

  const handleSendImage = () => {
    dispatch(setloading(true));
    setWadisable(true);

    ///  to jpeg

    toPng(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        dispatch(setSpinnerLoading('sending image'));
        CallAPIPromise(
          '/api/sendDigitalBill/saveBillImage',
          {
            dataUrl: JSON.stringify({ dataUrl }),
            invno: currentInvoiceNumber,
            mobile: sentdo,
          },
          token,
          dispatch
        )
          .then((response) => {
            setWadisable(false);
            dispatch(setAlertMsg('Message sent successfully'));
            dispatch(setALertStatus(true));
          })
          .catch((err) => {
            console.log(err);
            if (err?.response.data?.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
              dispatch(setAlertMsg('Message not sent'));
              dispatch(setALertStatus(true));
            }
          })
          .finally(() => {
            dispatch(setloading(false));
            setWadisable(false);
            dispatch(setSpinnerLoading(false));
          });
      })
      .catch((err) => console.log(err));
  };

  const handleSendSms = () => {
    dispatch(setloading(true));
    setsmsdisable(true);
    CallAPIPromise(
      '/api/sendDigitalBill/sendSms',
      {
        INVNO: currentInvoiceNumber,
        MOBILE: sentdo,
        AMOUNT: totalAmount,
      },

      token,
      dispatch
    )
      .then((response) => {
        dispatch(setAlertMsg('Message sent successfully'));
        dispatch(setALertStatus(true));
      })
      .catch((err) => {
        dispatch(setAlertMsg('Message not sent'));
        dispatch(setALertStatus(true));
      })
      .finally(() => {
        dispatch(setloading(false));
        setsmsdisable(false);
      });
  };

  const downloadPdf = () => {
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        pdf.save(`Bill_${currentInvoiceNumber}`);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    dispatch(setConfirmPrintBillModalStatus(false));
    dispatch(clearOrderBillingList());
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: '',
        TOTAL_COLLECTION: 0,
      })
    );
    dispatch(
      setBillingAccountDetails({
        accNumber: '',
        accMobile: '',
        accName: '',
      })
    );
    dispatch(setShareImageModalStatus(false));
    navigate('/');
  };

  return (
    <div className="-mt-1">
      <div className="flex w-full bg-brown">
        <img
          src={require('../../../../../utility/images/logo3.png')}
          className="w-36 md:w-48 h-auto"
        />

        {/* <div className="w-2 " /> */}
        <div className="flex  w-full justify-end items-center text-white bg-brown px-3 font-medium">
          <CancelPresentation
            onClick={handleClose}
            color="orange"
            fontSize="medium"
          />
        </div>
      </div>

      <div className="w-full h-full flex flex-col md:flex md:flex-row ">
        <div
          style={window.innerWidth < 770 ? { width: '100%' } : { width: '60%' }}
          className=" bg-[#EDEDF2] h-full flex justify-center"
        >
          <div className="bg-white w-full flex justify-center shadow-md border   ">
            <WhatsAppBillDesign
              total={totalAmount}
              billing={billSettelmentDetails}
              billItems={billingItemsList}
              ref={componentRef}
            />
          </div>
        </div>
        <div style={{ width: '40%' }} className="p-5  md:flex flex-col hidden ">
          <div className="w-full">
            <div className="flex justify-start items-center w-full pt-6 ">
              <div style={{ fontSize: 13 }}>Mobile</div>
              <div
                style={{
                  height: 27,
                  backgroundColor: '#EDEDF2',
                  overflow: 'hidden',
                  marginLeft: 10,
                }}
                className=" rounded-sm  w-16 md:w-40 border-2 border-slate-300 items-center "
              >
                <input
                  type={'Number'}
                  ref={numberRef}
                  autoFocus={true}
                  style={{
                    fontSize: 13,
                    backgroundColor: '#EDEDF2',
                    paddingLeft: 10,

                    fontWeight: 400,
                    outline: 0,
                  }}
                  placeholder={'123'}
                  value={sentdo}
                  onChange={(t) => seSendTo(t.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-5 justify-center">
            <Button
              size={'small'}
              onClick={() => {
                if (!waDisable && sentdo) {
                  handleSendImage();
                }
              }}
              style={{
                backgroundColor: '#A8CF45',
                color: 'white',
                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="contained"
            >
              <FaWhatsapp size={20} className="bg-green text-white" />
              <div className="px-2">Whatsapp</div>{' '}
              {waDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null}
            </Button>

            <Button
              size={'small'}
              onClick={() => {
                if (!smsDisable && sentdo) {
                  handleSendSms();
                }
              }}
              style={{
                backgroundColor: '#F5874F',
                color: 'white',
                marginTop: 10,
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,

                textTransform: 'none',
              }}
              variant="contained"
            >
              <TiMessage size={20} className="bg-orange text-white" />
              <div className="px-2">SMS</div>{' '}
              {smsDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={whitSpinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null}
            </Button>

            <Button
              size={'small'}
              onClick={() => {
                downloadPdf();
              }}
              style={{
                backgroundColor: '#F5874F5',
                color: 'white',
                marginTop: 10,
                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="contained"
            >
              <BiCloudDownload size={20} className="bg-brown text-white" />
              <div className="px-2">Downloads</div>{' '}
            </Button>

            <Button
              size={'small'}
              onClick={handleClose}
              style={{
                color: '#383535',
                marginTop: 10,
                borderColor: '#383535',
                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="outlined"
            >
              <TiArrowBack
                onClick={handleClose}
                size={20}
                className=" text-red-500"
              />
              <div onClick={handleClose} className="px-2 text-red-500">
                Back{' '}
              </div>{' '}
            </Button>
          </div>
          <div className="w-full flex justify-center mt-3"></div>
        </div>
        <div className="fixed bottom-0 w-full flex md:hidden p-3 bg-[#EDEDF2] items-center border-t-2 border-t-slate-200 ">
          <div
            style={{
              height: 35,
              backgroundColor: '#c9c5c5',
              overflow: 'hidden',
              width: '45%',
            }}
            className=" rounded-md mx-2 border-2  items-center "
          >
            <input
              type={'Number'}
              ref={numberRef}
              autoFocus={true}
              style={{
                fontSize: 14,
                backgroundColor: '#c9c5c5',
                paddingLeft: 10,
                height: '100%',
                fontWeight: 400,
                outline: 0,
              }}
              placeholder={'Mobile Number'}
              value={sentdo}
              onChange={(t) => seSendTo(t.target.value)}
            />
          </div>
          <div
            style={{ width: '55%' }}
            className=" rounded-sm flex justify-evenly "
          >
            <FaWhatsappSquare
              size={30}
              className="text-green"
              onClick={() => {
                if (!waDisable && sentdo) {
                  handleSendImage();
                }
              }}
            />

            <FaSms
              size={30}
              className="text-orange"
              onClick={() => {
                if (!smsDisable && sentdo) {
                  handleSendSms();
                }
              }}
            />

            <Button
              size={'small'}
              onClick={handleClose}
              style={{
                backgroundColor: '#383535',
                color: 'white',
                textTransform: 'none',
              }}
              variant="contained"
            >
              <div className="px-2">Back</div>{' '}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareBill;
