import { FormatNumber2, FormatNumber3 } from '../../../utility/component/tools';
export const ProfitFooter = (props) => {
  const { rows, columns } = props;

  const totalSaleQnty = rows.reduce((total, row) => total + row.SALE_QNTY, 0);

  const TotalSaleAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.SALE_AMOUNT),
    0
  );
  const totalIssueAmt = rows.reduce(
    (total, row) =>
      Number(total) + parseFloat(row.ISSUE_AMOUNT.replace(/,/g, '')),
    0
  );
  const totalProfit = rows.reduce((total, row) => {
    return Number(total) + parseFloat(row.PROFIT.replace(/,/g, ''));
  }, 0);
  console.log(totalProfit);
  return (
    <div className="  bg-[#e6e6e3] flex  text-sm ">
      {columns.map((item, index) => {
        const header = item.field;
        return (
          <div
            key={index}
            className="  text-black flex justify-end pr-2 items-center border-r py-2 border-slate-300"
            style={item.flex ? { flex: item.flex } : { width: item.width }}
          >
            {header === 'SID' ? 'Total' : null}
            {header === 'SALE_QNTY' ? FormatNumber2(totalSaleQnty) : null}
            {header === 'SALE_AMOUNT' ? FormatNumber2(TotalSaleAmount) : null}
            {header === 'ISSUE_AMOUNT' ? FormatNumber2(totalIssueAmt) : null}

            {header === 'PROFIT' ? FormatNumber2(totalProfit) : null}
          </div>
        );
      })}
    </div>
  );
};
