import React, { useRef } from 'react';
import Modal from '@mui/material/Modal';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectPrintModalStatus,
  setConfirmPrintBillModalStatus,
  setShareImageModalStatus,
} from '../../Redux/modal';
import { Button } from '@mui/material';
import logo from '../../images/ico.png';

import {
  clearOrderBillingList,
  SelectBillingDiscount,
  SelectBillSettlementDetails,
  SelectOrdersList,
  setBillSettlementDetails,
} from '../../Redux/tableOrder';
import { setBillingAccountDetails } from '../../Redux/profile';
import { useNavigate } from 'react-router-dom';
import BillDesign from '../../../routeBilling/menu/dashboard/biiling/modal/billRecipt';
import ReactToPrint from 'react-to-print';
import { Close } from '@mui/icons-material';

export default function ConfirmPrintAlert(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const billRef = useRef();

  const PrintBillStatus = useSelector(selectPrintModalStatus);

  const handleClose = () => {
    dispatch(setConfirmPrintBillModalStatus(false));
    dispatch(clearOrderBillingList());
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: '',
        TOTAL_COLLECTION: 0,
      })
    );
    dispatch(
      setBillingAccountDetails({
        accNumber: '',
        accMobile: '',
        accName: '',
      })
    );
    navigate('/');
  };
  const billingItemsList = useSelector(SelectOrdersList);
  const selectDiscountedAmount = useSelector(SelectBillingDiscount);

  const billSettelmentDetails = useSelector(SelectBillSettlementDetails);

  const handleShare = () => {
    dispatch(setShareImageModalStatus(true));
  };

  const totalAmount = Math.round(
    billingItemsList?.reduce((acc, curr) => acc + curr.AMOUNT, 0) -
      selectDiscountedAmount
  );
  //

  return (
    <div className="bg-white">
      <Modal
        open={PrintBillStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="d:w-4/12 ml:w-10/12 ms:w-11/12 l:w-5/12 bg-brown overflow-hidden rounded-lg t:w-4/12 p:w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-between pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            <div> genius office </div>
            <div className="mr-2">
              {' '}
              <Close
                onClick={handleClose}
                style={{
                  color: 'F5874F',
                }}
              />{' '}
            </div>
          </div>
          <div className="justify-center bg-white min-h-32 pt-5 items-center ">
            <div className="flex  w-full px-5 pb-6  items-center ">
              <img src={logo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown text-md ">
                  The billing information has been successfully saved. Do You
                  want to print this Bill.
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                {/* <button onClick={handlePrint}>Print</button> */}

                <ReactToPrint
                  trigger={() => (
                    <Button
                      variant="contained"
                      size={'small'}
                      style={{
                        backgroundColor: '#F5874F',
                        color: 'white',
                      }}
                    >
                      <div className="px-5">Print</div>{' '}
                    </Button>
                  )}
                  content={() => billRef.current}
                  onAfterPrint={async () => {}}
                />
              </div>
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                <Button
                  size={'small'}
                  onClick={handleShare}
                  style={{
                    backgroundColor: '#A8CF45',
                    color: '#090D00',
                    textTransform: 'none',
                  }}
                  variant="contained"
                >
                  <div className="px-2">Whatsapp </div>{' '}
                </Button>
              </div>
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                <Button
                  size={'small'}
                  onClick={handleClose}
                  style={{
                    backgroundColor: '#f1f5f9',
                    color: 'black',
                  }}
                  variant="contained"
                >
                  <div className="px-2">Back </div>{' '}
                </Button>

                <div className="hidden">
                  <BillDesign
                    total={totalAmount}
                    billing={billSettelmentDetails}
                    billItems={billingItemsList}
                    ref={billRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
