import React from 'react';

const ExcelExport = ({ columns, data }) => {
  let csvContent = 'data:text/csv;charset=utf-8,';

  // Add header row
  csvContent += columns.map((column) => column.headerName).join(',') + '\n';

  // Add data rows
  data.forEach((row) => {
    const rowData = columns.map((column) => {
      const value = row[column.field];
      const v =
        value !== undefined ? (value ? (value !== 0 ? value : '') : '') : '';
      return typeof v === 'string' ? v.replace(/[\r\n]+/g, '') : v; // Handle undefined values
    });

    csvContent += rowData.join(',') + '\n';
  });

  // Encode URI and create download link
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'data.csv');
  document.body.appendChild(link);

  return (
    <div>
      <button
        className="bg-green p-2 text-sm rounded-sm  px-4 "
        onClick={() => link.click()}
      >
        Export to Excel
      </button>
    </div>
  );
};

export default ExcelExport;
