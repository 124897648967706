import { Search } from '@mui/icons-material';
import { setcollVoucherAccModStatus } from '../Redux/modal';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectShopUserMobile,
  selectShopUserType,
  selectShopUsername,
  selectUserType,
} from '../Redux/profile';
import { useState } from 'react';
import { useEffect } from 'react';

export const SearchPriceMaster = (props) => {
  const { SetSearchText, searchText } = props;
  const [usertype, setUserType] = useState('');
  const userDetails = JSON.parse(localStorage.getItem('shopDetails'));
  const dispatch = useDispatch();
  const shopUserName = useSelector(selectShopUsername);

  const shopUsertype = useSelector(selectShopUserType);
  const AdminUsertype = useSelector(selectUserType);

  const shopuserMobile = useSelector(selectShopUserMobile);

  useEffect(() => {
    if (AdminUsertype) {
      setUserType(AdminUsertype);
      dispatch(setcollVoucherAccModStatus(true));
    } else {
      setUserType(shopUsertype);
    }
  }, [AdminUsertype, shopUsertype]);
  return (
    <div
      style={{ borderRadius: '3px', overflow: 'hidden' }}
      className=" flex  w-2/3 max-sm:w-full"
    >
      <div
        style={{
          borderRadius: '7px',
        }}
        className="flex border border-r-0 border-[#a6a2a2] w-full  "
      >
        <div
          style={{ width: '25%' }}
          className=" flex items-center  border-[#a6a2a2] border-r py-2 px-1  d:px-4 "
        >
          <div
            style={{
              border: '1px dotted #a6a2a2 ',
              borderRadius: '7px',
            }}
            className="flex  w-full  pl-2  "
          >
            <input
              size={'small'}
              placeholder="Search"
              value={searchText}
              style={{
                fontSize: 12,
                paddingTop: '2px',
                paddingBottom: '2px',
                backgroundColor: 'transparent',
              }}
              onChange={(t) => SetSearchText(t.target.value)}
              className=" outline-0 w-full "
            />
          </div>
        </div>

        {/* party name full */}

        <div
          style={{
            fontSize: 12,
            width: '80%',
            color: '#a6a2a2',
          }}
          className=" flex  whitespace-normal    justify-start items-center  "
        >
          {/* party name 1st part */}
          <div
            style={{
              borderRight: '1px solid #a6a2a2 ',
              flex: 1,
            }}
            className="flex  h-full items-center px-1 d:px-2 py-2"
          >
            <div className=" hover:cursor-pointer h-full items-center hidden d:flex">
              <span
                style={{ fontSize: 12 }}
                className=" text-[#5c5b5b]   flex font-semibold items-center"
              >
                {' '}
                Party
                <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                  {' '}
                  Name{' '}
                </span>
              </span>
            </div>

            <div
              style={{
                border: '1px dotted #a6a2a2 ',
                borderRadius: '3px',
              }}
              className="flex  w-full pl-2 d:ml-2"
            >
              <div
                style={{
                  fontSize: 12,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  backgroundColor: 'transparent',
                  color: 'black',
                }}
                className=" outline-0 w-full "
              >
                {shopUserName}
              </div>

              {usertype === 'MANAGER' ? (
                <Search
                  onClick={() => dispatch(setcollVoucherAccModStatus(true))}
                  sx={{
                    fontSize: '20px',
                    color: 'orangered',
                  }}
                />
              ) : null}
            </div>
          </div>
          <div
            style={{
              borderRight: '1px solid #a6a2a2 ',
              width: '40%',
            }}
            className="flex  h-full items-center rounded-r-sm px-1 d:px-4 "
          >
            <div className="hover:cursor-pointer h-full items-center hidden d:flex ">
              <span
                style={{ fontSize: 12 }}
                className=" text-[#5c5b5b]  flex font-semibold items-center"
              >
                {' '}
                Mobile{' '}
              </span>
            </div>

            <div
              style={{
                border: '1px dotted #a6a2a2 ',
                borderRadius: '7px',
              }}
              className="flex  w-full  pl-2 d:ml-2"
            >
              <input
                size={'small'}
                placeholder=""
                value={
                  usertype === 'MANAGER'
                    ? shopuserMobile
                    : userDetails?.user_mobile
                }
                style={{
                  fontSize: 12,
                  paddingTop: '2px',
                  paddingBottom: '2px',
                  backgroundColor: 'transparent',
                  color: 'black',
                }}
                className=" outline-0 w-full "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
