

const deleteDatabaseStore = (database, storeName) => {
  const deleteData = () => {
    const request = indexedDB.open(database);

    request.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction(storeName, 'readwrite');
      const objectStore = transaction.objectStore(storeName);

      const deleteRequest = objectStore.clear();

      deleteRequest.onsuccess = function () {
        console.log(`Object store "${storeName}" cleared successfully`);
      };

      deleteRequest.onerror = function (event) {
        console.error(
          `Error clearing object store "${storeName}":`,
          event.target.error
        );
      };
    };

    request.onerror = function (event) {
      console.error(
        `Error opening database "${database}":`,
        event.target.error
      );
    };
  };

  deleteData();
};

export default deleteDatabaseStore;
