import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef } from 'react';

const MapWithMultyMarkers = (props) => {
  const mapRef = useRef(null);
  const locationInfo = props.locations;

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_MAP_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      const geocoder = new google.maps.Geocoder();

      //correct working

      const map = new google.maps.Map(mapRef.current, {
        center: {
          lat: 31.4379995,
          lng: 75.7585377,
        },
        zoom: 9,
      });

      let prevInfowindow = null;
      locationInfo.forEach((info) => {
        const marker = new google.maps.Marker({
          position: {
            lat: Number(info.LATITUDE),
            lng: Number(info.LONGITUDE),
          },
          map,
          title: `Bill : ${info.INVNO}`,
        });

        marker.addListener('click', () => {
          // Open the info window when the user clicks on the marker

          if (prevInfowindow) {
            prevInfowindow.close();
          }
          const latLng = {
            lat: Number(info.LATITUDE),
            lng: Number(info.LONGITUDE),
          };
          geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                const infowindow = new google.maps.InfoWindow({
                  content: `
    <div style="font-size: 16px; width: 300px; ">

    <div style="display: flex; flex-direction: row; justify-content: center; width:300px; margin-bottom:5px" >
     <p style="font-size: 16px; font-weight:500; ">Bill No. :</p>
     <p style="font-size: 16px; margin-left: 2px">${info.INVNO}</p>

      <p style="font-size: 16px; font-weight:500; "> , SID :</p>
     <p style="font-size: 16px; margin-left: 2px">${info.sid}</p>

      <p style="font-size: 16px; font-weight:500; "> , User :</p>
     <p style="font-size: 16px; margin-left: 2px">${info.sName}</p>
     </div>

      <div style="display: flex; flex-direction: row;" >
     <p style="font-size: 16px; ">Account :</p>
     <p style="font-size: 16px; margin-left: 2px">${info.NAME}</p>
     </div>

     <div style="display: flex; flex-direction: row;" >

      <p  style="font-size: 16px;">Address :  ${results[0].formatted_address} </p>
     </div>
  
     
    </div>
  `,
                });

                infowindow.open(map, marker);
                prevInfowindow = infowindow;
              } else {
                console.log('No results found');
              }
            } else {
              console.log(`Geocoder failed due to: ${status}`);
            }
          });
        });
      });
    });
  }, [props]);

  return <div ref={mapRef} style={{ height: '100%' }} />;
};

export default MapWithMultyMarkers;
