import { useDispatch, useSelector } from 'react-redux';
import {
  SelectItemMasterHelpStatus,
  setItemMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { FormatNumber2 } from '../utility/component/tools';

import MasterHelp from '../utility/component/modals/masterHelp';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';

const ItemMasterHelp = ({ onRowClick }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const status = useSelector(SelectItemMasterHelpStatus);
  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const width = useSelector(selectWidth);
  const columns = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width <= 768 ? 60 : width <= 1024 ? 100 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'right',
      field: 'SALE_PRICE',
      headerName: 'Rate',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Rate</div>,
    },
    {
      field: 'DEPARTMENT_NAME',
      renderHeader: (params) => <div>Department Name</div>,
      width:
        width <= 768 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.ICODE.toString().includes(searchQuery);
        } else {
          return item.ITEMNAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPIPromise(
      '/api/issueMasterRoute/listItemMaster',
      {
        table_prefix: prefix,
        sname: '',
      },
      token,
      dispatch
    )
      .then((p) => {
        setFullList(p.data.response);
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const handleClose = () => {
    dispatch(setItemMasterHelpStatus(false));
    setSearchQuery('');
  };

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            handleClose();
            setRows([]);
          }}
          title={'Item master | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.ICODE}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default ItemMasterHelp;
