import React, { useEffect, useState } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setListStationMasterModalStatus,
  setModListStationMasterStatus,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import CallAPI from '../utility/functions/getData';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { ItemMasterColumns } from './columns';
import { SaveButton, UndoButton } from '../utility/component/buttons';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';

const StationItemMaster = () => {
  const [stationId, setStationId] = useState('');
  const [stationName, setStationName] = useState('');
  const [itemMasterList, setItemMasterList] = useState([]);
  const [saveCartList, setSaveCartList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [fullList, setFullList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const getData = () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPI(
      '/api/issueMasterRoute/loadStationItemMaster',
      {
        table_prefix: prefix,
        CODE: stationId,
      },
      token,
      (r) => {
        if (!r.data.error) {
          let list = [];
          const data = r.data.response;

          setFullList(data);
          setSaveCartList(() => {
            const d = data.filter((c) => c.SALE_PRICE);
            return d.map((e) => {
              const da = {
                ...e,
                CODE: stationId,
                STATION_NAME: stationName,
                SALE_PRICE: e.SALE_PRICE ? e.SALE_PRICE : e.MRP,
              };
              return da;
            });
          });
          setItemMasterList(() => {
            const d = data;
            return d.map((e) => {
              const da = {
                ...e,
                CODE: stationId,
                STATION_NAME: stationName,
                SALE_PRICE: e.SALE_PRICE ? e.SALE_PRICE : e.MRP,
              };
              return da;
            });
          });
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const width = useSelector(selectWidth);
  const navigate = useNavigate();
  const editCell = (e) => {
    if (stationId) {
      if (e.field === 'MRP') {
        setItemMasterList((i) => {
          return i.map((item) => {
            if (item.ICODE === e.id) {
              return {
                ...item,
                MRP: e.value,
              };
            } else {
              return item;
            }
          });
        });
        setSaveCartList((p) =>
          p.map((it) => {
            if (it.ICODE === e.id) {
              return {
                ...it,
                MRP: e.value,
              };
            } else {
              return it;
            }
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please select station first',
        })
      );
    }
  };

  const handleCheckboxChange = (e, p) => {
    if (stationId) {
      if (e.target.checked) {
        let filteredList = saveCartList[0]
          ? saveCartList.filter((i) => i.ICODE !== p.ICODE)
          : [];
        filteredList.push({
          CODE: stationId,
          STATION_NAME: stationName,
          ICODE: p.ICODE,
          ITEMNAME: p.ITEMNAME,
          REGIONAL_INAME: p.REGIONAL_INAME,
          SNAME: p.SNAME,
          MRP: p.MRP,
          SALE_PRICE: p.SALE_PRICE,
          TAX1_RATE: p.TAX1_RATE,
          TAX2_RATE: p.TAX2_RATE,
          TAX3_RATE: p.TAX3_RATE,
          HSN_CODE: p.HSN_CODE,
        });
        setSaveCartList(filteredList);
      } else {
        setSaveCartList((g) => g.filter((s) => s.ICODE !== p.ICODE));
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please select station first',
        })
      );
    }
  };

  const saveStationItemMaster = () => {
    if (saveCartList[0]) {
      dispatch(setSpinnerLoading('Saving'));
      CallAPI(
        '/api/issueMasterRoute/createStationItemMaster',
        { table_prefix: prefix, cartItems: saveCartList },
        token,
        (e) => {
          if (!e.data.error) {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Data Updated',
              })
            );
          }
        },
        dispatch,
        () => {
          setSaveCartList([]);
          setItemMasterList([]);
          setStationId('');
          setStationName('');
          dispatch(setSpinnerLoading(false));
          setSelectAll(false);
        }
      );
    }
  };
  const columns = ItemMasterColumns(
    width,
    handleCheckboxChange,
    saveCartList,
    selectAll,
    setSelectAll
  );

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.ICODE.toString().includes(searchText);
        } else {
          return item.ITEMNAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setItemMasterList(searchItems);
    } else {
      setItemMasterList(fullList);
    }
  }, [searchText]);
  useEffect(() => {
    if (selectAll) {
      setSaveCartList(
        itemMasterList.map((i) => {
          const d = {
            ...i,
            CODE: stationId,
            STATION_NAME: stationName,
            SALE_PRICE: i.SALE_PRICE ? i.SALE_PRICE : i.MRP,
          };
          return d;
        })
      );

      setItemMasterList((p) => {
        return p.map((i) => {
          const d = {
            ...i,
            CODE: stationId,
            STATION_NAME: stationName,
            SALE_PRICE: i.SALE_PRICE ? i.SALE_PRICE : i.MRP,
          };
          return d;
        });
      });
    } else {
      if (stationId) {
        getData();
      }
    }
  }, [selectAll, stationId]);
  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7] p-8 overflow-y-scroll">
      <div className="text-sm  rounded-sm  h-full">
        <div className="bg-white   ">
          <ModalHeaderStyle
            header={'Station Item Master'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>
        <div
          style={{
            height: `calc(100% - 100px)`,
          }}
          className="    bg-white px-5 pt-4  flex flex-col  "
        >
          <GeniustextInput
            type="text"
            search={() => {
              dispatch(setListStationMasterModalStatus(true));
            }}
            title={'SID'}
            backgroundColor={'#f1efeb'}
            value={stationName}
            secondText={{
              value: stationId,
              backgroundColor: '#f7dda4',
              width: '75px',
            }}
            width={'250px'}
            align={'left'}
          />
          <div
            style={{
              height: `calc(100% - 74px`,
              width:
                columns[0].width +
                columns[1].width +
                columns[2].width +
                columns[3].width +
                50,
            }}
            className="w-fit"
          >
            <GeniusDatagrid
              list={itemMasterList}
              columns={columns}
              rowID={(w) => w.ICODE}
              editCell={editCell}
            />
          </div>
          <div
            style={{
              width:
                columns[0].width +
                columns[1].width +
                columns[2].width +
                columns[3].width +
                50,
            }}
            className="flex py-3 justify-between"
          >
            <div
              style={{
                border: '1px solid #b5b3b3',
                width: columns[0].width + columns[1].width / 2,
              }}
              className="rounded-sm overflow-hidden px-2"
            >
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search"
                type="text"
                className="py-1 outline-none"
              />
            </div>
            <div className=" bg-white ">
              <SaveButton onClick={saveStationItemMaster} />
              <UndoButton
                onClick={() => {
                  setSaveCartList([]);
                  setItemMasterList([]);
                  setStationId('');
                  setStationName('');
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ModListStationMaster
        onRowClick={(e) => {
          setStationId(e.row.CODE);
          setStationName(e.row.STATION_NAME);
          dispatch(setListStationMasterModalStatus(false));
          setSelectAll(false);
        }}
      />
    </div>
  );
};

export default StationItemMaster;
