import { Button } from '@mui/material';
import React from 'react';
import { DownloadFile } from '../../utility/functions/firebase';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectPrefix } from '../../utility/Redux/profile';

const ItemBox = ({ item, itemNameStyle, onClick, index }) => {
  const [firbaseImg, setImg] = useState('');
  const prefix = useSelector(selectPrefix);

  const icode = item.ICODE;
  const path = `rootbilling/items/${prefix + icode}`;
  if (!firbaseImg) {
    DownloadFile(path)
      .then((e) => {
        if (!e.includes('FirebaseError')) {
          setImg(e);
        } else {
          setImg('ERROR');
        }
      })
      .catch((e) => {
        // setCapturedImage('');
        setImg('ERROR');
      });
  }

  return (
    <div>
      {' '}
      <Button
        variant="contained"
        onClick={onClick}
        style={itemNameStyle}
        className="text-[#4a4a4a] flex justify-start  text-sm pl-2 pt-2 text-left hover:font-bold"
      >
        <div className="h-full w-full p-1 px-2">
          <div className="flex text-[13px] font-semibold">{item.ITEMNAME}</div>
          <div className="flex justify-between">
            <div>{item.REGIONAL_INAME ? item.REGIONAL_INAME : ''}</div>
            {firbaseImg && firbaseImg !== 'ERROR' ? (
              <img
                alt=""
                src={firbaseImg}
                style={{
                  width: '30px',
                  height: '30px',
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Button>
    </div>
  );
};

export default ItemBox;
