import React, { useEffect } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import { selectWidth } from '../utility/Redux/profile';
import { useSelector } from 'react-redux';

const AddressInformation = ({
  address,
  setAddress,
  selectedState,
  stateList,
  cityList,
  handleCountryChange,
  countries,
  pinCode,
  setPinCode,
  handleStateChange,
  city,
  setCity,
  selectedCountry,
  stop,
  setStop,
  currencyName,
  currencySymbol,
  setCurrencyName,
  setCurrencySymbol,
  setTabScreen,
  formRef,
  addressRef,
}) => {
  const windowWidth = useSelector(selectWidth);

  useEffect(() => {
    if (formRef) {
      const inputFields = formRef.current.querySelectorAll('input, select');

      // Add a keydown event listener to each input and select field
      inputFields.forEach((field, index) => {
        field.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            const nextIndex = (index + 1) % inputFields.length;
            if (index === 7) {
              setTabScreen(1);
            } else {
              inputFields[nextIndex].focus();
            }
          }
        });
      });

      // Cleanup event listeners on unmount
      return () => {
        inputFields.forEach((field) => {
          field.removeEventListener('keydown', () => {});
        });
      };
    }
  }, []);

  return (
    <div
      style={{
        width: windowWidth > 764 ? '810px' : '355px',
      }}
      ref={formRef}
    >
      <div className="flex mt-4">
        <GeniustextInput
          title={'Address'}
          width={'810px'}
          type={'text'}
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          refrance={addressRef}
          placeholder={'Required'}
          autofocus={true}
        />
      </div>
      <div className=" flex">
        <GeniustextInput
          title={'Country'}
          width={windowWidth >= 768 ? '401px' : '150px'}
          option={countries}
          value={selectedCountry}
          onChange={handleCountryChange}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'State'}
            width={windowWidth >= 768 ? '401px' : '150px'}
            value={selectedState}
            option={stateList}
            onChange={handleStateChange}
          />
        </div>
      </div>
      <div className=" flex">
        <GeniustextInput
          title={'City'}
          width={windowWidth >= 768 ? '401px' : '150px'}
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          option={cityList}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Pin-Code'}
            width={windowWidth > 764 ? '401px' : '150px'}
            type={'text'}
            value={pinCode}
            onChange={(e) => {
              setPinCode(e.target.value);
            }}
          />
        </div>
      </div>
      <div className=" flex">
        <GeniustextInput
          title={'Currency Name'}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'text'}
          value={currencyName}
          onChange={(e) => {
            setCurrencyName(e.target.value);
          }}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Currency Symbol'}
            width={windowWidth > 764 ? '401px' : '150px'}
            type={'text'}
            value={currencySymbol}
            onChange={(e) => {
              setCurrencySymbol(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Stop'}
          width={windowWidth >= 768 ? '401px' : '150px'}
          option={[
            {
              name: 'NO',
            },
            {
              name: 'YES',
            },
          ]}
          value={stop}
          onChange={(e) => {
            setStop(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default AddressInformation;
