import React, { useEffect, useState } from 'react';
import GeniustextInput from '../utility/component/mastertext';

import { Button } from '@mui/material';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { ModuleMasterColumns } from './columns';
import { useDispatch, useSelector } from 'react-redux';
import { selectWidth } from '../utility/Redux/profile';
import {
  DeleteModuleMasetr,
  ModuleMasetrHelp,
  SaveModuleMaster,
} from './function';
import { selectToken } from '../utility/Redux/security';
import {
  setAlertWithTitle,
  setModCompanyHelpStatus,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import ModCompanyHelp from '../utility/component/modals/modCompanyHelp';
import { Close } from '@mui/icons-material';

const ModuleMaster = () => {
  const [tabName, settabName] = useState('Masters');
  const [moduleName, setModulName] = useState('');
  const [oldModuleName, setOldModulName] = useState('');
  const [oldTabName, setOldTabName] = useState('');
  const [moduleList, setModulList] = useState([]);
  const [moduleID, setModuleID] = useState('');
  const [fullModuleList, setFullModuleList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [moduleStatus, setModuleStatus] = useState('NO');
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [allCompanyList, setAllCompanyList] = useState('');
  const width = useSelector(selectWidth);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const ModuleColumns = ModuleMasterColumns(width);

  useEffect(() => {
    ModuleMasetrHelp(
      token,
      (e) => {
        if (!e.data.error) {
          const data = e.data.response;

          setModulList(data);
          setFullModuleList(data);
        }
      },
      dispatch,
      () => {},
      (e) => console.log(e)
    );
  }, []);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullModuleList.filter((item) => {
        if (!regex.test(searchText.substring(0, 1))) {
          return item.MODULE_NAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });

      setModulList(searchItems);
    } else {
      setModulList(fullModuleList);
    }
  }, [searchText]);

  const navigate = useNavigate();
  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7] p-4 ">
      <div className="text-sm  rounded-sm  h-full">
        <div className="bg-white rounded-t-sm  ">
          <ModalHeaderStyle
            header={'Module Master'}
            onClose={() => {
              navigate('/settings');
            }}
          />
        </div>
        <div
          style={{
            height: `calc(100% - 55px)`,
          }}
          className="  flex flex-col  "
        >
          <div className="flex bg-white p-3">
            <div>
              <GeniustextInput
                title={'Tab Name'}
                value={tabName}
                width={'500px'}
                option={[
                  {
                    name: 'Masters',
                    value: 'Masters',
                  },
                  {
                    name: 'Reports',
                    value: 'Reports',
                  },
                  {
                    name: 'Summary',
                    value: 'Summary',
                  },
                  {
                    name: 'Updation',
                    value: 'Updation',
                  },
                  {
                    name: 'Manufacturing',
                    value: 'Manufacturing',
                  },
                  {
                    name: 'User List',
                    value: 'User List',
                  },
                  {
                    name: 'Billing',
                    value: 'Billing',
                  },
                  {
                    name: 'Issue Stock',
                    value: 'Issue Stock',
                  },
                  {
                    name: 'Company Master',
                    value: 'Company Master',
                  },
                  {
                    name: 'Payments',
                    value: 'Payments',
                  },
                ]}
                titleBold={600}
                onChange={(p) => {
                  settabName(p.target.value);
                }}
              />
              <GeniustextInput
                title={'Module Name'}
                value={moduleName}
                type={'text'}
                width={'500px'}
                titleBold={600}
                onChange={(p) => {
                  setModulName(p.target.value);
                }}
              />

              <div className="flex">
                <GeniustextInput
                  title={'Module Status'}
                  value={moduleStatus}
                  width={'250px'}
                  option={[
                    {
                      name: 'NO',
                      value: 'NO',
                    },
                    {
                      name: 'YES',
                      value: 'YES',
                    },
                  ]}
                  onChange={(e) => {
                    setModuleStatus(e.target.value);
                  }}
                  titleBold={600}
                />
                <div className="ml-2 flex items-center">
                  <GeniustextInput
                    title={'Company Email'}
                    value={companyEmail}
                    type={'text'}
                    width={'240px'}
                    search={() => {
                      dispatch(setModCompanyHelpStatus(true));
                    }}
                    titleBold={600}
                  />
                  {companyEmail && (
                    <div className="mt-[18px] text-red-600  ">
                      <Close
                        fontSize={'small'}
                        onClick={() => {
                          setCompanyEmail('');
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col ml-3 mt-[29px]">
              <div>
                <Button
                  // ref={updateRef}
                  onClick={() => {
                    if (tabName && moduleName) {
                      const data = {
                        ID: moduleID,
                        TAB_NAME: tabName,
                        MODULE_NAME: moduleName,
                        ALLOW_YN: moduleStatus,
                        COMPANY_EMAIL: companyEmail,
                        OLD_TAB_NAME: oldTabName,
                        OLD_MODULE_NAME: oldModuleName,
                      };
                      dispatch(setSpinnerLoading('Saving'));
                      SaveModuleMaster(
                        data,
                        token,
                        (e) => {
                          if (e.status === 200) {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Success',
                                msg: e.data.message,
                                lottie: 'success',
                              })
                            );
                            setModulName('');
                            setModuleID('');
                            setCompanyEmail('');
                            setModuleStatus('NO');
                            ModuleMasetrHelp(
                              token,
                              (e) => {
                                if (!e.data.error) {
                                  const data = e.data.response;

                                  setModulList(data);
                                  setFullModuleList(data);
                                }
                              },
                              dispatch,
                              () => {
                                setOldModulName('');
                                setOldTabName('');
                              },
                              (e) => console.log(e)
                            );
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Error',
                                msg: 'Something went wrong',
                              })
                            );
                          }
                        },
                        dispatch,
                        () => {
                          dispatch(setSpinnerLoading(false));
                        },
                        (e) => console.log(e)
                      );
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Please fil all details',
                        })
                      );
                    }
                  }}
                  variant="contained"
                  style={{
                    textTransform: 'none',
                    letterSpacing: '1px',
                    backgroundColor: '#a8cf45',
                    width: 110,
                  }}
                >
                  <div className="text-black px-4 font-sans">Save </div>
                </Button>
              </div>
              <div className="mt-2">
                <Button
                  // ref={updateRef}
                  variant="contained"
                  style={{
                    textTransform: 'none',
                    letterSpacing: '1px',
                    backgroundColor: '#383535',
                    width: 110,
                  }}
                >
                  <div className="text-white font-sans px-4  ">Undo </div>
                </Button>
              </div>
              <div className="mt-2">
                <Button
                  // ref={updateRef}
                  variant="contained"
                  style={{
                    textTransform: 'none',
                    letterSpacing: '1px',
                    backgroundColor: '#db3737',
                    width: 110,
                  }}
                  onClick={() => {
                    if (moduleID) {
                      const data = {
                        ID: moduleID,
                        TAB_NAME: tabName,
                        MODULE_NAME: moduleName,
                      };
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: true,
                        title: 'Alert',
                        msg: 'Are you sure you want to delete this Item ?',
                        button1: 'Yes',
                        button2: 'Cancel',

                        button1Click: () => {
                          dispatch(setSpinnerLoading('Deleting'));

                          DeleteModuleMasetr(
                            moduleID,
                            token,
                            (e) => {
                              console.log(e);
                              if (!e.data.error) {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Success',
                                    msg: 'Deleted Successfully',
                                    lottie: 'success',
                                  })
                                );

                                setModulName('');
                                setModuleID('');
                                ModuleMasetrHelp(
                                  token,
                                  (e) => {
                                    if (!e.data.error) {
                                      const data = e.data.response;

                                      setModulList(data);
                                      setFullModuleList(data);
                                    }
                                  },
                                  dispatch,
                                  () => {},
                                  (e) => console.log(e)
                                );
                              } else {
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Error',
                                    msg: 'Something went wrong',
                                  })
                                );
                              }
                            },
                            dispatch,
                            () => {
                              dispatch(setSpinnerLoading(false));
                            },
                            (e) => console.log(e)
                          );
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,
                            status: false,
                          });
                        },
                        button2Click: () => {
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,
                            status: false,
                          });
                        },
                      });
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Please select any data to delete.',
                        })
                      );
                    }
                  }}
                >
                  <div className="text-white px-4 font-sans">Delete </div>
                </Button>
              </div>
            </div>
          </div>
          <div
            style={{
              height: `calc(100% - 240px)`,
            }}
            className="bg-white p-2"
          >
            <GeniusDatagrid
              columns={ModuleColumns}
              list={moduleList}
              rowID={(r) => r.ID}
              onDoubleClick={(e) => {
                console.log(e);
                setModuleID(e.row.ID);
                settabName(e.row.TAB_NAME);
                setModulName(e.row.MODULE_NAME);
                setOldTabName(e.row.TAB_NAME);
                setOldModulName(e.row.MODULE_NAME);
              }}
            />
          </div>
          <div className="px-2 pb-2 bg-white rounded-b-sm">
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="rounded-b-sm p-1 bg-white"
            >
              <input
                type="text"
                style={{
                  outline: 'none',
                  fontSize: '14px',
                }}
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
        </div>
        <ModCompanyHelp
          onRowClick={(p) => {
            dispatch(setModCompanyHelpStatus(false));
            setCompanyEmail(p.row.company_email);
          }}
        />
        <AlertModalTwoButton
          details={alertTwoButtonModal}
          setAlertTwoButtonModal={setAlertTwoButtonModal}
        />
      </div>
    </div>
  );
};

export default ModuleMaster;
