import { SaveButton, UndoButton } from '../../utility/component/buttons';
import { FormatNumber2 } from '../../utility/component/tools';

const ExpenseFooter = (props) => {
  const { rows, columns, handleSave, handleUndo } = props;
  const TotalAmountRecieved = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT_PAID),
    0
  );

  return (
    <div className="text-sm mt-2 w-full">
      <div
        className=" flex w-full  "
        style={{
          alignItems: 'center',
        }}
      >
        <div className="flex w-full justify-between">
          <div className="rounded-tl-sm">
            <div className="flex w-full justify-between items-center pr-3">
              <div>
                <SaveButton onClick={handleSave} />
                <UndoButton onClick={handleUndo} />
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="font-semibold flex items-center mr-2">Total</div>
            <div
              style={{
                border: '1px solid #f3f3f3',
                borderLeft: '1px solid #f3f3f3',
                width: columns[4].width,
              }}
              className="flex-1 flex justify-end items-center pr-3"
            >
              {FormatNumber2(TotalAmountRecieved)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExpenseFooter;
