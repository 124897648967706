// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database'; // Import the getDatabase function
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAbJG9ncx--2lRyGZwdcEWt8IL00p3m1bw',
  authDomain: 'genius-office.firebaseapp.com',
  projectId: 'genius-office',
  storageBucket: 'genius-office.appspot.com',
  messagingSenderId: '378785498497',
  appId: '1:378785498497:web:ebfe6c1115c0b081b71480',
  measurementId: 'G-DJBSL9DK35',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
signInAnonymously(auth)
  .then(() => {
    console.log('sign');
  })
  .catch((error) => {
    console.log(error);
    // ...
  });
onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    console.log('signin');
    // ...
  } else {
    // User is signed out
    // ...
    console.log('signout');
  }
});

const database = getDatabase(app);

export default database;
