import React from 'react';
import GeniusError from './styles/error';
import { useState } from 'react';
import { Checkbox } from '@mui/material';
import GeniusSelectDate from './selectDate';
import { Close } from '@mui/icons-material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const GeniustextInput = ({
  title,
  fromDate,
  id,
  titleAlign,
  readOnly,
  reset,
  onKeyDown,
  setFromDate,
  fromDateFocus,
  setFromDateFocus,
  search,
  type,
  option,
  width,
  SecondTitle,
  max,
  value,
  titleBold,
  onFocus,
  date,
  backgroundColor,
  onChange,
  error,
  refrance,
  onClick,
  hideError,
  country,
  color,
  titleColor,
  checkBox,
  placeholder,
  autofocus,
  checked,
  hideHeader,
  secondText,
  fontBold,
  align,
  iconAlign,
  textArea,
}) => {
  const [border, setBorder] = useState('#b5b3b3');
  const phoneRegex = /^[6-9]\d{9}$/;
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  // useEffect(() => {
  //   if (value) {
  //     if (error === 'mobile') {
  //       if (phoneRegex.test(value)) {
  //         setBorder('#43bff7');
  //       } else {
  //         setBorder('red');
  //       }
  //     } else {
  //       if (error === 'email') {
  //         if (emailRegex.test(value)) {
  //           setBorder('#43bff7');
  //         } else {
  //           setBorder('red');
  //         }
  //       } else {
  //         if (error) {
  //           setBorder('red');
  //         } else {
  //           setBorder('#43bff7');
  //         }
  //       }
  //     }
  //   } else {
  //     setBorder('#b5b3b3');
  //   }
  //   if (autofocus) {
  //     setBorder('#43bff7');
  //   }
  // }, [value]);

  return (
    <div className="text-[15px] ">
      {!titleAlign && (
        <div
          style={{
            height: !checkBox ? '24px' : 'auto',
            display: hideHeader ? 'none' : 'flex',
          }}
          className="flex  justify-between "
        >
          <div className="flex items-center w-fit  ">
            {title ? (
              <div
                style={{
                  fontWeight: titleBold ? 700 : 500,
                  color: titleColor ? titleColor : 'black',
                }}
              >
                {' '}
                {title}
              </div>
            ) : (
              '   '
            )}
            {search && (
              <button onClick={search}>
                <i className="bi bi-search text-orange ml-2 flex items-center"></i>
              </button>
            )}
          </div>
          {SecondTitle && <SecondTitle />}
        </div>
      )}
      <div className="flex items-center">
        {titleAlign && titleAlign === 'left' && (
          <div
            style={{
              height: !checkBox ? '24px' : 'auto',
              display: hideHeader ? 'none' : 'flex',
            }}
            className="flex  justify-between  mr-3"
          >
            <div className="flex items-center w-fit  ">
              {title ? (
                <div
                  style={{
                    fontWeight: titleBold ? 600 : 500,
                    color: titleColor ? titleColor : 'black',
                  }}
                >
                  {' '}
                  {title}
                </div>
              ) : (
                '   '
              )}
            </div>
            {SecondTitle && <SecondTitle />}
          </div>
        )}
        <div
          style={
            checkBox || date
              ? {
                  border: `0px solid ${border}`,
                  boxShadow: border === '#43bff7' && '0px 0px 0px 0px #cbeaf7',
                  padding: '0px',
                  width: date ? width : 'auto',
                }
              : {
                  border: `1px solid ${border}`,
                  width: width,
                  boxShadow: border === '#43bff7' && '0px 0px 0px 1px #cbeaf7',
                  backgroundColor: backgroundColor ? backgroundColor : 'white',
                }
          }
          className="rounded-sm overflow-hidden   "
        >
          {type && (
            <div className="flex items-center">
              {secondText && (
                <input
                  type={'text'}
                  readOnly={true}
                  style={{
                    outline: 'none',
                    color: '#4a4a4a',
                    borderRight: '1px solid #b5b3b3',
                    paddingLeft: '6px',
                    backgroundColor: secondText.backgroundColor
                      ? secondText.backgroundColor
                      : 'white',
                    fontWeight: fontBold ? fontBold : 500,
                    width: secondText.width ? secondText.width : '60px',
                  }}
                  onBlur={() => {
                    if (error === 'mobile' || error === 'email' || !error) {
                      if (error === 'mobile') {
                        if (value) {
                          if (phoneRegex.test(value)) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        if (error === 'email') {
                          if (value) {
                            if (emailRegex.test(value)) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          if (value) {
                            if (!error) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        }
                      }
                    }
                  }}
                  className="w-[50px]  h-full py-[11px] texrt[15px]"
                  value={secondText.value}
                />
              )}

              {type !== 'countryMobile' ? (
                <input
                  id={id ? id : ''}
                  type={type}
                  readOnly={readOnly ? true : false}
                  style={{
                    outline: 'none',
                    color: '#4a4a4a',
                    backgroundColor: backgroundColor
                      ? backgroundColor
                      : '#fdfffe',
                    width: '100%',
                    textAlign: align ? align : 'left',
                    fontWeight: fontBold ? fontBold : 500,
                  }}
                  ref={refrance}
                  onClick={onClick}
                  onFocus={
                    onFocus
                      ? onFocus
                      : () => {
                          if (
                            !error ||
                            error === 'mobile' ||
                            error === 'email'
                          ) {
                            setBorder('#43bff7');
                          }
                        }
                  }
                  onBlur={() => {
                    if (error === 'mobile' || error === 'email' || !error) {
                      if (error === 'mobile') {
                        if (value) {
                          if (phoneRegex.test(value)) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        if (error === 'email') {
                          if (value) {
                            if (emailRegex.test(value)) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          if (value) {
                            if (!error) {
                              setBorder('#b5b3b3');
                            }
                          } else {
                            setBorder('#b5b3b3');
                          }
                        }
                      }
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                      event.preventDefault();
                    }
                    if (onKeyDown) {
                      onKeyDown(event);
                    }
                  }}
                  value={value}
                  max={max ? max : 100}
                  maxLength={max ? max : 100}
                  onChange={(e) => {
                    if (max && type === 'number') {
                      if (e.target.value.length <= max) {
                        onChange(e);
                      }
                    } else {
                      onChange(e);
                    }
                  }}
                  placeholder={placeholder}
                  autoFocus={autofocus}
                  className="p-2 outline-none  texrt[15px]"
                />
              ) : (
                <PhoneInput
                  country={'in'} // Default country
                  containerStyle={{
                    backgroundColor: 'pink',
                    width: '200px',
                  }}
                  inputStyle={{
                    width: '290px',
                    backgroundColor: '#f1efeb',
                    height: '40px',
                  }}
                  value={value}
                  onChange={onChange}
                  enableSearch={true} // Enable search for countries
                  disableSearchIcon={true} // Optional: disable the search icon
                />
              )}
              {reset && value && (
                <div className="mr-1">
                  <Close
                    fontSize={'small'}
                    sx={{
                      color: 'red',
                    }}
                    onClick={reset}
                  />{' '}
                </div>
              )}
            </div>
          )}
          {textArea && (
            <div className="flex items-center">
              {' '}
              <textarea
                type={type}
                readOnly={readOnly ? true : false}
                rows={4}
                style={{
                  outline: 'none',
                  color: '#4a4a4a',
                  backgroundColor: backgroundColor ? backgroundColor : 'white',
                  fontWeight: fontBold ? fontBold : 500,
                  width: '100%',
                  textAlign: align ? align : 'left',
                }}
                ref={refrance}
                onClick={onClick}
                onFocus={
                  onFocus
                    ? onFocus
                    : () => {
                        if (!error || error === 'mobile' || error === 'email') {
                          setBorder('#03A9F4');
                        }
                      }
                }
                onBlur={() => {
                  if (error === 'mobile' || error === 'email' || !error) {
                    if (error === 'mobile') {
                      if (value) {
                        if (phoneRegex.test(value)) {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        setBorder('#b5b3b3');
                      }
                    } else {
                      if (error === 'email') {
                        if (value) {
                          if (emailRegex.test(value)) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      } else {
                        if (value) {
                          if (!error) {
                            setBorder('#b5b3b3');
                          }
                        } else {
                          setBorder('#b5b3b3');
                        }
                      }
                    }
                  }
                }}
                value={value}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
                autoFocus={autofocus}
                className="p-2"
              />{' '}
              {reset && value && (
                <div className="mr-1">
                  {' '}
                  <Close
                    fontSize={'small'}
                    sx={{ color: 'red' }}
                    onClick={reset}
                  />{' '}
                </div>
              )}{' '}
            </div>
          )}
          {option && !country ? (
            <div className="p-2">
              <select
                value={value}
                onChange={onChange}
                className="texrt[15px]"
                style={{
                  fontSize: window.innerWidth <= 1280 ? '16px' : '15px',
                  outline: 'none',
                  width: '100%',
                  backgroundColor: backgroundColor
                    ? backgroundColor
                    : '#fdfffe',
                  textAlign: 'left',
                }}
              >
                {option.map((e, i) => {
                  return (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : null}
          {country && (
            <div className="p-2">
              <select
                className=""
                value={value}
                onChange={onChange}
                style={{
                  fontSize: window.innerWidth <= 1280 ? '16px' : '15px',
                  outline: 'none',
                  width: '100%',
                  backgroundColor: backgroundColor
                    ? backgroundColor
                    : '#fdfffe',
                  textAlign: 'left',
                }}
              >
                {option.map((e, k) => {
                  return (
                    <option key={k} value={e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          )}

          {date && (
            <div>
              <GeniusSelectDate
                date={fromDate}
                setDate={setFromDate}
                dateFocus={fromDateFocus}
                SetDateFocus={setFromDateFocus}
              />
            </div>
          )}

          {checkBox ? (
            <Checkbox
              size="small"
              sx={{ border: '0px' }}
              checked={checked}
              onChange={onChange}
              color={color ? 'primary' : 'secondary'}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ) : null}
        </div>
        {iconAlign && iconAlign === 'right' && search && (
          <button onClick={search}>
            <i className="bi bi-search text-orange ml-2 flex items-center"></i>
          </button>
        )}
      </div>

      {!hideError ? (
        <GeniusError
          error={
            error === 'mobile'
              ? !value || phoneRegex.test(value)
                ? false
                : 'Invalid mobile'
              : error === 'email'
              ? !value || emailRegex.test(value)
                ? false
                : 'Invalid Email'
              : error
          }
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default GeniustextInput;
