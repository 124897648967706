import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSaleSummaryList,
  setSaleSummaryList,
} from '../../utility/Redux/list';
import Lottie from 'lottie-react';
import { TotalFooter } from '../../utility/component/datagrid/fotter';
import { selectToken } from '../../utility/Redux/security';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserCode,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setStationID,
  setStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../../utility/Redux/profile';

import { setReportType } from '../../utility/Redux/refresh';

import {
  SaleSummaryColumns,
  TabSummaryColumns,
} from '../../utility/columns/saleSummaryCol';
import { getSaleSummaryDetails } from '../../utility/list/saleSummaryList';

import {
  selectCollectionAccountDetails,
  setCollectionAccountDetails,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
  setviewBillModalStatus,
} from '../../utility/Redux/modal';
import ViewBillModal from '../../utility/component/modals/modBillInvoice';
import { setViewBillInvoiceId } from '../../utility/Redux/tableOrder';
import { selectStatus } from '../../utility/Redux/modal';

import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { FromToDate } from '../../utility/component/fromToDate';
import ModPartyMasterHelp from '../../master/partyMasterHelp';
import GeniusReportsDate from '../../utility/component/reportDate';
import moment from 'moment';
import { Skeleton } from '@mui/material';
import GeniusTableReports from '../../utility/component/datagrid/geniusTableReports';
import HandleIndexedDB from '../../utility/functions/indexDbDatabase';

const Summary = () => {
  const firstDate = useSelector(selectreportFromDate);

  const SecondDate = useSelector(selectreportToDate);
  const userType = useSelector(selectUserType);
  const windowWidth = useSelector(selectWidth);
  const columns = SaleSummaryColumns(windowWidth);
  const accountDetails = useSelector(selectCollectionAccountDetails);
  const ReduxStation_id = useSelector(selectStationId);
  const ReduxStationName = useSelector(selectStationName);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const { accName, accAddress, accMobile, accNumber, stationId } =
    accountDetails;

  const usertype = useSelector(selectUserType);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const dispatch = useDispatch();
  const summaryList = useSelector(selectSaleSummaryList);
  console.log(summaryList);
  const loading = useSelector(selectStatus);
  const userCode = useSelector(selectUserCode);
  const okButton = () => {
    getSaleSummaryDetails(
      dispatch,
      prefix,
      firstDate,
      SecondDate,
      accNumber,
      token,
      usertype,
      ReduxStation_id,
      userCode,
      callback
    );
    dispatch(setviewBillModalStatus(false));
  };
  const callback = (rowList) => {
    const databaseName = 'Sale-Summary';
    const storeName = 'Data';

    const dataToSave = rowList;
    HandleIndexedDB({
      databaseName,
      storeName,
      dataToSave,
      callback: () => {
        const storeName = 'Details';
        const dataToSave = [
          {
            SID: ReduxStation_id,
            SNAME: ReduxStationName,
            FDATE: moment(firstDate).format('YYYY-MM-DD'),
            TDATE: moment(SecondDate).format('YYYY-MM-DD'),
            accName: accName,
            accAddress: accAddress,
            accMobile: accMobile,
            accNumber: accNumber,
            stationId: stationId,
            ICODE: 1,
            USERCODE: userCode,
          },
        ];
        HandleIndexedDB({
          databaseName,
          storeName,
          dataToSave,
        });
      },
    });
  };
  useEffect(() => {
    let db;
    let database = 'Sale-Summary';
    const request = indexedDB.open(database);
    request.onsuccess = function (event) {
      // Assign the opened database instance to the db variable
      db = event.target.result;
      if (db.objectStoreNames.length > 0) {
        const transaction = db.transaction(db.objectStoreNames, 'readonly');

        const objectStoreNames = transaction.objectStoreNames;
        db.close();
        const databaseName = 'Sale-Summary';
        const storeNamesArray = Array.from(objectStoreNames);
        const storeName = 'Data';
        if (storeNamesArray[0]) {
          HandleIndexedDB({
            databaseName,
            storeName,
            callback: (data) => {
              const storeName = 'Details';
              HandleIndexedDB({
                databaseName,
                storeName,
                callback: (d) => {
                  dispatch(setSaleSummaryList(data));
                  dispatch(setStationID(d[0].SID));
                  dispatch(setStationName(d[0].SNAME));
                  dispatch(
                    setreportsFromDate(moment(d[0].FDATE).format('YYYY-MM-DD'))
                  );
                  dispatch(
                    setreportsTodate(moment(d[0].TDATE).format('YYYY-MM-DD'))
                  );
                  dispatch(
                    setCollectionAccountDetails({
                      accName: d[0].accName,
                      accAddress: d[0].accAddress,
                      accMobile: d[0].accMobile,
                      accNumber: d[0].accNumber,
                      stationId: d[0].stationId,
                    })
                  );
                },
              });
            },
          });
        } else {
          dispatch(
            setCollectionAccountDetails({
              accName: '',
              accAddress: '',
              accMobile: '',
              accNumber: '',
              stationId: '',
            })
          );
          if (usertype !== 'USER') {
            dispatch(setStationID(''));
            dispatch(setStationName(''));
          }
          dispatch(setSaleSummaryList([]));
        }
      } else {
        db.close();
        dispatch(
          setCollectionAccountDetails({
            accName: '',
            accAddress: '',
            accMobile: '',
            accNumber: '',
            stationId: '',
          })
        );
        if (usertype !== 'USER') {
          dispatch(setStationID(''));
          dispatch(setStationName(''));
        }
        dispatch(setSaleSummaryList([]));
        const deleteRequest = indexedDB.deleteDatabase(database);

        deleteRequest.onsuccess = function () {
          console.log('Database deleted successfully');
        };

        deleteRequest.onerror = function (event) {
          console.error('Error deleting database:', event.target.error);
        };
        // Delete the database if no object stores are available
      }
    };

    dispatch(setReportType('saleSummary'));
  }, []);

  return (
    <div className=" bg-white flex flex-col h-full  overflow-y-scroll ">
      <div className=" flex flex-col h-full p-3 ">
        <div>
          {window.innerWidth <= 500 ? (
            <>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                }}
                className="p-1 py-3 rounded-t-sm  flex items-center "
              >
                <div className="px-1 ">From</div>

                <GeniusReportsDate
                  date={firstDate}
                  onChange={(e) => {
                    dispatch(
                      setreportsFromDate(moment(e).format('YYYY-MM-DD'))
                    );
                    setFromDateFocus(false);
                  }}
                  dateFocus={fromDateFocus}
                  SetDateFocus={setFromDateFocus}
                />
                <div className="px-1 pl-2 ">To</div>
                <GeniusReportsDate
                  date={SecondDate}
                  onChange={(e) => {
                    dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                    setToDateFocus(false);
                  }}
                  dateFocus={toDateFocus}
                  SetDateFocus={setToDateFocus}
                />
              </div>
              <div style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}>
                {userType === 'USER' ? null : (
                  <div className="flex  text-[#4B4545] p-2  ">
                    <div
                      onClick={() => {
                        if (userType === 'MANAGER') {
                          dispatch(setListStationMasterModalStatus(true));
                        }
                      }}
                      style={{ fontSize: 13 }}
                      className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                    >
                      <div className="flex items-center ">
                        <div>
                          <i
                            className="bi bi-search"
                            style={{ color: '#f44336', fontSize: 15 }}
                          ></i>
                        </div>

                        <span
                          style={{ fontSize: 12, fontWeight: 600 }}
                          className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                        >
                          Station ID
                        </span>
                      </div>

                      <div
                        style={{
                          border: '1px dotted #a6a2a2 ',
                          borderRadius: '3px',
                        }}
                        className="flex text-[#a6a2a2]  pl-2 ml-2  "
                      >
                        <input
                          size={'small'}
                          placeholder="All"
                          value={ReduxStation_id ? ReduxStation_id : 'All'}
                          style={{
                            // width: '20%',
                            fontSize: 12,
                            paddingTop: '2px',
                            paddingBottom: '2px',
                            backgroundColor: 'transparent',
                          }}
                          readOnly
                          className=" outline-0 w-6"
                        />

                        <div
                          style={{
                            borderLeft: '1px dotted #a6a2a2 ',
                          }}
                          className="px-2 p-1 w-52 whitespace-nowrap print:hidden"
                        >
                          {ReduxStationName ? `${ReduxStationName}` : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {userType === 'USER' ? (
                  <div
                    style={{
                      borderTop: '1px solid #a6a2a2 ',
                    }}
                    className="py-2"
                  >
                    <button
                      onClick={okButton}
                      className="flex items-center rounded-sm ml-2"
                      style={{
                        backgroundColor: '#c1c1c1',
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: 'black',
                      }}
                    >
                      <div className="flex items-center text-sm">Ok</div>
                    </button>
                  </div>
                ) : (
                  <div
                    style={
                      userType === 'MANAGER'
                        ? {
                            fontSize: 12,
                            width: '100%',
                            color: '#a6a2a2',
                            borderTop: '1px solid #a6a2a2 ',
                          }
                        : {
                            fontSize: 12,
                            width: '100%',
                            color: '#a6a2a2',
                          }
                    }
                    className=" flex whitespace-normal  p-2  justify-start items-center  "
                  >
                    {/* party name 1st part */}
                    <div className="flex  h-full items-center ">
                      <div
                        className="flex hover:cursor-pointer h-full items-center"
                        onClick={() =>
                          dispatch(setModPartyMasterHelpStatus(true))
                        }
                      >
                        <div>
                          <i
                            className="bi bi-search"
                            style={{ color: '#f44336', fontSize: 15 }}
                          ></i>
                        </div>

                        <span
                          style={{ fontSize: 12 }}
                          className=" text-[#5c5b5b]  pl-2 flex font-semibold items-center"
                        >
                          {' '}
                          Party{' '}
                          <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                            {' '}
                            Name{' '}
                          </span>
                        </span>
                      </div>

                      <div
                        style={{
                          border: '1px dotted #a6a2a2 ',
                          borderRadius: '3px',
                        }}
                        className="flex  w-20  pl-2 ml-2 "
                      >
                        <input
                          size={'small'}
                          placeholder="All"
                          value={accNumber ? accNumber : 'All'}
                          style={{
                            fontSize: 12,
                            paddingTop: '2px',
                            paddingBottom: '2px',
                            backgroundColor: 'transparent',
                          }}
                          readOnly
                          className=" outline-0 w-30 "
                        />
                      </div>
                      <div>
                        <button
                          onClick={okButton}
                          className="flex items-center rounded-sm ml-2"
                          style={{
                            backgroundColor: '#c1c1c1',
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 4,
                            paddingBottom: 4,
                            color: 'black',
                          }}
                        >
                          <div className="flex items-center text-sm">Ok</div>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <FromToDate
              reset={() => {
                if (userType === 'USER') {
                  dispatch(
                    setCollectionAccountDetails({
                      accName: '',
                      accAddress: '',
                      accMobile: '',
                      accNumber: '',
                      stationId: '',
                    })
                  );
                } else {
                  dispatch(setStationID(''));
                  dispatch(setStationName(''));
                  dispatch(
                    setCollectionAccountDetails({
                      accName: '',
                      accAddress: '',
                      accMobile: '',
                      accNumber: '',
                      stationId: '',
                    })
                  );
                }
              }}
              submit={okButton}
            />
          )}

          <ModPartyMasterHelp
            SID={ReduxStation_id}
            onRowClick={(params) => {
              const name = params.row.NAME;
              const mobile = params.row.MOBILE;
              const accNumber = params.row.ACCOUNT_NO;
              const address = params.row.ADDRESS;
              const stationId = params.row.SID;
              const stationName = params.row.SNAME;
              dispatch(
                setCollectionAccountDetails({
                  accName: name,
                  accAddress: address,
                  accMobile: mobile,
                  accNumber: accNumber,
                  stationId: stationId,
                  sName: stationName,
                })
              );
              dispatch(setModPartyMasterHelpStatus(false));
            }}
            onClose={() => {
              dispatch(setModPartyMasterHelpStatus(false));
            }}
          />
        </div>
        <ModListStationMaster
          onRowClick={(p) => {
            dispatch(setStationID(p.row.CODE));
            dispatch(setStationName(p.row.STATION_NAME));
            dispatch(setListStationMasterModalStatus(false));
          }}
        />
        <ViewBillModal />

        {loading ? (
          <div className="w-full ">
            <div className="flex w-full ">
              {columns.map((e, k) => {
                return (
                  <Skeleton
                    animation={'wave'}
                    key={k}
                    width={e.width ? e.width : 100}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {columns.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 100}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div
            style={{ height: '80%', width: '100%', marginTop: 10 }}
            className=" mb-2 "
          >
            {summaryList[0] ? (
              <GeniusTableReports
                columns={columns}
                data={summaryList}
                cellStyle={{
                  col: 'TOTAL_COLLECTION',
                  bgColor: '#EAECEF',
                }}
                rowBgColor={summaryList.filter((s) => s.BILL_STATUS === 'Void')}
                onRowDoubleClick={(p) => {
                  dispatch(setviewBillModalStatus(true));
                  dispatch(setViewBillInvoiceId(p.values.INVNO));
                }}
                footer={
                  <TotalFooter
                    rows={summaryList ? summaryList : []}
                    columns={columns}
                  />
                }
              />
            ) : (
              <div className="text-sm text-gray-500 flex justify-center items-center h-full border rounded-sm">
                No Records Found{' '}
                <span
                  onClick={() => {
                    okButton();
                  }}
                  className="text-orange ml-1  hover:cursor-pointer"
                >
                  Load data
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Summary;
