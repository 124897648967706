import { Button, Modal } from '@mui/material';
import React from 'react';

const ForgetModel = ({ forget, setForget, forgetPassword, email }) => {
  return (
    <div>
      {' '}
      <Modal open={forget} onClose={() => setForget(false)}>
        <div
          className="d:w-6/8 ml:w-11/12 xl:w-2/6 ms:w-11/12 md: l:w-3/6 bg-brown overflow-hidden rounded-lg t:w-4/6 w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start font-normal py-2 pl-3    d:bg-brown drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white py-3 ">
            <div className="flex  w-full px-5 pt-4   items-center ">
              <img
                src={require('../utility/images/ico.png')}
                className="mr-3 w-10 h-10 "
                alt=" "
              />
              <div>
                <div className="text-brown text-md  mb-4 ">
                  <div> Click Continue to reset your Password.</div>
                  <div>
                    Reset Password link has been sent on your Email :
                    <div className="font-semibold">{email}</div>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex  justify-end  bg-white pr-3 ">
              <Button
                size={'small'}
                onClick={() => {
                  forgetPassword(email);
                  setForget(false);
                }}
                color="secondary"
                style={{ marginRight: 4 }}
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Continue
                </div>
              </Button>

              <Button
                size={'small'}
                onClick={() => setForget(false)}
                color="secondary"
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Cancel
                </div>
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ForgetModel;
