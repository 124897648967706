import { Search } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormatNumber2 } from '../../../../../utility/component/tools';
import {
  selectSaleSummaryList,
  setSaleSummaryList,
} from '../../../../../utility/Redux/list';
import {
  setloading,
  setSessionExpired,
} from '../../../../../utility/Redux/modal';
import {
  selectPrefix,
  selectStationId,
} from '../../../../../utility/Redux/profile';
import { selectToken } from '../../../../../utility/Redux/security';
import { CallAPIPromise } from './comman';

const BillhistryComp = () => {
  const dispatch = useDispatch();
  const summaryList = useSelector(selectSaleSummaryList);
  const [searchText, SetSearchText] = useState('');
  const [filterdList, setFilteredList] = useState([]);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const stationId = useSelector(selectStationId);

  const columns = React.useMemo(
    () => [
      {
        field: 'INVNO',
        type: 'string',
        width: 70,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Bill No.'}
          </strong>
        ),
      },

      {
        field: 'DATE',
        type: 'string',
        width: 150,
        headerAlign: 'center',
        align: 'left',
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD'),
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Date'}
          </strong>
        ),
      },
      {
        field: 'NAME',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Party Name'}
          </strong>
        ),
      },
      {
        field: 'MOBILE',
        type: 'string',
        width: 150,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Mobile'}
          </strong>
        ),
      },

      {
        field: 'NET_AMOUNT',
        type: 'string',
        width: 120,
        headerAlign: 'right',
        headerClassName: 'super-app-theme--header',
        valueFormatter: (params) => FormatNumber2(params.value),
        align: 'right',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Net Amount'}
          </strong>
        ),
      },
    ],
    []
  );
  const mobileColoums = React.useMemo(
    () => [
      {
        field: 'INVNO',
        type: 'string',
        width: 60,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Bill No.'}
          </strong>
        ),
      },

      {
        field: 'DATE',
        type: 'string',
        width: 100,
        headerAlign: 'center',
        align: 'left',
        valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD'),
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Date'}
          </strong>
        ),
      },
      {
        field: 'NAME',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Party Name'}
          </strong>
        ),
      },
    ],
    []
  );

  const getSaleSummary = () => {
    dispatch(setloading(true));

    CallAPIPromise(
      `/api/reports/saleSummary`,
      {
        table_prefix: prefix,
        sid: stationId,
        from_date: moment().format('YYYY-MM-DD'),
        to_date: moment().format('YYYY-MM-DD'),
        account_no: '',
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            const data = res.data.response;
            let rowList = [];

            for (let index = 0; index < data.length; index++) {
              rowList.push({
                ...data[index],
                id: index + 1,
              });
            }
            dispatch(setSaleSummaryList(rowList));
          }
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getSaleSummary();
  }, []);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = summaryList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchText);
        } else {
          return item.NAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setFilteredList(searchItems);
    } else {
      setFilteredList('');
    }
  }, [searchText]);

  return (
    <div className="h-full  w-full p-3">
      <div style={{ height: '90%', width: '100%' }}>
        <DataGrid
          hideFooterPagination
          hideFooter
          scrollbarSize={0}
          sx={{
            backgroundColor: 'white',

            fontSize: 13,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
            },
          }}
          headerHeight={45}
          rowHeight={33}
          columns={window.innerWidth < 770 ? mobileColoums : columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          rows={filterdList ? filterdList : summaryList}
          experimentalFeatures={{ newEditingApi: true }}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          disableColumnMenu={true}
        />
      </div>
      <div className="text-sm grow mt-2 text-[#4a4646]  mb-2 w-full flex justify-between   items-center">
        <div
          style={{ borderWidth: '1px' }}
          className="flex items-center border-orange rounded-md  w-full p-1"
        >
          <Search style={{ color: '#f5874f' }} />
          <input
            type={'text'}
            value={searchText}
            style={{ fontSize: 13 }}
            className="w-full  outline-0 ml-1"
            placeholder="Search Items"
            onChange={(t) => {
              SetSearchText(t.target.value);
            }}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default BillhistryComp;
