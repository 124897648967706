import React from 'react';
import IssueTable from './printissueTable';
import './print.css';

const PrintIssueReport = React.forwardRef((props, ref) => {
  const {
    data,
    stationId,
    columns,
    stationName,
    date,
    mobile,
    truckNo,
    helperName,
    footer,
  } = props;

  const companyName = localStorage.getItem('company');
  const exactData = data.map((o) => {
    return {
      ...o,
      TotalIQnty: o.PRV_BAL + o.IQNTY,
      RAMOUNT: o.RQNTY * o.SALE_PRICE,
    };
  });
  return (
    <div ref={ref}>
      <div
        className="p-6"
        id="print-header"
        style={{
          width: '96%',
          border: '1px solid black',
          marginLeft: '2%',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <div style={{ width: '100%' }}>
            <p
              className="flex justify-center items-center font-semibold"
              style={{ fontSize: '20px' }}
            >
              {companyName}
            </p>
          </div>

          <div
            style={{ width: '100%', boxSizing: 'border-box' }}
            className="flex justify-center items-center mt-1"
          >
            <div className="flex ">
              <div className="font-semibold mx-1 flex">Date :</div>{' '}
              <div className="mx-1">{date}</div>
            </div>

            <div className="flex ml-3">
              <div className="font-semibold mx-1 flex">Station ID :</div>{' '}
              <div className="mx-1">{stationId + ','}</div>{' '}
              <div className="mx-1">{stationName}</div>
            </div>

            {mobile ? (
              <div className="flex ml-3">
                <div className="font-semibold mx-1">Mobile :</div>{' '}
                <div className="mx-1">{mobile}</div>
              </div>
            ) : null}

            {truckNo ? (
              <div className="flex ml-3">
                <div className="font-semibold mx-1">Truck No. :</div>{' '}
                <div className="mx-1">{truckNo}</div>
              </div>
            ) : null}

            {helperName ? (
              <div className="flex ml-3">
                <div className=" mx-1">[ {helperName} ]</div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        id="print-component"
        className="p-6"
        style={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
          marginTop: '130px',
        }}
      >
        <IssueTable data={exactData} columns={columns} />
        {footer}
      </div>
    </div>
  );
});

export default PrintIssueReport;
