import { FormatNumber2 } from '../tools';
const width = (window.innerWidth * 1) / 100;

export const DayEndFooter = (props) => {
  const { rows, columns } = props;

  const TotalCashAmt = rows.reduce(
    (total, row) => Number(total) + Number(row.cash_amount),
    0
  );

  const TotalCardAmt = rows.reduce(
    (total, row) => Number(total) + Number(row.card_amount),
    0
  );

  const TotalBtcAmt = rows.reduce(
    (total, row) => Number(total) + Number(row.btc_amount),
    0
  );

  const Totalcollection_amt = rows.reduce(
    (total, row) => Number(total) + Number(row.collection_amt),
    0
  );

  const TotalChequeAmt = rows.reduce(
    (total, row) => Number(total) + Number(row.cheque_amount),
    0
  );

  const Totalepayment = rows.reduce(
    (total, row) => Number(total) + Number(row.epayment),
    0
  );

  const Totaldiscount = rows.reduce(
    (total, row) => Number(total) + Number(row.discount),
    0
  );

  const TotalamountPaid = rows.reduce(
    (total, row) => Number(total) + Number(row.amount_paid),
    0
  );

  return (
    <div
      className=" flex bg-[#f5f0e8] rounded-b-sm overflow-hidden "
      style={{
        display: 'flex',
        borderTop: '1px solid #ded9d9',
        alignItems: 'center',
      }}
    >
      <div className="p:flex w-full hidden ">
        <div
          className="  text-[#f5f0e8]  text-right  pl-2    md:flex justify-between"
          style={{ borderRight: '1px solid #ded9d9', flex: 1 }}
        >
          {'desc'}
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 10 }}
          className="   items-center    flex justify-between "
        >
          <div className="pl-2">{''}</div>
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 9,
          }}
          className=" flex  text-[#555a66]     items-center justify-end  "
        >
          <div className="pr-3 py-[8px]">{''}</div>
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 6,
          }}
          className=" text-black  flex   items-center  "
        >
          <div className="pr-3 py-[8px] text-[#1b1c1f] flex grow  justify-end pl-3">
            Total
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 8 }}
          className="hidden bg-[#f5f0e8]   md:flex text-[#1c400a] items-center   justify-end "
        >
          <div className="pr-3 py-[8px]">
            {TotalCashAmt ? FormatNumber2(TotalCashAmt) : null}
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 8 }}
          className="hidden md:flex  bg-[#f5f0e8]    text-[#1c400a] items-center   justify-end  "
        >
          <div className="pr-3 py-[8px]">
            {TotalCardAmt ? FormatNumber2(TotalCardAmt) : null}
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 8 }}
          className=" bg-[#f5f0e8]    hidden  text-[#1c400a]  md:flex justify-end w-full items-center   text-right "
        >
          <div className="pr-3 py-[8px]">
            {TotalBtcAmt ? FormatNumber2(TotalBtcAmt) : null}
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 8 }}
          className=" bg-[#f5f0e8]    hidden    md:flex justify-end w-full items-center   text-right "
        >
          <div className="pr-3 py-[8px] bg-[#f5f0e8]    text-[#1c400a]">
            {Totalcollection_amt ? FormatNumber2(Totalcollection_amt) : null}
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 8 }}
          className=" bg-[#f5f0e8]    hidden  text-[#1c400a]  md:flex justify-end w-full items-center   text-right "
        >
          <div className="pr-3 py-[8px]">
            {TotalChequeAmt ? FormatNumber2(TotalChequeAmt) : null}
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 8 }}
          className=" bg-[#f5f0e8]    hidden  text-[#1c400a]  md:flex justify-end w-full items-center   text-right "
        >
          <div className="pr-3 py-[8px]">
            {Totalepayment ? FormatNumber2(Totalepayment) : null}
          </div>
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 6 }}
          className=" hidden bg-[#f5f0e8]    text-[#1b1c1f]  md:flex justify-end w-full items-center   text-right "
        >
          <div className="pr-3 py-[8px]">
            {Totaldiscount ? FormatNumber2(Totaldiscount) : null}
          </div>
        </div>

        <div
          style={{ width: width * 6 }}
          className=" hidden  bg-[#f5f0e8]    text-[#1b1c1f]  md:flex justify-end w-full items-center   text-right "
        >
          <div className="pr-3 py-[8px]">
            {TotalamountPaid ? FormatNumber2(TotalamountPaid) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
