import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectUserType,
  seledctAlowedpathlist,
} from '../../utility/Redux/profile';

const ManufacturingIndex = () => {
  const navigate = useNavigate();
  const list = [
    {
      name: 'Manufacturing',
      path: '',
      userType: 'MANAGER,ADMIN ,MANUFACTURING,USER',
      onclick: () => {
        navigate('/Manufacturing');
      },
    },
    {
      name: 'Manufacturing Summary',
      path: '',
      userType: 'MANAGER,ADMIN ,MANUFACTURING,USER',
      onclick: () => {
        navigate('/Manufacturing_Summary');
      },
    },
    {
      name: 'Order Booking',
      path: '',
      userType: 'MANAGER,STORE,USER',
      onclick: () => {
        navigate('/order_booking');
      },
    },
    {
      name: 'Mfg Balance Report',
      path: '',
      userType: 'MANAGER,STORE,USER',
      onclick: () => {
        navigate('/Mfg_balance_report');
      },
    },
    {
      name: 'Order Summary ',
      path: '',
      userType: 'MANAGER,STORE,USER',
      onclick: () => {
        navigate('/Order_summary');
      },
    },
  ];

  const userType = useSelector(selectUserType);
  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-2 t:grid-cols-3 d:grid-cols-6 ll:grid-cols-7 gap-3  ">
        {list[0] &&
          list.map((e, i) => {
            if (e.userType.includes(userType)) {
              return (
                <Button
                  key={i}
                  onClick={() => {
                    if (e.path) {
                      navigate(e.path);
                    } else {
                      e.onclick();
                    }
                  }}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#e9e9e5] py-5 w-full text-brown">
                    {e.name}
                  </div>
                </Button>
              );
            }
          })}
      </div>
    </div>
  );
};

export default ManufacturingIndex;
