import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';

import CryptoJS from 'crypto-js';
import Lottie from 'lottie-react';
import spinner from '../../../utility/json/Spinner.json';
import MapWithMarkerWithInfo from '../mapMarkerWithInfo';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';
import { useDispatch } from 'react-redux';

const width = window.innerWidth / 100;

const LoginRequest = () => {
  const [userdatagridLoading, setUserDatagridLoading] = useState(false);
  const [loginRequestList, setLoginRequestList] = useState([]);
  const [latitude, setlatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [sid, setsid] = useState('');
  const [userName, setUserName] = useState('');

  const columns = React.useMemo(
    () => [
      {
        field: 'user_email',
        type: 'string',
        width: width * 15,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Email'}</strong>,
      },
    ],
    []
  );
  const dispatch = useDispatch();
  const onRowClick = (param) => {
    setlatitude(param.row.latitude);
    setLongitude(param.row.longitude);
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const hmac = CryptoJS.HmacSHA256(param.row.user_email, secretPass);
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    CallAPIPromise(
      '/api/users/userInfo',
      {
        user_email: param.row.user_email,
        user_mobile: '',
      },
      hmacString,
      dispatch
    )
      .then((res) => {
        if (res.data.response[0]) {
          setsid(res.data.response[0].station_id);
          setUserName(res.data.response[0].user_name);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="h-full w-full flex   p-4">
      <div style={{ height: '100%' }} className="w-2/4">
        {userdatagridLoading ? (
          <div className=" w-full   flex justify-center items-center">
            <div className="w-10 h-10">
              <Lottie
                animationData={spinner}
                loop={true}
                style={{ fontSize: 15 }}
              />
            </div>
          </div>
        ) : (
          <DataGrid
            hideFooterPagination
            hideFooter
            getRowId={(d) => d.user_email}
            headerHeight={43}
            scrollbarSize={0}
            sx={{
              backgroundColor: 'white',

              fontSize: 13,
              '& .super-app-theme--header': {
                backgroundColor: '#E6E6E3',
              },
            }}
            rowHeight={38}
            columns={columns}
            onRowClick={onRowClick}
            rows={loginRequestList}
            // rows={data}
            pageSize={100}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        )}
      </div>
      <div className="w-1/2 ml-4">
        <MapWithMarkerWithInfo
          lng={longitude}
          lat={latitude}
          sid={sid}
          user={userName}
        />
      </div>
    </div>
  );
};

export default LoginRequest;
