import React from 'react';
import Modal from '@mui/material/Modal';

import logo from '../../images/icon.svg';
import { Alert1Button, Alert2Button } from '../buttons';

export default function AlertModalTwoButton({
  details,
  setAlertTwoButtonModal,
}) {
  const handleClose = () =>
    setAlertTwoButtonModal({
      title: '',
      msg: '',
      status: false,
      setStatus: '',
      button1: '',
      button1Click: '',
      button2: '',
      button2Click: '',
    });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    minWidth: '280px',
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: '7px',
    outline: 'none',
  };

  return (
    <div>
      <Modal open={details?.status}>
        <div style={style} className=" w-full ">
          <div className={'h-full w-full bg-white  rounded-sm  p-3'}>
            <div className=" flex justify-center w-full rounded-sm">
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: -30,

                  borderRadius: 100,
                }}
                className="bg-white p-[2px]"
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: '50px',
                    height: 'auto',
                  }}
                />
              </div>
            </div>

            <div className="w-full justify-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
              {details?.title}
            </div>
            <div
              style={{
                fontSize: '13px',
              }}
              className="w-full justify-center text-[#1c1a1a] flex mt-2 "
            >
              {details?.msg}
            </div>

            <div className="w-full flex justify-center mt-4">
              <Alert1Button
                text={details?.button1}
                onClick={details?.button1Click}
              />
              {details?.button2 ? (
                <Alert2Button
                  text={details?.button2 ? details?.button2 : 'cancel'}
                  onClick={
                    details?.button2Click ? details?.button2Click : handleClose
                  }
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
