import { Close } from '@mui/icons-material';
import React from 'react';
import DeleteItem from './deleteItem';
import { setAlertWithTitle } from '../../../utility/Redux/modal';

const MobileOrderColumns = (
  windowWidth,
  prefix,
  sid,
  token,
  icode,
  dispatch,
  setOrderDetails,
  date,
  setAlertTwoButtonModal,
  alertTwoButtonModal
) => {
  const c = [
    {
      field: 'ITEMNAME',
      type: 'string',
      width: 90,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Item Name'}</strong>
      ),
    },

    {
      field: 'ORDER_QNTY',
      type: 'string',
      width: 50,
      align: 'right',
      renderCell: (params) => {
        return <div className="font-semibold">{params.value}</div>;
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Qnty'}</strong>
      ),
    },

    {
      field: 'EMPLOYEE_NAME',
      type: 'string',
      width: 80,
      align: 'left',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div className="flex items-center justify-between  w-full">
            <div>{params.value}</div>

            <button
              onClick={() => {
                if (params.row.FNO === 0) {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: true,
                    title: 'Alert',
                    msg: 'Are you sure you want to delete this Item ?',
                    button1: 'Yes',
                    button2: 'Cancel',

                    button1Click: () => {
                      DeleteItem(
                        prefix,
                        sid,
                        token,
                        params.row.ICODE,
                        params.row.SERIAL_NO,
                        dispatch,
                        setOrderDetails,
                        date
                      );
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                  });
                } else {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: 'Items already isssued againt this order',
                    })
                  );
                }
              }}
            >
              <Close
                fontSize={'small'}
                style={{
                  color: 'red',
                }}
              />
            </button>
          </div>
        );
      },
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Employee'}</strong>
      ),
    },
  ];
  return c;
};

export default MobileOrderColumns;
