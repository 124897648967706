import React, { useRef, useState } from 'react';
import GeniusSelectDate from '../utility/component/selectDate';
import moment from 'moment';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { useEffect } from 'react';
import { GetStationReport } from './function';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { StationReportColumns, StationReportColumnsQntyWise } from './columns';
import { FormatNumber2 } from '../utility/component/tools';
import GeniusReportsDate from '../utility/component/reportDate';
import { selectReportType } from '../utility/Redux/refresh';
import ReactToPrint from 'react-to-print';
import GeniusTableForReports from '../utility/component/geniusComponents/geniusTableforReports';
import IsLocalData from '../utility/functions/ceckLocalData';

const StationBalanceReport = () => {
  // Date Properties
  const fromDate = useSelector(selectreportFromDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const toDate = useSelector(selectreportToDate);
  const [toDateFocus, setToDateFocus] = useState(false);
  const printRef = useRef();
  const [showType, setShowType] = useState(1);

  // Datagrid Properties
  const [list, setList] = useState([]);

  // redux Propertioes
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);

  // useEffect;
  const okButton = () => {
    GetStationReport(prefix, dispatch, token, setList, fromDate, toDate);
  };

  const columns =
    showType === 1
      ? StationReportColumns(windowWidth)
      : StationReportColumnsQntyWise(windowWidth);

  const totalCash = list.reduce(
    (total, row) => Number(total) + Number(row.CASH),
    0
  );
  const totalCollection = list.reduce(
    (total, row) => Number(total) + Number(row.COLLECTION),
    0
  );
  const totalDebit = list.reduce(
    (total, row) => Number(total) + Number(row.DEBIT),
    0
  );

  const totalBalcence = list.reduce(
    (total, row) => Number(total) + Number(row.CASH - row.EXPENSE),
    0
  );

  const totalDUE = list.reduce(
    (total, row) => Number(total) + Number(row.DEBIT - row.COLLECTION),
    0
  );

  const totalExpense = list.reduce(
    (total, row) => Number(total) + Number(row.EXPENSE),
    0
  );

  const totalLess = list.reduce(
    (total, row) => Number(total) + Number(row.LESS),
    0
  );

  const totalIqnty = list.reduce(
    (total, row) => Number(total) + Number(row.IQNTY),
    0
  );

  const totalRQnty = list.reduce(
    (total, row) => Number(total) + Number(row.RQNTY),
    0
  );

  const totalIAmount = list.reduce(
    (total, row) => Number(total) + Number(row.IAMOUNT),
    0
  );

  const totalRAmount = list.reduce(
    (total, row) => Number(total) + Number(row.RAMOUNT),
    0
  );
  const totalSqnty = list.reduce(
    (total, row) => Number(total) + Number(row.SQNTY),
    0
  );
  const totalSAmount = list.reduce(
    (total, row) => Number(total) + Number(row.SAMOUNT),
    0
  );

  useEffect(() => {
    const elseFunc = () => {};
    const callback = (details, data) => {
      setList(data);
      console.log(details);
      dispatch(
        setreportsFromDate(moment(details[0].FDATE).format('YYYY-MM-DD'))
      );
      dispatch(setreportsTodate(moment(details[0].TDATE).format('YYYY-MM-DD')));
    };
    IsLocalData('All_Stations_Balance_Report', callback, elseFunc);
  }, []);
  return (
    <div className="h-full w-full p-1 text-sm">
      <div
        style={{
          border: '1px solid #b5b3b3',
        }}
        className="p-1 py-[11px] rounded-sm flex items-center "
      >
        <div className="px-1 pl-3">From </div>

        <GeniusReportsDate
          date={fromDate}
          onChange={(d) => {
            dispatch(setreportsFromDate(moment(d).format('YYYY-MM-DD')));
            setFromDateFocus(false);
          }}
          dateFocus={fromDateFocus}
          SetDateFocus={setFromDateFocus}
        />
        <div className="mx-4">To</div>
        <GeniusReportsDate
          date={toDate}
          onChange={(d) => {
            dispatch(setreportsTodate(moment(d).format('YYYY-MM-DD')));
            setToDateFocus(false);
          }}
          dateFocus={toDateFocus}
          SetDateFocus={setToDateFocus}
        />
        <div className="flex">
          <button
            onClick={okButton}
            className="flex items-center rounded-sm ml-2"
            style={{
              backgroundColor: '#c1c1c1',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 4,
              paddingBottom: 4,
              color: 'black',
            }}
          >
            <div className="flex items-center text-sm">Ok</div>
          </button>

          <button
            onClick={() => {
              setShowType((o) => (o === 1 ? 2 : 1));
            }}
            className="flex items-center rounded-sm ml-2 bg-orange"
            style={{
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 4,
              paddingBottom: 4,
              color: 'black',
            }}
          >
            <div className="flex items-center text-sm">
              {' '}
              {showType === 1 ? 'Show Qnty Wise' : 'Full Report'}
            </div>
          </button>
        </div>
      </div>

      <div
        style={{
          height: `calc(100% - 120px)`,
        }}
        className="mt-2 w-full"
      >
        <GeniusDatagrid
          list={list}
          rowID={(r) => r.SID}
          columns={columns}
          dataGridHeader={true}
        />
        <div
          style={{
            border: '1px solid #b5b3b3',
          }}
          className=" flex rounded-sm mt-[8px] w-full "
        >
          <div className="w-full flex overflow-x-scroll  h-full ">
            {columns.map((i) => {
              return (
                <div
                  style={{
                    minWidth: `${i.width}px`,
                    borderRight: '1px solid #b5b3b3',
                    textAlign: i.align,
                  }}
                  className="p-1"
                >
                  {i.field === 'CASH' &&
                    totalCash > 0 &&
                    FormatNumber2(totalCash)}
                  {i.field === 'COLLECTION' &&
                    totalCollection > 0 &&
                    FormatNumber2(totalCollection)}
                  {i.field === 'DEBIT' &&
                    totalDebit > 0 &&
                    FormatNumber2(totalDebit)}
                  {i.field === 'DUE' && totalDUE && FormatNumber2(totalDUE)}
                  {i.field === 'EXPENSE' &&
                    totalExpense > 0 &&
                    FormatNumber2(totalExpense)}
                  {i.field === 'LESS' &&
                    totalLess > 0 &&
                    FormatNumber2(totalLess)}{' '}
                  {i.field === 'IQNTY' &&
                    totalIqnty > 0 &&
                    FormatNumber2(totalIqnty)}
                  {i.field === 'RQNTY' &&
                    totalRQnty > 0 &&
                    FormatNumber2(totalRQnty)}{' '}
                  {i.field === 'RAMOUNT' &&
                    totalRAmount > 0 &&
                    FormatNumber2(totalRAmount)}
                  {i.field === 'IAMOUNT' &&
                    totalIAmount > 0 &&
                    FormatNumber2(totalIAmount)}{' '}
                  {i.field === 'SQNTY' &&
                    totalSqnty > 0 &&
                    FormatNumber2(totalSqnty)}
                  {i.field === 'SAMOUNT' &&
                    totalSAmount > 0 &&
                    FormatNumber2(totalSAmount)}
                  {i.field === 'BALANCE' &&
                    totalBalcence > 0 &&
                    FormatNumber2(totalBalcence)}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        ref={printRef}
        style={{ width: '100%', display: 'block', visibility: 'hidden' }}
      >
        <GeniusTableForReports
          date={fromDate}
          date1={toDate}
          columns={columns}
          data={list}
          title={'Station Day End Reports'}
        />
      </div>
      <div
        style={
          window.innerWidth < 500
            ? {
                width: '50%',
                marginLeft: '5px',
              }
            : {
                width: '20%',
                marginLeft: '5px',
              }
        }
      >
        <ReactToPrint
          trigger={() => {
            return (
              <button
                style={{
                  width: '100%',
                  borderRadius: '7px',
                  height: 'auto',
                  padding: 10,
                  backgroundColor: '#bad4f9',
                  color: 'black',
                }}
              >
                Print Report
              </button>
            );
          }}
          content={() => printRef.current}
        />
      </div>
    </div>
  );
};

export default StationBalanceReport;
