import { Modal } from '@mui/material';
import React from 'react';
import {
  SelectStation_MasterStatus,
  setStation_MasterStatus,
} from '../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import ModalBody from '../utility/component/styles/modalBody';
import GeniusStationMaster from './geniusStattionmaster';

const Station_Master = () => {
  const dispatch = useDispatch();
  const status = useSelector(SelectStation_MasterStatus);

  const handleClose = () => {
    dispatch(setStation_MasterStatus(false));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: '2px solid black',
  };

  return (
    <div>
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={'rounded-lg overflow-hidden '}>
          <div className="w-full flex flex-col bg-white h-full ">
            <ModalHeaderStyle header={'Station Master'} onClose={handleClose} />

            <ModalBody>
              <GeniusStationMaster />
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Station_Master;
