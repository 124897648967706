import axios from 'axios';

import {
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import { CallAPIPromise } from '../../menu/dashboard/biiling/modal/comman';

export const GetItemList = async (
  setItemList,
  prefix,
  token,
  dispatch,
  sname,
  setFullItemList
) => {
  dispatch(setSpinnerLoading('Loading'));
  CallAPIPromise(
    '/api/issueMasterRoute/listItemMaster',
    {
      table_prefix: prefix,
      sname: sname,
    },
    token,
    dispatch
  )
    .then((p) => {
      setItemList(p.data.response);
      setFullItemList(p.data.response);
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
