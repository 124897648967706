export const PartyColumns = (windowWidth) => {
  return [
    {
      field: 'ACCOUNT_NO',
      accessor: 'ACCOUNT_NO',

      width: windowWidth <= 768 ? 80 : 80,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Acc. No.'}</strong>
      ),
    },
    {
      field: 'SID',
      accessor: 'SID',

      width: windowWidth <= 768 ? 50 : 50,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'SID'}</strong>
      ),
    },

    {
      accessor: 'NAME',
      field: 'NAME',
      width: windowWidth <= 768 ? 100 : windowWidth <= 1024 ? 100 : 230,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong
          style={{
            fontSize: 13,
          }}
        >
          {'Party Name'}
        </strong>
      ),
    },
    {
      accessor: 'MOBILE',
      field: 'MOBILE',
      width: windowWidth <= 768 ? 100 : 100,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Mobile'}</strong>
      ),
    },
    {
      accessor: 'ADDRESS',
      field: 'ADDRESS',
      width: windowWidth <= 768 ? 130 : windowWidth <= 1024 ? 130 : 200,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong
          style={{
            fontSize: 13,
          }}
        >
          {'Address'}
        </strong>
      ),
    },
    {
      accessor: 'CITY',
      field: 'CITY',
      width: windowWidth <= 768 ? 130 : windowWidth <= 1024 ? 130 : 130,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong
          style={{
            fontSize: 13,
          }}
        >
          {'City'}
        </strong>
      ),
    },
  ];
};
