import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectOrdersList,
  selectQntyEditIcode,
  setQntyEditModalStatus,
  updateOrderlist,
} from '../../../../../utility/Redux/tableOrder';
import { Add, Remove } from '@mui/icons-material';
import {
  BackButton,
  SaveButton,
} from '../../../../../utility/component/buttons';
import {
  setALertStatus,
  setAlertMsg,
} from '../../../../../utility/Redux/modal';
import { Button } from '@mui/material';

const QntyEdit = () => {
  const ICODE = useSelector(selectQntyEditIcode);
  const dispatch = useDispatch();
  const orderList = useSelector(SelectOrdersList);
  const index = orderList.findIndex((item) => item.ICODE === ICODE);
  const ITEMNAME = orderList[index].ITEMNAME;
  const TotalBQnty = orderList[index].TotalBQnty;
  const QNTY = orderList[index].QNTY;
  const [iqnty, setIqnty] = useState(QNTY);
  const defaultQnty = [5, 10, 15, 20, 25, 30, 40, 50, 100];

  const addItem = () => {
    if (TotalBQnty >= Number(iqnty) + 1) {
      setIqnty((prev) => Number(prev) + 1);
    } else {
      dispatch(setAlertMsg('insufficient balance'));
      dispatch(setALertStatus(true));
    }
  };

  const subItem = () => {
    setIqnty((prev) => Number(prev) - 1);
  };

  const saveItem = () => {
    if (iqnty > 0) {
      if (index >= 0) {
        if (Number(iqnty) !== 0) {
          if (Number(iqnty) <= orderList[index].TotalBQnty) {
            const updatedItem = {
              ...orderList[index],
              AMOUNT: Number(iqnty) * orderList[index].SALE_PRICE,
              QNTY: Number(iqnty),
              BQNTY: orderList[index].TotalBQnty - Number(iqnty),
            };
            const updatedItems = [...orderList];
            updatedItems[index] = updatedItem;
            dispatch(updateOrderlist(updatedItems));
            dispatch(setQntyEditModalStatus(false));
          } else {
            dispatch(setAlertMsg('insufficient balance'));
            dispatch(setALertStatus(true));
          }
        }
      }
    }
  };
  const handleclose = () => {
    dispatch(setQntyEditModalStatus(false));
  };
  return (
    <div className="p-5 ">
      <div className="flex w-full justify-center pb-3">{ITEMNAME}</div>

      <div className=" flex w-full justify-center">
        <div className="flex items-center  ">
          <div className="border-2  items-center px-2  rounded-sm   hover:cursor-pointer font-semibold">
            <Remove onClick={subItem} fontSize="20" color={'secondary'} />
          </div>

          <div
            className="w-20 text-center mx-2 bottom-2 border-slate-400 bg-slate-200 outline-1 outline-slate-500"
            // onClick={() => dispatch(setEditQntyStatus(true))}
          >
            <input
              type="number"
              autoFocus
              className="w-full px-1 py-1 bg-slate-200 text-center   bottom-2 border-slate-300"
              value={iqnty}
              onChange={(t) => {
                setIqnty(t.target.value);
              }}
            />

            {/* {value} */}
          </div>

          <div className="border-2 border-slate-200 px-2 rounded-sm  hover:cursor-pointer">
            <Add onClick={addItem} fontSize="8" color={'secondary'} />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 pt-5">
        {defaultQnty.map((item, index) => {
          return (
            <Button onClick={() => setIqnty(item)}>
              <div className="border-2  px-3 border-slate-200 justify-between flex w-full text-center">
                <div className="text-center  w-full"> {item} </div>
              </div>
            </Button>
          );
        })}
      </div>

      <div className="w-full flex justify-center pt-5">
        <SaveButton onClick={saveItem} />
        <BackButton onClick={handleclose} />
      </div>
    </div>
  );
};

export default QntyEdit;
