import { FormatNumber2 } from '../utility/component/tools';

export const DiffrenceFooter = (props) => {
  const { rows, columns } = props;
  const totalBillAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.BILL_CASH_AMOUNT),
    0
  );
  const TotalIssueCashAmt = rows.reduce(
    (total, row) => Number(total) + Number(row.CASH),
    0
  );

  const TotalBTCAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.BTC_AMOUNT),
    0
  );

  const TotalChequeAmt = rows.reduce(
    (total, row) =>
      Number(total) +
      Number(row.BILL_STATUS === 'Settled' ? row.CHEQUE_AMOUNT : 0),
    0
  );

  const TotalePayment = rows.reduce(
    (total, row) =>
      Number(total) + (row.BILL_STATUS === 'Settled' ? row.EPAYMENT : 0),
    0
  );

  const TotaleDisqtAmt = rows.reduce(
    (total, row) =>
      Number(total) + (row.BILL_STATUS === 'Settled' ? row.DISQ_AMT : 0),
    0
  );

  const TotaleBtcAmt = rows.reduce(
    (total, row) =>
      Number(total) + (row.BILL_STATUS === 'Settled' ? row.BTC_AMOUNT : 0),
    0
  );
  const TotalePayRec = rows.reduce(
    (total, row) =>
      Number(total) + (row.CASH_AMOUNT + row.CARD_AMOUNT + row.EPAYMENT),
    0
  );

  return (
    <div className="flex  w-full  ">
      <div className="flex">
        {columns.map((c, i) => {
          return (
            <div
              key={i}
              style={
                i === columns.length - 1
                  ? {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',

                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                    }
                  : {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',

                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                    }
              }
              className="  h-8  text-black   justify-end  flex items-center"
            >
              <div className="p-2 flex  text-right">
                {c.field === 'SID' && 'Total'}

                {c.field === 'CASH' && FormatNumber2(TotalIssueCashAmt)}

                {c.field === 'BILL_CASH_AMOUNT' &&
                  FormatNumber2(totalBillAmount)}
                {c.field === 'BTC_AMOUNT' && FormatNumber2(TotalBTCAmount)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
