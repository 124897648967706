import axios from 'axios';
import moment from 'moment';
import { CallAPIPromise } from '../../menu/dashboard/biiling/modal/comman';

export const LoadOrderDetials = async (
  setlist,
  prefix,
  token,
  date,
  SID,
  dispatch
) => {
  const data = {
    table_prefix: prefix,
    DATE: moment(date).format('YYYY-MM-DD'),
    SID: SID,
  };
  CallAPIPromise('/api/orderBooking/loadOrder', data, token, dispatch)
    .then((res) => {
      if (!res.data.error) {
        setlist(res.data.response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
