import moment from 'moment';

export const issueCartRQNTY = (
  IQNTY,
  FNO,
  DATE,
  HELPER_NAME,
  TRUCK_NO,
  SID,
  SNAME,
  ICODE,
  ITEMNAME,
  SECTION_NAME,
  SALE_PRICE,
  PRV_BAL,

  RQNTY,
  RAMOUNT,
  ITOTAL,
  RTOTAL,
  KM,
  KM_END,
  REMARKS,
  CASH,
  LESS,
  EXPENSE,
  COLLECTION,
  DEBIT,
  SQNTY,
  SAMOUNT,
  userdefaultSetting,
  TAX1_RATE,
  TAX2_RATE,
  TAX3_RATE
) => {
  const tQNTY = PRV_BAL ? PRV_BAL + IQNTY : IQNTY;
  const s = RQNTY >= 0 ? tQNTY - RQNTY : SQNTY;
  const sQnty = s ? s : 0;
  const data = {
    IQNTY: IQNTY,
    FNO: FNO,
    DATE: moment(DATE).format('YYYY-MM-DD'),
    HELPER_NAME: HELPER_NAME,
    TRUCK_NO: TRUCK_NO,
    SID: SID,
    SNAME: SNAME,
    ICODE: ICODE,
    ITEMNAME: ITEMNAME,
    SECTION_NAME: SECTION_NAME,
    SALE_PRICE: SALE_PRICE,
    PRV_BAL: PRV_BAL ? PRV_BAL : 0,
    IAMOUNT: SALE_PRICE * tQNTY,
    BALANCE_QNTY:
      userdefaultSetting.carry_previous_balance === 'NO' ? 0 : tQNTY - sQnty,
    BALANCE:
      userdefaultSetting.carry_previous_balance === 'NO' ? 0 : tQNTY - sQnty,
    RQNTY: RQNTY,
    RAMOUNT: RAMOUNT,
    ITOTAL: ITOTAL,
    RTOTAL: RTOTAL,
    KM: KM ? KM : 0,
    KM_END: KM_END ? KM_END : 0,
    REMARKS: REMARKS ? REMARKS : '',

    CASH: CASH ? CASH : 0,
    LESS: LESS ? LESS : 0,
    EXPENSE: EXPENSE ? EXPENSE : 0,
    COLLECTION: COLLECTION ? COLLECTION : 0,
    DEBIT: DEBIT ? DEBIT : 0,
    SQNTY: sQnty ? sQnty : 0,
    SAMOUNT: sQnty * SALE_PRICE,
    TAX1_RATE: TAX1_RATE ? TAX1_RATE : 0,
    TAX2_RATE: TAX2_RATE ? TAX2_RATE : 0,
    TAX3_RATE: TAX3_RATE ? TAX3_RATE : 0,
  };
  return data;
};
