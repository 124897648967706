import { FormatNumber2 } from '../tools';
const width = (window.innerWidth * 1) / 100;
export const CollectionSummaryFooter = (props) => {
  const { rows, columns } = props;

  const TotalReceivedAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT_RECEIVED),
    0
  );
  const TotalPaidRec = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT_PAID),
    0
  );
  return (
    <div className="flex  w-full  ">
      <div className="flex">
        {columns.map((c, i) => {
          return (
            <div
              key={i}
              style={
                i === columns.length - 1
                  ? {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',

                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                    }
                  : {
                      width: c.width,
                      borderLeft:
                        i === 0 ? '1px solid black' : '1px solid black',
                      borderRight:
                        i === columns.length - 1
                          ? '1px solid black'
                          : '0px solid black',

                      borderBottom: '1px solid black',
                      borderTop: '1px solid black',
                    }
              }
              className="  h-8  text-black   justify-end  flex items-center"
            >
              <div className="p-2 flex  text-right">
                {c.field === 'INVNO' && ''}
                {c.field === 'DATE' && ''}

                {c.field === 'ACCOUNT_NO' && ''}
                {c.field === 'NAME' && ''}
                {c.field === 'MOBILE' && 'Total'}
                {c.field === 'AMOUNT_RECEIVED' &&
                  FormatNumber2(TotalReceivedAmount)}
                {c.field === 'AMOUNT_PAID' && FormatNumber2(TotalPaidRec)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
