import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectModPartyMasterHelpStatus,
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';

import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';

import MasterHelp from '../utility/component/modals/masterHelp';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';

const ModPartyMasterHelp = ({ onRowClick, SID, onClose }) => {
  const [rows, setRows] = useState([]);
  const [fullList, setFullList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const status = useSelector(SelectModPartyMasterHelpStatus);

  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const windowWidth = useSelector(selectWidth);

  const columns = [
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth < 764
          ? 70
          : windowWidth <= 940
          ? 120
          : windowWidth <= 1156
          ? 110
          : 120,
      headerAlign: 'center',
      align: 'left',
      field: 'MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 50
          : windowWidth < 764
          ? 100
          : windowWidth <= 940
          ? 250
          : windowWidth <= 1156
          ? 250
          : 300,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth < 764
          ? 80
          : windowWidth <= 940
          ? 200
          : windowWidth <= 1156
          ? 200
          : windowWidth <= 1280
          ? 150
          : 250,
      headerAlign: 'center',
      align: 'left',
      field: 'ADDRESS',
      renderHeader: (params) => <div>Addrress</div>,
    },

    {
      width: windowWidth <= 940 ? 50 : windowWidth <= 1156 ? 60 : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'ACCOUNT_NO',
      renderHeader: (params) => <div>Acc.No.</div>,
    },
    {
      width: windowWidth <= 940 ? 30 : windowWidth <= 1156 ? 60 : 70,
      headerAlign: 'center',
      align: 'left',
      field: 'SID',
      renderHeader: (params) => <div>SID</div>,
    },
  ];
  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    try {
      CallAPIPromise(
        '/api/masters/listAccountMaster',
        {
          table_prefix: prefix,
          mobile: '',
          name: '',
          sid: SID ? SID : '',
        },
        token,
        dispatch
      )
        .then((p) => {
          setRows(p.data.response);
          setFullList(p.data.response);
          if (!p.data.response[0]) {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'No data found',
              })
            );
          }
        })
        .catch((error) => {
          console.log(error.response.data.name);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchQuery);
        } else {
          return item.NAME.toLowerCase().includes(searchQuery.toLowerCase());
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);
  return (
    <>
      <MasterHelp
        status={status}
        style={style}
        onClose={() => {
          onClose();
          setRows([]);
        }}
        title={'Party Master | Help'}
        columns={columns}
        list={rows}
        rowId={(p) => p.ACCOUNT_NO}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          onRowClick(p);
          setRows([]);
        }}
      />
    </>
  );
};

export default ModPartyMasterHelp;
