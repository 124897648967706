import React, { useEffect, useState } from 'react';

const GeniusAlphatecKeyboard = ({ text, setText }) => {
  const Alph1StRow = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'];
  const Alph2ndRow = ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'];
  const Alph3rdRow = ['up', 'z', 'x', 'c', 'v', 'b', 'n', 'm', 'del', 'cut'];
  const Numeric = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  const [active, setactive] = useState('');
  const [press, setPress] = useState(false);

  const other = ['space', 'enter'];
  const [upperCase, setUpperCase] = useState(false);

  useEffect(() => {
    if (
      active &&
      !active.includes('cut') &&
      !active.includes('enter') &&
      !active.includes('space') &&
      !active.includes('enter') &&
      !active.includes('up') &&
      !active.includes('del')
    ) {
      setText((t) => {
        if (upperCase) {
          return t + active.toUpperCase();
        } else {
          return t + active;
        }
      });
    } else {
      if (active.includes('cut')) {
        setText((t) => t.slice(0, -1));
      } else {
        if (active.includes('del')) {
          setText('');
        } else {
          if (active.includes('space')) {
            setText((p) => p + ' ');
          }
        }
      }
    }
  }, [active, press]);

  useEffect(() => {
    // Set an interval to clear the active state every 2 seconds
    const intervalId = setInterval(() => {
      setactive('');
    }, 500);

    // Clear the interval when the component is unmounted or when the button is clicked
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      style={{
        width: '480px',
      }}
      className="bg-[#f8f7f7]  flex flex-col justify-center items-center  pb-4  "
    >
      <div className="grid grid-cols-10 px-3  border-t">
        {Numeric.map((e) => {
          return (
            <button
              onClick={() => {
                setactive(e);
                setPress((p) => !p);
              }}
              className={`${
                active === e ? 'bg-white text-black' : 'text-black'
              } flex justify-center h-8  items-center  w-8 mx-2 rounded-[64px] `}
            >
              {upperCase ? e.toUpperCase() : e}
            </button>
          );
        })}
      </div>

      <div className="grid grid-cols-10 mt-[4px]  fl  px-3">
        {Alph1StRow.map((e) => {
          return (
            <button
              onClick={() => {
                setactive(e);
                setPress((p) => !p);
              }}
              className={` flex justify-center border-[#363333] border h-9 items-center  w-9 mx-2 rounded-sm ${
                active === e ? 'bg-white text-black' : 'bg-[#f5f0e8] text-black'
              }`}
            >
              {upperCase ? e.toUpperCase() : e}
            </button>
          );
        })}
      </div>
      <div className="w-full flex justify-center mt-[4px] px-3">
        <div>
          <div className="grid grid-cols-10 w-full mx-5 ">
            {Alph2ndRow.map((e) => {
              return (
                <button
                  onClick={() => {
                    setPress((p) => !p);
                    setactive(e);
                  }}
                  className={` flex justify-center border-[#363333] border h-9 items-center  w-9 mx-2 rounded-sm ${
                    active === e
                      ? 'bg-white text-black'
                      : 'bg-[#f5f0e8] text-black'
                  }`}
                >
                  {upperCase ? e.toUpperCase() : e}
                </button>
              );
            })}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-10 mt-[4px] px-3">
        {Alph3rdRow.map((e) => {
          return (
            <button
              onClick={() => {
                setactive(e);
                setPress((p) => !p);
                if (e === 'up') {
                  setUpperCase((p) => !p);
                }
              }}
              className={` flex justify-center border-[#363333] border h-9 items-center  w-9 mx-2 rounded-sm ${
                active === e ? 'bg-white text-black' : 'bg-[#f5f0e8] text-black'
              }`}
            >
              {e === 'up' ? (
                <i className={`bi bi-capslock `} />
              ) : e === 'cut' ? (
                <i className="bi bi-backspace"></i>
              ) : e === 'del' ? (
                <i class="bi bi-trash3"></i>
              ) : upperCase ? (
                e.toUpperCase()
              ) : (
                e
              )}
            </button>
          );
        })}
      </div>
      <div className="flex justify-center mt-[10px] w-full px-3">
        {other.map((e) => {
          return (
            <button
              onClick={() => {
                setactive(e);
                setPress((p) => !p);
                if (e === 'up') {
                  setUpperCase((p) => !p);
                }
              }}
              className={`e flex justify-center h-8 items-center ${
                e === 'space' ? 'w-1/2' : 'w-1/2'
              }  rounded-sm  mx-1 bg-[#f5f0e8] border-[#363333] border text-black`}
            >
              {e}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default GeniusAlphatecKeyboard;
