import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectShopkeeperToken,
  selectShopkeeperUserId,
  selectToken,
} from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';

import { SearchPriceMaster } from '../utility/component/shopPriceMasterSearch';
import { DataGrid } from '@mui/x-data-grid';
import { FormatNumber2 } from '../utility/component/tools';

import smrat from '../utility/images/icon.png';
import ModAccountMasterCollectionVoucher from '../utility/component/modals/modAccountMaster';
import spinner from '../utility/json/Spinner.json';
import Lottie from 'lottie-react';
import { selectPrefix } from '../utility/Redux/profile';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { setcollVoucherAccModStatus } from '../utility/Redux/modal';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';
const width = window.innerWidth / 100;

const ShopDashboard = () => {
  const ShopToken = useSelector(selectShopkeeperToken);
  const AdminToken = useSelector(selectToken);
  const AdminPrefix = useSelector(selectPrefix);
  const [rows, setRows] = useState([]);
  const [filterdList, setFilteredList] = useState([]);
  const [searchText, SetSearchText] = useState();
  const userId = useSelector(selectShopkeeperUserId);
  const userDetails = JSON.parse(localStorage.getItem('shopDetails'));
  const [loading, setloading] = useState(true);
  const [lang, setLang] = useState('Both');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderCell = (params) => {
    return (
      <div
        style={{ height: '50px', width: 'auto' }}
        className="flex justify-center items-center"
      >
        <img
          src={smrat}
          style={{ height: '50px', opacity: 0.5 }}
          // onError={() => handleImageError(imageSource)}
          alt="Original Image"
        />
      </div>
    );
  };

  const columns = [
    {
      field: 'ICODE',
      type: 'string',
      width: 70,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Code'}</div>,
    },
    {
      field: 'ITEMNAME',
      type: 'string',
      width: 350,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        if (params) {
          return (
            <div>
              <div>
                {lang === 'Eng'
                  ? params.row.ITEMNAME
                  : lang === 'Both'
                  ? params.row.ITEMNAME
                  : null}
              </div>
              <div
                style={{
                  fontSize: '15px',
                }}
              >
                {lang === 'Pun'
                  ? params.row.REGIONAL_INAME
                  : lang === 'Both'
                  ? params.row.REGIONAL_INAME
                  : null}
              </div>
            </div>
          );
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>{'Item Name'}</div>,
    },

    {
      field: 'SALE_PRICE',
      type: 'string',
      width: 150,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div>{'Rate'}</div>,
    },
    {
      field: 'IMAGES',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',

      renderCell: renderCell,
      renderHeader: (params) => <div>{'Image'}</div>,
    },
  ];

  const Mobilecolumns = [
    {
      field: 'ICODE',
      type: 'string',
      width: 30,
      align: 'center',
      headerAlign: 'center',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <strong>{'Code'}</strong>,
    },
    {
      field: 'ITEMNAME',
      type: 'string',
      width: width * 40,
      headerAlign: 'center',
      renderCell: (params) => {
        if (params) {
          return (
            <div>
              <div>
                {lang === 'Eng'
                  ? params.row.ITEMNAME
                  : lang === 'Both'
                  ? params.row.ITEMNAME
                  : null}
              </div>
              <div
                style={{
                  fontSize: '15px',
                }}
              >
                {lang === 'Eng'
                  ? null
                  : lang === 'Both'
                  ? params.row.REGIONAL_INAME
                  : null}
              </div>
            </div>
          );
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <strong>{'Item Name'}</strong>,
    },

    {
      field: 'SALE_PRICE',
      type: 'string',
      width: width * 20,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <strong>{'Rate'}</strong>,
    },

    {
      field: 'IMAGES',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: renderCell,
      renderHeader: (params) => <strong>{'Images'}</strong>,
    },
  ];

  const prefix = userDetails?.table_prefix;

  const getPriceList = () => {
    setloading(true);
    CallAPIPromise(
      '/api/cpmasters/partyRateMaster',
      {
        table_prefix: prefix ? prefix : AdminPrefix,
        USER_ID: userId ? userId : '439',
      },
      ShopToken ? ShopToken : AdminToken,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          setRows(res.data.response);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    getPriceList();
  }, [userId, lang]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = rows.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.ICODE.toString().includes(searchText);
        } else {
          return item.ITEMNAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setFilteredList(searchItems);
    } else {
      setFilteredList('');
    }
  }, [searchText]);

  return (
    <div className="h-full w-full overflow-y-hidden p-4 ">
      <div className="w-full flex">
        <SearchPriceMaster
          SetSearchText={SetSearchText}
          searchText={searchText}
        />
      </div>

      {loading ? (
        <div
          style={{
            height: '80%',
          }}
          className=" d:w-1/2 l:w-3/4 max-sm:w-full flex justify-center h-full items-center "
        >
          <div className="w-6 h-6">
            <Lottie
              animationData={spinner}
              loop={true}
              style={{ fontSize: 15 }}
              color="white"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            height: '80%',
          }}
          className=" w-2/3 mt-3 max-sm:w-full"
        >
          <DataGrid
            hideFooterSelectedRowCount
            headerHeight={45}
            components={{
              Header: () => (
                <div className="flex w-full ">
                  <Button
                    onClick={() => {
                      setLang('Eng');
                    }}
                    sx={{
                      color: lang === 'Eng' ? 'orangered' : 'black',
                      width: '33.3%',
                      textTransform: 'none',
                      borderRight: '1px dotted #c9c5c5',
                    }}
                  >
                    English
                  </Button>

                  <Button
                    onClick={() => {
                      setLang('Pun');
                    }}
                    sx={{
                      color: lang === 'Pun' ? 'orangered' : 'black',
                      width: '33.3%',
                      textTransform: 'none',
                      borderRight: '1px dotted #c9c5c5',
                    }}
                  >
                    ਪੰਜਾਬੀ
                  </Button>
                  <Button
                    onClick={() => {
                      setLang('Both');
                    }}
                    sx={{
                      color: lang === 'Both' ? 'orangered' : 'black',
                      width: '33.3%',
                      textTransform: 'none',
                    }}
                  >
                    Both
                  </Button>
                </div>
              ),
              NoRowsOverlay: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    No Records Found
                  </div>
                );
              },
            }}
            sx={
              window.innerWidth < 770
                ? {
                    backgroundColor: 'white',

                    fontSize: 12,
                    '& .super-app-theme--header': {
                      backgroundColor: '#383535',
                      color: '#f1f5f9',
                      fontWeight: 400,
                    },
                    '& .MuiDataGrid-columnHeader': {
                      borderColor: '#0a0a0a',
                    },
                    '& .MuiDataGrid-cell, & .MuiDataGrid-row': {
                      borderColor: '#383535', // Replace "red" with your desired color
                    },
                  }
                : {
                    backgroundColor: 'white',
                    border: '1px solid #c9c5c5',

                    fontSize: 15,
                    '& .super-app-theme--header': {
                      backgroundColor: '#383535',
                      color: '#f1f5f9',
                      fontWeight: 400,
                    },
                    '& .MuiDataGrid-columnHeader': {
                      border: '1px solid #0a0a0a',
                    },
                    '& .MuiDataGrid-cell, & .MuiDataGrid-row': {
                      borderColor: '#cfcdca',
                    },
                    '&.datagrid-container .MuiDataGrid-footerContainer': {
                      height: '20px' /* Adjust the height value as needed */,
                    },
                  }
            }
            rowHeight={window.innerWidth < 768 ? 60 : 70}
            columns={window.innerWidth < 768 ? Mobilecolumns : columns}
            rows={filterdList ? filterdList : rows}
            getRowId={(e) => e.ICODE}
            pageSize={100}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        </div>
      )}

      <ModAccountMasterCollectionVoucher
        onClose={() => {
          dispatch(setcollVoucherAccModStatus(false));
          navigate('/masters');
        }}
      />
    </div>
  );
};

export default ShopDashboard;
