import moment from 'moment';
import { FormatNumber2 } from '../component/tools';

export const SaleSummaryColumns = (width) => {
  const columns = [
    {
      field: 'INVNO',
      accessor: 'INVNO',
      type: 'string',
      headerAlign: 'center',
      width: 80,
      headerClassName: 'super-app-theme--header',
      headerName: 'Invoice',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Invoice'}{' '}
        </strong>
      ),
    },
    {
      field: 'BILL_STATUS',
      accessor: 'BILL_STATUS',
      type: 'string',
      headerAlign: 'center',
      width: 70,
      headerClassName: 'super-app-theme--header',
      headerName: 'Bill Status',

      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong
          className="text-white"
          style={{ fontSize: 13, display: width <= 1280 ? 'none' : 'flex' }}
        >
          {' '}
          {'Bill Status'}{' '}
        </strong>
      ),
    },
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      headerAlign: 'center',
      headerName: 'Date',

      width: 100,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full flex items-center  `}
          >
            {moment(params.value).format('YYYY-MM-DD')}{' '}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Date'}{' '}
        </strong>
      ),
    },
    {
      field: 'SID',
      accessor: 'SID',
      type: 'string',
      width: 80,
      headerName: 'Acc. No.',
      headerAlign: 'center',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Station'}{' '}
        </strong>
      ),
    },
    {
      field: 'ACCOUNT_NO',
      accessor: 'ACCOUNT_NO',
      type: 'string',
      width: 80,
      headerName: 'Acc. No.',
      headerAlign: 'center',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Acc. No.'}{' '}
        </strong>
      ),
    },

    {
      field: 'NAME',
      accessor: 'NAME',
      type: 'string',
      width: 250,
      headerClassName: 'super-app-theme--header',
      headerName: 'Party Name',
      headerAlign: 'center',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center overflow-x-hidden   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          Party Name
        </strong>
      ),
    },

    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      headerName: 'Mobile',
      type: 'number',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      width: 110,
      headerAlign: 'center',
      align: 'left',

      renderHeader: (params) => (
        <strong
          className="text-white  whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Mobile
        </strong>
      ),
    },

    {
      field: 'ADDRESS',
      accessor: 'ADDRESS',
      headerName: 'Address',
      type: 'number',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      width: 260,
      headerAlign: 'center',
      align: 'left',

      renderHeader: (params) => (
        <strong
          className="text-white  leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Address
        </strong>
      ),
    },

    {
      field: 'CASH_AMOUNT',
      accessor: 'CASH_AMOUNT',
      type: 'number',
      headerName: 'Cash Amt.',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   justify-end  `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      width: 120,
      align: 'right',

      headerAlign: 'center',
      headerAlign: 'center',
      // renderCell: (params) => {
      //   if (params.row.SQNTY) {
      //     return params.row.IAMOUNT - params.row.SAMOUNT + params.row.RAMOUNT;
      //   } else {
      //     return "";
      //   }
      // },
      renderHeader: (params) => (
        <strong
          className="text-white leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          Cash Amt.
        </strong>
      ),
    },

    {
      field: 'CARD_AMOUNT',
      accessor: 'CARD_AMOUNT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 120,

      headerName: 'Card Amt.',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {FormatNumber2(param.value)}
          </div>
        );
      },

      headerAlign: 'center',
      headerAlign: 'center',
      // renderCell: (params) => {
      //   if (params.row.SQNTY) {
      //     return params.row.IAMOUNT - params.row.SAMOUNT + params.row.RAMOUNT;
      //   } else {
      //     return "";
      //   }
      // },
      renderHeader: (params) => (
        <strong
          className="text-white leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          Card Amt.
        </strong>
      ),
    },

    {
      field: 'CHEQUE_AMOUNT',
      accessor: 'CHEQUE_AMOUNT',
      type: 'number',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   justify-end`}
          >
            {FormatNumber2(param.value)}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      width: 120,

      headerName: 'Cheque Amt.',

      align: 'right',

      headerAlign: 'center',
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          Cheque Amt.
        </strong>
      ),
    },

    {
      field: 'EPAYMENT',
      accessor: 'EPAYMENT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 120,

      headerName: 'ePayment Amt.',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          ePayment Amt.
        </strong>
      ),
    },

    {
      field: 'BTC_AMOUNT',
      accessor: 'BTC_AMOUNT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 120,

      headerName: 'BTC Amount',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },
      headerAlign: 'center',
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          BTC Amount
        </strong>
      ),
    },
    {
      field: 'DISQ_AMT',
      accessor: 'DISQ_AMT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      headerName: 'Discount',
      width: 120,

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          Discount
        </strong>
      ),
    },

    {
      field: 'TOTAL',
      accessor: 'TOTAL',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 120,

      headerName: 'Total',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          Total
        </strong>
      ),
    },
    {
      field: 'TOTAL_COLLECTION',
      accessor: 'TOTAL_COLLECTION',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 120,
      headerName: 'Collection',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          Collection
        </strong>
      ),
    },
  ];
  return columns;
};

export const TabSummaryColumns = (width) => {
  const columns = [
    {
      field: 'INVNO',
      accessor: 'INVNO',
      type: 'string',
      headerAlign: 'center',
      width: 80,
      headerClassName: 'super-app-theme--header',
      headerName: 'Invoice',

      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Invoice'}{' '}
        </strong>
      ),
    },

    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'string',
      headerAlign: 'center',
      headerName: 'Date',

      width: 100,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <div
            className={`${
              params.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full flex items-center  `}
          >
            {moment(params.value).format('YYYY-MM-DD')}{' '}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Date'}{' '}
        </strong>
      ),
    },
    {
      field: 'SID',
      accessor: 'SID',
      type: 'string',
      width: 80,
      headerName: 'Acc. No.',
      headerAlign: 'center',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          {'Station'}{' '}
        </strong>
      ),
    },
    {
      field: 'NAME',
      accessor: 'NAME',
      type: 'string',
      headerName: 'Party Name',
      width: 280,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            }  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <strong className="text-white" style={{ fontSize: 13 }}>
          {' '}
          Party Name
        </strong>
      ),
    },

    {
      field: 'MOBILE',
      accessor: 'MOBILE',
      type: 'number',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center   `}
          >
            {param.value}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      width: 100,
      headerAlign: 'center',
      align: 'left',
      headerName: 'Mobile',

      renderHeader: (params) => (
        <strong
          className="text-white  whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Mobile
        </strong>
      ),
    },

    {
      field: 'TotalCash',
      accessor: 'TotalCash',
      type: 'number',
      renderCell: (param) => {
        const cash = param.row.CASH_AMOUNT
          ? param.row.CASH_AMOUNT > 0
            ? param.row.CASH_AMOUNT
            : 0
          : 0;
        const card = param.row.CARD_AMOUNT
          ? param.row.CARD_AMOUNT > 0
            ? param.row.CARD_AMOUNT
            : 0
          : 0;
        const ePay = param.row.EPAYMENT
          ? param.row.EPAYMENT > 0
            ? param.row.EPAYMENT
            : 0
          : 0;
        console.log(cash);
        console.log(card);
        console.log(ePay);
        const paymentRecievd = cash + card + ePay;
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center justify-end   `}
          >
            {paymentRecievd > 0 ? FormatNumber2(paymentRecievd) : ''}
          </div>
        );
      },
      headerClassName: 'super-app-theme--header',
      width: 150,

      headerAlign: 'center',
      align: 'right',

      renderHeader: (params) => (
        <strong
          className="text-white w-16 leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          {' '}
          Payment Received
        </strong>
      ),
    },

    {
      field: 'BTC_AMOUNT',
      accessor: 'BTC_AMOUNT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerName: 'BTC Amount',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white leading-4 whitespace-normal text-center"
          style={{ fontSize: 13 }}
        >
          BTC Amount
        </strong>
      ),
    },
    {
      field: 'DISQ_AMT',
      accessor: 'DISQ_AMT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerName: 'Discount',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          Discount
        </strong>
      ),
    },
    {
      field: 'TOTAL',
      accessor: 'TOTAL',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 150,
      headerName: 'Total',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong
          className="text-white  pr-4 text-center"
          style={{ fontSize: 13 }}
        >
          Total
        </strong>
      ),
    },
    {
      field: 'TOTAL_COLLECTION',
      accessor: 'TOTAL_COLLECTION',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: 120,
      headerName: 'Collection',

      align: 'right',
      renderCell: (param) => {
        return (
          <div
            className={`${
              param.row.BILL_STATUS === 'Void' && 'text-[#e97a7a]'
            } h-full w-full  flex items-center  justify-end `}
          >
            {param.value ? FormatNumber2(param.value) : ''}
          </div>
        );
      },

      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-white  text-center" style={{ fontSize: 13 }}>
          Collection
        </strong>
      ),
    },
  ];
  return columns;
};
