import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import { BarLoader } from 'react-spinners';
import {
  selectCollectionVocuherModalStatus,
  selectStatus,
  setCollectionAccountDetails,
  setVoucherSummaryModalStatus,
} from '../../Redux/modal';
import SummaryDatagrid from '../datagrid/summaryDatagrid';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85%',
  height: '90%',
  border: '2px solid #000',
  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '80%',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function ModVoucherSummary() {
  const dispatch = useDispatch();

  const ModalStatus = useSelector(selectCollectionVocuherModalStatus);
  const loading = useSelector(selectStatus);

  const handleClose = () => {
    dispatch(setVoucherSummaryModalStatus(false));
    dispatch(
      setCollectionAccountDetails({
        accName: '',
        accAddress: '',
        accMobile: '',
        accNumber: '',
        stationId: '',
      })
    );
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={ModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={'rounded-lg overflow-hidden  '}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className="flex w-full bg-brown items-center">
              <img
                src={require('../../../utility/images/logo3.png')}
                className="w-36 md:w-48 h-auto"
              />
              <div
                style={{ width: '1px', height: 50 }}
                className=" bg-orange"
              />
              <div className="flex  grow justify-between items-center text-white bg-brown px-3 font-medium ">
                Collection Summary
                <CancelPresentationIcon
                  onClick={handleClose}
                  sx={{
                    color: 'orangered',
                  }}
                  fontSize="medium"
                />
              </div>
            </div>

            <div className="flex-grow h-full bg-white    border-brown">
              <div className="h-0.5 ">
                <BarLoader
                  color={'#F5874F'}
                  loading={loading}
                  cssOverride={{
                    top: 0,
                    width: 'auto',
                  }}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
              </div>
              <SummaryDatagrid />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
