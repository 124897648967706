import { FormatNumber2, FormatNumber3 } from '../../../utility/component/tools';
export const ItemWiseFooter = (props) => {
  const { rows, columns } = props;

  const totalSaleQnty = rows.reduce((total, row) => total + row.SQNTY, 0);

  const TotalAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );
  const totalCGST = rows.reduce(
    (total, row) => Number(total) + Number(row.TAX1_AMT),
    0
  );
  const totalSGST = rows.reduce(
    (total, row) => Number(total) + Number(row.TAX2_AMT),
    0
  );

  const totalIGST = rows.reduce(
    (total, row) => Number(total) + Number(row.TAX3_AMT),
    0
  );

  const totalAMT = rows.reduce(
    (total, row) =>
      Number(total) +
      Number(row.AMOUNT + row.TAX1_AMT + row.TAX2_AMT + row.TAX3_AMT),
    0
  );

  return (
    <div id={'print-Fotter'} className="  bg-[#e6e6e3] flex  text-sm ">
      {columns.map((item, index) => {
        const header = item.field;
        return (
          <div
            key={index}
            className="  text-black flex justify-end pr-2 items-center border-r py-2 border-slate-300"
            style={item.flex ? { flex: item.flex } : { width: item.width }}
          >
            {header === 'DESC_NAME' ? 'Total' : null}
            {header === 'SQNTY' ? FormatNumber3(totalSaleQnty) : null}
            {header === 'AMOUNT' ? FormatNumber2(TotalAmount) : null}
            {header === 'TAX1_AMT' ? FormatNumber2(totalCGST) : null}

            {header === 'TAX2_AMT' ? FormatNumber2(totalSGST) : null}

            {header === 'TAX3_AMT' ? FormatNumber2(totalIGST) : null}
            {header === 'TOTAL_AMOUNT' ? FormatNumber2(totalAMT) : null}
          </div>
        );
      })}
    </div>
  );
};
