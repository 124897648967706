import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectDefultProfileSetting,
  selectEmail,
  selectFirebaseStatusField,
  selectISFirebaseSocketOn,
  selectPathHistory,
  selectPrefix,
  selectUserCode,
  selectUserType,
  selectWidth,
  setPathHistory,
} from '../utility/Redux/profile';
import 'react-datepicker/dist/react-datepicker.css';
import CustomeTwoButtonAlert from '../utility/component/modals/twoButtonAlert';
import Dashboard from './menu/dashboard/dashboard';
import AlertModal from '../utility/component/modals/alert';
import SessionOutModal from '../utility/component/modals/geniusModal';
import AdminDashboard from './menu/dashboard/adminDashboard';
import { Tooltip } from 'react-tooltip';
import ModVoucherSummary from '../utility/component/modals/modVoucherSummary';
import ModalUserStatus from '../utility/component/modals/modUserStatus';
import ModExpenseMaster from '../master/modExpenseMaster';
import ModalLoginRequest from '../utility/component/modals/modloginRequests';
import { useEffect } from 'react';
import ModApproveSucces from '../utility/component/modals/modApprvSuccess';
import ModApproveRejected from '../utility/component/modals/apprvRejected';
import ModSystemAdmin from '../utility/component/modals/modSystAdmin';
import AppBarDesign from '../utility/component/styles/homeStyles';
import ModDayEndSummary from '../utility/component/modals/modDayEndSummary';
import { getDefaultProfileSetting } from '../utility/component/functions';
import { selectCurrentInvoiceBillNumber } from '../utility/Redux/tableOrder';
import moment from 'moment';
import { toProperCase } from '../utility/component/format';
import { selectToken } from '../utility/Redux/security';
import AlertModal2 from '../utility/component/modals/alertWithLottie';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';
import NoIssueMod from '../utility/component/modals/notIssue';
import Section_Master from '../master/sectionMaster';
import Station_Master from '../master/stationMaster';
import GeniusDashboard from './admin/dashboard';
import { child, get, onValue, ref, set } from 'firebase/database';
import database from './issueStock/firebase';
import { handleSignout } from '../utility/component/modals/logout';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import HandleIndexedDB from '../utility/functions/indexDbDatabase';
import deleteWholeDatabase from '../utility/functions/deleteWholeDatabase';
import { Collapse } from '@mui/material';
import { setAlertWithTitle } from '../utility/Redux/modal';
const Home = () => {
  const location = useLocation();
  let email = useSelector(selectEmail);
  const navigate = useNavigate();
  const [isPathShow, setIsPathShown] = useState(true);
  const companyDetails = JSON.parse(localStorage.getItem('companyDetails'));
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const userType = useSelector(selectUserType);
  const currentInvoiceNumber = useSelector(selectCurrentInvoiceBillNumber);
  const token = useSelector(selectToken);
  const [expiredIn, setExpiredIn] = useState('');

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const statusField = useSelector(selectFirebaseStatusField);

  useEffect(() => {
    if (location.pathname === '/' && userType !== 'MANAGER') {
      getDefaultProfileSetting(
        dispatch,
        email,
        token,
        prefix,
        setAlertTwoButtonModal,
        navigate,
        userCode,
        isFieebaseSocketOn,
        statusField
      );
    }
  }, [currentInvoiceNumber]);

  const isFieebaseSocketOn = useSelector(selectISFirebaseSocketOn);
  const windowWidth = useSelector(selectWidth);
  const userCode = useSelector(selectUserCode);

  const connectedR = ref(
    database,
    `${prefix}/user/${userCode}/${statusField}/msg`
  );

  const connectedConR = ref(
    database,
    `${prefix}/user/${userCode}/${statusField}/connection`
  );

  const expireAt = localStorage.getItem('expiresAt');

  useEffect(() => {
    if (
      prefix &&
      userCode &&
      localStorage.getItem('expiresAt') &&
      !alertTwoButtonModal.status &&
      isFieebaseSocketOn
    ) {
      const connectedRef = ref(database, `${prefix}/user/${userCode}/`);
      const connectedMsgRef = ref(database, `${prefix}/user/${userCode}/msg`);

      get(child(connectedRef, `/${statusField}/connection`))
        .then((sn) => {
          if (sn.exists()) {
            const data = sn.val();
            if (data.includes(expireAt)) {
              get(child(connectedRef, `/${statusField}/msg`)).then((msg) => {
                if (msg.exists()) {
                  onValue(connectedMsgRef, (sn) => {
                    if (sn.exists()) {
                      const data = sn.val();
                      if (data === 'isConnected') {
                        set(connectedMsgRef, `rejected`);
                      }
                    }
                  });
                }
              });
            } else {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: true,
                title: 'Alert',
                msg: 'This account is already connected E-1',
                button1: 'Signout',

                button1Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                  handleSignout(
                    dispatch,
                    navigate,
                    prefix,
                    userCode,
                    isFieebaseSocketOn,
                    statusField
                  );
                },
              });
            }
          } else {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: true,
              title: 'Alert',
              msg: 'This account is already connected E-2',
              button1: 'Signout',

              button1Click: () => {
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                handleSignout(
                  dispatch,
                  navigate,
                  prefix,
                  userCode,
                  isFieebaseSocketOn,
                  statusField
                );
              },
            });
          }
        })
        .catch((e) => {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'Alert',
            msg: 'This account is already connected E-3',
            button1: 'Signout',

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              handleSignout(
                dispatch,
                navigate,
                prefix,
                userCode,
                isFieebaseSocketOn,
                statusField
              );
            },
          });
        });
    }
  }, []);

  const defaultProfileSetting = useSelector(selectDefultProfileSetting);

  const defaultProfile = useSelector(selectDefultProfileSetting);

  useEffect(() => {
    if (!defaultProfile?.station_id && email) {
      if (prefix)
        if (!defaultProfileSetting.enable_customer_mobile) {
          if (email !== undefined) {
            getDefaultProfileSetting(
              dispatch,
              email,
              token,
              prefix,
              setAlertTwoButtonModal,
              navigate,
              userCode,
              isFieebaseSocketOn,
              statusField
            );
          } else {
            getDefaultProfileSetting(
              dispatch,
              email,
              token,
              prefix,
              setAlertTwoButtonModal,
              navigate,
              userCode,
              isFieebaseSocketOn,
              statusField
            );
          }
        }
    }
  }, []);

  useEffect(() => {
    onValue(connectedR, (sn) => {
      if (sn.exists()) {
        const data = sn.val();
        if (data === 'isConnected' && document.visibilityState === 'visible') {
          set(connectedR, `rejected`);
        }
      }
    });

    onValue(connectedConR, (sn) => {
      if (sn.exists()) {
        const data = sn.val();
        if (!data.includes(localStorage.getItem('expiresAt'))) {
          setAlertTwoButtonModal({
            ...alertTwoButtonModal,
            status: true,
            title: 'Alert',
            msg: 'This account is already connected or you are not allowed to use this session',
            button1: 'Signout',

            button1Click: () => {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: false,
              });
              handleSignout(
                dispatch,
                navigate,
                prefix,
                userCode,
                isFieebaseSocketOn,
                statusField
              );
            },
          });
        }
      }
    });
  }, []);

  const pathHistory = useSelector(selectPathHistory);

  const removePath = (tab) => {
    const filterdPath = pathHistory.filter((data) => data.path !== tab.path);
    dispatch(setPathHistory(filterdPath));
    const databaseName = 'pathHistory';
    const storeName = 'data';
    const dataToSave = filterdPath;
    deleteWholeDatabase('pathHistory');
    if (!pathHistory[1]) {
      setIsPathShown(false);
    }
    HandleIndexedDB({ databaseName, storeName, dataToSave });
  };

  const addpath = (history) => {
    if (
      location.pathname !== '/masters' &&
      location.pathname !== '/reports' &&
      location.pathname !== '/summary' &&
      location.pathname !== '/updation' &&
      location.pathname !== '/summary' &&
      location.pathname !== '/updation' &&
      location.pathname !== '/Mfg' &&
      location.pathname !== '/settings'
    ) {
      const isDup = history.filter((o) => o.path === location.pathname);
      if (!isDup[0]) {
        const list = [
          ...history,
          {
            ICODE: Math.random(),
            path: location.pathname,
          },
        ];
        const availvalSpace = Math.round(window.innerWidth / 220);
        const slice = availvalSpace - 1;
        const dataToSave = list.slice(-slice);
        const databaseName = 'pathHistory';
        const storeName = 'data';
        if (!pathHistory[0]) {
          setIsPathShown(true);
        }
        dispatch(setPathHistory(dataToSave));
        deleteWholeDatabase('pathHistory');
        HandleIndexedDB({ databaseName, storeName, dataToSave });
      }
    }
  };

  useEffect(() => {
    if (pathHistory[0]) {
      addpath(pathHistory);
    } else {
      const databaseName = 'pathHistory';
      const storeName = 'data';

      HandleIndexedDB({
        databaseName,
        storeName,
        callback: (data) => {
          if (data[0]) {
            dispatch(setPathHistory(data));
          }
          addpath(data);
        },
      });
    }
  }, [location.pathname]);
  useEffect(() => {
    if (!expiredIn) {
      const databaseName = 'userDetails';
      const storeName = 'data';

      HandleIndexedDB({
        databaseName,
        storeName,
        callback: (data) => {
          if (data[0]) {
            const endDate = moment(data[0].end_date).diff(
              moment(moment().format('YYYY-MM-DD')),
              'days'
            );
            if (endDate < 11) {
              setExpiredIn(endDate);
            } else {
              if (endDate < 4) {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: `License will expire in ${endDate} days.`,
                  })
                );
              }
            }
          }
        },
      });
    }
  }, []);
  return (
    <Box className="flex  h-screen w-screen  ">
      <AppBarDesign />

      <div
        style={{
          paddingTop: 50,
        }}
        className="overflow-hidden p:pl-[49px] pb-10 h-full bg-white  w-full "
      >
        <div className="w-full  flex      z-100">
          <div className="flex p-2 items-center flex-1 bg-[#e6e6e3]  ">
            <span
              style={{
                fontSize: 12,
              }}
              className="text-black font-semibold "
            >
              {location.pathname === '/'
                ? ' Dashboard'
                : toProperCase(
                    location.pathname
                      .substring(1)
                      .replace('_', ' ')
                      .replace('_', ' ')
                      .replace('_', ' ')
                  )}
            </span>
            <div className="flex items-center">
              <div style={{ fontSize: 12 }} className={'text-[#3b3939] ml-1 '}>
                {moment().format('dddd DD-MMM-YYYY')}
              </div>
            </div>
            {expiredIn && (
              <div
                style={{
                  letterSpacing: '2px',
                }}
                className="ml-4 text-[13px] font-semibold text-[#cf4848] "
              >
                - License will expired in {expiredIn} days
              </div>
            )}
          </div>

          <div
            style={{
              fontSize: 13,
              paddingRight: '2px',
            }}
            className="text-[#4B4545] bg-[#e6e6e3] p-2 hidden md:flex items-center justify-between "
          >
            <label
              style={{
                fontWeight: 600,
              }}
            >
              {companyDetails?.companyName}
            </label>

            <div
              style={{
                borderLeft: '1px solid black',
              }}
              className="h-4  mx-2  whitespace-nowrap"
            />

            <label
              style={{
                paddingRight: isPathShow ? '4px' : '0px',
              }}
              className="flex pa"
            >
              <label>Financial Year : </label>

              {moment(companyDetails?.start_date).format('YYYY') +
                '-' +
                moment(companyDetails?.end_date).format('YYYY')}
            </label>
          </div>

          {!isPathShow && (
            <button
              onClick={() => {
                setIsPathShown((e) => !e);
              }}
              className=" bg-[#e6e6e3]"
            >
              <i
                className={`bi px-2  flex items-center ${
                  isPathShow ? 'bi-chevron-up' : 'bi-chevron-down'
                }`}
              ></i>
            </button>
          )}
        </div>

        <Collapse in={isPathShow} timeout="auto" unmountOnExit>
          <div className="w-full flex bg-[#e6e6e3] justify-between">
            <div className="flex">
              {pathHistory.map((o) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        location.pathname === o.path ? 'white' : '#e6e6e3',
                      borderRight:
                        location.pathname === o.path
                          ? '1px solid black'
                          : '1px solid #bfbfbd',
                      borderTop:
                        location.pathname === o.path
                          ? '1px solid black'
                          : '1px solid #bfbfbd',
                    }}
                    className={`min-w-[220px] ${
                      location.pathname === o.path && 'font-semibold'
                    } flex rounded-t-sm   px-2 py-1 hover:cursor-pointer text-sm ${
                      o.path === location.pathname && 'rounded-t-sm'
                    }`}
                  >
                    <div
                      onClick={() => {
                        navigate(o.path);
                      }}
                      className="flex-1 text-[#5f5f5e] "
                    >
                      {o.path === '/'
                        ? 'Home'
                        : toProperCase(
                            o.path.replace('/', '').replace(/_/g, ' ')
                          )}
                    </div>
                    <div
                      onClick={() => {
                        removePath(o);
                      }}
                      className="flex items-center hover:bg-gray-300 rounded-sm p-[3px]"
                    >
                      <i className="bi bi-x flex items-center "></i>
                    </div>
                  </div>
                );
              })}
            </div>

            <button
              onClick={() => {
                setIsPathShown((e) => !e);
              }}
              className=" bg-[#e6e6e3]"
            >
              <i
                className={`bi px-2  flex items-center ${
                  isPathShow ? 'bi-chevron-up' : 'bi-chevron-down'
                }`}
              ></i>
            </button>
          </div>
        </Collapse>

        <div
          style={{
            height: `calc(100% - ${isPathShow ? 28 : 0}px)`,
          }}
          className="flex flex-col   "
        >
          {location.pathname === '/' ? (
            userType === 'MANAGER' ? (
              <AdminDashboard />
            ) : userType === 'USER' ? (
              <Dashboard />
            ) : userType === 'ADMIN' ? (
              <GeniusDashboard />
            ) : (
              <Dashboard />
            )
          ) : (
            <Outlet />
          )}
        </div>
      </div>

      <CustomeTwoButtonAlert />
      <AlertModal />
      <ModalUserStatus />
      <SessionOutModal />
      <ModApproveSucces />
      <ModApproveRejected />
      <ModVoucherSummary />
      <ModExpenseMaster />
      <ModSystemAdmin />
      {userType === 'MANAGER' ? <ModalLoginRequest /> : null}
      <ModDayEndSummary />
      <AlertModal2 />
      <SpinnerLoading />
      <NoIssueMod />
      <Section_Master />
      <Station_Master />
      {windowWidth > 1280 && (
        <Tooltip
          id="tip"
          style={{
            padding: 3,
            backgroundColor: '#f7dda4',
            color: 'black',
          }}
        />
      )}
      {windowWidth > 1280 && (
        <Tooltip
          id="tip2"
          opacity={100}
          effect="solid"
          style={{
            padding: 5,
            backgroundColor: 'black',
            color: 'white',
            paddingLeft: 7,
          }}
        />
      )}
      {windowWidth > 1280 && (
        <Tooltip
          id="tip3"
          opacity={100}
          effect="solid"
          style={{
            padding: 5,
            backgroundColor: 'red',
            color: 'white',
            paddingLeft: 7,
          }}
        />
      )}
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </Box>
  );
};

export default Home;
