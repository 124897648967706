import axios from 'axios';

import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import { CallAPIPromise } from '../../menu/dashboard/biiling/modal/comman';

export const GetEmployeeSummary = async (
  setEmployeelist,
  prefix,
  token,
  dispatch,
  fromDate,
  toDate
) => {
  dispatch(setSpinnerLoading('Loading employee data'));
  CallAPIPromise(
    '/api/saveInHouseMfg/mfgEmployeeSummary',
    {
      table_prefix: prefix,
      FDATE: fromDate,
      TDATE: toDate,
    },
    token,
    dispatch
  )
    .then((p) => {
      if (!p.data.error) {
        const data = p.data.response;
        let rowList = [];

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }
        setEmployeelist(rowList);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: p.data.error,
          })
        );
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something went wrong',
          })
        );
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
