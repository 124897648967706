import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef } from 'react';

const MapWithMarker = (info) => {
  const mapRef = useRef(null);
  const details = info;
  const longitude = Number(details.lng);
  const latitude = Number(details.lat);

  useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_MAP_KEY,
      version: 'weekly',
      libraries: ['places'],
    });

    loader.load().then((google) => {
      // const geocoder = new google.maps.Geocoder();
      const map = new google.maps.Map(mapRef.current, {
        center: {
          lat: latitude,
          lng: longitude,
        },
        zoom: 13,
      });

      const marker = new google.maps.Marker({
        position: {
          lat: latitude,
          lng: longitude,
        },
        map,
        title: 'My Marker',
      });
    });
  }, [info]);

  return <div ref={mapRef} className="w-full h-full " />;
};

export default MapWithMarker;
