import React from 'react';
import { FormatNumber2, FormatNumber3 } from '../../utility/component/tools';

const IsssueColumns = (
  width,
  userType,
  setAlertTwoButtonModal,
  DeleteItem,
  alertTwoButtonModal
) => {
  const c = [
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 70
          : width <= 1200
          ? 85
          : width <= 1280
          ? 85
          : width < 1450
          ? 90
          : width < 1600
          ? 100
          : width < 1700
          ? 100
          : 100,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ICODE',

      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 100
          : width <= 1200
          ? 110
          : width <= 1280
          ? 120
          : width < 1450
          ? 150
          : width < 1600
          ? 150
          : width < 1700
          ? 150
          : 200,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ITEMNAME',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 80
          : width <= 1200
          ? 90
          : width <= 1280
          ? 90
          : width < 1450
          ? 95
          : width < 1600
          ? 100
          : width < 1700
          ? 130
          : 160,
      headerAlign: 'center',
      accessor: 'SALE_PRICE',
      align: 'right',
      field: 'SALE_PRICE',
      headerName: 'Rate',
      editable: userType === 'MANAGER' ? true : false,
      type: 'number',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Rate</div>,
    },
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 80
          : width <= 1200
          ? 90
          : width <= 1280
          ? 90
          : width < 1450
          ? 95
          : width < 1600
          ? 100
          : width < 1700
          ? 140
          : 160,
      headerAlign: 'center',
      accessor: 'PRV_BAL',
      align: 'right',
      field: 'PRV_BAL',
      renderCell: (param) => {
        if (!param.value) {
          return <div> {''}</div>;
        } else {
          if (param.value === 'undefined') {
            return '';
          } else {
            return FormatNumber3(param.value);
          }
        }
      },
      renderHeader: () => <div>Opening</div>,
    },
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 80
          : width <= 1200
          ? 85
          : width <= 1280
          ? 90
          : width < 1450
          ? 95
          : width < 1600
          ? 110
          : width < 1700
          ? 130
          : 160,
      headerAlign: 'center',
      align: 'right',
      accessor: 'IQNTY',
      field: 'IQNTY',
      editable: 'true',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return <div className="">{param.value}</div>;
        }
      },
      renderHeader: (params) => <div>Issue Qnty.</div>,
    },
    {
      field: 'TotalIQnty',
      width:
        width < 768
          ? 100
          : width < 1100
          ? 80
          : width <= 1200
          ? 90
          : width <= 1280
          ? 95
          : width < 1450
          ? 95
          : width < 1600
          ? 110
          : width < 1700
          ? 140
          : 160,
      headerAlign: 'right',
      cellClassName: 'super-app-theme--IQNTY',

      accessor: 'TotalIQnty',
      align: 'right',
      renderCell: (param) => {
        if (param.row.PRV_BAL) {
          const d = Number(param.row.PRV_BAL) + Number(param.row.IQNTY);

          if (d > 0) {
            return (
              <div className="font-semibold text-[#5c5656]">
                {FormatNumber2(d)}
              </div>
            );
          } else {
            return 2;
          }
        } else {
          if (param.row.IQNTY > 0) {
            return (
              <div className="font-semibold text-[#5c5656]">
                {FormatNumber2(param.row.IQNTY)}
              </div>
            );
          } else {
            return '';
          }
        }
      },
      renderHeader: (params) => <div>Total Qnty.</div>,
    },
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 80
          : width <= 1200
          ? 85
          : width <= 1280
          ? 95
          : width < 1450
          ? 95
          : width < 1600
          ? 110
          : width < 1700
          ? 120
          : 150,
      headerAlign: 'center',
      align: 'right',
      type: 'number',

      accessor: 'IAMOUNT',
      field: 'IAMOUNT',
      renderCell: (param) => {
        if (param.value) {
          return FormatNumber2(param.value);
        } else {
          return '';
        }
      },
      renderHeader: () => <div>Issue Amount</div>,
    },
    {
      field: 'SQNTY',
      width:
        width < 768
          ? 100
          : width < 1100
          ? 80
          : width <= 1200
          ? 90
          : width <= 1280
          ? 95
          : width < 1450
          ? 95
          : width < 1600
          ? 120
          : width < 1700
          ? 130
          : 150,
      headerAlign: 'right',
      accessor: 'SQNTY',
      align: 'right',
      renderCell: (param) => {
        if (param.value) {
          return FormatNumber2(param.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Sale Qnty.</div>,
    },

    {
      field: 'SAMOUNT',
      width:
        width < 768
          ? 100
          : width < 1100
          ? 75
          : width <= 1200
          ? 80
          : width <= 1280
          ? 95
          : width < 1450
          ? 95
          : width < 1600
          ? 120
          : width < 1700
          ? 120
          : 150,
      headerAlign: 'right',
      accessor: 'SAMOUNT',
      type: 'number',

      align: 'right',
      renderCell: (param) => {
        if (param.value) {
          return FormatNumber2(param.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Sale Amount</div>,
    },

    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 75
          : width <= 1200
          ? 80
          : width <= 1280
          ? 95
          : width < 1450
          ? 95
          : width < 1600
          ? 110
          : width < 1700
          ? 120
          : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'RQNTY',
      editable: 'true',
      field: 'RQNTY',
      cellClassName: 'super-app-theme--RQNTY',

      renderCell: (param) => {
        const rqnty = param.value;
        if (rqnty === 0) {
          return <div> {''}</div>;
        } else {
          return (
            <div
              className="font-semibold text-[15px] text-[#5c5656]
"
            >
              {FormatNumber2(param.value)}
            </div>
          );
        }
      },
      renderHeader: () => <div className="">Return Qnty.</div>,
    },
    {
      width:
        width < 768
          ? 100
          : width < 1100
          ? 75
          : width <= 1200
          ? 85
          : width <= 1280
          ? 90
          : width < 1450
          ? 95
          : width < 1600
          ? 110
          : width < 1700
          ? 120
          : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'RAMOUNT',
      type: 'number',

      field: 'RAMOUNT',
      renderCell: (param) => {
        const rAmount = param.row.RQNTY * param.row.SALE_PRICE;
        if (rAmount > 0) {
          return FormatNumber2(rAmount);
        } else {
          return '';
        }
      },
      renderHeader: () => <div>R.Amount</div>,
    },

    {
      flex: 1,
      field: 'Dlete',
      accessor: 'Dlete',
      align: 'center',
      renderHeader: () => <div>Delete</div>,
      renderCell: (p) => {
        return (
          <button
            className="w-full text-center "
            onClick={() => {
              setAlertTwoButtonModal({
                title: 'Alert',
                msg: 'Are you sure you want to delete this Item',
                status: true,
                button1: 'Yes',
                button1Click: () => {
                  DeleteItem(p.row.ICODE);
                },
                button2: 'Cancel',
                button2Click: () =>
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  }),
              });
            }}
          >
            <i
              style={{ fontSize: '25px', fontWeight: 'bolder', color: 'red' }}
              className="bi bi-x"
            ></i>
          </button>
        );
      },
    },
  ];
  return c;
};

export default IsssueColumns;
