import React from 'react';

import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';

import Collectlist from './collectlist';
import { selectWidth } from '../../Redux/profile';
import ModalHeaderStyle from '../styles/modalHeader';
import ModalBody from '../styles/modalBody';

export default function ModExpenseColllection({
  setStatus,
  status,
  stationId,
  stationName,
  date,
  setCollection,
}) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    boxShadow: 24,
  };

  const handleClose = () => {
    setStatus(false);
  };

  return (
    <div className="bg-white">
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={'rounded-lg overflow-hidden '}>
          <div className="w-full flex flex-col bg-white h-full ">
            <ModalHeaderStyle
              header={'Party wise Collection'}
              onClose={handleClose}
            />

            <ModalBody>
              <div className="flex-grow  bg-white    border-brown">
                <Collectlist
                  stationId={stationId}
                  stationName={stationName}
                  setStatus={setStatus}
                  setCollection={setCollection}
                  date={date}
                />
              </div>
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
}
