import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { SchemaValidateCompany } from './schemaValidate';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { InputAdornment, MenuItem } from '@mui/material';
import logo from '../utility/images/rootbillingHorinzontal.svg';
import { IconButton } from '@mui/material';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AlertModal from '../utility/component/modals/alert';
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import {
  selectStatus,
  setAlertMsg,
  setALertStatus,
  setCheckVerifyEmailAlert,
  setloading,
} from '../utility/Redux/modal';
import { BarLoader } from 'react-spinners';
import { toProperCase } from '../utility/component/format';
import moment from 'moment';
import RoutbillingLogo from '../utility/component/styles/routbillingLogo';
import Gicon from '../utility/images/go_logo.svg';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';
export const DesktopCompnySignup = () => {
  const override = {
    display: 'block',
    margin: '0 auto',
    top: 0,
    width: 'auto',
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [isCompanyExist, setCompnyExist] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [companyEmail, setCmpnyEmail] = useState();
  const [allReadyAddedUser, setAllReadyAddedUser] = useState();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const loading = useSelector(selectStatus);
  const countries = Country.getAllCountries();
  const inputprops = {
    sx: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      height: '32px',

      backgroundColor: 'white',
      // Adjust the font size as per your preference
      '& .MuiOutlinedInput-notchedOutline': {
        // Remove the default outline color
        border: '0.5px solid #6e6d6b',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#03a9f4', // Change to your desired color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '0.1px solid #03a9f4', // Change to your desired color on focus
      },
    },
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const inputLabels = {
    sx: {
      fontSize: '13px',
      display: 'flex',
      alignItems: 'center',
    },
  };

  function handleEnter(event) {
    if (event.key === 'Enter') {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);

      if (index !== 18) {
        form.elements[index + 2].focus();
      } else {
        form.elements[index + 3].focus();
      }

      event.preventDefault();
    }
  }
  const initialValues = {
    company: '',
    group: '',
    email: '',
    address: '',
    city: '',
    state: '',
    mobile: '',
    password: '',
    pincode: '',
    country: '',
    confirmPassword: '',
    userEmail: '',
    userName: '',
    companyPassword: '',
    station: '',
    station_id: '',
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowPassword(false), 1000);
    return () => clearTimeout(timer);
  }, [showPassword]);

  const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: SchemaValidateCompany,
    onSubmit: (values) => {
      const text = values.company;
      const words = text.split(' ');

      const result =
        words.reduce((acc, word) => {
          if (word) {
            acc += word[0];
          }
          return acc;
        }, '') + '_';
      if (!isUserExist) {
        const data = {
          user_email: values.userEmail,
          user_password: values.password,
          user_name: values.userName,
          user_type: 'MANAGER',
          user_status: 'ACTIVE',
          company_email: values.email,
          company_name: values.company,
          company_mobile: values.mobile,
          company_website: values.group,
          address: values.address,
          city: values.city,
          state: values.state,
          pin_code: values.pincode,
          user_role: 'Manager',
          currency_name: 'Rupee',
          currency_symbol: '₹',
          regd_no1: '',
          regd_no2: '',
          regd_no3: '',
          disputes: `All Disputes Subject to ${values.city}`,
          company_logo: '',
          table_prefix: result.toLowerCase(),
        };
        const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
        const email = values.userEmail;

        const hmac = CryptoJS.HmacSHA256(email, secretPass);

        const hmacString = hmac.toString(CryptoJS.enc.Base64);

        CallAPIPromise(
          `/api/users/registerCompany`,

          data,
          hmacString,
          dispatch
        )
          .then(function (response) {
            if (response.status === 200) {
              // localStorage.setItem('user',JSON.stringify(values))
              const code = response.data.results.insertId;
              createFinacialYear(code, values.userEmail, hmacString);
              localStorage.clear('rememberd');
              navigate('/auth/login', {
                state: {
                  signupEmail: values.userEmail,
                },
              });
              dispatch(setCheckVerifyEmailAlert(true));
            } else {
              alert(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  });

  const CompanyInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = values.email;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    dispatch(setloading(true));
    CallAPIPromise(
      `/api/users/companyInfo`,
      {
        company_email: values.email,
      },
      hmacString,
      dispatch
    )
      .then(async function (response) {
        if (response.data.response[0]) {
          dispatch(
            setAlertMsg(
              "It seems that the email you've provided is already associated with an existing account."
            )
          );
          dispatch(setALertStatus('true'));
          values.email = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const userInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = values.userEmail;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    dispatch(setloading(true));

    CallAPIPromise(
      `/api/users/userInfo`,
      {
        user_email: values.userEmail,
        user_mobile: '',
      },
      hmacString,
      dispatch
    )
      .then(async function (response) {
        if (response.data.response[0]) {
          dispatch(setAlertMsg('User exist with same email '));
          dispatch(setALertStatus(true));
          values.userEmail = '';
          setAllReadyAddedUser(values.userEmail);
          setUserExist(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const createFinacialYear = (code, email, hmac) => {
    const data = {
      company_code: code,
      user_email: email,
      user_mobile: '',
    };
    CallAPIPromise(
      `/api/users/createFinancialYear`,

      data,
      hmac,
      dispatch
    )
      .then(async function (response) {
        console.log('');
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(values.email)) {
      CompanyInfo();
    }
    if (companyEmail !== values.email) {
      setCompnyExist(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (isCompanyExist) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(values.userEmail)) {
        userInfo();
      }
    }
    if (allReadyAddedUser !== values.userEmail) {
      setUserExist(false);
    } else {
      setUserExist(true);
    }
  }, [values.userEmail, values.email]);

  useEffect(() => {
    if (values.country) {
      const selectedCountry = countries.filter(
        (country) => country.name === values.country
      );
      setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
    }
  }, [values.country]);
  useEffect(() => {
    if (values.state) {
      const selectedstate = stateList.filter(
        (state) => state.name === values.state
      );
      setCitylist(
        City.getCitiesOfState(
          selectedstate[0].countryCode,
          selectedstate[0].isoCode
        )
      );
    }
  }, [values.state]);
  return (
    <div className=" items-center flex   px-10 w-screen   h-screen  justify-center bg-white  ">
      <div
        className={`  bg-white   rounded-lg t:h-6/12  d:w-6/12 t:w-8/12  pb-4   `}
      >
        <div className=" rounded-lg">
          <div className="w-full  items-center flex justify-center ">
            <div>
              <RoutbillingLogo />
            </div>
          </div>
          <div
            style={{ fontSize: 20 }}
            className="flex w-full justify-center text-black pb-3 font-medium "
          >
            Sign Up
          </div>

          <BarLoader
            color={'#F5874F'}
            loading={loading}
            cssOverride={override}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={1}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex w-full  ">
            {/* company details */}
            <div className="flex-1  ">
              <div
                style={{ fontSize: 15, fontWeight: '600' }}
                className="flex justify-center text-black   pt-2"
              >
                Company Details
              </div>
              <div className="w-full ">
                <div className="flex w-full">
                  <div className="w-full px-6  ">
                    <div
                      style={{ height: '32px' }}
                      className="flex mt-4 t:mt-3  items-center"
                    >
                      <TextField
                        onKeyDown={handleEnter}
                        InputLabelProps={inputLabels}
                        InputProps={inputprops}
                        type="email"
                        size={'small'}
                        name="email"
                        id="email"
                        label={values.email ? 'Company Email' : ''}
                        onBlur={handleBlur}
                        error={errors.email ? true : false}
                        fullWidth
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.email}
                        placeholder="Company Email"
                      />
                    </div>

                    <div
                      style={{ height: '32px' }}
                      className="flex mt-4 t:mt-3 items-center"
                    >
                      <TextField
                        size={'small'}
                        onKeyDown={handleEnter}
                        type="group"
                        name="group"
                        id="group"
                        InputLabelProps={inputLabels}
                        InputProps={inputprops}
                        label={
                          isCompanyExist ? null : values.group ? 'Website' : ''
                        }
                        onBlur={handleBlur}
                        error={
                          isCompanyExist
                            ? false
                            : values.group && errors.group
                            ? true
                            : false
                        }
                        fullWidth
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.group}
                        placeholder="Website"
                      />
                    </div>
                    <div
                      style={{ height: '32px' }}
                      className="flex  w-full items-center mt-4 t:mt-3 "
                    >
                      <TextField
                        fullWidth
                        type="company"
                        name="company"
                        id="company"
                        InputLabelProps={inputLabels}
                        InputProps={inputprops}
                        onKeyDown={handleEnter}
                        label={
                          isCompanyExist
                            ? null
                            : values.company
                            ? 'Comapany Name'
                            : ''
                        }
                        error={
                          isCompanyExist
                            ? false
                            : values.company && errors.company
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.company}
                        size={'small'}
                        placeholder="Company Name"
                      />
                    </div>
                    <div className="flex mt-4 t:mt-3  items-center">
                      <div style={{ height: 32 }} className="w-full">
                        <TextField
                          size={'small'}
                          type="number"
                          name="mobile"
                          id="mobile"
                          InputLabelProps={inputLabels}
                          InputProps={inputprops}
                          onKeyDown={handleEnter}
                          label={
                            isCompanyExist
                              ? null
                              : values.mobile
                              ? 'Mobile'
                              : ''
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.mobile && errors.mobile
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.mobile}
                          placeholder="Mobile"
                        />
                      </div>
                    </div>
                    <div
                      style={{ height: '32px' }}
                      className="flex mt-4 t:mt-3  items-center"
                    >
                      <div className="w-full">
                        <TextField
                          size={'small'}
                          type="address"
                          name="address"
                          id="address"
                          InputLabelProps={inputLabels}
                          InputProps={inputprops}
                          onKeyDown={handleEnter}
                          label={
                            isCompanyExist
                              ? null
                              : values.address
                              ? 'Address'
                              : ''
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.address && errors.address
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.address}
                          placeholder="Address"
                        />
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 t:mt-3 w-full items-center"
                      >
                        <div className=" w-full">
                          <TextField
                            select
                            size={'small'}
                            type="text"
                            InputLabelProps={inputLabels}
                            InputProps={inputprops}
                            name="country"
                            onKeyDown={handleEnter}
                            id="country"
                            label={
                              isCompanyExist
                                ? null
                                : values.country
                                ? 'Country'
                                : 'Country'
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.country && errors.country
                                ? true
                                : false
                            }
                            fullWidth
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.country}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the height as needed
                                    maxWidth: 150,
                                  },
                                },
                              },
                            }}
                          >
                            {countries.map((e, index) => (
                              <MenuItem
                                id="country"
                                name="country"
                                label="Country"
                                key={index}
                                value={e.name}
                              >
                                {e.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>

                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 t:mt-3  w-full  ml-1 items-center"
                      >
                        <TextField
                          size={'small'}
                          type="state"
                          select
                          onKeyDown={handleEnter}
                          // InputLabelProps={{
                          //   style: { display: 'flex', alignItems: 'center' },
                          // }}
                          InputLabelProps={inputLabels}
                          InputProps={inputprops}
                          name="state"
                          id="state"
                          label={
                            isCompanyExist
                              ? null
                              : values.state
                              ? 'State'
                              : 'State'
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.state && errors.state
                              ? true
                              : false
                          }
                          fullWidth
                          placeholder="State"
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.state}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: 200, // Adjust the height as needed
                                  maxWidth: 150,
                                },
                              },
                            },
                          }}
                        >
                          {stateList.map((e, index) => (
                            <MenuItem
                              id="country"
                              name="country"
                              label="Country"
                              key={index}
                              value={e.name}
                            >
                              {e.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div className="flex">
                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 t:mt-3 w-full items-center"
                      >
                        <div className=" w-full">
                          <TextField
                            select
                            size={'small'}
                            type="city"
                            InputLabelProps={inputLabels}
                            InputProps={inputprops}
                            SelectProps={{
                              MenuProps: {
                                PaperProps: {
                                  style: {
                                    maxHeight: 200, // Adjust the height as needed
                                    maxWidth: 150,
                                  },
                                },
                              },
                            }}
                            name="city"
                            onKeyDown={handleEnter}
                            id="city"
                            label={
                              isCompanyExist
                                ? 'City'
                                : values.city
                                ? 'City'
                                : 'City'
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.city && errors.city
                                ? true
                                : false
                            }
                            fullWidth
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.city}
                          >
                            {cityList.map((e, index) => (
                              <MenuItem
                                id="city"
                                name="city"
                                label="City"
                                key={index}
                                value={e.name}
                              >
                                {e.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </div>

                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 t:mt-3  w-full  ml-1 items-center"
                      >
                        <TextField
                          size={'small'}
                          type="text"
                          onKeyDown={handleEnter}
                          InputLabelProps={inputLabels}
                          InputProps={inputprops}
                          name="pincode"
                          id="pincode"
                          label={
                            isCompanyExist
                              ? null
                              : values.pincode
                              ? 'Pincode'
                              : ''
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.pincode && errors.pincode
                              ? true
                              : false
                          }
                          fullWidth
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.pincode}
                          placeholder="Pincode"
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ width: 1, backgroundColor: '#e0e0e0' }} />
                </div>
              </div>
            </div>

            {/* user details */}
            <div className="flex-1  justify-between">
              <div>
                <div
                  style={{ fontSize: 15, fontWeight: '600' }}
                  className="flex justify-center text-black  pt-2"
                >
                  User Details
                </div>
                <div className="w-full px-6 ">
                  <div className="flex mt-4 t:mt-3  items-center">
                    <div style={{ height: 32 }} className="w-full">
                      <TextField
                        size={'small'}
                        InputLabelProps={inputLabels}
                        InputProps={inputprops}
                        type="email"
                        name="userEmail"
                        onKeyDown={handleEnter}
                        id="userEmail"
                        label={
                          values.userEmail
                            ? isUserExist
                              ? null
                              : 'User Email'
                            : ''
                        }
                        onBlur={handleBlur}
                        error={
                          values.userEmail
                            ? errors.userEmail || isUserExist
                              ? true
                              : false
                            : false
                        }
                        fullWidth
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.userEmail}
                        placeholder="User Email"
                      />
                    </div>
                  </div>

                  <div className="flex w-full items-center ">
                    <div style={{ height: 32 }} className=" w-full mt-4 t:mt-3">
                      <TextField
                        fullWidth
                        type="userName"
                        name="userName"
                        id="userName"
                        InputLabelProps={inputLabels}
                        InputProps={inputprops}
                        onKeyDown={handleEnter}
                        label={values.userName ? 'User Name' : ''}
                        error={
                          values.userName && errors.userName ? true : false
                        }
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.userName}
                        size={'small'}
                        placeholder="User Name"
                      />
                    </div>
                  </div>

                  <div className="flex mt-4 t:mt-3 items-center ">
                    <div style={{ height: 32 }} className="w-full flex">
                      <TextField
                        size={'small'}
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        error={
                          values.password && errors.password ? true : false
                        }
                        label={values.password ? '' : 'Password'}
                        name="password"
                        onKeyDown={handleEnter}
                        id="password"
                        InputLabelProps={inputLabels}
                        placeholder="Password"
                        InputProps={{
                          sx: {
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            height: '32px',

                            backgroundColor: 'white',
                            // Adjust the font size as per your preference
                            '& .MuiOutlinedInput-notchedOutline': {
                              // Remove the default outline color
                              border: '0.5px solid #6e6d6b',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#03a9f4', // Change to your desired color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '0.1px solid #03a9f4', // Change to your desired color on focus
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: '14',
                            height: 32,

                            justifyContent: 'center',
                          },
                        }}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        // value={values.password}
                      />
                    </div>
                  </div>

                  <div className="flex mt-4 t:mt-3 w-full items-center">
                    <div style={{ height: 32 }} className="w-full ">
                      <TextField
                        size={'small'}
                        fullWidth
                        label={values.confirmPassword ? '' : 'Confirm Password'}
                        error={
                          values.confirmPassword && errors.confirmPassword
                            ? true
                            : false
                        }
                        type={showPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        onKeyDown={handleEnter}
                        id="confirmPassword"
                        InputLabelProps={inputLabels}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        // value={values.confirmPassword}
                        InputProps={{
                          sx: {
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: '14px',
                            height: '32px',

                            backgroundColor: 'white',
                            // Adjust the font size as per your preference
                            '& .MuiOutlinedInput-notchedOutline': {
                              // Remove the default outline color
                              border: '0.5px solid #6e6d6b',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#03a9f4', // Change to your desired color on hover
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: '0.1px solid #03a9f4', // Change to your desired color on focus
                            },
                          },
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ marginLeft: 1 }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          style: {
                            fontSize: '14',
                            height: 32,

                            justifyContent: 'center',
                          },
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full  mt-3  flex justify-center ">
                    <button
                      type="button"
                      onClick={() => {
                        if (!values.email || errors.email) {
                          dispatch(setAlertMsg('Please enter company email'));
                          dispatch(setALertStatus(true));
                        } else {
                          if (!values.company || errors.company) {
                            dispatch(setAlertMsg('Please enter company name'));
                            dispatch(setALertStatus(true));
                          } else {
                            if (!values.group || errors.group) {
                              dispatch(setAlertMsg('Please enter website'));
                              dispatch(setALertStatus(true));
                            } else {
                              if (!values.address || errors.address) {
                                dispatch(
                                  setAlertMsg('Please enter company address')
                                );
                                dispatch(setALertStatus(true));
                              } else {
                                if (!values.city || errors.city) {
                                  dispatch(setAlertMsg('Please enter city'));
                                  dispatch(setALertStatus(true));
                                } else {
                                  if (!values.state || errors.state) {
                                    dispatch(setAlertMsg('Please enter state'));
                                    dispatch(setALertStatus(true));
                                  } else {
                                    if (!values.mobile || errors.mobile) {
                                      dispatch(
                                        setAlertMsg(
                                          'Please enter company mobile'
                                        )
                                      );
                                      dispatch(setALertStatus(true));
                                    } else {
                                      if (
                                        !values.userEmail ||
                                        errors.userEmail
                                      ) {
                                        dispatch(
                                          setAlertMsg('Please enter user email')
                                        );
                                        dispatch(setALertStatus(true));
                                      } else {
                                        if (
                                          !values.userName ||
                                          errors.userName
                                        ) {
                                          dispatch(
                                            setAlertMsg(
                                              'Please enter user name'
                                            )
                                          );
                                          dispatch(setALertStatus(true));
                                        } else {
                                          if (
                                            !values.password ||
                                            errors.password
                                          ) {
                                            dispatch(
                                              setAlertMsg(
                                                'Please enter password'
                                              )
                                            );
                                            dispatch(setALertStatus(true));
                                          } else {
                                            if (
                                              !values.confirmPassword ||
                                              errors.confirmPassword
                                            ) {
                                              dispatch(
                                                setAlertMsg(
                                                  'Please enter confirm password'
                                                )
                                              );
                                              dispatch(setALertStatus(true));
                                            } else {
                                              if (!values.country) {
                                                dispatch(
                                                  setAlertMsg(
                                                    'Please select country'
                                                  )
                                                );
                                                dispatch(setALertStatus(true));
                                              } else {
                                                handleSubmit();
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }}
                      className="w-full  flex justify-center "
                    >
                      <div
                        className=" py-2 rounded-lg bg-[#383535]"
                        style={{ width: '100%' }}
                      >
                        <div className="text-white font-semibold">
                          <div> Sign Up</div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='w-full flex justify-center pt-2 '>
            <Button type='submit'  variant='contained' color='orange' sx={{width:'60%'}} > <div >Signup </div> </Button> 
                  </div> */}
        </form>
        <AlertModal />

        <div className="w-full flex justify-center text-sm  pt-6">
          Already have an account ?
          <div
            onClick={() => navigate('/auth/login')}
            className="text-orange font-medium pl-2"
          >
            Login
          </div>
        </div>
        <button
          onClick={() => {
            window.open('https://geniusoffice.com', '_blank');
          }}
          className="mt-10 text-[13px] w-full justify-center items-center text-brown flex"
        >
          A product of{' '}
          <div className="pl-1 flex items-center">
            <img
              src={Gicon}
              style={{
                width: '14px',
                height: '14px',
              }}
            />
          </div>
          <label
            onClick={() => {
              window.open('https://geniusoffice.com', '_blank');
            }}
            className="font-semibold pl-1 pr-1 hover:cursor-pointer"
          >
            {' '}
            genius office
          </label>
          2023 All rights reserved.
        </button>
      </div>
    </div>
  );
};
