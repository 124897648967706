const DistanceCalculator = (driverLat, driverLong, userLat, userLong) => {
  const earthRadius = 6371; // Radius of the earth in kilometers
  const dLat = degToRad(userLat - driverLat);
  const dLon = degToRad(userLong - driverLong);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degToRad(driverLat)) *
      Math.cos(degToRad(userLat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
};

const degToRad = (degrees) => {
  return degrees * (Math.PI / 180);
};

export default DistanceCalculator;
