import React from 'react';
import PartyBalanceTable from './partyBalanceTable';
import './print.css';

const PrintPartyBalanceReport = ({ data, date, date1, stationId }) => {
  const columns = [
    {
      width: 100, // Numeric value for width
      accessor: 'ACCOUNT_NO',
      Header: 'Account No',
    },
    {
      // flex:1,
      width: 200,
      accessor: 'NAME',
      Header: 'Name',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'ADDRESS',
      Header: 'Address',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'MOBILE',
      Header: 'Mobile',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'SID',
      Header: 'RID',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'OP_CREDIT',
      Header: 'Opening Debit',
      // cell: ({cell}) => cell.Value === 0 ? "" : cell.Value
    },
    {
      width: 100, // Numeric value for width
      accessor: 'OP_DEBIT',
      Header: 'Opening Credit',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'DEBIT',
      Header: 'Debit',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'CREDIT',
      Header: 'Credit',
    },
    {
      width: 100, // Numeric value for width
      accessor: 'balance',
      Header: 'Balance',
    },
  ];

  const companyName = localStorage.getItem('company');

  return (
    <>
      <div
        id="main"
        style={{ width: '100%', height: '100%', backgroundColor: 'white' }}
      >
        <div
          id="print-header"
          style={{
            width: '96%',
            border: '1px solid black',
            height: ' 10%',
            borderRadius: '7px',
            marginLeft: '2%',
          }}
          className="  items-center px-5 py-3"
        >
          <div style={{ width: '100%' }}>
            <p
              className="flex justify-center items-center font-semibold"
              style={{ fontSize: '20px' }}
            >
              {companyName}
            </p>
          </div>
          <div
            style={{ width: '100%', flexGrow: '1', boxSizing: 'border-box' }}
            className="flex justify-center items-center mt-1"
          >
            <p className="flex justify-center">Party Balance Report : </p>

            <input
              style={{ width: '9%' }}
              className="ml-3"
              value={date}
              readOnly
            />
            <p className="flex justify-center">To</p>

            <input
              style={{ width: '9%' }}
              className="ml-3"
              value={date1}
              readOnly
            />
            {stationId ? (
              <div className="flex">
                [ Route Id : <div>{stationId}</div>]
              </div>
            ) : null}
          </div>
        </div>
        <div
          id="print-component"
          className="p-5 "
          style={
            window.innerWidth < 600
              ? {
                  width: '100%',
                  height: 'auto',
                  overflow: 'hidden',
                  marginTop: '10%',
                }
              : {
                  width: '100%',
                  height: 'auto',
                  overflow: 'hidden',
                  marginTop: '6.5%',
                }
          }
        >
          {data ? (
            <PartyBalanceTable
              data={data}
              columns={columns}
              date={date}
              date1={date1}
              stationId={stationId}
            />
          ) : null}
        </div>
        <div></div>
      </div>
    </>
  );
};

export default PrintPartyBalanceReport;
