import { Modal, Skeleton } from '@mui/material';
import React, { useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { BackButton } from '../buttons';
import { useSelector } from 'react-redux';
import { selectWidth } from '../../Redux/profile';
import Fade from '@mui/material/Fade';
import RouteBillingLogo from '../styles/routeBillingLogo';
import GeniusAlphatecKeyboard from '../geniusAlphatecKeyboard';
import { selectSpinnerLoading } from '../../Redux/modal';

const MasterHelp = ({
  status,
  style,
  title,
  onClose,
  rowId,
  list,
  columns,
  onRowClick,
  searchQuery,
  setSearchQuery,
  loading,
}) => {
  const headerRef = useRef(null);
  const windowWidth = useSelector(selectWidth);
  const spinerLoading = useSelector(selectSpinnerLoading);
  return (
    <Modal
      open={status}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade
        in={status}
        style={{
          transitionDelay: status ? '100ms' : '0ms',
        }}
      >
        <div style={style}>
          <div className="w-full flex flex-col bg-white h-full ">
            <div ref={headerRef}>
              <div
                style={{
                  border: '1px solid black',
                  borderTopRightRadius: '8px',
                  borderTopLeftRadius: '8px',
                  overflow: 'hidden',
                  borderBottom: '0px',
                }}
                className="flex flex-col bg-white Fade"
              >
                <div
                  style={{
                    fontSize: '14px',
                  }}
                  className="font-semibold text-[#4a4a4a] bg-[#f5f0e8] font-arial p-3"
                >
                  {title}
                </div>
              </div>
            </div>
            <div
              style={{
                height: headerRef.current
                  ? `calc(100% - ${headerRef.current.clientHeight}px)`
                  : '80%',
                border: '1px solid black',
                borderBottom: '0px',
              }}
              className="t:flex "
            >
              <div className="h-full ">
                <div
                  style={{
                    height: `calc(100% - ${38}px)`,
                    width:
                      columns.reduce(
                        (total, row) => Number(total) + Number(row.width),
                        0
                      ) + 20,
                  }}
                >
                  {!spinerLoading ? (
                    <DataGrid
                      onRowClick={onRowClick}
                      headerHeight={0}
                      hideFooter={list.length < 100}
                      rowHeight={30}
                      showCellRightBorder={true}
                      showColumnRightBorder={true}
                      disableColumnMenu={true}
                      getRowId={rowId}
                      loading={loading}
                      components={{
                        Header: () => {
                          const headerDetails = columns;
                          return (
                            <div className="  bg-[#f5f0e8] flex ">
                              {headerDetails?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="  text-#4a4a4a flex justify-center items-center py-2  "
                                    style={
                                      item.flex
                                        ? index < headerDetails.length - 1
                                          ? {
                                              flex: item.flex,
                                              borderRight: '1px solid black',
                                              borderBottom: '1px solid black',
                                            }
                                          : {
                                              flex: item.flex,
                                              borderBottom: '1px solid black',
                                            }
                                        : index < headerDetails.length - 1
                                        ? {
                                            width: item.width,

                                            borderRight:
                                              item.width > 0
                                                ? '1px solid black'
                                                : '0px solid black',
                                            borderBottom: '1px solid black',
                                          }
                                        : {
                                            width: item.width,
                                            borderBottom: '1px solid black',
                                          }
                                    }
                                  >
                                    {item.renderHeader()}
                                  </div>
                                );
                              })}
                            </div>
                          );
                        },
                        NoRowsOverlay: () => {
                          return (
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              No Records Found
                            </div>
                          );
                        },
                      }}
                      sx={
                        window.innerWidth < 770
                          ? {
                              backgroundColor: 'white',

                              fontSize: 13,
                              '& .super-app-theme--header': {
                                backgroundColor: '#f5f0e8',
                                display: 'none',
                              },
                              borderRadius: 0,
                            }
                          : {
                              backgroundColor: 'white',
                              fontSize: 15,
                              borderBottom: '0px solid black',
                              borderRadius: 0,
                              '& .super-app-theme--header': {
                                backgroundColor: '#f5f0e8',

                                display: 'none',
                              },
                            }
                      }
                      columns={columns}
                      rows={list}
                      experimentalFeatures={{ newEditingApi: true }}
                      disableColumnSelector
                      disableColumnReorder
                      pageSize={100}
                      rowsPerPageOptions={[100]}
                    />
                  ) : (
                    <div className="w-full ">
                      <div className="flex w-full ">
                        {columns.map((e, i) => {
                          return (
                            <Skeleton
                              key={i}
                              width={e.width}
                              height={60}
                              style={{
                                marginLeft: 3,
                              }}
                            />
                          );
                        })}
                      </div>
                      <div>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                          return (
                            <div className="flex ">
                              {columns.map((e, i) => {
                                return (
                                  <Skeleton
                                    key={i}
                                    width={e.width}
                                    height={50}
                                    style={{
                                      marginLeft: 3,
                                    }}
                                  >
                                    {' '}
                                  </Skeleton>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  style={{
                    borderBottom: '0px solid black',

                    fontSize: '14px',
                    backgroundColor: '#f5f0e8',
                  }}
                  className="flex p-2 "
                >
                  <i className="bi bi-search pr-2 text-orange"></i>
                  <input
                    type={'text'}
                    placeholder="Search"
                    value={searchQuery}
                    {...(window.innerWidth > 1280 && { autoFocus: true })}
                    onChange={(t) => {
                      setSearchQuery(t.target.value);
                    }}
                    className="w-full outline-0 bg-[#f5f0e8] text-[#4a4a4a]"
                  />
                </div>
              </div>
              {windowWidth <= 1280 && windowWidth >= 1024 && (
                <div className=" h-full border   flex flex-col ">
                  <div className=" w-full flex justify-center  flex-col grow items-center  ">
                    {/* <img src={tailoringLogo} className="w-44 flex" /> */}
                    <RouteBillingLogo />
                  </div>

                  <div className="  justify-end">
                    <GeniusAlphatecKeyboard
                      text={searchQuery}
                      setText={setSearchQuery}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                borderTop: '1px dotted black',
                borderLeft: '1px dotted black',
                borderRight: '1px dotted black',
                borderBottom: '1px dotted black',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              }}
              className="w-full flex justify-end p-2 bg-white  "
            >
              <BackButton onClick={onClose} />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default MasterHelp;
