import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setQntyEditICODE,
  setQntyEditModalStatus,
  updateOrderlist,
} from '../../../../utility/Redux/tableOrder';
import { setALertStatus, setAlertMsg } from '../../../../utility/Redux/modal';

const ItemCart = ({ columnsMobileView, columns, orderList }) => {
  const dispatch = useDispatch();
  const editCell = ({ field, id, value }) => {
    if (field === 'SALE_PRICE') {
      const index = orderList.findIndex((item) => item.id === id);
      const oldSalePrice = orderList[index].BASIC_RT;
      if (Number(oldSalePrice) <= Number(value)) {
        const updatedItem = {
          ...orderList[index],
          SALE_PRICE: Number(value),
          AMOUNT: Number(value) * Number(orderList[index].QNTY),
        };
        const updatedItems = [...orderList];
        updatedItems[index] = updatedItem;
        dispatch(updateOrderlist(updatedItems));
      } else {
        const index = orderList.findIndex((item) => item.id === id);
        const oldSalePrice = orderList[index].SALE_PRICE;
        const updatedItem = {
          ...orderList[index],
          SALE_PRICE: oldSalePrice,
        };
        const updatedItems = [...orderList];
        updatedItems[index] = updatedItem;
        dispatch(updateOrderlist(updatedItems));
        dispatch(
          setAlertMsg('Sale Price can not be lower than its actual sale price.')
        );
        dispatch(setALertStatus(true));
      }
    }
  };

  return (
    <DataGrid
      hideFooterPagination
      hideFooter
      disableColumnMenu
      disableColumnFilter
      hideFooterRowCount
      headerHeight={0}
      onCellDoubleClick={(param) => {
        if (param.field === 'QNTY') {
          dispatch(setQntyEditModalStatus(true));
          dispatch(setQntyEditICODE(param.row.ICODE));
        }
      }}
      hideFooterSelectedRowCount
      scrollbarSize={0}
      sx={{
        borderBottom: '0px',
        borderRight: 0,
        borderLeft: 0,
        borderRadius: '2px',
        borderTop: 0,
        overflow: 'hidden',
        fontSize: window.innerWidth <= 764 ? 11 : 13,
        '& .super-app-theme--header': {
          backgroundColor: '#383535',
          fontFamily: 'Verdana',
          display: 'none',
        },

        color: 'black',
      }}
      components={{
        Header: () => {
          const headDetail =
            window.innerWidth < 770 ? columnsMobileView : columns;
          return (
            <div
              style={{
                border: '1px solid #bab5ad',
                overflow: 'hidden',

                borderBottom: '0px',
                borderLeft: 0,
                borderRight: 0,
                borderTop: 0,
              }}
              className="  bg-[#e6e6e3] flex "
            >
              {headDetail?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="  bg-[#383535]  text-black flex justify-center items-center  py-2 "
                    style={
                      item.flex
                        ? {
                            flex: item.flex,
                            borderRight:
                              index + 1 < columns.length
                                ? '1px solid #bdb3a2'
                                : '0px',
                            fontSize: '12px',
                          }
                        : {
                            width: item.width,
                            fontSize: '12px',
                            borderRight:
                              index + 1 < columns.length
                                ? '1px solid #bdb3a2'
                                : '0px',
                          }
                    }
                  >
                    {item.renderHeader()}
                  </div>
                );
              })}
            </div>
          );
        },
        NoRowsOverlay: () => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
              }}
            >
              No Records Found
            </div>
          );
        },
      }}
      rowHeight={34}
      columns={window.innerWidth < 770 ? columnsMobileView : columns}
      rows={orderList}
      pageSize={100}
      rowsPerPageOptions={[100]}
      showCellRightBorder={true}
      showColumnRightBorder={true}
      //   onCellClick={handleCellClick}
      editMode={'cell'}
      onCellEditCommit={editCell}
    />
  );
};

export default ItemCart;
