import { Fade, Modal } from '@mui/material';
import React from 'react';
import {
  SelectDepartmentMasterStatus,
  setDepartmentMasterStatus,
} from '../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import ModalBody from '../utility/component/styles/modalBody';
import GeniusDepartmentMaster from './geniusDepartmentMaster';

const Department_Master = () => {
  const dispatch = useDispatch();
  const status = useSelector(SelectDepartmentMasterStatus);

  const handleClose = () => {
    dispatch(setDepartmentMasterStatus(false));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: '2px solid black',
  };

  return (
    <div>
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={status}>
          <div style={style} className={'rounded-lg overflow-hidden '}>
            <div className="w-full flex flex-col bg-white h-full ">
              <ModalHeaderStyle
                header={'Department Master'}
                onClose={handleClose}
              />

              <ModalBody>
                <GeniusDepartmentMaster />
              </ModalBody>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Department_Master;
