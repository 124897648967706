import { Close } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import React from 'react';

const SectionComponent = ({ sectionList, setSname, sname }) => {
  return (
    <div
      style={{
        border: '1px solid #e0e0e0',
      }}
      className="mt-2 p-1 bg-white mr-3 rounded-sm h-full w-full"
    >
      <div className="flex overflow-x-scroll p-1 w-full ">
        {sectionList[0]
          ? sectionList.map((sec, index) => {
              return (
                <button
                  key={index}
                  style={{
                    height: '45px',
                    borderRadius: '7px',
                    textTransform: 'none',
                    backgroundColor:
                      sname === sec.SECTION_NAME ? '#f7e207' : '#f5eb82',
                    fontSize: '12px',
                    color: '#4a4a4a',
                    width: '100%',
                    marginRight: '6px',
                  }}
                  className="text-[#4a4a4a] flex items-center px-3 p-2 rounded-sm  whitespace-nowrap "
                >
                  <div
                    onClick={() => {
                      setSname(sec.SECTION_NAME);
                    }}
                  >
                    {sec.SECTION_NAME.toUpperCase()}
                  </div>

                  {sec.SECTION_NAME === sname && (
                    <Close
                      sx={{
                        color: '#4f4801',
                        marginLeft: '6px',
                        zIndex: '200',
                      }}
                      onClick={() => {
                        setSname('');
                      }}
                      fontSize={'small'}
                    />
                  )}
                </button>
              );
            })
          : [
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
            ].map(() => {
              return (
                <Skeleton
                  variant="rectangular"
                  width={300}
                  height={47}
                  sx={{
                    fontSize: '1rem',
                    borderRadius: '7px',
                    marginRight: '6px',
                  }}
                />
              );
            })}
      </div>
    </div>
  );
};

export default SectionComponent;
