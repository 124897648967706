import { setSpinnerLoading } from '../utility/Redux/modal';
import { DeleteUSerModuleMasetr } from './function';

export const ModuleMasterColumns = (width) => {
  const c = [
    {
      field: 'TAB_NAME',
      type: 'string',
      renderHeader: (params) => <div>Tab Name</div>,
      width: 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'MODULE_NAME',
      type: 'string',
      renderHeader: (params) => <div>Module Name</div>,
      width: 400,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  return c;
};

export const UserModuleMasterColumns = (
  width,
  setUserModuleList,
  token,
  dispatch
) => {
  const c = [
    {
      field: 'TAB_NAME',
      type: 'string',
      renderHeader: (params) => <div>Tab Name</div>,
      width: 200,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'MODULE_NAME',
      type: 'string',
      renderHeader: (params) => <div>Module Name</div>,
      width: 400,
      headerAlign: 'center',
      align: 'left',
    },
    {
      field: 'Delete',
      type: 'string',
      renderHeader: (params) => <div>Delete</div>,
      width: 80,
      align: 'center',
      renderCell: (params) => {
        return (
          <button
            onClick={() => {
              dispatch(setSpinnerLoading('Deleting'));
              DeleteUSerModuleMasetr(
                params.row.ID,
                params.row.USER_ID,
                token,
                (e) => {
                  if (!e.data.error) {
                    setUserModuleList((p) => {
                      return p.filter((u) => u.ID !== params.row.ID);
                    });
                  }
                },
                dispatch,
                () => {
                  dispatch(setSpinnerLoading(false));
                }
              );
            }}
            className="w-full flex justify-center"
          >
            <i className="bi text-red-500 bi-x-lg"></i>
          </button>
        );
      },
      headerAlign: 'center',
      align: 'left',
    },
  ];

  return c;
};
