import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setIssueStockItem,
  setSessionExpired,
  setSpinnerLoading,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../Redux/modal';
import axios from 'axios';
import {
  selectLatitude,
  selectLongitude,
  selectPrefix,
  selectWidth,
} from '../../Redux/profile';
import moment from 'moment';
import { selectToken } from '../../Redux/security';
import { useState } from 'react';
import { FormatNumber2 } from '../tools';
import PartyWiseFooter from '../partyWiseFotter';
import AlertModalTwoButton from './alertWithTwoButton';
import { Close } from '@mui/icons-material';
import ModNumericKeyboard from '../../../routeBilling/menu/dashboard/biiling/modal/modalNumericKeyBoard';
import GeniusDatagrid from '../datagrid/geniusDatagrid';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

const Collectlist = ({
  rows,
  stationId,
  stationName,
  setStatus,
  setCollection,
  date,
}) => {
  const dispatch = useDispatch();
  const [list, setlist] = useState([]);
  const [voucherList, setVoucherList] = useState([]);
  const [searchCode, setSearchCode] = useState('');
  const [filterdList, SetFilterdList] = useState([]);
  const lattitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const windowWidth = useSelector(selectWidth);
  const deleteVoucher = (voucher) => {
    setAlertTwoButtonModal({
      ...alertTwoButtonModal,
      status: false,
    });
    const data = {
      table_prefix: prefix,
      VOUCHER_NO: voucher,
    };
    dispatch(setSpinnerLoading('Loading'));
    CallAPIPromise(
      `/api/voucherRoute/deleteRowVoucherGrid`,

      data,
      token,
      dispatch
    )
      .then(async function (response) {
        if (!response.data.error) {
          setlist((p) =>
            p.map((p) => {
              if (p.VOUCHER_NO === voucher) {
                return {
                  ...p,
                  AMOUNT_RECEIVED: 0,
                };
              } else {
                return {
                  ...p,
                };
              }
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const columns = [
    {
      width: windowWidth > 768 ? 80 : 60,
      headerAlign: 'center',
      align: 'left',
      field: 'ACCOUNT_NO',
      renderHeader: (params) => <div>Acc No.</div>,
    },
    {
      width: windowWidth > 768 ? 320 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width: windowWidth > 768 ? 130 : 120,

      field: 'PAY_MODE',
      renderHeader: (params) => <div>Pay Mode</div>,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const index = list.findIndex(
          (item) => item.ACCOUNT_NO === params.row.ACCOUNT_NO
        );
        return (
          <select
            id="countryDropdown"
            value={list[index].PAY_MODE !== '' ? list[index].PAY_MODE : 'Cash'}
            onChange={(e) => {
              const updatedItem = {
                ...list[index],
                PAY_MODE: e.target.value,
                AMOUNT_DESC: `Amount Received by ${e.target.value}`,
              };
              const updatedItems = [...list];
              updatedItems[index] = updatedItem;
              setlist(updatedItems);
            }}
            className="w-full text-sm t:text-t lg:text-sm pl-2   focus:outline-none border border-dotted border-[#b5b3b3] border-l-0 focus:border-solid focus:border-blue-300 text-[#4a4a4a]"
            style={{
              borderRadius: '0px 7px 7px 0px',
              border: '0px dotted #b5b3b3',
              backgroundColor: 'transparent',
              color: '#4a4a4a',
            }}
          >
            {['Cash', 'Card', 'ePayment', 'BTC'].map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      width: windowWidth > 768 ? 120 : 100,
      field: 'AMOUNT_RECEIVED',
      renderHeader: (params) => <div>Amount Received</div>,
      headerAlign: 'center',
      editable: true,
      align: 'right',
      renderCell: (param) => {
        if (param.value) {
          return (
            <div className="flex   ">
              <div> {FormatNumber2(param.value)}</div>
              {param.row.VOUCHER_NO > 0 && (
                <button>
                  <Close
                    onClick={() => {
                      setAlertTwoButtonModal({
                        title: 'Alert',
                        msg: 'Are you sure you want to delete this Item',
                        status: true,
                        button1: 'Yes',
                        button1Click: () => {
                          deleteVoucher(param.row.VOUCHER_NO);
                        },
                        button2: 'Cancel',
                        button2Click: () =>
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,
                            status: false,
                          }),
                      });
                    }}
                    color="red"
                    sx={{
                      color: 'red',
                      marginLeft: '8px',
                      fontSize: '18px',
                    }}
                  />
                </button>
              )}
            </div>
          );
        } else {
          return '';
        }
      },
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getCollection = () => {
    const data = {
      table_prefix: prefix,
      SID: stationId,
      DATE: moment(date).format('YYYY-MM-DD'),
    };
    dispatch(setSpinnerLoading('Loading'));
    CallAPIPromise(
      `/api/dayEndSumRoute/expenceCollectionSum`,

      data,
      token,
      dispatch
    )
      .then(async function (response) {
        console.log(response);
        if (!response.data.error) {
          const data = response.data.response;
          let l = [];
          for (let i = 0; i < data.length; i++) {
            if (data[i].AMOUNT_RECEIVED >= 0) {
              l.push({
                VOUCHER_NO: data[i].VOUCHER_NO ? data[i].VOUCHER_NO : '',
                DATE: moment(date).format('YYYY-MM-DD'),
                PAY_MODE: data[i].PAY_MODE ? data[i].PAY_MODE : 'Cash',
                CARD_NO: '',
                BANK_NAME: '',
                EPAYMENT_BANK: '',
                ACCOUNT_NO: data[i].ACCOUNT_NO,
                NAME: data[i].NAME,
                ADDRESS: data[i].ADDRESS,
                MOBILE: data[i].MOBILE,
                AMOUNT_RECEIVED: data[i].AMOUNT_RECEIVED
                  ? data[i].AMOUNT_RECEIVED
                  : 0,
                AMOUNT_PAID: data[i].AMOUNT_PAID ? data[i].AMOUNT_PAID : 0,
                AMOUNT_DESC: `Amount Recieved by ${data[i].PAY_MODE}`,
                INVNO: '0',
                SID: stationId,
                SNAME: stationName,
                LATITUDE: lattitude,
                LONGITUDE: longitude,
                APPROVED: 'NO',
              });
            }
          }

          setlist(l);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const handleSave = () => {
    if (voucherList[0]) {
      dispatch(setSpinnerLoading('Saving'));
      const list = {
        table_prefix: prefix,
        cart_item: voucherList.map((o) => {
          return {
            ...o,
            APPROVED: 'YES',
          };
        }),
      };

      CallAPIPromise(`/api/voucherRoute/saveVoucher`, list, token, dispatch)
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            dispatch(
              setAlertWithTitle({
                title: 'success',
                msg: 'Item saved successfully',
                lottie: 'success',
              })
            );
            setCollection(
              voucherList.reduce((acc, curr) => acc + curr.AMOUNT_RECEIVED, 0)
            );

            setStatus(false);
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Soemthing went wrong',
                lottie: 'reject',
              })
            );
          }
        })
        .catch(function (error) {
          console.log(error);

          if (error?.response.data?.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Soemthing went wrong',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      setCollection(
        voucherList.reduce((acc, curr) => acc + curr.AMOUNT_RECEIVED, 0)
      );
      setStatus(false);
    }
  };
  const editCell = ({ field, id, value }) => {
    if (field === 'AMOUNT_RECEIVED') {
      const index = list.findIndex((item) => item.ACCOUNT_NO === id);

      const updatedItem = {
        ...list[index],
        AMOUNT_RECEIVED: Number(value),
      };
      const updatedItems = [...list];
      updatedItems[index] = updatedItem;
      setlist(updatedItems);
    }
  };

  useEffect(() => {
    setVoucherList(() => list.filter((i) => i.AMOUNT_RECEIVED > 0));
  }, [list]);
  useEffect(() => {
    getCollection();
  }, []);

  useEffect(() => {
    if (searchCode) {
      const regex = /\d+/;

      const searchItems = list.filter((item) => {
        if (regex.test(searchCode.substring(0, 1))) {
          return item.ACCOUNT_NO.toString().includes(searchCode);
        } else {
          return item.NAME.toLowerCase().includes(searchCode.toLowerCase());
        }
      });
      SetFilterdList(searchItems);
    }

    if (!searchCode) {
      SetFilterdList([]);
    }
  }, [searchCode]);
  return (
    <div className="h-full p-2 ">
      <div
        style={{
          border: '1px solid #D7D5D5',
          fontSize: '14px',
        }}
        className="flex p-2  rounded-sm"
      >
        <i className="bi bi-search pr-2"></i>
        <input
          type={'text'}
          placeholder="Search"
          value={searchCode}
          onChange={(t) => {
            setSearchCode(t.target.value);
          }}
          className="w-full outline-0 "
        />
      </div>
      <div
        style={{
          height: window.innerHeight * 0.5,
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            20,
        }}
        className="mt-1"
      >
        {' '}
        <GeniusDatagrid
          onDoubleClick={(e) => {
            if (e.field === 'AMOUNT_RECEIVED') {
              dispatch(setnumericKeyboardType(e.field));
              dispatch(
                setIssueStockItem({
                  title: e.row.NAME,
                  id: e.id,
                  value: e.value,
                })
              );
              dispatch(setnumericKeyboardStatus(true));
            }
          }}
          rowID={(row) => row.ACCOUNT_NO}
          columns={columns}
          list={filterdList[0] ? filterdList : list}
          showPagination={true}
          editCell={editCell}
        />
      </div>
      <PartyWiseFooter
        rows={voucherList}
        columns={columns}
        handleSave={handleSave}
        handleUndo={getCollection}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ModNumericKeyboard onChange={editCell} mode={'dark'} />
    </div>
  );
};

export default Collectlist;
