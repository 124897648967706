import React, { useEffect } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import {
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
} from '../utility/Redux/modal';
import { selectWidth } from '../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { toProperCase } from '../utility/component/format';

const BasicInformation = ({
  partyName,
  setPartyName,
  gstNo,
  setGstNo,
  opBalTypeOption,
  setStationName,
  panNo,
  stationName,
  setPanNo,
  partyRef,
  mobileRef,
  openingRef,
  stationRef,
  phoneNo,
  setPhoneNo,
  mobile,
  setMobile,
  email,
  setEmail,
  website,
  setWebsite,
  creditAllow,
  creditPeriod,
  setCreditAllow,
  setCreditPeriod,
  stationId,
  opening,
  setOpening,
  openingType,
  setOpeningType,
  formRef,
  setTabScreen,
  setStationId,
}) => {
  const windowWidth = useSelector(selectWidth);
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setModPartyMasterHelpStatus(true));
  };

  const openStationMaster = () => {
    dispatch(setListStationMasterModalStatus(true));
  };

  useEffect(() => {
    if (formRef) {
      const inputFields = formRef.current.querySelectorAll('input, select');

      // Add a keydown event listener to each input and select field
      inputFields.forEach((field, index) => {
        field.addEventListener('keydown', (event) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission
            const nextIndex = (index + 1) % inputFields.length;
            if (index === 11) {
              setTabScreen(2);
            } else {
              inputFields[nextIndex].focus();
            }
          }
        });
      });

      // Cleanup event listeners on unmount
      return () => {
        inputFields.forEach((field) => {
          field.removeEventListener('keydown', () => {});
        });
      };
    }
  }, []);

  return (
    <div
      style={{
        width: windowWidth > 764 ? '810px' : '355px',
      }}
      ref={formRef}
    >
      <div className="flex mt-4">
        <GeniustextInput
          title={'Party Name'}
          refrance={partyRef}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'text'}
          search={handleOpen}
          value={partyName}
          onChange={(e) => {
            setPartyName(toProperCase(e.target.value));
          }}
          autofocus={true}
          placeholder={'Required'}
        />
        <div className="ml-2">
          <GeniustextInput
            type="text"
            title={'Select Station'}
            reset={() => {
              setStationId('');
              setStationName('');
            }}
            search={() => {
              dispatch(setListStationMasterModalStatus(true));
            }}
            hideError={true}
            backgroundColor={'#f1efeb'}
            value={stationName}
            secondText={{
              value: stationId,
              backgroundColor: '#f7dda4',
              width: '75px',
            }}
            width={windowWidth > 764 ? '401px' : '150px'}
            align={'left'}
          />
        </div>
      </div>
      <div className=" flex ">
        <GeniustextInput
          title={'GST Number'}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'text'}
          value={gstNo}
          onChange={(e) => {
            setGstNo(e.target.value);
          }}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'PAN Number'}
            width={windowWidth > 764 ? '401px' : '150px'}
            type={'text'}
            value={panNo}
            onChange={(e) => {
              setPanNo(e.target.value);
            }}
          />
        </div>
      </div>
      <div className=" flex ">
        <GeniustextInput
          title={'Phone'}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'text'}
          value={phoneNo}
          onChange={(e) => {
            setPhoneNo(e.target.value);
          }}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Mobile'}
            width={windowWidth > 764 ? '401px' : '150px'}
            type={'number'}
            error={'mobile'}
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            refrance={mobileRef}
            placeholder={'Required'}
          />
        </div>
      </div>
      <div className=" flex ">
        <GeniustextInput
          title={'Email'}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'text'}
          error={'email'}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Website'}
            width={windowWidth > 764 ? '401px' : '150px'}
            type={'text'}
            value={website}
            onChange={(e) => {
              setWebsite(e.target.value);
            }}
          />
        </div>
      </div>

      <div className=" flex ">
        <GeniustextInput
          title={'Credit Limit Allow'}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'number'}
          value={creditAllow}
          onChange={(e) => {
            setCreditAllow(e.target.value);
          }}
        />
        <div className="ml-2">
          <GeniustextInput
            title={'Credit Limit Period'}
            width={windowWidth > 764 ? '401px' : '150px'}
            type={'number'}
            value={creditPeriod}
            onChange={(e) => {
              setCreditPeriod(e.target.value);
            }}
          />
        </div>
      </div>

      <div className=" flex ">
        <GeniustextInput
          title={'Opening Balance'}
          width={windowWidth > 764 ? '401px' : '150px'}
          type={'number'}
          value={opening}
          onChange={(e) => {
            setOpening(e.target.value);
          }}
          refrance={openingRef}
        />
        <div className="ml-2">
          <GeniustextInput
            title={
              windowWidth >= 500 ? 'Opening Balance Type' : 'Opening Bal Type'
            }
            width={windowWidth >= 768 ? '401px' : '150px'}
            option={opBalTypeOption}
            value={openingType}
            onChange={(e) => setOpeningType(e.target.value)}
          />
        </div>
      </div>

      <div className="flex">
        <GeniustextInput
          title={'Station ID'}
          width={'401px'}
          type={'number'}
          search={() => {
            dispatch(setListStationMasterModalStatus(true));
          }}
          value={stationId}
          onChange={openStationMaster}
          refrance={stationRef}
          placeholder={'Required'}
        />
      </div>
    </div>
  );
};

export default BasicInformation;
