import axios from 'axios';

import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import { LoadMfg } from './loagMfg';
import moment from 'moment';
import { CallAPIPromise } from '../../menu/dashboard/biiling/modal/comman';

export const SaveHouseMfg = async (
  list,
  prefix,
  token,
  dispatch,
  date,
  setList,
  setMfgDetails
) => {
  dispatch(setSpinnerLoading('Saving data'));
  const data = {
    ...list,
    MFG_QNTY: list.MFG_QNTY ? list.MFG_QNTY : 0,
    DMG_QNTY: list.DMG_QNTY ? list.DMG_QNTY : 0,
    table_prefix: prefix,
  };
  CallAPIPromise('/api/saveInHouseMfg/saveInHouseMfg', data, token, dispatch)
    .then((p) => {
      dispatch(
        setAlertWithTitle({
          title: 'Sucess',
          msg: 'Data saved successfully',
          lottie: 'success',
        })
      );
      setMfgDetails({
        DATE: moment().format('YYYY-MM-DD'),
        DMG_QNTY: '0',
        EMPLOYEE_NAME: '',
        ICODE: '',
        ITEMNAME: '',
        MFG_QNTY: '0',
        MFG_TIME: '',
        REMARKS: '',
        SECTION_NAME: '',
        SERIAL_NO: '',
      });
      LoadMfg(date, prefix, token, setList, dispatch);
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
