import { child, get, ref, set } from 'firebase/database';
import { setBillSattalmentModalStatus } from '../../Redux/modal';
import database from '../../../routeBilling/issueStock/firebase';

export const handleSignout = (
  dispatch,
  navigate,
  prefix,
  userCode,
  isFieebaseSocketOn,
  statusField
) => {
  localStorage.removeItem('userType');
  dispatch(setBillSattalmentModalStatus(false));
  localStorage.removeItem('stationName');
  localStorage.removeItem('stationId');
  localStorage.removeItem('billDetails');
  localStorage.removeItem('userName');
  localStorage.removeItem('count');
  localStorage.removeItem('tkn');
  localStorage.removeItem('company');
  localStorage.removeItem('histryId');
  localStorage.removeItem('prefix');
  localStorage.removeItem('expiresAt');
  localStorage.removeItem('orderdItemList');
  localStorage.removeItem('companyDetails');
  localStorage.removeItem('email');
  localStorage.removeItem('dev');
  localStorage.removeItem('devloper');
  localStorage.removeItem('userDefaultSetting');
  localStorage.removeItem('statusField');
  navigate('/auth/login');
  window.location.reload();
};
