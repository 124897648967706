import CallAPI from '../utility/functions/getData';

export const SaveModuleMaster = (data, token, then, dispatch, final, c) => {
  CallAPI(
    '/api/userSettings/saveModuleMaster',
    data,
    token,
    then,
    dispatch,
    final,
    c
  );
};
export const SaveUserModuleMaster = (data, token, then, dispatch, final, c) => {
  CallAPI(
    '/api/userSettings/saveUserModuleMaster',
    data,
    token,
    then,
    dispatch,
    final,
    c
  );
};
export const ModuleMasetrHelp = (token, then, dispatch, final, c) => {
  CallAPI(
    '/api/userSettings/moduleMasterHelp',
    {},
    token,
    then,
    dispatch,
    final,
    c
  );
};
export const UserModuleMasetrHelp = (id, token, then, dispatch, final, c) => {
  CallAPI(
    '/api/userSettings/moduleUserMasterHelp',
    {
      USER_ID: id,
    },
    token,
    then,
    dispatch,
    final,
    c
  );
};

export const DeleteModuleMasetr = (id, token, then, dispatch, final, c) => {
  CallAPI(
    '/api/userSettings/deleteModuleMaster',
    {
      ID: id,
    },
    token,
    then,
    dispatch,
    final,
    c
  );
};
export const DeleteUSerModuleMasetr = (
  id,
  userId,
  token,
  then,
  dispatch,
  final,
  c
) => {
  CallAPI(
    '/api/userSettings/deleteUserModuleMaster',
    {
      USER_ID: userId,
      ID: id,
    },
    token,
    then,
    dispatch,
    final,
    c
  );
};
