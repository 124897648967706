import { FormatNumber2 } from '../tools';

export const GeniusHeader = (props) => {
  const { columns } = props;

  return (
    <div className="  bg-[#e6e6e3] flex ">
      {columns.map((item, index) => {
        return (
          <div
            key={index}
            className="  text-black flex justify-center items-center border-r py-2 border-slate-300"
            style={item.flex ? { flex: item.flex } : { width: item.width }}
          >
            {item.renderHeader()}
          </div>
        );
      })}
    </div>
  );
};

export const GeniusHeader2 = (props) => {
  const { columns } = props;

  return (
    <div
      style={{
        border: '1px dotted black',
        borderLeft: '0px',
        borderRight: '0px',
      }}
      className="  bg-[#e6e6e3] flex "
    >
      {columns.map((item, index) => {
        return (
          <div
            key={index}
            className="  text-black flex justify-center items-center py-2 "
            style={
              item.flex
                ? index < columns.length - 1
                  ? {
                      flex: item.flex,
                      borderRight: '1px dotted black',
                      // borderTop: '1px solid black',
                    }
                  : {
                      flex: item.flex,
                    }
                : index < columns.length - 1
                ? {
                    width: item.width,

                    borderRight: '1px dotted black',
                    // border: '1px solid black',
                    // borderTop: '1px solid black',
                  }
                : {
                    width: item.width,
                  }
            }
          >
            {item.renderHeader()}
          </div>
        );
      })}
    </div>
  );
};
