import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCustomeTwoButtomAlert,
  setCustomeTwoButtomAlert,
} from '../../Redux/modal';
import { setLogin, setToken } from '../../Redux/security';
import { useNavigate } from 'react-router-dom';
import rootBillimgLogo from '../../images/icon.svg';
import { handleSignout } from './logout';
import {
  selectFirebaseStatusField,
  selectISFirebaseSocketOn,
  selectPrefix,
  selectUserCode,
} from '../../Redux/profile';

export default function CustomeTwoButtonAlert() {
  const dispatch = useDispatch();
  const status = useSelector(selectCustomeTwoButtomAlert);

  const navigate = useNavigate();
  const myFunction = () => {
    if (status) {
      dispatch(setToken(''));
      dispatch(setLogin(false));
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        if (status) {
          myFunction();
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [status]);

  const handleClose = () => {
    dispatch(setCustomeTwoButtomAlert(false));
  };
  const prefix = useSelector(selectPrefix);
  const userCode = useSelector(selectUserCode);
  const isFieebaseSocketOn = useSelector(selectISFirebaseSocketOn);
  const statusField = useSelector(selectFirebaseStatusField);
  return (
    <div className=" rounded-lg">
      <Modal open={status} onClose={handleClose}>
        <div
          className=" bg-brown overflow-hidden rounded-lg min-w-[350px] justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 pt-4 items-center ">
            <div className="flex  w-full px-6 pr-20 pb-3  items-center ">
              <img src={rootBillimgLogo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown text-sm ">
                  Are you sure you want to log out ?
                </div>
              </div>
            </div>
            <div className="flex justify-end pb-4  bg-white pr-6">
              <div className="px-2">
                <Button
                  size={'small'}
                  onClick={() => {
                    handleSignout(
                      dispatch,
                      navigate,
                      prefix,
                      userCode,
                      isFieebaseSocketOn,
                      statusField
                    );
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{
                    transition: 'none',
                    textTransform: 'capitalize',
                  }}
                >
                  <div className="px-2">Logout </div>{' '}
                </Button>
              </div>

              <div>
                <Button
                  size={'small'}
                  onClick={handleClose}
                  color="gray"
                  variant="contained"
                  sx={{ transition: 'none', textTransform: 'capitalize' }}
                >
                  <div className="px-2">Cancel </div>{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
