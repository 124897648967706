import moment from 'moment';

const width = (window.innerWidth * 1) / 100;

export const columns = [
  {
    field: 'ACCOUNT_NO',
    accessor: 'ACCOUNT_NO',

    type: 'string',
    width: 100,
    align: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        Account No
      </strong>
    ),
  },

  {
    field: 'NAME',
    accessor: 'NAME',
    type: 'string',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        Party Name
      </strong>
    ),
  },

  {
    field: 'ADDRESS',
    accessor: 'ADDRESS',
    type: 'string',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        Address
      </strong>
    ),
  },

  {
    field: 'MOBILE',
    accessor: 'MOBILE',
    type: 'string',

    width: width * 7,
    align: 'center',
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        {' '}
        Mobile
      </strong>
    ),
  },

  {
    field: 'DATE',
    accessor: 'DATE',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 13,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => (
      <div
        style={{
          fontWeight: '600',
        }}
      >
        {moment(params.value).format('DD-MM-YYYY')}
      </div>
    ),
    renderHeader: (params) => (
      <strong className="  whitespace-normal" style={{ fontSize: 13 }}>
        {' '}
        Last Bill Date
      </strong>
    ),
  },

  {
    field: 'NO_OF_DAYS',
    accessor: 'NO_OF_DAYS',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 8,
    headerAlign: 'center',
    align: 'right',
    renderCell: (params) => (
      <div
        style={{
          fontWeight: '600',
        }}
      >
        {params.value}
      </div>
    ),

    renderHeader: (params) => (
      <strong
        className="  leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        No Of Days
      </strong>
    ),
  },
  {
    field: 'SID',
    accessor: 'SID',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 6,
    align: 'center',

    headerAlign: 'center',
    headerAlign: 'center',

    renderHeader: (params) => (
      <strong
        className=" w-16 leading-4 whitespace-normal text-center"
        style={{ fontSize: 13 }}
      >
        Station Id
      </strong>
    ),
  },

  {
    field: 'SNAME',
    accessor: 'SNAME',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 15,
    align: 'left',

    headerAlign: 'center',
    headerAlign: 'center',

    renderHeader: (params) => (
      <strong
        className=" w-28 leading-4 whitespace-normal text-center"
        style={{ fontSize: 13 }}
      >
        Station Name
      </strong>
    ),
  },
];

export const columnsMobileView = [
  {
    field: 'ACCOUNT_NO',
    type: 'string',
    width: 100,
    align: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        Account No
      </strong>
    ),
  },

  {
    field: 'NAME',
    type: 'string',
    width: 120,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        Party Name
      </strong>
    ),
  },

  {
    field: 'ADDRESS',
    type: 'string',
    width: 120,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        Address
      </strong>
    ),
  },

  {
    field: 'MOBILE',
    type: 'string',

    width: 90,
    align: 'center',
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <strong className="" style={{ fontSize: 13 }}>
        {' '}
        Mobile
      </strong>
    ),
  },

  {
    field: 'DATE',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: 100,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => moment(params.value).format('YYYY-MM-DD'),
    renderHeader: (params) => (
      <strong className="  whitespace-normal" style={{ fontSize: 13 }}>
        {' '}
        Date
      </strong>
    ),
  },

  {
    field: 'NO_OF_DAYS',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: 100,
    headerAlign: 'center',
    align: 'center',

    renderHeader: (params) => (
      <strong
        className="  leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        No Of Days
      </strong>
    ),
  },
  {
    field: 'SID',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: 60,
    align: 'center',

    headerAlign: 'center',
    headerAlign: 'center',

    renderHeader: (params) => (
      <strong
        className=" w-16 leading-4 whitespace-normal text-center"
        style={{ fontSize: 13 }}
      >
        St. Id
      </strong>
    ),
  },

  {
    field: 'SNAME',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: 120,
    align: 'left',

    headerAlign: 'center',
    headerAlign: 'center',

    renderHeader: (params) => (
      <strong
        className=" w-16 leading-4 whitespace-normal text-center"
        style={{ fontSize: 13 }}
      >
        Station Name
      </strong>
    ),
  },
];
