import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setItemMasterHelpStatus,
  setSectionMasterHelpStatus,
  setDepartmentMasterHelpStatus,
  setAlertWithTitle,
  setSpinnerLoading,
  setSessionExpired,
} from '../utility/Redux/modal';
import ItemMasterHelp from './iteemMasterHelp';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix } from '../utility/Redux/profile';
import { toProperCase } from '../utility/component/format';

import DepartmentMasterHelp from './departmentHelp';
import { useNavigate } from 'react-router-dom';

import { DownloadFile, UploadFile } from '../utility/functions/firebase';
import GeniustextInput from '../utility/component/mastertext';
import ProfilePicFrame from '../utility/component/styles/profilePicFrame';
import {
  BackButton,
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import UploadImageButtons from '../utility/component/UploadImageButtons';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import SectionMasterHelp from '../utility/component/modals/sectionMasterHelp';
import { useFocus } from '../utility/hooks/hooks';
import GeniusTabView from '../utility/component/tabView';
import {
  itemNameStyle,
  sectionStyleTab,
} from '../routeBilling/issueStock/issueStockStyle';
import ItemBox from '../routeBilling/issueStock/itemBox';
import { Skeleton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';

const ItemMaster = () => {
  const webcamRef = useRef(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [updateAll, setUpdateAll] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedFile, setSelectedFile] = useState('');
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [screenTab, setScreenTab] = useState(1);
  const navigate = useNavigate();
  const [itemList, setItemList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [ListsectionName, setListSecname] = useState('');
  const [path, setpath] = useState('');

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const handleBack = () => {
    navigate('/masters');
  };

  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(setItemMasterHelpStatus(true));
  };

  const handleOpenSectionHelp = () => {
    dispatch(setSectionMasterHelpStatus(true));
  };

  const handleOpenDepartmentHelp = () => {
    dispatch(setDepartmentMasterHelpStatus(true));
  };

  const [id, setId] = useState();
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [secName, setSecName] = useState('');
  const [deptName, setDeptName] = useState('');
  const [rate, setRate] = useState('');
  const [hsnCode, setHsnCode] = useState('');
  const [tax1, setTax1] = useState('');
  const [tax2, setTax2] = useState('');
  const [tax3, setTax3] = useState(0);
  const [REGIONAL_INAME, setRegionalIname] = useState();
  const [stop, setStop] = useState('');

  const [rows, setRows] = useState([]);

  const [firebaseImage, setFirebaseImage] = useState(null);

  const prefix = useSelector(selectPrefix);

  const token = useSelector(selectToken);

  const [itemRef, setItemFocus] = useFocus();
  const [descRef, setDescFocus] = useFocus();
  const [sectionRef, setSectionFocus] = useFocus();
  const [rateRef, setRateFocus] = useFocus();
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('regional')
  );

  const postData = async () => {
    dispatch(setSpinnerLoading('Saving'));
    const data = {
      table_prefix: prefix,
      ICODE: '',
      ITEMNAME: itemName,
      REGIONAL_INAME: REGIONAL_INAME,
      DESC_NAME: description,
      SNAME: secName,
      DEPARTMENT_NAME: deptName,
      SALE_PRICE: Number(rate),
      TAX1_RATE: tax1 ? tax1 : 0,
      TAX2_RATE: tax2 ? tax2 : 0,
      TAX3_RATE: tax3 ? tax3 : 0,
      HSN_CODE: hsnCode,
    };
    CallAPIPromise(
      `/api/issueMasterRoute/saveItemMaster`,
      data,

      token,
      dispatch
    )
      .then(async function (response) {
        if (!response.data.error) {
          recordSaved();
          if (capturedImage || selectedFile) {
            const camera = capturedImage
              ? await fetch(capturedImage).then((response) => response.blob())
              : '';
            const file = selectedFile ? selectedFile : camera;
            const name = prefix + response.data.result.insertId;
            const path = 'rootbilling/items/';
            UploadFile(file, path, name);
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error ',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setCapturedImage('');
      });
  };

  const recordSaved = () => {
    dispatch(
      setAlertWithTitle({
        title: 'Success Message',
        msg: 'Record Saved Successfully',
      })
    );
  };

  const clearState = () => {
    setId('');
    setItemName('');
    setDescription('');
    setSecName('');
    setDeptName('');
    setRate('');
    setHsnCode('');
    setTax1('');
    setTax2('');
    setTax3('');
    setCapturedImage('');
    setFirebaseImage('');
    setUpdateAll(false);
  };

  const onSave = (e) => {
    e.preventDefault();
    if (itemName && secName && rate) {
      postData();
      setRows([
        ...rows,
        {
          id: id,
          itemName: itemName,
          description: description,
          secName: secName,
          hsnCode: hsnCode,
          tax1: tax1 ? tax1 : 0,
          tax2: tax2 ? tax2 : 0,
          tax3: tax3 ? tax3 : 0,
          deptName: deptName,
          rate: rate,
        },
      ]);

      setId((prev) => Number(prev) + 1);
      clearState();
    } else {
      if (!rate) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Rate.',
            lottie: 'reject',
          })
        );
        setRateFocus();
      }

      if (!secName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Section.',
            lottie: 'reject',
          })
        );
        setSectionFocus();
      }

      if (!itemName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Item Name.',
            lottie: 'reject',
          })
        );
        setItemFocus();
      }
    }
  };

  const onHandleUpdate = async () => {
    dispatch(setSpinnerLoading('Modifying'));
    const data = {
      table_prefix: prefix,
      ICODE: id,
      ITEMNAME: itemName,
      REGIONAL_INAME: REGIONAL_INAME,
      DESC_NAME: description,
      SNAME: secName,
      DEPARTMENT_NAME: deptName,
      SALE_PRICE: Number(rate),
      TAX1_RATE: tax1 ? tax1 : 0,
      TAX2_RATE: tax2 ? tax2 : 0,
      TAX3_RATE: tax3 ? tax3 : 0,
      HSN_CODE: hsnCode,
    };
    CallAPIPromise(
      `/api/issueMasterRoute/saveItemMaster`,
      data,
      token,
      dispatch
    )
      .then(async function (response) {
        if (!response.data.error) {
          if (capturedImage || selectedFile) {
            const camera = capturedImage
              ? await fetch(capturedImage).then((response) => response.blob())
              : '';
            const file = selectedFile ? selectedFile : camera;
            const name = prefix + id;
            const path = 'rootbilling/items/';
            UploadFile(file, path, name);

            setFirebaseImage('');
          } else {
            setFirebaseImage('');
          }

          if (updateAll) {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: true,
              title: 'Update Rate to All Party',
              msg: 'Are you sure you want to update rate to all party',
              button1: 'Yes',
              button2: 'Cancel',

              button1Click: () => {
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                UpdateRateInAllItemAPI();
              },
              button2Click: () => {
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                recordModify();
                clearState();
              },
            });
          } else {
            recordModify();
            clearState();
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setSelectedFile('');
        setFirebaseImage('');
      });
  };

  const UpdateRateInAllItemAPI = async () => {
    dispatch(setSpinnerLoading('Updating rate in all items'));
    CallAPIPromise(
      `/api/issueMasterRoute/teToAll`,
      {
        table_prefix: prefix,
        ICODE: id,

        SALE_PRICE: Number(rate),
      },
      token,
      dispatch
    )
      .then(async function (response) {
        if (!response.data.error) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: 'Item rate updated to all party',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        setSelectedFile('');
        setFirebaseImage('');
        clearState();
      });
  };

  const recordModify = () => {
    dispatch(
      setAlertWithTitle({
        title: 'Success Message',
        msg: 'Record Modify Successfully!',
      })
    );
  };
  const formRef = useRef(null);

  const transliterateText = async (text) => {
    const response = await fetch(
      `https://inputtools.google.com/request?text=${text}&itc=${
        selectedLanguage === 'en'
          ? 'en-t-i0-und'
          : selectedLanguage === 'hi'
          ? 'hi-t-i0-und'
          : selectedLanguage === 'pa'
          ? 'pa-t-i0-und'
          : selectedLanguage === 'es'
          ? 'es-t-i0-und'
          : selectedLanguage === 'ru'
          ? 'ru-t-i0-und'
          : selectedLanguage === 'fr'
          ? 'fr-t-i0-und'
          : selectedLanguage === 'de'
          ? 'de-t-i0-und'
          : selectedLanguage === 'pt'
          ? 'pt-t-i0-und'
          : 'roboto-font'
      }&num=1&cp=0&cs=1&ie=utf-8&oe=utf-8&app=jsapi&uv`
    );
    const data = await response.json();
    if (data && data[1] && data[1][0] && data[1][0][1] && data[1][0][1][0]) {
      const t = data[1][0][1][0];
      setRegionalIname(t);
    }
  };

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission

          const nextIndex = (index + 1) % inputFields.length; // Calculate the index of the next field
          if (index === 9) {
            inputFields[0].focus(); // Set focus on the 13th field for the 10th field
          } else {
            if (index === 4) {
              inputFields[6].focus(); // Set focus on the 13th field for the 10th field
            } else {
              inputFields[nextIndex].focus(); // Set focus on the next field
            }
          }
        }
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  const handleSectionInputChange = (e) => {
    const newValue = toProperCase(e.target.value);
    setSecName(newValue);

    if (newValue) {
      dispatch(setSectionMasterHelpStatus(true));
    } else {
      dispatch(setSectionMasterHelpStatus(false));
    }
  };

  const handleDeptInputChange = (e) => {
    const newValueDept = toProperCase(e.target.value);
    setDeptName(newValueDept);

    if (newValueDept) {
      dispatch(setDepartmentMasterHelpStatus(true));
    } else {
      dispatch(setDepartmentMasterHelpStatus(false));
    }
  };

  const getSectionList = () => {
    CallAPIPromise(
      '/api/issueMasterRoute/listSectionMaster',
      {
        table_prefix: prefix,
        SECTION_NAME: '',
      },
      token,
      dispatch
    )
      .then((res) => {
        setSectionList(res.data.response);
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  const getData = async (s) => {
    dispatch(setSpinnerLoading('Loading'));

    CallAPIPromise(
      '/api/issueMasterRoute/listItemMaster',
      {
        table_prefix: prefix,
        sname: s,
      },
      token,
      dispatch
    )
      .then((p) => {
        setItemList(p.data.response);
        getSectionList();
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const headerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getData('');
  }, []);

  return (
    <div
      ref={formRef}
      className="w-full h-full flex justify-center bg-[#f5f5f7] p-8 overflow-y-scroll"
    >
      <div className="text-sm  rounded-sm  h-full">
        <div ref={headerRef} className="bg-white   ">
          <ModalHeaderStyle
            header={'Item Master'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>
        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="      flex flex-col  "
        >
          <GeniusTabView
            tab1Title={'Master'}
            tab2Title={'Items List'}
            tab1={
              <div
                style={{
                  width: '800px',
                }}
                className="py-3 px-5 justify-center p-3 max-sm:px-2  pt-5 bg-white  flex  "
              >
                <div>
                  <div
                    style={{
                      display: windowSize >= 768 ? 'none' : 'block',
                    }}
                    className="mb-4 "
                  >
                    {' '}
                    <div className="w-[180px] h-[180px]  ">
                      <ProfilePicFrame
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                        webcamRef={webcamRef}
                        webcamStatus={webcamStatus}
                        capturedImage={capturedImage}
                        setCapturedImage={setCapturedImage}
                        setWebcamStatus={setWebcamStatus}
                        customerFirebasePic={firebaseImage}
                        setFirebaseUrl={setFirebaseImage}
                        path={path}
                      />
                    </div>
                    <div
                      style={{
                        display: windowSize > 768 ? 'none' : 'block',
                      }}
                      className="flex mt-2 w-[180px]  "
                    >
                      <div className="flex w-[100%] items-center ">
                        <UploadImageButtons
                          title={'Item Image'}
                          setSelectedFile={setSelectedFile}
                          webcamStatus={webcamStatus}
                          setWebcamStatus={setWebcamStatus}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex  ">
                    <div>
                      <div className="flex justify-between ">
                        <GeniustextInput
                          search={handleOpen}
                          value={itemName}
                          onChange={(e) => {
                            setItemName(toProperCase(e.target.value));
                          }}
                          type={'text'}
                          title={'Item Name'}
                          width={'380px'}
                          error={false}
                          autofocus={true}
                          refrance={itemRef}
                        />
                      </div>
                      <div>
                        <div className="flex justify-between ">
                          <GeniustextInput
                            width={'380px'}
                            type={'text'}
                            title={'  Description'}
                            value={description}
                            onChange={(e) => {
                              setDescription(toProperCase(e.target.value));
                            }}
                            error={false}
                            refrance={descRef}
                          />
                        </div>
                      </div>

                      <div className="flex">
                        <GeniustextInput
                          type={'text'}
                          width={'380px'}
                          search={handleOpenSectionHelp}
                          title={'Section Name'}
                          value={secName}
                          onChange={handleSectionInputChange}
                          refrance={sectionRef}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: windowSize < 768 ? 'none' : 'block',
                      }}
                    >
                      {' '}
                      <div className="w-[180px] h-[180px] ml-3">
                        <ProfilePicFrame
                          selectedFile={selectedFile}
                          setSelectedFile={setSelectedFile}
                          webcamRef={webcamRef}
                          webcamStatus={webcamStatus}
                          capturedImage={capturedImage}
                          setCapturedImage={setCapturedImage}
                          setWebcamStatus={setWebcamStatus}
                          customerFirebasePic={firebaseImage}
                          setFirebaseUrl={setFirebaseImage}
                          path={path}
                        />
                      </div>
                      <div className="flex mt-2 w-[180px] ml-3  ">
                        <div className="flex w-[100%] items-center ">
                          <UploadImageButtons
                            title={'item image'}
                            setSelectedFile={setSelectedFile}
                            webcamStatus={webcamStatus}
                            setWebcamStatus={setWebcamStatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex  ">
                    <div className=" flex ">
                      <GeniustextInput
                        type={'text'}
                        search={handleOpenDepartmentHelp}
                        title={'Department Name'}
                        width={'380px'}
                        value={deptName}
                        onChange={handleDeptInputChange}
                      />
                    </div>
                  </div>

                  <div className="flex">
                    <div className=" ">
                      <GeniustextInput
                        type={'text'}
                        title={'Regional Item Name'}
                        width={windowSize <= 764 ? '150px' : '380px'}
                        value={REGIONAL_INAME}
                        onChange={(e) => {
                          transliterateText(e.target.value);
                          setRegionalIname(e.target.value);
                        }}
                      />
                    </div>
                    <div className=" ml-3 ">
                      <GeniustextInput
                        title={'Regional language'}
                        width={windowSize <= 764 ? '150px' : '380px'}
                        option={[
                          {
                            name: 'Select Language',
                            value: '',
                          },
                          {
                            name: 'English',
                            value: 'en',
                          },
                          {
                            name: 'ਪੰਜਾਬੀ',
                            value: 'pa',
                          },
                          {
                            name: 'हिंदी',
                            value: 'hi',
                          },
                          {
                            name: 'Spanish',
                            value: 'es',
                          },
                          {
                            name: 'Russian',
                            value: 'ru',
                          },
                          {
                            name: 'French',
                            value: 'fr',
                          },
                          {
                            name: 'German',
                            value: 'de',
                          },
                          {
                            name: 'Portuguese',
                            value: 'pt',
                          },
                        ]}
                        value={selectedLanguage}
                        onChange={(e) => {
                          setSelectedLanguage(e.target.value);
                          localStorage.setItem('regional', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex  ">
                    <div>
                      <GeniustextInput
                        type={'number'}
                        title={'Rate'}
                        SecondTitle={
                          id
                            ? () => {
                                return (
                                  <div className="flex items-center">
                                    <input
                                      type="checkbox"
                                      checked={updateAll}
                                      onChange={(e) =>
                                        setUpdateAll((f) => {
                                          return !f;
                                        })
                                      }
                                    />
                                    <div className="text-sm ml-3 text-blue-700">
                                      Update Rate to All Party
                                    </div>
                                  </div>
                                );
                              }
                            : null
                        }
                        width={windowSize <= 764 ? '150px' : '380px'}
                        value={rate}
                        onChange={(e) => {
                          const inputValue = e.target.value;

                          if (isNaN(inputValue)) {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Type Error',
                                msg: 'Please Enter Number Value!',
                              })
                            );
                          } else {
                            setRate(inputValue);
                          }
                        }}
                        refrance={rateRef}
                      />
                    </div>

                    <div className="ml-3 ">
                      <GeniustextInput
                        type={'text'}
                        title={'HSN Code'}
                        width={windowSize <= 764 ? '150px' : '380px'}
                        value={hsnCode}
                        onChange={(e) => {
                          setHsnCode(toProperCase(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex ">
                    <GeniustextInput
                      type={'number'}
                      title={'Tax-1 (CGST)'}
                      width={windowSize <= 764 ? '150px' : '380px'}
                      value={tax1}
                      onChange={(e) => {
                        setTax1(toProperCase(e.target.value));
                      }}
                    />
                    <div className="flex ml-3">
                      <GeniustextInput
                        type={'number'}
                        title={'Tax-2 (SGST)'}
                        width={windowSize <= 764 ? '150px' : '380px'}
                        value={tax2}
                        onChange={(e) => {
                          setTax2(toProperCase(e.target.value));
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex ">
                    <GeniustextInput
                      type={'number'}
                      title={'Tax-3'}
                      width={windowSize <= 764 ? '150px' : '380px'}
                      value={tax3}
                      onChange={(e) => {
                        setTax3(toProperCase(e.target.value));
                      }}
                    />
                    <div className="flex ml-3">
                      <GeniustextInput
                        title={'Stop'}
                        width={windowSize <= 764 ? '150px' : '380px'}
                        option={[
                          {
                            name: 'NO',
                          },
                          {
                            name: 'YES',
                          },
                        ]}
                        value={stop}
                        onChange={(e) => {
                          setStop(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
            tab2={
              <div
                style={{
                  width:
                    windowSize < 700
                      ? '380px'
                      : windowSize < 1024
                      ? '680px'
                      : '800px',
                }}
                className="   bg-white  flex px-5 py-3 pt-3 "
              >
                <div
                  style={{
                    height: `550px`,
                    width: '240px',
                  }}
                  className=" overflow-y-scroll"
                >
                  <div className="grid  gap-1 grid-cols-1">
                    {sectionList
                      ? sectionList.map((item, key) => (
                          <>
                            <div
                              key={key}
                              style={sectionStyleTab}
                              className="text-[#4a4a4a]  flex items-center px-4 text-[15px] w-[150px]  font-semibold whitespace-nowrap "
                            >
                              <button
                                onClick={() => {
                                  getData(item.SECTION_NAME);
                                  setListSecname(item.SECTION_NAME);
                                }}
                                className="w-full text-left"
                              >
                                {toProperCase(item.SECTION_NAME)}
                              </button>
                              {item.SECTION_NAME === ListsectionName && (
                                <button
                                  onClick={() => {
                                    setListSecname('');
                                    getData('');
                                  }}
                                >
                                  <Close
                                    fontSize={'small'}
                                    sx={{
                                      color: 'red',
                                      marginLeft: '8px',
                                    }}
                                  />
                                </button>
                              )}
                            </div>
                          </>
                        ))
                      : [
                          '1',
                          '2',
                          '3',
                          '4',
                          '5',
                          '6',
                          '7',
                          '8',
                          '9',
                          '10',
                          '11',
                          '12',
                          '13',
                          '14',
                          '15',
                          '16',
                        ].map(() => {
                          return (
                            <Skeleton
                              variant="rectangular"
                              width={136}
                              animation="wave"
                              height={70}
                              sx={{ fontSize: '1rem', borderRadius: '7px' }}
                            />
                          );
                        })}
                  </div>
                </div>
                <div
                  style={{
                    height: `550px`,
                    width:
                      windowSize < 700
                        ? '380px'
                        : windowSize < 1024
                        ? '680px'
                        : '800px',
                  }}
                  className="   bg-white overflow-y-scroll  "
                >
                  <div className="grid px-2  gap-2 grid-cols-2 t:grid-cols-3 l:grid-cols-4 ">
                    {itemList[0]
                      ? itemList.map((i, index) => {
                          return (
                            <ItemBox
                              key={index}
                              item={i}
                              index={index}
                              itemNameStyle={itemNameStyle}
                            />
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            }
            screenTab={screenTab}
            setScreenTab={setScreenTab}
          />

          <div
            style={{
              borderTop: '1px solid #D2D2D7',
            }}
            className="flex justify-end bg-white py-5 rounded-b-sm  px-4"
          >
            {id ? (
              <ModifyButton onClick={onHandleUpdate} />
            ) : (
              <SaveButton onClick={onSave} />
            )}

            <UndoButton onClick={clearState} />

            <BackButton onClick={handleBack} />
          </div>
        </div>
      </div>

      <ItemMasterHelp
        onRowClick={(p) => {
          const icode = p.row.ICODE;
          const path = `rootbilling/items/${prefix + icode}`;
          DownloadFile(path)
            .then((e) => {
              if (!e.includes('FirebaseError')) {
                setFirebaseImage(e);
                setpath(e);
              } else {
                setpath('');

                setFirebaseImage('');
              }
            })
            .catch((e) => {
              // setCapturedImage('');
              setFirebaseImage('');
            });
          setItemName(p.row.ITEMNAME);
          setDescription(p.row.DESC_NAME);
          setSecName(p.row.SNAME);
          setDeptName(p.row.DEPARTMENT_NAME);
          setRate(p.row.SALE_PRICE);
          setHsnCode(p.row.HSN_CODE);
          setTax1(p.row.TAX1_RATE);
          setTax2(p.row.TAX2_RATE);
          setTax3(p.row.TAX3_RATE);
          setId(p.row.ICODE);
          dispatch(setItemMasterHelpStatus(false));
        }}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <SectionMasterHelp
        onRowClick={(p) => {
          setSecName(p.row.SECTION_NAME);
          dispatch(setSectionMasterHelpStatus(false));
        }}
      />
      <DepartmentMasterHelp setDeptName={setDeptName} />
    </div>
  );
};

export default ItemMaster;
