import React from 'react';
import GeniusExpenseMaster from './geniusExpenseMaster';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import {
  selectExpensemasterModalStatus,
  setExpenseMasterModalStatus,
} from '../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from '@mui/material';
import ModalBody from '../utility/component/styles/modalBody';

const Expense_Master = () => {
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectExpensemasterModalStatus);

  const handleClose = () => {
    dispatch(setExpenseMasterModalStatus(false));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'transparent',
    borderRadius: '12px',
    border: '2px solid black',
  };

  return (
    <div>
      <Modal
        open={ModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={'rounded-lg overflow-hidden '}>
          <div className="w-full flex flex-col bg-white h-full ">
            <ModalHeaderStyle header={'Expense Master'} onClose={handleClose} />

            <ModalBody>
              <GeniusExpenseMaster />
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Expense_Master;
