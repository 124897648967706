import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {
  SelectAlertStatus,
  setALertStatus,
  selectAlertOkStatus,
  setAlertOkStatus,
  setloading,
  setBillSattalmentModalStatus,
  SelectNoIssueStatus,
} from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/ico.png';
import { useNavigate } from 'react-router-dom';
import {
  selectFirebaseStatusField,
  selectISFirebaseSocketOn,
  selectPrefix,
  selectUserCode,
} from '../../Redux/profile';
import { handleSignout } from './logout';

export default function NoIssueMod() {
  const dispatch = useDispatch();
  const alertStatus = useSelector(SelectAlertStatus);
  const prefix = useSelector(selectPrefix);
  const isExpired = useSelector(SelectNoIssueStatus);
  const navigate = useNavigate();
  const okStatus = useSelector(selectAlertOkStatus);
  const isFieebaseSocketOn = useSelector(selectISFirebaseSocketOn);
  const statusField = useSelector(selectFirebaseStatusField);
  const userCode = useSelector(selectUserCode);
  const myFunction = () => {
    // your logic here
    if (alertStatus) {
      if (Number(okStatus) === 0) {
        dispatch(setAlertOkStatus(Number(okStatus) + 1));
      } else {
        dispatch(setAlertOkStatus(0));
        dispatch(setALertStatus(false));
        dispatch(setloading(false));
      }
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        if (alertStatus) {
          myFunction();
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [alertStatus, okStatus]);

  return (
    <div className=" rounded-lg">
      <Modal open={isExpired}>
        <div
          className="d:w-4/12  ml:w-10/12 ms:w-11/12 l:w-5/12 bg-brown overflow-hidden rounded-lg t:w-4/12 p:w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 pt-5 items-center ">
            <div className="flex  w-full px-5 pb-6  items-center ">
              <img src={logo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown text-md ">
                  {
                    'Unfortunately, there are no items for sale in your account right now. Please check back later when new items become available'
                  }
                </div>
              </div>
            </div>
            <div className="flex justify-end pb-3  bg-white pr-3 ">
              <Button
                size={'small'}
                onClick={() => {}}
                color="secondary"
                variant="contained"
              >
                <div
                  onClick={() => {
                    handleSignout(
                      dispatch,
                      navigate,
                      prefix,
                      userCode,
                      isFieebaseSocketOn,
                      statusField
                    );
                  }}
                  className="px-5"
                >
                  Ok{' '}
                </div>{' '}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
