import React  from "react";

import Modal from "@mui/material/Modal";
import {

   selectLoginRejectedModalStatus,  setLoginRejected, 
} from "../../Redux/modal";

import { useDispatch, useSelector } from "react-redux";


import Lottie from "lottie-react";

import Reject from '../../../utility/json/rejected2.json'
import { useNavigate } from "react-router-dom";

export default function ModApproveRejected() {
    const navigate = useNavigate()
  const dispatch = useDispatch();
  const modalStatus = useSelector(selectLoginRejectedModalStatus)
    const handleClose = () => {
        dispatch(setLoginRejected(false))
        localStorage.clear()
        navigate('/auth/login')

  };



  return (
    <div className=" rounded-lg">
      <Modal open={modalStatus} onClose={handleClose} >
        
        <div
          className="d:w-1/5  ml:w-10/12 ms:w-11/12 l:w-5/12 bg-brown overflow-hidden rounded-lg t:w-4/12 p:w-5/6 justify-center  "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2 bg-[#262424] drop-shadow-xl">
            genius office

          </div>
          <div className="justify-center bg-white  w-full items-center ">
            
         
        
            <div className="w-full flex justify-center">
              <div className="flex justify-center w-20 pt-4 ">
              <Lottie animationData={ Reject} loop={false} style={{ fontSize: 15, }} />
         
               
            </div>
            </div>
         

           <div className="flex w-full justify-center py-4 text-xl">
       
              Login Rejected
              </div>
        
          </div> 
            
        </div>
      </Modal>
    </div>
  );
}
