import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setloading,
} from '../Redux/modal';
import { setRouteAlertReport } from '../Redux/list';
import deleteWholeDatabase from '../functions/deleteWholeDatabase';
import dataToLocal from '../functions/summaryToLocal';
import { CallAPIPromise } from '../../routeBilling/menu/dashboard/biiling/modal/comman';

export function getRouteAlertReport(
  dispatch,
  prefix,
  token,
  ReduxStation_id,
  ReduxStationName
) {
  dispatch(setloading(true));
  deleteWholeDatabase('Route_Alert_Report');
  CallAPIPromise(
    `/api/reports/routeAlertReport`,
    {
      table_prefix: prefix,
      SID: ReduxStation_id,
    },

    token,
    dispatch
  )
    .then((res) => {
      if (!res.data.error) {
        const data = res.data.response;
        if (data[0]) {
          dispatch(setRouteAlertReport(data));
          dataToLocal('Route_Alert_Report', data, [
            {
              SID: ReduxStation_id,
              ICODE: 1,
              SNAME: ReduxStationName,
            },
          ]);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Data not found',
            })
          );
        }
      }
    })
    .catch((e) => {
      console.log(e);

      if (e.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setloading(false));
    });
}
