import React from 'react';
import SwipeableEdgeDrawer from './swipableEdgeDrawer';
import { Close } from '@mui/icons-material';
import { Fade, Grow } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix } from '../../../utility/Redux/profile';
import { selectToken } from '../../../utility/Redux/security';
import { toProperCase } from '../../../utility/component/format';

export const SectionBillhelp = ({
  setIsSectionDraweropen,
  isSectionDrawerOpen,
  sectionList,
  sectionName,
  SecClick,
  onClick,
}) => {
  return (
    <div className="flex w-full justify-center ">
      <button
        onClick={() => {
          setIsSectionDraweropen(true);
        }}
        style={{
          height: '100%',
          width: '100%',
        }}
        className="rounded-sm bg-gray-100 hover:cursor-pointer   "
      >
        <div className="p-1 flex justify-center w-full">
          <div>Section </div>
        </div>
      </button>

      <SwipeableEdgeDrawer
        setStatus={setIsSectionDraweropen}
        status={isSectionDrawerOpen}
        data={
          <div className=" rounded-sm bg-white flex flex-col h-full ">
            <div className="border-[#A09E9E] bg-white  border rounded-sm flex flex-col h-full">
              <div className="flex justify-between rounded-t-sm bg-slate-100">
                <div className="flex items-center pl-3 text-sm font-semibold">
                  Section Master - Help
                </div>
                <button
                  onClick={() => {
                    setIsSectionDraweropen(false);
                  }}
                  className="w-fit p-1 "
                >
                  <Close
                    sx={{
                      color: 'red',
                    }}
                  />
                </button>
              </div>

              <div className="grid grid-cols-1 w-full  rounded-b-sm gap-2  p-2  overflow-y-scroll">
                {sectionList.map((item, key) => (
                  <Grow
                    in={true}
                    key={key}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(true ? { timeout: key === 0 ? 0 : key * 100 } : {})}
                  >
                    <div
                      key={key}
                      className="flex overflow-hidden"
                      onClick={() => {
                        SecClick(item);
                      }}
                      style={{
                        border:
                          item.SECTION_NAME === sectionName
                            ? '2px solid #A8CF45'
                            : '0px solid #A8CF45',
                        height: '45px',
                        boxShadow: '1px 1px 3px 0px #9F9C9C',
                        borderRadius: '7px',
                        textTransform: 'none',
                        backgroundColor: '#f5eb82',
                        fontSize: '15px',
                        color: '#4a4a4a',
                      }}
                    >
                      <button
                        style={{
                          width: `calc(100% - 20px)`,
                        }}
                        className="text-[#4a4a4a] px-2 text-[15px] text-start  font-semibold whitespace-nowrap "
                      >
                        {toProperCase(item.SECTION_NAME)}
                      </button>
                      <Fade
                        orientation="horizontal"
                        in={item.SECTION_NAME === sectionName}
                      >
                        <button
                          style={{
                            paddingRight: '5px',
                            paddingLeft: '5px',
                          }}
                          className="bg-green h-full"
                          onClick={onClick}
                        >
                          <Close
                            color="white"
                            fontSize={'small'}
                            sx={{
                              fontSize: '15px',
                              color: 'white',
                            }}
                          />
                        </button>
                      </Fade>
                    </div>
                  </Grow>
                ))}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default SectionBillhelp;
