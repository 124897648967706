import React from 'react';
import { useSelector } from 'react-redux';
import { selectCurrency } from '../Redux/profile';

const DashCollectionsButton = ({ mode, payment, currency, bg, iconBg }) => {
  const userCurrency = useSelector(selectCurrency);

  return (
    <div
      style={{
        borderRadius: '3px',
        backgroundColor: bg,
      }}
      className={`flex justify-between px-1 shadow-lg py-1 `}
    >
      <div className="flex">
        <div
          style={{
            height: '50px',
            width: '50px',
            borderRadius: '3px',
            backgroundColor: iconBg,
          }}
          className="bg-[#bbe06c] flex justify-center items-center "
        >
          <div
            style={{
              fontSize: '18px',
            }}
          >
            {currency ? currency : userCurrency}
          </div>
        </div>
        <div className="pl-2 flex justify-center items-center ">
          <div>
            <div
              style={{
                fontSize: '13px',
                fontWeight: 600,
              }}
            >
              {mode}
            </div>
            {/* <div
              style={{
                fontSize: '12px',
              }}
            ></div> */}
            <div
              style={{
                fontSize: '16px',
                fontWeight: 600,
              }}
              className=" flex items-center text-black"
            >
              {payment}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashCollectionsButton;
