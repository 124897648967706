import React, { useEffect } from 'react';
import { CalendarMonth, Search } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
  selectDefultProfileSetting,
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
} from '../../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../utility/Redux/security';
import { setSessionExpired, setloading } from '../../utility/Redux/modal';
import axios from 'axios';
import { FormatNumber2 } from '../../utility/component/tools';

import Lottie from 'lottie-react';
import spinner from '../../utility/json/Spinner.json';
import { Button } from '@mui/material';
import { CallAPIPromise } from './dashboard/biiling/modal/comman';
const width = window.innerWidth / 100;

const PartyWiseReport = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const ReduxStation_id = useSelector(selectStationId);
  const dispatch = useDispatch();
  const ReduxStationName = useSelector(selectStationName);
  const [station_id, setStationId] = useState(ReduxStation_id);
  const [stationName, setStationname] = useState(ReduxStationName);

  const userType = useSelector(selectUserType);
  const [secondDateFocus, SetSecondDateFocus] = useState(false);
  const [firstdateFocus, setfirstDateFocus] = useState('');
  const [firstDate, setFirstDate] = useState(moment().format('YYYY-MM-DD'));
  const [SecondDate, setSecondDate] = useState(moment().format('YYYY-MM-DD'));
  const [dataLoading, setDataLoading] = useState(false);

  const defaultProfile = useSelector(selectDefultProfileSetting);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAccountData = () => {
    setDataLoading(true);
    CallAPIPromise(
      `/api/reports/collectionSummary`,
      {
        table_prefix: prefix,
        from_date: firstDate,
        to_date: SecondDate,
        sid: ReduxStation_id,
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
        setDataLoading(false);
      });
    // };
  };

  useEffect(() => {
    getAccountData();
  }, [firstDate, SecondDate]);

  const columns = [
    {
      field: 'DATE',
      type: 'string',
      width: width * 6,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('YYYY-MM-DD');
        } else {
          return '';
        }
      },

      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Date'}</div>,
    },

    {
      field: 'VOUCHER_NO',
      type: 'number',
      width: width * 8,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex text-center">
          {' '}
          {'Voucher No.'}
        </div>
      ),
    },

    {
      field: 'ACCOUNT_NO',
      type: 'string',
      width: width * 8.5,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Account No.'}
        </div>
      ),
    },

    {
      field: 'NAME',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Party Name'}</div>
      ),
    },

    {
      field: 'AMOUNT_DESC',
      type: 'number',
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Description'}
        </div>
      ),
    },

    {
      field: 'AMOUNT_RECEIVED',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Amount Received'}
        </div>
      ),
    },

    {
      field: 'AMOUNT_PAID',
      type: 'string',
      width: width * 8,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ width: width * 5, fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Amount Paid'}
        </div>
      ),
    },

    {
      field: 'INVNO',
      type: 'string',
      width: width * 8,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value;
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Bill No.'}</div>
      ),
    },
    {
      field: 'APPROVED',
      type: 'string',
      width: width * 4,
      //             valueFormatter: (params) => {
      //   if (params.value) {
      //     return  params.value
      //   } else {
      //     return "";
      //   }
      // },
      renderCell: (params) => {
        return (
          <div className="flex w-full justify-center  pr-4">
            <div className="w-full  flex justify-center items-center  "></div>
          </div>
        );
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Approved'}</div>
      ),
    },
  ];

  return (
    <div className="p-4 w-full h-full items-center">
      {window.innerWidth >= 425 ? (
        <div className="flex items-center  ">
          <div
            style={{ fontSize: 13, width: width * 6 }}
            className="font-semibold whitespace-normal text-[#4B4545]"
          >
            From Date :
          </div>

          <div>
            <DatePicker
              onFocus={() => setfirstDateFocus(true)}
              onClickOutside={() => setfirstDateFocus(false)}
              onSelect={() => setfirstDateFocus(false)}
              // readOnly={location.pathname === "/" ? false : true}
              // open ={'true'}
              open={
                userType === 'USER'
                  ? defaultProfile?.enable_user_dashboard_date === 'YES'
                    ? firstdateFocus
                    : false
                  : firstdateFocus
              }
              readOnly={true}
              className=" bg-transparent text-sm w-28  "
              dateFormat=" d MMMM , yyyy "
              selected={parseISO(firstDate)}
              onChange={(date) =>
                setFirstDate(moment(date).format('YYYY-MM-DD'))
              }
            />
          </div>

          <div className=" items-center flex justify-start bg-[#f7dda4] rounded-r-sm overflow-hidden">
            <CalendarMonth
              color={'secondary'}
              sx={{ opacity: 0.9 }}
              fontSize="small"
              onClick={() => {
                if (
                  userType === 'USER'
                    ? defaultProfile?.enable_user_dashboard_date === 'YES'
                    : true
                ) {
                  setfirstDateFocus(true);
                }
              }}
            />
          </div>

          <div
            style={{ fontSize: 13, width: width * 6 }}
            className="font-semibold text-[#4B4545] pl-3 "
          >
            To Date :
          </div>

          <div>
            <DatePicker
              onFocus={() => SetSecondDateFocus(true)}
              onClickOutside={() => SetSecondDateFocus(false)}
              onSelect={() => SetSecondDateFocus(false)}
              open={
                userType === 'USER'
                  ? defaultProfile?.enable_user_dashboard_date === 'YES'
                    ? secondDateFocus
                    : false
                  : secondDateFocus
              }
              readOnly={true}
              style={{ fontSize: 13 }}
              className=" bg-transparent w-28 text-sm    "
              dateFormat=" d MMMM , yyyy "
              selected={parseISO(SecondDate)}
              onChange={(date) =>
                setSecondDate(moment(date).format('YYYY-MM-DD'))
              }
            />
          </div>

          <div className=" items-center  flex justify-start mr-2  rounded-sm overflow-hidden">
            <CalendarMonth
              color={'secondary'}
              sx={{ opacity: 0.9 }}
              fontSize="small"
              onClick={() => {
                if (
                  userType === 'USER'
                    ? defaultProfile?.enable_user_dashboard_date === 'YES'
                    : true
                ) {
                  SetSecondDateFocus(true);
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="flex items-center">
            <div
              style={{ fontSize: 13, width: width * 20 }}
              className="font-semibold whitespace-normal  text-[#4B4545]"
            >
              From Date :
            </div>
            <div>
              <DatePicker
                onFocus={() => setfirstDateFocus(true)}
                onClickOutside={() => setfirstDateFocus(false)}
                onSelect={() => setfirstDateFocus(false)}
                // readOnly={location.pathname === "/" ? false : true}
                open={
                  userType === 'USER'
                    ? defaultProfile?.enable_user_dashboard_date === 'YES'
                      ? firstdateFocus
                      : false
                    : firstdateFocus
                }
                readOnly={true}
                className=" bg-transparent text-sm w-24 "
                dateFormat=" d MMMM , yyyy "
                selected={parseISO(firstDate)}
                onChange={(date) =>
                  setFirstDate(moment(date).format('YYYY-MM-DD'))
                }
              />
            </div>

            <div className=" items-center flex justify-start bg-[#f7dda4] rounded-r-sm overflow-hidden">
              <CalendarMonth
                color={'secondary'}
                sx={{ opacity: 0.9 }}
                fontSize="small"
                onClick={() => setfirstDateFocus(true)}
              />
            </div>
          </div>

          <div className="flex items-center">
            <div
              style={{ fontSize: 13, width: width * 20 }}
              className="font-semibold w-20 whitespace-normal text-[#4B4545] "
            >
              To Date :
            </div>

            <div>
              <DatePicker
                onFocus={() => SetSecondDateFocus(true)}
                onClickOutside={() => SetSecondDateFocus(false)}
                onSelect={() => SetSecondDateFocus(false)}
                open={
                  userType === 'USER'
                    ? defaultProfile?.enable_user_dashboard_date === 'YES'
                      ? secondDateFocus
                      : false
                    : secondDateFocus
                }
                readOnly={true}
                style={{ fontSize: 13 }}
                className=" bg-transparent w-24  text-sm    "
                dateFormat=" d MMMM , yyyy "
                selected={parseISO(SecondDate)}
                onChange={(date) =>
                  setSecondDate(moment(date).format('YYYY-MM-DD'))
                }
              />
            </div>

            <div className=" items-center  flex justify-start mr-2 bg-[#f7dda4] rounded-r-sm overflow-hidden">
              <CalendarMonth
                color={'secondary'}
                sx={{ opacity: 0.9 }}
                fontSize="small"
                onClick={() => {
                  if (defaultProfile?.enable_user_dashboard_date === 'YES') {
                    SetSecondDateFocus(true);
                  }
                }}
              />
            </div>
          </div>

          <div
            style={{ fontSize: 13 }}
            className=" hidden  md:flex whitespace-normal  "
          >
            <span
              style={{ fontSize: 13 }}
              className="whitespace-nowrap  font-semibold text-[#4B4545] pl-2"
            >
              {' '}
              Station Name :{' '}
            </span>
            <input
              size={'small'}
              placeholder="Station id"
              value={station_id}
              style={{ fontSize: 14, width: width * 2.4 }}
              readOnly
              className="outline-0 pl-2"
            />
            ({stationName})
            <Search color={'secondary'} fontSize="small" className="ml-1" />
          </div>
        </div>
      )}
      <div
        // onClick={() => dispatch(setcollVoucherAccModStatus(true))}
        className="flex md:pt-2 items-center "
      >
        <span
          style={
            window.innerWidth <= 425
              ? { fontSize: 13, width: width * 20 }
              : { fontSize: 13, width: width * 6 }
          }
          className="font-semibold text-[#4B4545] w-24 whitespace-nowrap  mr-2"
        >
          {' '}
          Account No :{' '}
        </span>

        <Search
          //   onClick={() => dispatch(setcollVoucherAccModStatus(true))}
          color={'secondary'}
          fontSize="small"
          className="ml-1 "
        />
        {userType === 'USER' ? null : (
          <div
            // onClick={() => {
            //   if (userType === 'MANAGER') {
            //     dispatch(setListStationMasterModalStatus(true));
            //   }
            // }}
            style={{ fontSize: 13 }}
            className=" hidden  md:flex whitespace-normal  "
          >
            <span
              style={{ fontSize: 13 }}
              className="whitespace-nowrap  font-semibold text-[#4B4545] pl-2"
            >
              {' '}
              Station :{' '}
            </span>

            <input
              size={'small'}
              placeholder="All"
              value={ReduxStation_id ? ReduxStation_id : 'All'}
              style={{ fontSize: 14, width: width * 2 }}
              readOnly
              className=" pl-1 outline-0 bg-[#f1f5f9] "
            />
            {ReduxStationName ? `(${ReduxStationName})` : null}
            {userType === 'MANAGER' ? (
              <Search color={'secondary'} fontSize="small" />
            ) : null}
          </div>
        )}
        <div className="ml-2">
          {' '}
          <Button
            sx={{ textTransform: 'none' }}
            size={'small'}
            variant={'outlined'}
            color={'primary'}
          >
            {' '}
            Reset{' '}
          </Button>{' '}
        </div>
      </div>

      <div style={{ height: '85%', width: '100%', marginTop: 10 }}>
        {dataLoading ? (
          <div className="w-full flex justify-center items-center">
            <div className="w-6 h-6">
              <Lottie
                animationData={spinner}
                loop={true}
                style={{ fontSize: 15 }}
                color="white"
              />
            </div>
          </div>
        ) : (
          <DataGrid
            // hideFooterPagination

            headerHeight={0}
            scrollbarSize={0}
            sx={
              window.innerWidth <= 425
                ? {
                    backgroundColor: 'white',

                    fontSize: 9,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                      display: 'none',
                    },
                  }
                : {
                    backgroundColor: 'white',

                    fontSize: 12,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                    },
                  }
            }
            rowHeight={33}
            columns={columns}
            rows={[]}
            // components={{
            //   Header: () => <GeniusHeader columns={columns} />,
            //   Pagination: GridPagination,
            //   Footer: () => (
            //     <CollectionSummaryFooter
            //       rows={voucherSummaryList}
            //       columns={columns}
            //     />
            //   ),
            // }}
            pageSize={100}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            pagination={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        )}
      </div>
    </div>
  );
};

export default PartyWiseReport;
