import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { parseISO } from 'date-fns';
import TotalIssueStockFooter from './functions/totalManufactoreFooter';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../utility/Redux/security';
import {
  selectPrefix,
  selectUserType,
  selectWidth,
} from '../../utility/Redux/profile';
import MasterFunction from './functions/masterFunction';
import SectionComponent from './component/sectionList';
import { useRef } from 'react';
import SpinnerLoading from '../../utility/component/modals/spinnerLoading';
import { SaveHouseMfg } from './functions/savehouseMfg';
import AlertModalTwoButton from '../../utility/component/modals/alertWithTwoButton';
import { DeleteItem } from './functions/deletItem';
import ModEmployeeMasterHelp from '../../utility/component/modals/modEmployeeMasterHelp';
import {
  setAlertWithTitle,
  setIssueStockItem,
  setModEmployeeMasterHelpStatus,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../utility/Redux/modal';
import ModNumericKeyboard from '../menu/dashboard/biiling/modal/modalNumericKeyBoard';
import {
  DeleteButton,
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../../utility/component/buttons';
import {
  ManufacturingColumns,
  ManufacturingMobColumns,
} from './datagrid/columns';
import ItemBox from '../issueStock/itemBox';
import { itemNameStyle } from '../issueStock/issueStockStyle';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import { LoadMfg } from './functions/loagMfg';
import { Skeleton } from '@mui/material';
const Manufactring = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateFocus, SetDateFocus] = useState(false);
  const [sname, setSname] = useState('');
  const [fullItemList, setFullItemList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [itemlist, setItemList] = useState([]);
  const [list, setlist] = useState([]);
  const [mfgDetails, setMfgDetails] = useState({
    DATE: moment().format('YYYY-MM-DD'),
    DMG_QNTY: '',
    EMPLOYEE_NAME: '',
    ICODE: '',
    ITEMNAME: '',
    MFG_QNTY: '',
    MFG_TIME: '',
    REMARKS: '',
    SECTION_NAME: '',
    SERIAL_NO: '',
  });
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [sectionList, setSectionList] = useState([]);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const userType = useSelector(selectUserType);
  const dispatch = useDispatch();
  const secRef = useRef(null);
  const [row, setRowData] = useState('');
  const searchRef = useRef(null);
  const windowWidth = useSelector(selectWidth);
  const columns = ManufacturingColumns(
    windowWidth,
    setAlertTwoButtonModal,
    prefix,
    token,
    dispatch,
    alertTwoButtonModal,
    list,
    setlist,
    DeleteItem
  );
  const MobColumns = ManufacturingMobColumns(
    windowWidth,
    setAlertTwoButtonModal,
    prefix,
    token,
    dispatch,
    alertTwoButtonModal,
    list,
    setlist,
    DeleteItem
  );
  useEffect(() => {
    if (sname) {
      MasterFunction(
        setSectionList,
        prefix,
        token,
        dispatch,
        setItemList,
        sname,
        setFullItemList
      );
    } else {
      MasterFunction(
        setSectionList,
        prefix,
        token,
        dispatch,
        setItemList,
        sname,
        setFullItemList
      );
    }
  }, [sname]);
  useEffect(() => {
    LoadMfg(date, prefix, token, setlist, dispatch);
  }, [date]);

  const cellEdit = ({ id, field, value }) => {
    if (field === 'MFG_QNTY') {
      setlist((e) =>
        e.map((m) => {
          if (m.ICODE === id) {
            return {
              ...m,
              MFG_QNTY: value,
            };
          } else {
            return m;
          }
        })
      );
    } else {
      if (field === 'DMG_QNTY') {
        setlist((e) =>
          e.map((m) => {
            if (m.ICODE === id) {
              return {
                ...m,
                DMG_QNTY: value,
              };
            } else {
              return m;
            }
          })
        );
      } else {
        if (field === 'REMARKS') {
          setlist((e) =>
            e.map((m) => {
              if (m.ICODE === id) {
                return {
                  ...m,
                  REMARKS: value,
                };
              } else {
                return m;
              }
            })
          );
        } else {
          if (field === 'EMPLOYEE_NAME') {
            setlist((e) =>
              e.map((m) => {
                if (m.ICODE === id) {
                  return {
                    ...m,
                    EMPLOYEE_NAME: value,
                  };
                } else {
                  return m;
                }
              })
            );
          } else {
            if (field === 'MFG_QNTY_TEXTINPUT') {
              setMfgDetails((d) => {
                return {
                  ...d,
                  MFG_QNTY: value,
                };
              });
            } else {
              if ((field = 'DMG_QNTY_TEXTINPUT')) {
                setMfgDetails((d) => {
                  return {
                    ...d,
                    DMG_QNTY: value,
                  };
                });
              } else {
                if (field === 'REMARKS_TEXTINPUT') {
                  setMfgDetails((d) => {
                    return {
                      ...d,
                      REMARKS: value,
                    };
                  });
                }
              }
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullItemList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.ICODE.toString().includes(searchText);
        } else {
          return item.ITEMNAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });
      setItemList(searchItems);
    } else {
      setItemList(fullItemList);
    }
  }, [searchText]);

  return (
    <div className="w-full h-full p-2 text-sm bg-slate-50">
      <div className="t:hidden">
        <MfnDetails />
      </div>
      <div
        style={{
          height: windowWidth < 768 ? `calc(100% - 355px)` : '100%',
        }}
        className=" w-full  flex mt-2 t:mt-0"
      >
        <div
          // className="t:h-full flex flex-col-reverse t:flex-col   "
          className="h-full flex-col  m "
        >
          <div className="hidden t:flex flex-col">{MfnDetails()}</div>

          <div
            style={{
              height: `calc(100% - 0px)`,
            }}
            className=" t:mt-2 w-full "
          >
            <div
              style={{
                height: `calc(100% - ${windowWidth < 768 ? 80 : 370}px)`,
              }}
            >
              {list && (
                <GeniusDatagrid
                  list={list}
                  columns={windowWidth < 768 ? MobColumns : columns}
                  editCell={cellEdit}
                  rowID={(row) => row.SERIAL_NO}
                  onDoubleClick={(e) => {
                    setMfgDetails({
                      DATE: e.row.DATE,
                      DMG_QNTY: e.row.DMG_QNTY,
                      EMPLOYEE_NAME: e.row.EMPLOYEE_NAME,
                      ICODE: e.row.ICODE,
                      ITEMNAME: e.row.ITEMNAME,
                      MFG_QNTY: e.row.MFG_QNTY,
                      MFG_TIME: e.row.MFG_TIME,
                      REMARKS: e.row.REMARKS,
                      SECTION_NAME: e.row.SECTION_NAME,
                      SERIAL_NO: e.row.SERIAL_NO,
                    });
                  }}
                />
              )}
            </div>

            <TotalIssueStockFooter
              rows={list}
              columns={windowWidth < 768 ? MobColumns : columns}
            />
          </div>
        </div>

        <div
          style={{
            height: `calc(100% - ${windowWidth < 768 ? 50 : 0}px)`,
            width: `calc(100% -  ${
              windowWidth < 768
                ? MobColumns.reduce((acc, row) => acc + Number(row.width), 0) +
                  10
                : columns.reduce((acc, row) => acc + Number(row.width), 0) + 10
            }px)`,
          }}
          className="flex    ml-2 "
        >
          <div
            // className="w-full hidden t:flex flex-col  t:h-full "
            className="w-full flex flex-col h-full "
          >
            <div
              style={{
                border: '1px solid #e0e0e0',
              }}
              ref={searchRef}
              className="w-full h-fit rounded-sm  flex items-center px-2 bg-white "
            >
              <input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                className="w-full py-2 outline-0"
                type="text"
                placeholder="Search"
              />
            </div>
            <div ref={secRef} className="h-[62px]  w-full   ">
              <SectionComponent
                sectionList={sectionList}
                setSname={setSname}
                sname={sname}
              />
            </div>

            <div
              style={{
                height: `calc(100% - ${
                  secRef.current?.clientHeight +
                  searchRef.current?.clientHeight +
                  18
                }px)`,
              }}
              className="flex flex-col mt-7  "
            >
              <div
                style={{
                  border: '1px solid #e0e0e0',
                }}
                className="h-full rounded-sm p-1 bg-white overflow-y-scroll  "
              >
                <div className="grid d:grid-cols-4 grid-cols-1 gap-1  p-1 ">
                  {itemlist[0]
                    ? itemlist.map((box) => {
                        return (
                          <ItemBox
                            item={box}
                            itemNameStyle={itemNameStyle}
                            onClick={(t) => {
                              setMfgDetails({
                                SERIAL_NO: '',
                                DATE: moment().format('YYYY-MM-DD'),
                                SECTION_NAME: box.SNAME,
                                ICODE: box.ICODE,
                                ITEMNAME: box.ITEMNAME,
                                MFG_QNTY: '',
                                DMG_QNTY: '',
                                REMARKS: '',
                                MFG_TIME: '',
                                EMPLOYEE_NAME: '',
                              });
                            }}
                          />
                        );
                      })
                    : [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                      ].map(() => {
                        return (
                          <Skeleton
                            variant="rectangular"
                            width={148}
                            height={70}
                            sx={{ fontSize: '1rem', borderRadius: '7px' }}
                          />
                        );
                      })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <SpinnerLoading />
        <AlertModalTwoButton
          details={alertTwoButtonModal}
          setAlertTwoButtonModal={setAlertTwoButtonModal}
        />
        <ModEmployeeMasterHelp
          onRowClick={(p) => {
            setMfgDetails((m) => {
              return {
                ...m,
                EMPLOYEE_NAME: p.row.employee_name,
              };
            });
            dispatch(setModEmployeeMasterHelpStatus(false));
          }}
        />
        <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
      </div>
      <div className=" flex justify-end t:hidden">
        {' '}
        <ButtonFunc />
      </div>
    </div>
  );

  function MfnDetails() {
    return (
      <div
        style={{
          border: '1px solid #ebe6e6',
          width:
            windowWidth < 768
              ? '100%'
              : columns.reduce((acc, row) => acc + Number(row.width), 0),
        }}
        className="rounded-sm p-4 bg-white "
      >
        <div className="w-full flex ">
          <div className="flex-1">
            <div className="flex justify-between">
              <div className="font-semibold text-[#5a5959]">Date</div>
              <div className="text-orange font-semibold">
                {moment(date).format('dddd')}
              </div>
            </div>
            <div
              style={{
                border: '1px solid  #b5b3b3',
              }}
              className="rounded-sm flex mt-[2px] flex-1 bg-[#F1EFEB]  "
            >
              <div className="flex flex-1">
                <DatePicker
                  className=" text-sm w-full bg-transparent py-1 focus:outline-none pl-2 t:pl-1 text-[#3a3939]"
                  dateFormat=" d/MM/yyyy "
                  selected={parseISO(moment(date).format('YYYY-MM-DD'))}
                  onChange={(e) => {
                    if (userType === 'MANAGER') {
                      setDate(moment(e).format('YYYY-MM-DD'));
                      SetDateFocus(false);
                    }
                  }}
                  onFocus={() => {
                    if (userType === 'MANAGER') {
                      SetDateFocus(true);
                    }
                  }}
                  readOnly={true}
                  onClickOutside={() => SetDateFocus(false)}
                  open={dateFocus}
                />
                <div
                  style={{
                    borderLeft: '1px solid #b5b3b3',
                  }}
                  className="flex items-center bg-[#f7dda4] rounded-r-sm"
                >
                  <i
                    className="bi bi-calendar2 py-1 px-2"
                    onClick={() => {
                      if (userType === 'MANAGER') {
                        SetDateFocus(true);
                      }
                    }}
                    style={{ fontSize: '14px' }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="flex  ml-3 flex-1 ">
            <div className="flex-1">
              <div className="flex items-center ">
                <div className=" text-[#5a5959]">Employee</div>
                <button
                  onClick={() => {
                    dispatch(setModEmployeeMasterHelpStatus(true));
                  }}
                  className="flex items-center"
                >
                  <i class="bi bi-search text-orange ml-2 font-semibold  flex items-center text-[12px]"></i>
                </button>
              </div>
              <div
                style={{
                  border: '1px solid  #b5b3b3',
                }}
                className="rounded-sm flex w-full   mt-[2px]"
              >
                <input
                  type={'text'}
                  value={mfgDetails.EMPLOYEE_NAME}
                  className="py-1 outline-0 w-full pl-1 rounded-sm "
                  onClick={() => {
                    dispatch(setModEmployeeMasterHelpStatus(true));
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full  flex">
          <div className="flex-1">
            <div className="flex w-full mt-3">
              <div className="w-full">
                <div className="flex justify-between">
                  <div className="font-semibold text-[#5a5959]">Item Name</div>
                </div>
                <div
                  style={{
                    border: '1px solid  #b5b3b3',
                  }}
                  className="rounded-sm flex w-full mt-[2px]"
                >
                  <input
                    type={'text'}
                    value={mfgDetails.ITEMNAME}
                    className="py-1 outline-0 w-full pl-1 rounded-sm "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" flex-1   mt-3 ml-3">
            <div className="flex justify-between">
              <div className=" text-[#5a5959]">Section Name</div>
            </div>
            <div
              style={{
                border: '1px solid  #b5b3b3',
              }}
              className="rounded-sm  w-full flex   mt-[2px]"
            >
              <input
                type={'text'}
                value={mfgDetails.SECTION_NAME}
                className="py-1 outline-0 w-full pl-1 rounded-sm "
              />
            </div>
          </div>
        </div>

        <div className="w-full flex ">
          <div className="w-full">
            <div className="flex w-full mt-3 ">
              <div className="flex-1">
                <div className="flex justify-between">
                  <div className="font-semibold text-[#5a5959]">
                    Qnty. Manf.
                  </div>
                </div>
                <div
                  style={{
                    border: '1px solid  #b5b3b3',
                  }}
                  className="rounded-sm flex w-full   mt-[2px]"
                >
                  <input
                    type={'number'}
                    readOnly={windowWidth < 768}
                    value={mfgDetails.MFG_QNTY}
                    className="py-1 outline-0 w-full pl-1 rounded-sm "
                    onClick={() => {
                      dispatch(setnumericKeyboardStatus(true));
                      dispatch(setnumericKeyboardType('MFG_QNTY_TEXTINPUT'));
                      dispatch(
                        setIssueStockItem({
                          title: 'Qnty Manufactured',
                          value: mfgDetails.MFG_QNTY ? mfgDetails.MFG_QNTY : 0,
                          id: '1',
                        })
                      );
                    }}
                    onChange={(e) => {
                      setMfgDetails({
                        ...mfgDetails,
                        MFG_QNTY: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="ml-3 flex-1 ">
                <div className="flex justify-between">
                  <div className="text-orange font-semibold">Damage Qnty</div>
                </div>
                <div
                  style={{
                    border: '1px solid  #b5b3b3',
                  }}
                  className="rounded-sm flex w-full   mt-[2px]"
                >
                  <input
                    type={'number'}
                    readOnly={windowWidth < 768}
                    value={mfgDetails.DMG_QNTY}
                    className="py-1 outline-0 w-full pl-1 rounded-sm "
                    onChange={(e) => {
                      setMfgDetails({
                        ...mfgDetails,
                        DMG_QNTY: e.target.value,
                      });
                    }}
                    onClick={() => {
                      dispatch(setnumericKeyboardStatus(true));
                      dispatch(setnumericKeyboardType('DMG_QNTY_TEXTINPUT'));
                      dispatch(
                        setIssueStockItem({
                          title: 'Damage Qnty',
                          value: mfgDetails.DMG_QNTY ? mfgDetails.DMG_QNTY : 0,
                          id: '1',
                        })
                      );
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="w-full  flex">
              <div className="flex-1">
                <div className=" text-[#5a5959] mt-2">Time</div>

                <div
                  style={{
                    border: '1px solid  #b5b3b3',
                  }}
                  className="rounded-sm flex  w-full mt-[2px]"
                >
                  <input
                    type={'time'}
                    value={mfgDetails.MFG_TIME}
                    className="py-1 outline-0 w-full pl-1 rounded-sm "
                    onChange={(e) => {
                      setMfgDetails((d) => {
                        return {
                          ...d,
                          MFG_TIME: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex-1 ml-3 mt-3">
                <div className=" text-[#5a5959]">Remarks</div>
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                  }}
                  className="rounded-sm overflow-hidden w-full mt-[2px] "
                >
                  <input
                    value={mfgDetails.REMARKS}
                    className="py-1 outline-0 w-full pl-1 rounded-sm "
                    onChange={(e) => {
                      setMfgDetails((d) => {
                        return {
                          ...d,
                          REMARKS: e.target.value,
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="t:flex hidden w-full justify-between mt-3">
          {ButtonFunc()}
        </div>
      </div>
    );
  }

  function ButtonFunc() {
    return (
      <div className="flex">
        {mfgDetails.SERIAL_NO ? (
          <ModifyButton
            onClick={() => {
              if (mfgDetails.EMPLOYEE_NAME) {
                SaveHouseMfg(
                  mfgDetails,
                  prefix,
                  token,
                  dispatch,
                  date,
                  setlist,
                  setMfgDetails
                );
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'Please select any employee first to save',
                  })
                );
              }
            }}
          />
        ) : (
          <SaveButton
            onClick={() => {
              if (
                mfgDetails.EMPLOYEE_NAME &&
                mfgDetails.MFG_QNTY &&
                mfgDetails.MFG_TIME
              ) {
                SaveHouseMfg(
                  mfgDetails,
                  prefix,
                  token,
                  dispatch,
                  date,
                  setlist,
                  setMfgDetails
                );
              } else {
                if (!mfgDetails.EMPLOYEE_NAME) {
                  dispatch(
                    setAlertWithTitle({
                      title: 'Alert',
                      msg: 'Please select any employee first to save',
                    })
                  );
                } else {
                  if (!mfgDetails.MFG_QNTY) {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Please enter Manufactured Quantity',
                      })
                    );
                  } else {
                    if (!mfgDetails.MFG_TIME) {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Please enter Manufactured Time',
                        })
                      );
                    }
                  }
                }
              }
            }}
          />
        )}
        <UndoButton
          onClick={() => {
            setMfgDetails({
              DATE: moment().format('YYYY-MM-DD'),
              DMG_QNTY: '',
              EMPLOYEE_NAME: '',
              ICODE: '',
              ITEMNAME: '',
              MFG_QNTY: '',
              MFG_TIME: '',
              REMARKS: '',
              SECTION_NAME: '',
              SERIAL_NO: '',
            });
          }}
        />
        <DeleteButton
          onClick={() => {
            if (mfgDetails.SERIAL_NO) {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: true,
                title: 'Delete Item',
                msg: 'Are you sure you want to delete this item.',
                button1: 'Yes',
                button2: 'Cancel',
                button1Click: () => {
                  DeleteItem(
                    mfgDetails,
                    prefix,
                    token,
                    dispatch,
                    setAlertTwoButtonModal,
                    alertTwoButtonModal,
                    setlist
                  ).then(() => {
                    setMfgDetails({
                      DATE: moment().format('YYYY-MM-DD'),
                      DMG_QNTY: '',
                      EMPLOYEE_NAME: '',
                      ICODE: '',
                      ITEMNAME: '',
                      MFG_QNTY: '',
                      MFG_TIME: '',
                      REMARKS: '',
                      SECTION_NAME: '',
                      SERIAL_NO: '',
                    });
                  });
                },
                button2Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                },
              });
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Select any item to delete',
                })
              );
            }
          }}
        />
      </div>
    );
  }
};

export default Manufactring;
