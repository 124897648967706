import React from 'react';

export const ItemMasterColumns = (
  windowWidth,
  handleCheckboxChange,
  saveCartList,
  selectAll,
  setSelectAll
) => {
  const columns = [
    {
      field: 'ICODE',
      width: windowWidth <= 1000 ? 60 : 80,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Code'}</strong>
      ),
    },
    {
      field: 'ITEMNAME',
      width: windowWidth <= 500 ? 100 : windowWidth <= 1000 ? 150 : 250,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Item Name'}</strong>
      ),
    },

    {
      field: 'MRP',
      width: windowWidth <= 1000 ? 80 : 150,
      headerAlign: 'center',
      editable: true,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'MRP'}</strong>
      ),
    },

    {
      field: 'Select',
      width: windowWidth <= 1000 ? 70 : 75,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={saveCartList.some((row) => row.ICODE === params.row.ICODE)}
            // isSelected={checkk}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }} className="flex items-center">
          {'Select'}{' '}
          <input
            className="ml-2"
            type="checkbox"
            checked={selectAll}
            onChange={(e) => setSelectAll(e.target.checked)}
          />
        </strong>
      ),
    },
  ];

  return columns;
};
