import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Lottie from 'lottie-react';
import success from '../../../utility/json/success2.json';
import {
  selectLoginSuccesModalSuccess,
  setLoginSuccess,
} from '../../Redux/modal';

export default function ModApproveSucces() {
  const dispatch = useDispatch();
  const alertStatus = useSelector(selectLoginSuccesModalSuccess);
  const handleClose = () => dispatch(setLoginSuccess(false));

  return (
    <div className=" rounded-lg">
      <Modal open={alertStatus} onClose={handleClose}>
        <div
          className="d:w-1/5  ml:w-10/12 ms:w-11/12 l:w-5/12 bg-brown overflow-hidden rounded-lg t:w-4/12 p:w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2 bg-[#262424] drop-shadow-xl">
            genius office
          </div>

          <div className="justify-center bg-white  w-full items-center ">
            <div className="flex justify-center w-full pt-4 ">
              <Lottie
                animationData={success}
                loop={false}
                style={{ fontSize: 30 }}
              />
            </div>
            <div className="flex w-full justify-center py-4 text-xl">
              Login Success
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
