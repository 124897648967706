import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectShareImageModalStatus,
  selectStatus,
  setConfirmPrintBillModalStatus,
  setShareImageModalStatus,
} from '../../../../../utility/Redux/modal';

import { BarLoader } from 'react-spinners';
import ShareBill from './shareBill';
import {
  clearOrderBillingList,
  setBillSettlementDetails,
} from '../../../../../utility/Redux/tableOrder';
import { setBillingAccountDetails } from '../../../../../utility/Redux/profile';
import { useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',

  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function ShareBillModal() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ModalStatus = useSelector(selectShareImageModalStatus);
  const loading = useSelector(selectStatus);

  const handleClose = () => {
    dispatch(setConfirmPrintBillModalStatus(false));
    dispatch(clearOrderBillingList());
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: '',
        TOTAL_COLLECTION: 0,
      })
    );
    dispatch(
      setBillingAccountDetails({
        accNumber: '',
        accMobile: '',
        accName: '',
      })
    );
    dispatch(setShareImageModalStatus(false));
    navigate('/');
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={ModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className="flex-grow h-full bg-white    border-brown">
              <div className="h-0.5 ">
                <BarLoader
                  color={'#F5874F'}
                  loading={loading}
                  cssOverride={{
                    top: 0,
                    width: 'auto',
                  }}
                  size={150}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                />
              </div>
              <div className="h-full w-full">
                <ShareBill />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
