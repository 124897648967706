import React, { useEffect } from 'react';
import {
  AppBar,
  Drawer,
  DrawerHeader,
} from '../../../utility/component/compHome';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  Close,
  Help,
  Logout,
  Person,
  QuestionMark,
  SupportAgent,
} from '@mui/icons-material';
import goLogo from '../../../utility/images/go_logo.svg';
import {
  selectDevloperMode,
  selectFullScreen,
  selectPrefix,
  selectUserName,
  selectUserType,
  setFullScreen,
} from '../../Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCustomeTwoButtomAlert,
  setSessionExpired,
  setUserStatusModalStatus,
} from '../../../utility/Redux/modal';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutbillingHomeLogo from './homeLogo';

const AppBarDesign = () => {
  const [timeLeft, setTimeLeft] = useState({ minutes: 0, seconds: 0 });
  const [MobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = React.useState('Dashboard');
  const [subDomain, setSubDomain] = useState('');
  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  const userName = useSelector(selectUserName);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const isFullscreen = useSelector(selectFullScreen);
  const isDevloper = useSelector(selectDevloperMode);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const requestFullscreen = () => {
    const element = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
      dispatch(setFullScreen(true));
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
      dispatch(setFullScreen(true));
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
      dispatch(setFullScreen(true));
    }
  };
  const prefix = useSelector(selectPrefix);

  useEffect(() => {
    setSubDomain(() => {
      if (
        prefix === 'stc_' ||
        prefix === 'mb_' ||
        prefix === 'go_' ||
        window.location.href.includes('localhost') ||
        window.location.href.includes('192')
      ) {
        return true;
      } else {
        return false;
      }
    });
  }, [prefix]);

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      dispatch(setFullScreen(false));
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
      dispatch(setFullScreen(false));
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
      dispatch(setFullScreen(false));
    }
  };

  const userRoute = subDomain
    ? ['Dashboard', 'Reports', 'Summary', 'Updation', 'Manufacturing']
    : ['Dashboard', 'Reports', 'Summary', 'Updation'];

  const ManagerRoute = subDomain
    ? [
        'Dashboard',
        'Masters',
        'Reports',
        'Summary',
        'Updation',
        'Issue Stock',
        'Manufacturing',
      ]
    : ['Dashboard', 'Masters', 'Reports', 'Summary', 'Updation', 'Issue Stock'];

  const menuDrawer =
    userType === 'SYSADMIN'
      ? ['Dashboard']
      : userType === 'ADMIN'
      ? [
          'Dashboard',
          'Company Master',
          'User Master',
          'Settings',
          'Messanger Master',
          'Send Promo',
        ]
      : userType === 'USER'
      ? userRoute
      : userType === 'MANAGER' && isDevloper
      ? ['Dashboard', 'Masters', 'Reports', 'Summary', 'Updation']
      : userType === 'MANAGER'
      ? ManagerRoute
      : userType === 'STORE'
      ? ['Issue Stock', 'Reports', 'Updation', 'Summary']
      : userType === 'MANUFACTURING'
      ? ['Manufacturing', 'Mfg.Summary']
      : [];

  const expirationTime = localStorage.getItem('tknTime'); // 1 hour in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      // Get the current timestamp
      const currentTime = new Date().getTime();

      const difference = expirationTime - currentTime;

      const minutes = Math.floor((difference / 60000) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({ minutes, seconds });
      if (minutes < 1) {
        dispatch(setSessionExpired(true));
      }
    }, 1000); // Update every second

    // Cleanup function to clear the interval
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <AppBar
        elevation={2}
        position="fixed"
        open={open}
        style={{
          height: 50,
          justifyItems: 'start',
          backgroundColor: '#0d0d0d',
        }}
      >
        <Toolbar
          style={{ height: '100%', justifyItems: 'start' }}
          className={' -mt-[3px] t:-mt-[7px] ll:-mt-[6px] '}
        >
          {window.innerWidth <= 600 ? (
            <MenuIcon
              sx={{ marginRight: 1 }}
              onClick={() => setMobileMenu(!MobileMenu)}
            />
          ) : location.pathname === '/' ? (
            <MenuIcon
              color="inherit "
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 1,
                marginLeft: -1,

                ...(open && { display: 'none' }),
              }}
              onClick={handleDrawerOpen}
            />
          ) : (
            <MenuIcon
              color="inherit"
              aria-label="open drawer"
              edge="start"
              sx={{
                marginRight: 1,
                marginLeft: -1,

                ...(open && { display: 'none' }),
              }}
              onClick={handleDrawerOpen}
            />
          )}

          {/* </IconButton> */}

          <div className="   flex grow justify-between items-center  w-full     ">
            <div className="flex ">
              <div className={`flex items-center  ${open && '-ml-3'}   `}>
                <RoutbillingHomeLogo />{' '}
              </div>
            </div>

            <div
              style={{ fontSize: 14 }}
              className={'tracking-[.1em]    flex items-center ml-4  '}
            >
              {' '}
              <div className="text-[#e65858]  hidden mr-2 text-[15px] l:flex justify-center flex-1 ">
                {timeLeft.minutes < 11 &&
                  `Session Expire in ${timeLeft.minutes}m : ${timeLeft.seconds}s`}
              </div>
              <button
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={'Full Screen'}
                onClick={!isFullscreen ? requestFullscreen : exitFullscreen}
                className="mr-2 hidden md:flex items-center"
              >
                {' '}
                <i className="bi bi-fullscreen flex items-center text-orange"></i>
              </button>{' '}
              <div
                data-tooltip-place="bottom"
                data-tooltip-id="tip2"
                data-tooltip-offset={25}
                data-tooltip-content={userType}
                className="pl-2 w-fit  text-right  capitalize overflow-ellipsis overflow-hidden  "
              >
                {' '}
                {userName}
              </div>{' '}
              <div
                className={`flex items-center ml-2 ${
                  userType === 'MANAGER'
                    ? ' text-[#2bf403e5] '
                    : userType === 'ADMIN'
                    ? 'text-orange '
                    : 'text-[#03a9f4] '
                } `}
              >
                <button
                  onClick={() => {
                    dispatch(setCustomeTwoButtomAlert(true));
                  }}
                  data-tooltip-place="bottom"
                  data-tooltip-id="tip3"
                  data-tooltip-class-name="bg-red-500"
                  data-tooltip-offset={25}
                  data-tooltip-content="Logout"
                >
                  <i
                    style={{ fontSize: 20 }}
                    className={`${
                      userType === 'ADMIN'
                        ? 'bi bi-person-gear'
                        : 'bi bi-person-circle '
                    } flex items-center -mt-[2px] ll:mt-0`}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        className={`${
          MobileMenu ? ' p:flex absolute  ' : 'ms:hidden p:flex absolute '
        } `}
      >
        <div className="bg-[#0d0d0d]  ">
          {open && (
            <DrawerHeader className=" justify-start items-start h-full mt-2   ">
              <div className="flex w-full justify-center   ">
                <div className="flex flex-col items-center ">
                  <img alt="" src={goLogo} className="h-auto  w-40 " />
                </div>
              </div>
              <div
                style={{
                  zIndex: 100,
                  marginRight: '13px',
                }}
                className="  h-full  items-start "
              >
                {' '}
                <Close color="white" onClick={() => setOpen(false)} />
              </div>
            </DrawerHeader>
          )}
        </div>

        <div
          className={`${open ? 'bg-[#0d0d0d] pt-5' : ' pt-10 bg-[#0d0d0d]'} `}
        >
          <List
            sx={{
              width: '100%',
            }}
          >
            {menuDrawer.map((text, index) => (
              <ListItem
                sx={{
                  width: '100%',
                }}
                onClick={() => {
                  if (text === 'Dashboard') {
                    navigate('/');
                  }
                  if (text === 'Masters') {
                    navigate('masters');
                  }
                  if (text === 'Reports') {
                    navigate('/reports');
                  }
                  if (text === 'Send Promo') {
                    navigate('/send_promo_message');
                  }
                  if (text === 'Summary') {
                    navigate('/summary');
                  }
                  if (text === 'Updation') {
                    navigate('/updation');
                  }

                  if (text === 'Issue Stock') {
                    navigate('/issue_stock');
                  }
                  if (text === 'Manufacturing') {
                    navigate('/Mfg');
                  }
                  if (text === 'Company Master') {
                    navigate('/company_master');
                  }
                  if (text === 'User Master') {
                    navigate('/user_master');
                  }

                  if (text === 'Settings') {
                    navigate('/settings');
                  }
                  if (text === 'Messanger Master') {
                    navigate('/promo_messanger_master');
                  }
                  setSelectedMenu(text);
                  setMobileMenu(false);
                }}
                key={text}
                disablePadding
              >
                <div
                  className={`${
                    !open && selectedMenu === text
                      ? 'bg-[#1a1919]  '
                      : !open && 'hover:bg-[#2e2d2d]'
                  } w-full  cursor-pointer`}
                  style={
                    selectedMenu === text
                      ? {
                          color: '#03a9f4',
                          display: 'flex',
                          paddingLeft: '10px',
                          paddingRight: '10px',
                          paddingTop: '1px',

                          flexDirection: 'row',
                          alignItems: 'center',
                        }
                      : {
                          color: '#f2f4f7',
                          paddingLeft: '10px',
                          paddingTop: '1px',

                          paddingRight: '10px',

                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }
                  }
                >
                  <div
                    className={`flex ${open && 'hover:bg-[#2e2d2d]'}  ${
                      selectedMenu === text && open && 'bg-[#222121]'
                    } w-full p-2 pl-2 items-center rounded-sm`}
                  >
                    <div className="">
                      {text === 'Dashboard' && (
                        <div
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Dashboard"
                          className="w-10  flex justify-start"
                        >
                          <i className="bi  text-[18px]  bi-columns-gap"></i>
                        </div>
                      )}
                      {text === 'Masters' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10  ${
                            selectedMenu === text && 'rounded-sm'
                          } flex justify-start `}
                        >
                          MS
                        </div>
                      )}
                      {text === 'Messanger Master' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10  ${
                            selectedMenu === text && 'rounded-sm'
                          } flex justify-start `}
                        >
                          PM
                        </div>
                      )}
                      {text === 'Settings' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Settings"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          SS
                        </div>
                      )}
                      {text === 'Manufacturing' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Manufacturing"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          MF
                        </div>
                      )}
                      {text === 'Send Promo' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Manufacturing"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          SP
                        </div>
                      )}
                      {text === 'Promotional' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Manufacturing"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          PM
                        </div>
                      )}
                      {text === 'Company Master' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Company Master"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          CM
                        </div>
                      )}
                      {text === 'User Master' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="User Master"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          UM
                        </div>
                      )}
                      {text === 'User List' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="User List"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          UL
                        </div>
                      )}
                      {text === 'Issue Stock' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Issue stock"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          IS
                        </div>
                      )}
                      {text === 'Reports' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Reports"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          RE
                        </div>
                      )}
                      {text === 'Updation' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Updation"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          UP
                        </div>
                      )}
                      {text === 'Summary' && (
                        <div
                          style={{
                            fontSize: '15px',
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Summary"
                          className={`w-10  ${
                            selectedMenu === text && ''
                          } flex justify-start`}
                        >
                          SU
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      className=""
                    >
                      {text}
                    </div>
                  </div>
                </div>
              </ListItem>
            ))}
          </List>
        </div>

        <div className="h-full bg-[#0d0d0d] flex flex-col justify-end pb-0 items-center ">
          <div style={{ height: '0.5px' }} className=" bg-[#a9a9a9]  w-11/12" />
          <ListItem disablePadding>
            <a
              href="https://geniusoffice.freshdesk.com/support/tickets/new"
              target="_blank"
              style={{
                textDecoration: 'none',
              }}
              rel="noopener noreferrer"
              className="text-blue-400 underline"
            >
              <ListItemButton style={{ paddingLeft: 12 }}>
                <ListItemIcon>
                  <QuestionMark style={{ fontSize: 20, color: '#03a9f4' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Creat a ticket"
                  className="text-[#a9a9a9] text-sm"
                  style={{ marginLeft: -18 }}
                  primaryTypographyProps={{
                    variant: 'subtitle',
                  }}
                />
              </ListItemButton>
            </a>
          </ListItem>
          {userType === 'MANAGER' ? (
            <ListItem disablePadding>
              <ListItemButton
                style={{ paddingLeft: 12 }}
                onClick={() => {
                  navigate('/settings');

                  setMobileMenu(false);
                }}
              >
                <ListItemIcon>
                  <i
                    className="bi bi-gear"
                    style={{ fontSize: 20, color: '#df7943' }}
                  ></i>
                </ListItemIcon>
                <ListItemText
                  primary="System Default Settings"
                  className="text-[#a9a9a9] text-sm"
                  style={{ marginLeft: -18 }}
                  primaryTypographyProps={{
                    variant: 'subtitle',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}

          {userType === 'MANAGER' ? (
            <ListItem disablePadding>
              <ListItemButton
                style={{ paddingLeft: 12 }}
                onClick={() => {
                  dispatch(setUserStatusModalStatus(true));

                  setMobileMenu(false);
                }}
              >
                <ListItemIcon>
                  <i
                    style={{ fontSize: 24, color: '#ea5656' }}
                    className="bi bi-person-check-fill"
                  ></i>
                </ListItemIcon>
                <ListItemText
                  primary="User Active/Deactive"
                  className="text-[#a9a9a9] text-sm"
                  style={{ marginLeft: -18 }}
                  primaryTypographyProps={{
                    variant: 'subtitle',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                setOpen(false);

                navigate('/user_profile');
              }}
            >
              <ListItemIcon>
                <Person style={{ fontSize: 24, color: '#03a9f4' }} />
              </ListItemIcon>
              <ListItemText
                primary="Profile"
                className="text-[#a9a9a9]"
                style={{ marginLeft: -14 }}
                primaryTypographyProps={{
                  variant: 'subtitle',
                  style: { fontSize: 16 },
                }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                dispatch(
                  setCustomeTwoButtomAlert({
                    status: true,
                  })
                );
                setMobileMenu(false);
              }}
            >
              <ListItemIcon
                onClick={() => {
                  dispatch(
                    setCustomeTwoButtomAlert({
                      status: true,
                    })
                  );
                  setMobileMenu(false);
                }}
              >
                <Logout style={{ fontSize: 20, color: '#03a9f4' }} />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                className="text-[#a9a9a9] text-sm"
                style={{ marginLeft: -18 }}
                primaryTypographyProps={{
                  variant: 'subtitle',
                }}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Drawer>
    </div>
  );
};

export default AppBarDesign;
