import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setDepartmentMasterStatus,
  setExpenseMasterModalStatus,
  setSection_MasterStatus,
  setStation_MasterStatus,
} from '../utility/Redux/modal';
import SectionMaster from './sectionMaster';
import DepartmentMaster from './departmentMaster';
import {
  selectUserType,
  seledctAlowedpathlist,
} from '../utility/Redux/profile';
const Masters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const list = [
    {
      name: 'Section Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        dispatch(setSection_MasterStatus(true));
      },
    },
    {
      name: 'Department Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        dispatch(setDepartmentMasterStatus(true));
      },
    },
    {
      name: 'Item Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        navigate('/item_master');
      },
    },
    {
      name: 'Party Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        navigate('/party_master');
      },
    },

    {
      name: 'Expense Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        dispatch(setExpenseMasterModalStatus(true));
      },
    },
    {
      name: 'Party Price List',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        navigate('/shopkeeper_price_list');
      },
    },
    {
      name: 'Station Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        dispatch(setStation_MasterStatus(true));
      },
    },
    {
      name: 'Employee Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        navigate('/Employee_Master');
      },
    },
    {
      name: 'Station Item Master',
      path: '',
      userType: 'MANAGER,ADMIN',

      onclick: () => {
        navigate('/station_item_master');
      },
    },
  ];

  const partyList = [
    {
      name: 'Create Party Rate Master',
      path: '',
      userType: 'MANAGER,USER,ADMIN',

      onclick: () => {
        navigate('/party_rate_master');
      },
    },

    {
      name: 'Update Party Item Rate',
      path: '',
      userType: 'MANAGER,ADMIN',

      onclick: () => {
        navigate('/update_Party_Item_Rate');
      },
    },
  ];

  const userType = useSelector(selectUserType);

  return (
    <div className="h-full p-4 text-sm ">
      <div className="grid grid-cols-2 t:grid-cols-3 d:grid-cols-6 ll:grid-cols-6 gap-3  ">
        {list[0] &&
          list.map((e, i) => {
            if (e.userType.includes(userType)) {
              return (
                <Button
                  key={i}
                  onClick={() => {
                    if (e.path) {
                      navigate(e.path);
                    } else {
                      e.onclick();
                    }
                  }}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#e9e9e5] py-5 w-full whitespace-nowrap text-[#302d2d]">
                    {e.name}
                  </div>
                </Button>
              );
            }
          })}
      </div>
      <div className="flex mt-3 font-semibold text-gray-700">
        Update Party Rate Masters
      </div>
      <div className="grid grid-cols-2 t:grid-cols-3 d:grid-cols-6 ll:grid-cols-6 gap-3 mt-2  ">
        {partyList[0] &&
          partyList.map((e, i) => {
            if (e.userType.includes(userType)) {
              return (
                <Button
                  key={i}
                  onClick={() => {
                    if (e.path) {
                      navigate(e.path);
                    } else {
                      e.onclick();
                    }
                  }}
                  variant={'contained'}
                  style={{
                    textTransform: 'none',
                    backgroundColor: 'transparent',
                    padding: '0px',
                    borderRadius: '7px',
                    overflow: 'hidden',
                  }}
                >
                  <div className="bg-[#e9e9e5] py-5 w-full whitespace-nowrap text-[#302d2d]">
                    {e.name}
                  </div>
                </Button>
              );
            }
          })}
      </div>
      <SectionMaster />
      <DepartmentMaster />
    </div>
  );
};

export default Masters;
