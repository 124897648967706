import React from 'react';

const GeniusTabView = ({
  tab1,
  tab1Title,
  tab2Title,
  screenTab,
  setScreenTab,
  tab2,
}) => {
  return (
    <div className="w-full bg-white">
      <div className=" w-full flex  bg-[#e6e6e3]">
        <div className=" w-1/2">
          <div
            onClick={() => {
              setScreenTab(1);
            }}
            style={{
              borderBottom:
                screenTab === 2 ? '1px solid #b5b3b3' : '0px solid #b5b3b3',
              borderTop:
                screenTab === 1 ? '1px solid #b5b3b3' : '0px solid #b5b3b3',
              borderRight:
                screenTab === 2 ? '1px solid #b5b3b3' : '0px solid #b5b3b3',

              borderBottomRightRadius: screenTab === 2 ? '7px' : '0px',
            }}
            className={`w-full p-2   ${
              screenTab === 1
                ? ' bg-[#e6e6e3]  text-black font-bold'
                : 'bg-white text-gray-500'
            } text-center overflow-hidden`}
          >
            {tab1Title}
          </div>
        </div>

        <div
          onClick={() => {
            setScreenTab(2);
          }}
          style={{
            borderTop:
              screenTab === 1 ? '0px solid #b5b3b3' : '1px solid #b5b3b3',

            borderBottom:
              screenTab === 1 ? '1px solid #b5b3b3' : '0px solid #b5b3b3',
            borderLeft:
              screenTab === 1 ? '1px solid #b5b3b3' : '0px solid #b5b3b3',
            borderBottomLeftRadius: screenTab === 1 ? '7px' : '0px',
          }}
          className={`w-1/2 p-2 overflow-hidden  ${
            screenTab === 2
              ? ' text-black font-bold bg-[#e6e6e3] '
              : 'bg-white  text-gray-500'
          } text-center`}
        >
          {tab2Title}
        </div>
      </div>
      {screenTab === 1 && tab1}
      {screenTab === 2 && tab2}
    </div>
  );
};

export default GeniusTabView;
