import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './utility/Redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import HttpsRedirect from 'react-https-redirect';

// import * as Sentry from '@sentry/react';

const theme = createTheme({
  palette: {
    primary: {
      main: '#383535',
      light: '#757ce8',
      dark: '#babcbf',
    },
    secondary: {
      main: '#f5874f',
      light: '#a8a4a2',
      dark: '#babcbf',
    },
    success: {
      main: '#a8cf45',
      dark: '#babcbf',
    },
    orange: {
      light: '#f5874f',
      main: '#f5874f',
      dark: '#b36239',
      contrastText: '#FFFFFF',
    },
    green: {
      light: '#c9f752',
      main: '#a8cf45',
      dark: '#67802a',
      contrastText: '#FFFFFF',
    },
    indigo: {
      light: '#655ced',
      main: '#4f46e5',
      dark: '#18163d',
      contrastText: '#FFFFFF',
    },
    gray: {
      light: '#babcbf',
      main: '#969799',
      dark: '#A9A9A9',
      contrastText: '#FFFFFF',
    },
    gray2: {
      main: '#A9A9A9',
      dark: '#babcbf',
    },
    white: {
      main: '#FFFFFF',
      dark: '#babcbf',
    },
  },
  typography: {
    fontFamily: [
      'Arial',
      'Verdana',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Segoe UI"',
      '"Open Sans"',
    ].join(','),
  },
  components: {
    MuiDataGridCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, sans-serif',
          fontSize: 14,
        },
      },
    },
    MuiDataGridRow: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, sans-serif',
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: 'green', // Replace 'green' with your desired color
            outline: 0,
          },
          '&$focused $notchedOutline': {
            borderColor: 'blue', // Replace 'blue' with your desired color
            borderWidth: 1,
            outline: 0,
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <HttpsRedirect>
      <BrowserRouter basename={'/'}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </HttpsRedirect>
  </Provider>
);

reportWebVitals();
