import { useDispatch, useSelector } from 'react-redux';
import { setSpinnerLoading } from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import { useEffect } from 'react';

import MasterHelp from '../utility/component/modals/masterHelp';
import { ModuleMasterColumns } from '../settings/columns';
import { ModuleMasetrHelp } from '../settings/function';
import { selectWidth } from '../utility/Redux/profile';

const ModulMasterHelp = ({ onRowClick, status, setStatus }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);

  const width = useSelector(selectWidth);
  const columns = ModuleMasterColumns(width);

  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (!regex.test(searchQuery.substring(0, 1))) {
          return item.MODULE_NAME.toString().includes(searchQuery);
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const dispatch = useDispatch();

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    ModuleMasetrHelp(
      token,
      (e) => {
        if (!e.data.error) {
          const data = e.data.response;
          setRows(data);
          setFullList(data);
        }
      },
      dispatch,
      () => {
        console.log('f');
        dispatch(setSpinnerLoading(false));
      },
      (e) => console.log(e)
    );
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  return (
    <>
      <div className="bg-white">
        <MasterHelp
          status={status}
          style={style}
          onClose={() => {
            setStatus();
            setRows([]);
          }}
          title={'Module Master | Help'}
          columns={columns}
          list={rows}
          rowId={(p) => p.ID}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onRowClick={(p) => {
            onRowClick(p);
            setRows([]);
          }}
        />
      </div>
    </>
  );
};

export default ModulMasterHelp;
