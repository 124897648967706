import axios from 'axios';
import React from 'react';
import {
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import GetItemList from '../orderBooking/getItemList';
import { CallAPIPromise } from '../../menu/dashboard/biiling/modal/comman';

export const GetsectionList = async (
  setlist,
  prefix,
  token,

  dispatch,
  sname,
  setItemList,
  setAllData
) => {
  dispatch(setSpinnerLoading('Loading section list'));
  CallAPIPromise(
    '/api/issueMasterRoute/listSectionMaster',
    {
      table_prefix: prefix,
      SECTION_NAME: '',
    },
    token,
    dispatch
  )
    .then((p) => {
      setlist(p.data.response);
      GetItemList(prefix, sname, token, setItemList, setAllData, dispatch);
    })
    .catch((error) => {
      console.log(error);

      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    });
};

export default GetsectionList;
