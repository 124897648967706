import React, { useState } from 'react';

import moment from 'moment';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setStationID,
  setStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../../utility/Redux/profile';
import { selectToken } from '../../utility/Redux/security';
import { FormatNumber2 } from '../../utility/component/tools';
import GeniusReportsDate from '../../utility/component/reportDate';
import { setListStationMasterModalStatus } from '../../utility/Redux/modal';
import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { MfgBalReportColumns } from './columns';
import { GetMfgBalReport } from './function';
import { OkButton } from '../../utility/component/buttons';
const MfgReport = () => {
  // Date Properties
  const fromDate = useSelector(selectreportFromDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const toDate = useSelector(selectreportToDate);
  const [toDateFocus, setToDateFocus] = useState(false);
  const userType = useSelector(selectUserType);
  // Datagrid Properties
  const [list, setList] = useState([]);

  // redux Propertioes
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);

  const columns = MfgBalReportColumns(windowWidth);

  const totalMfg = list.reduce(
    (total, row) => Number(total) + Number(row.MFG_QNTY ? row.MFG_QNTY : 0),
    0
  );
  const totalDamage = list.reduce(
    (total, row) => Number(total) + Number(row.DMG_QNTY ? row.DMG_QNTY : 0),
    0
  );
  const totalIQntySum = list.reduce(
    (total, row) => Number(total) + Number(row.IQNTY_SUM ? row.IQNTY_SUM : 0),
    0
  );

  const totalRqntySum = list.reduce(
    (total, row) => Number(total) + Number(row.RQNTY_SUM ? row.RQNTY_SUM : 0),
    0
  );
  const totalBalQnty = list.reduce(
    (total, row) => Number(total) + Number(row.BAL_QNTY ? row.BAL_QNTY : 0),
    0
  );

  const okButtonClick = () => {
    GetMfgBalReport(prefix, dispatch, token, setList, fromDate, toDate);
  };

  return (
    <div className="h-full w-full p-2 text-sm">
      <div
        style={{
          border: '1px solid #b5b3b3',
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            columns[5].width +
            columns[6].width,
        }}
        className=" rounded-sm  p-2 flex items-center "
      >
        <div className="px-1 font-semibold">From </div>
        <GeniusReportsDate
          date={fromDate}
          onChange={(d) => {
            dispatch(setreportsFromDate(moment(d).format('YYYY-MM-DD')));
            setFromDateFocus(false);
          }}
          dateFocus={fromDateFocus}
          SetDateFocus={setFromDateFocus}
        />
        <div className="px-1 pl-2 font-semibold">To</div>
        <GeniusReportsDate
          date={toDate}
          onChange={(d) => {
            dispatch(setreportsTodate(moment(d).format('YYYY-MM-DD')));
            setToDateFocus(false);
          }}
          dateFocus={toDateFocus}
          SetDateFocus={setToDateFocus}
        />
        <button
          onClick={okButtonClick}
          className="flex items-center rounded-sm ml-2"
          style={{
            backgroundColor: '#c1c1c1',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 4,
            paddingBottom: 4,
            color: 'black',
          }}
        >
          <div className="flex items-center text-sm">Ok</div>
        </button>
      </div>

      <div
        style={{
          height: `calc(100% - 120px)`,
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            columns[5].width +
            columns[6].width,
        }}
        className="mt-2 w-full"
      >
        <GeniusDatagrid
          list={list}
          rowID={(r) => r.ICODE}
          columns={columns}
          dataGridHeader={true}
        />
        <div
          style={{
            border: '1px solid #b5b3b3',
          }}
          className=" flex rounded-sm mt-[8px] w-full h-10"
        >
          <div className="w-full flex  items-center  h-full  ">
            {columns.map((i) => {
              return (
                <div
                  style={{
                    minWidth: `${i.width}px`,
                    borderRight:
                      i.field !== 'BAL_QNTY'
                        ? '1px solid #b5b3b3'
                        : '0px solid #b5b3b3',
                    textAlign: i.align,
                  }}
                  className="p-1 h-full flex items-center justify-end"
                >
                  {i.field === 'BAL_QNTY' &&
                    totalBalQnty > 0 &&
                    FormatNumber2(totalBalQnty)}
                  {i.field === 'DMG_QNTY' &&
                    totalDamage > 0 &&
                    FormatNumber2(totalDamage)}
                  {i.field === 'IQNTY_SUM' &&
                    totalIQntySum > 0 &&
                    FormatNumber2(totalIQntySum)}
                  {i.field === 'MFG_QNTY' && totalMfg > 0
                    ? FormatNumber2(totalMfg)
                    : ''}
                  {i.field === 'RQNTY_SUM' &&
                    totalRqntySum > 0 &&
                    FormatNumber2(totalRqntySum)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <ModListStationMaster
        onRowClick={(p) => {
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
    </div>
  );
};

export default MfgReport;
