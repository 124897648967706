import React, { useState } from 'react';

import moment from 'moment';
import GeniusDatagrid from '../../../utility/component/datagrid/geniusDatagrid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from '../../../utility/Redux/profile';
import { selectToken } from '../../../utility/Redux/security';
import GeniusReportsDate from '../../../utility/component/reportDate';
import {
  setAlertWithTitle,
  setListStationMasterModalStatus,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import ModListStationMaster from '../../../utility/component/modals/modListStationMaster';
import { OrderSumColumns } from './columns';
import CallAPI from '../../../utility/functions/getData';

const OrderSummary = () => {
  // Date Properties
  const fromDate = useSelector(selectreportFromDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const toDate = useSelector(selectreportToDate);
  const [toDateFocus, setToDateFocus] = useState(false);
  const userType = useSelector(selectUserType);
  const [list, setList] = useState([]);
  const [stationName, setStationName] = useState('');
  const [stationId, setStationId] = useState('');
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);

  const columns = OrderSumColumns(windowWidth);

  const okButtonClick = () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPI(
      '/api/orderBooking/orderSumamry',
      {
        table_prefix: prefix,
        FDATE: moment(fromDate).format('YYYY-MM-DD'),
        TDATE: moment(toDate).format('YYYY-MM-DD'),
        SID: stationId,
      },
      token,
      (e) => {
        if (!e.data.error) {
          setList(e.data.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Something went wrong',
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };
  return (
    <div className="h-full w-full p-2 text-sm">
      <div
        style={{
          border: '1px solid #b5b3b3',
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            columns[5].width +
            columns[6].width,
        }}
        className=" rounded-sm  p-2 flex items-center "
      >
        <div className="px-1 font-semibold">From </div>
        <GeniusReportsDate
          date={fromDate}
          onChange={(d) => {
            dispatch(setreportsFromDate(moment(d).format('YYYY-MM-DD')));
            setFromDateFocus(false);
          }}
          dateFocus={fromDateFocus}
          SetDateFocus={setFromDateFocus}
        />
        <div className="px-1 pl-2 font-semibold">To</div>
        <GeniusReportsDate
          date={toDate}
          onChange={(d) => {
            dispatch(setreportsTodate(moment(d).format('YYYY-MM-DD')));
            setToDateFocus(false);
          }}
          dateFocus={toDateFocus}
          SetDateFocus={setToDateFocus}
        />
        <div className="ml-2">
          {userType === 'USER' ? null : (
            <div className="flex  text-[#4B4545] p-2  ">
              <div
                onClick={() => {
                  if (userType === 'MANAGER') {
                    dispatch(setListStationMasterModalStatus(true));
                  }
                }}
                style={{ fontSize: 13 }}
                className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
              >
                <div className="flex items-center ">
                  <div>
                    <i
                      className="bi bi-search"
                      style={{ color: '#f44336', fontSize: 15 }}
                    ></i>
                  </div>

                  <span
                    style={{ fontSize: 12, fontWeight: 600 }}
                    className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                  >
                    Station ID
                  </span>
                </div>

                <div
                  style={{
                    border: '1px dotted #a6a2a2 ',
                    borderRadius: '3px',
                  }}
                  className="flex text-[#a6a2a2]  pl-2 ml-2  "
                >
                  <input
                    size={'small'}
                    placeholder="All"
                    value={stationId ? stationId : 'All'}
                    style={{
                      // width: '20%',
                      fontSize: 12,
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      backgroundColor: 'transparent',
                    }}
                    readOnly
                    className=" outline-0 w-6"
                  />

                  <div
                    style={{
                      borderLeft: '1px dotted #a6a2a2 ',
                    }}
                    className="px-2 p-1 w-48 whitespace-nowrap print:hidden"
                  >
                    {stationName ? `${stationName}` : null}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <button
          onClick={okButtonClick}
          className="flex items-center rounded-sm ml-2"
          style={{
            backgroundColor: '#c1c1c1',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 4,
            paddingBottom: 4,
            color: 'black',
          }}
        >
          <div className="flex items-center text-sm">Ok</div>
        </button>
      </div>

      <div
        style={{
          height: `calc(100% - 120px)`,
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            columns[5].width +
            columns[6].width,
        }}
        className="mt-2 w-full"
      >
        <GeniusDatagrid
          list={list}
          rowID={(r) => r.SERIAL_NO}
          columns={columns}
          dataGridHeader={true}
        />
      </div>

      <ModListStationMaster
        onRowClick={(p) => {
          setStationId(p.row.CODE);
          setStationName(p.row.STATION_NAME);
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
    </div>
  );
};

export default OrderSummary;
