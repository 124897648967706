import React from 'react';
import { useSelector } from 'react-redux';
import { selectCompanyAddress, selectUserName } from '../Redux/profile';
import NumberToWords3 from './amountinWords3';
import { FormatNumber2 } from './tools';
import { selectVoucherNumber } from '../Redux/modal';
import moment from 'moment';

const CollPrintView = React.forwardRef((props, ref) => {
  const data = props.data;
  const paymode = props.paymode;
  const companyDetails = useSelector(selectCompanyAddress);
  const { DATE, NAME, AMOUNT_RECEIVED, AMOUNT_DESC } = data;

  const user = useSelector(selectUserName);
  const voucherNumber = useSelector(selectVoucherNumber);

  const { address, city, companyName, companyWebsite, email, state, mobile } =
    companyDetails;
  return (
    <div
      ref={ref}
      style={{ height: 'auto', fontSize: 12 }}
      className=" bg-white border border-black  "
    >
      <div className="w-full  flex justify-center h-auto pt-4 ">
        <img
          style={{ width: '40%', height: 'auto' }}
          src={require('../../utility/images/smrat.png')}
          className={'w-full h-auto'}
        />
      </div>

      <div
        style={{ fontSize: 14 }}
        className="w-full font-semibold  flex justify-center "
      >
        {companyName}
      </div>

      <div style={{ fontSize: 12 }} className="w-full  flex justify-center  ">
        {address}, {city}
      </div>

      <div style={{ fontSize: 12 }} className="w-full  flex justify-center  ">
        {state} , {mobile}
      </div>

      <div className="w-full  flex justify-center  ">{companyWebsite}</div>

      <div
        style={{
          fontSize: 13,
          fontWeight: 600,
          borderTopWidth: '1px',
          borderBottomWidth: '1px',
          borderBottomColor: 'black',
          borderTopColor: 'black',
        }}
        className="flex justify-center border p-2 mt-2"
      >
        Collection Voucher
      </div>

      <div
        style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}
        className="p-2 flex justify-between border"
      >
        <div>Voucher No. : {voucherNumber}</div>

        <div className="font-semibold">
          Date : {moment(DATE).format('YYYY-MM-DD hh:mm a')}
        </div>
      </div>

      <div
        style={{
          borderBottomWidth: '1px',
          fontSize: 12,
          borderBottomColor: 'black',
        }}
        className="flex flex-col items-center border p-2"
      >
        <div>Mode Of Payment : {paymode} </div>
        <div> by {user}</div>
      </div>

      <div
        style={{ borderBottomWidth: '1px', borderBottomColor: 'black' }}
        className=" border p-2"
      >
        <span className="whitespace-nowrap">Received From</span>{' '}
        <span
          style={{ fontSize: 11 }}
          className="whitespace-nowrap font-semibold"
        >
          {NAME}
        </span>{' '}
        <span>
          on account of {AMOUNT_DESC} {NumberToWords3(AMOUNT_RECEIVED)} only.
        </span>
      </div>

      <div
        style={{
          borderBottomWidth: '1px',
          borderBottomColor: 'black',
          fontWeight: 600,
          fontSize: 14,
        }}
        className="flex  flex-col items-start border p-2"
      >
        RS : {FormatNumber2(AMOUNT_RECEIVED)} /-
      </div>

      <div className="flex flex-col items-end p-2 pt-10">Signature</div>
    </div>
  );
});

export default CollPrintView;
