import React from 'react';

import { useSelector } from 'react-redux';
import { BarLoader } from 'react-spinners';
import { selectStatus } from '../../Redux/modal';
import PartyLedger from '../../../routeBilling/menu/partyledger';

export default function ModPartyLedger() {
  const loading = useSelector(selectStatus);

  return (
    <div className="bg-white h-full">
      <PartyLedger />
    </div>
  );
}
