import { fromByteArray } from 'base64-js';

export async function isTokenExpired(token) {
  const decodedToken = await decodeToken(token);

  if (!decodedToken || !decodedToken.exp) {
    // Token is invalid or doesn't have an expiration time
    return true;
  }

  // Get the current time in seconds
  const currentTime = Math.floor(Date.now() / 1000);

  // Check if the token expiration time is in the past
  return decodedToken.exp < currentTime;
}

async function decodeToken(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const tokenArray = new Uint8Array(fromByteArray(base64));
    const text = new TextDecoder().decode(tokenArray);
    return JSON.parse(text);
  } catch (error) {
    // Handle decoding errors
    console.error('Error decoding token:', error);
    return null;
  }
}

// Example usage
// const jwtToken = 'your_jwt_token_here';
