import { createSlice } from '@reduxjs/toolkit';

// const orderdItemList = () => {
//   const list = localStorage.getItem("orderdItemList");
//   if (list) {
//     return JSON.parse(list);
//   } else {
//     return [];
//   }
// };
// const counting = () => {
//   const count = localStorage.getItem("count");
//   if (count) {
//     return count;
//   } else {
//     return 1;
//   }
// };

const billingIntialState = {
  tableList: [],
  tablesType: 'Restaurant',
  selectedSection: '',
  // count: counting(),
  // billingItemsList: orderdItemList(),
  billingItemsList: [],
  discount: '',
  print: {
    status: false,
  },
  stationWiseReportDetails: {
    stationId: '',
    date: '',
  },
  ViewBill: {
    invoiceId: '',
  },
  netAmount: '',
  QntyEdit: {
    status: false,
    ICODE: '',
  },
  editQnty: false,
  allItemsList: [],
  currentBillInvoiceNumber: '',
  billSettlement: {
    cash: '',
    card: '',
    cardNumber: '',
    coupanAmount: '',
    coupanNumber: '',
    epayment: '',
    epaymentAmount: '',
    btc: '',
    btcName: '',
    disc: '',
    btcMobile: '',
    btcAccNumber: '0',
    btcAddress: '',
    TOTAL_COLLECTION: 0,
  },
  billSettlementBalance: 0,
  cartItems: [],
};

export const TableOrder = createSlice({
  name: 'tableOrder',
  initialState: billingIntialState,
  reducers: {
    setTableList(state, action) {
      state.tableList = action.payload;
    },

    setQntyEditModalStatus(state, action) {
      state.QntyEdit.status = action.payload;
    },
    setQntyEditICODE(state, action) {
      state.QntyEdit.ICODE = action.payload;
    },

    setStationWiseIssueReportApiDetails(state, action) {
      state.stationWiseReportDetails = action.payload;
    },
    setViewBillInvoiceId(state, action) {
      state.ViewBill.invoiceId = action.payload;
    },
    setCartItems(state, action) {
      state.cartItems = action.payload;
    },
    setPrintStatus(state, action) {
      state.print.status = action.payload;
    },
    setCurrentInvoiceNumber(state, action) {
      state.currentBillInvoiceNumber = action.payload;
    },
    setBillingDiscount(state, action) {
      state.discount = action.payload;
    },
    setBillSettlementBalnce(state, action) {
      state.billSettlementBalance = action.payload;
    },
    setBillSettlementDetails(state, action) {
      state.billSettlement = action.payload;
    },
    setAllItemsList(state, action) {
      state.allItemsList = action.payload;
    },
    setBillingNetAmount(state, action) {
      state.netAmount = action.payload;
    },
    setEditQntyStatus(state, action) {
      state.editQnty = action.payload;
    },
    setOrderList(state, action) {
      const itemExists = state.billingItemsList.some(
        (item) => item.ICODE === action.payload?.ICODE
      );

      if (!itemExists) {
        state.billingItemsList = [
          ...state.billingItemsList,
          {
            ...action.payload,
            id: action.payload.ICODE,
          },
        ];
      } else {
        state.billingItemsList = [];
      }

      // state.count = Number(state.count) + 1;
      // const listInString = JSON.stringify([...state.billingItemsList]);

      // localStorage.setItem("orderdItemList", listInString);
      // localStorage.setItem("count", state.count);
    },
    deletOrderItem(state, action) {
      state.billingItemsList = action.payload;
      // localStorage.setItem("orderdItemList", JSON.stringify(action.payload));
    },
    clearOrderBillingList(state, action) {
      state.billingItemsList = [];
      // state.count = 1;
      // localStorage.removeItem("orderdItemList");
      // localStorage.removeItem("count");
    },
    updateOrderlist(state, action) {
      const duplicateIds = action.payload.filter(
        (obj, index, self) =>
          index !== self.findIndex((o) => o.ICODE === obj.ICODE)
      );
      if (duplicateIds.length > 0) {
        state.billingItemsList = [];
      } else {
        state.billingItemsList = action.payload;
      }

      // localStorage.setItem("orderdItemList", JSON.stringify(action.payload));
    },
    setSelectedSection(state, action) {
      state.selectedSection = action.payload;
    },
    setTablesType(state, action) {
      state.tablesType = action.payload;
    },
    resetTableOrder(state, action) {
      state = billingIntialState;
    },
  },
});

export default TableOrder.reducer;
export const {
  setTableList,
  setCartItems,
  resetTableOrder,
  setQntyEditICODE,
  setQntyEditModalStatus,
  setViewBillInvoiceId,
  setStationWiseIssueReportApiDetails,
  setPrintStatus,
  clearOrderBillingList,
  setAllItemsList,
  setBillingDiscount,
  updateOrderlist,
  setBillingNetAmount,
  setEditQntyStatus,
  deletOrderItem,
  setCurrentInvoiceNumber,
  setTablesType,
  setBillSettlementBalnce,
  setSelectedSection,
  setOrderList,
  setBillSettlementDetails,
} = TableOrder.actions;

export const SelectTableList = (state) => state.tableOrder.tableList;
export const SelectTablesType = (state) => state.tableOrder.tablesType;
export const SelectedSection = (state) => state.tableOrder.selectedSection;
export const SelectOrdersList = (state) => state.tableOrder.billingItemsList;
export const SelectEditQntyStatus = (state) => state.tableOrder.editQnty;
export const SelectAllItemsList = (state) => state.tableOrder.allItemsList;

export const SelectBillSettlementDetails = (state) =>
  state.tableOrder.billSettlement;

export const SelectBillSettlementBalance = (state) =>
  state.tableOrder.billSettlementBalance;
export const SelectBillingDiscount = (state) => state.tableOrder.discount;

export const selectBillingNetAmount = (state) => state.tableOrder.netAmount;
export const selectPrintStatus = (state) => state.tableOrder.print.status;
export const selectCurrentInvoiceBillNumber = (state) =>
  state.tableOrder.currentBillInvoiceNumber;
export const selectCartItems = (state) => state.tableOrder.cartItems;
export const selectViewBillInvoiceId = (state) =>
  state.tableOrder.ViewBill.invoiceId;
export const selectStationWiseIssueApiDetails = (state) =>
  state.tableOrder.stationWiseReportDetails;

// qnty edit

export const slectQntyEditModalStatus = (state) =>
  state.tableOrder.QntyEdit.status;

export const selectQntyEditIcode = (state) => state.tableOrder.QntyEdit.ICODE;
