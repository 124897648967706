import React from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';

import {
  slectQntyEditModalStatus,
  setBillSettlementDetails,
  setQntyEditModalStatus,
} from '../../../../../utility/Redux/tableOrder';
import QntyEdit from './qntyEdit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function ModalQntyEdit() {
  const dispatch = useDispatch();
  const modalStatus = useSelector(slectQntyEditModalStatus);

  const handleClose = () => {
    dispatch(setQntyEditModalStatus(false));
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: ' ',
        TOTAL_COLLECTION: 0,
      })
    );
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={modalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth <= 760 ? styleMobile : style}
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className="flex-grow h-full bg-white    border-brown">
              <QntyEdit />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
