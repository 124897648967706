import React from 'react';
import text from '../../images/text.svg';
import lcon from '../../images/icon.svg';
const RoutbillingHomeLogo = () => {
  return (
    <div className=" flex  justify-center items-center">
      <img
        src={lcon}
        style={{
          width: '30px',
          height: 'auto',
        }}
      />
      <img
        src={text}
        style={{
          width: '140px',
          height: 'auto',
        }}
        className="ml-[8px]"
      />
    </div>
  );
};

export default RoutbillingHomeLogo;
