import React from 'react';
import Modal from '@mui/material/Modal';

import succcess from '../../../utility/json/send1.json';
import Lottie from 'lottie-react';

import { Close } from '@mui/icons-material';
import { OK_button } from '../buttons';

export default function ModSendMsgStatus({ status, setStatus }) {
  const handleClose = () => {
    setStatus(false);
  };

  return (
    <div className="bg-white">
      <Modal
        open={status}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className=" bg-brown overflow-hidden rounded-lg w-auto justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-between pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl ">
            <div> genius office </div>
            <div className="mr-2">
              {' '}
              <Close
                onClick={handleClose}
                style={{
                  color: 'F5874F',
                }}
              />{' '}
            </div>
          </div>

          <div className="bg-white px-12">
            <div className="w-full items-center flex justify-center  ">
              <div className="w-full flex flex-col items-center pb-10  ">
                <div className="w-[60px]">
                  <Lottie
                    animationData={succcess}
                    loop={false}
                    style={{ fontSize: 50 }}
                    color="white"
                  />
                </div>
                <div className="w-full  text-center flex justify center">
                  <div
                    style={{
                      fontSize: '23px',
                      fontWeight: 'bold',
                      color: '#45db39',
                    }}
                    className="w-full "
                  >
                    Message Sent !
                  </div>
                </div>
                <div
                  style={{
                    fontSize: '13px',
                    color: 'gray',
                  }}
                  className="mt-1 flex flex-col justify-center "
                >
                  <div>Your message has been</div>
                  <div className="text-center">sent successfully.</div>
                </div>
                <div className="mt-5">
                  <OK_button onClick={handleClose} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
