import moment from 'moment';
import { FormatNumber2 } from '../utility/component/tools';

const StationReportColumns = (width) => {
  const Columns = [
    {
      width: width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 70 : 80,
      headerAlign: 'center',
      align: 'left',
      field: 'SID',
      accessor: 'SID',
      renderHeader: (params) => <div>Station</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 140 : 250,
      headerAlign: 'center',
      align: 'left',
      field: 'SNAME',
      accessor: 'SNAME',
      renderHeader: (params) => <div>Station Name</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 130,
      headerAlign: 'center',
      align: 'right',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      field: 'CASH',
      accessor: 'CASH',
      renderHeader: (params) => <div>Cash</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 130,
      headerAlign: 'center',
      align: 'right',
      field: 'COLLECTION',
      accessor: 'COLLECTION',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Collection</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 130,
      headerAlign: 'center',
      align: 'right',
      field: 'DEBIT',
      accessor: 'DEBIT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Debit</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'DUE',
      accessor: 'DUE',
      renderCell: (p) => {
        const due = p.row.DEBIT - p.row.COLLECTION;
        if (due) {
          return <div>{FormatNumber2(due)}</div>;
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Due</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 110,
      headerAlign: 'center',
      align: 'right',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      field: 'EXPENSE',
      accessor: 'EXPENSE',
      renderHeader: (params) => <div>Expense</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'LESS',
      accessor: 'LESS',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Less</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'IQNTY',
      accessor: 'IQNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Issue Qnty.</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'IAMOUNT',
      accessor: 'IAMOUNT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Issue Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'RQNTY',
      accessor: 'RQNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Return Qnty.</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'RAMOUNT',
      accessor: 'RAMOUNT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Return Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'SQNTY',
      accessor: 'SQNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Sale Qnty.</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'SAMOUNT',
      accessor: 'SAMOUNT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Sale Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'BALANCE',
      accessor: 'BALANCE',
      renderCell: (p) => {
        const d = 0;
        const bal = Number(p.row.CASH) - Number(p.row.EXPENSE) + Number(d);
        if (bal > 0) {
          return <div>{FormatNumber2(bal)}</div>;
        }
        return '';
      },
      renderHeader: (params) => <div>Balance</div>,
    },
  ];

  return Columns;
};
const StationReportColumnsQntyWise = (width) => {
  const Columns = [
    {
      width: width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 70 : 80,
      headerAlign: 'center',
      align: 'left',
      field: 'SID',
      accessor: 'SID',
      renderHeader: (params) => <div>Station</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 140 : 250,
      headerAlign: 'center',
      align: 'left',
      field: 'SNAME',
      accessor: 'SNAME',
      renderHeader: (params) => <div>Station Name</div>,
    },

    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'IQNTY',
      accessor: 'IQNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Issue Qnty.</div>,
    },
    {
      width: width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 70 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'IAMOUNT',
      accessor: 'IAMOUNT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Issue Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'RQNTY',
      accessor: 'RQNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Return Qnty.</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'RAMOUNT',
      accessor: 'RAMOUNT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Return Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'SQNTY',
      accessor: 'SQNTY',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Sale Qnty.</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'SAMOUNT',
      accessor: 'SAMOUNT',
      renderCell: (p) => {
        if (p.value && p.value > 0) {
          return FormatNumber2(p.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => <div>Sale Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'BALANCE',
      accessor: 'BALANCE',
      renderCell: (p) => {
        const d = 0;
        const bal = Number(p.row.CASH) - Number(p.row.EXPENSE) + Number(d);
        if (bal > 0) {
          return <div>{FormatNumber2(bal)}</div>;
        }
        return '';
      },
      renderHeader: (params) => <div>Balance</div>,
    },
  ];

  return Columns;
};
const StationDayEndReports = (width) => {
  const C = [
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'FNO',
      accessor: 'FNO',
      renderHeader: (params) => <div>FNO</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      type: 'date',
      field: 'DATE',
      accessor: 'DATE',
      renderCell: (params) => (
        <div> {moment(params.value).format('DD-MM-YYYY')} </div>
      ),
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'DEBIT',
      accessor: 'DEBIT',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Debit</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'COLLECTION',
      accessor: 'COLLECTION',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Collection</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'NET_DEBIT',
      accessor: 'NET_DEBIT',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Net Debit</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'NET_COLLECTION',
      accessor: 'NET_COLLECTION',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Net Collection</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'LESS',
      accessor: 'LESS',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Less</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'EXPENSE',
      accessor: 'EXPENSE',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Expense</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'DUE',
      accessor: 'DUE',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Due</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 120,
      headerAlign: 'center',
      align: 'right',
      field: 'CASH',
      accessor: 'CASH',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Cash</div>,
    },

    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'BALANCE',
      accessor: 'BALANCE',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Balance</div>,
    },
  ];

  return C;
};

const DeffrenceReportColumns = (width) => {
  const C = [
    {
      width:
        width <= 768 ? 100 : width <= 1024 ? 150 : width <= 1440 ? 150 : 150,
      headerAlign: 'center',
      align: 'left',
      type: 'date',
      field: 'DATE',
      accessor: 'DATE',
      renderCell: (params) => (
        <div> {moment(params.value).format('DD-MM-YYYY')} </div>
      ),
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width:
        width <= 768 ? 50 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'SID',
      accessor: 'SID',
      renderHeader: (params) => <div>Station ID</div>,
    },
    {
      width:
        width <= 768 ? 180 : width <= 1024 ? 180 : width <= 1440 ? 180 : 180,
      headerAlign: 'center',
      align: 'right',
      field: 'BTC_AMOUNT',
      accessor: 'BTC_AMOUNT',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>BTC Amount</div>,
    },
    {
      width:
        width <= 768 ? 70 : width <= 1024 ? 100 : width <= 1440 ? 100 : 110,
      headerAlign: 'center',
      align: 'right',
      field: 'DEBIT',
      accessor: 'DEBIT',
      renderCell: (p) => {
        return p.value ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Debit</div>,
    },
    {
      width:
        width <= 768 ? 150 : width <= 1024 ? 200 : width <= 1440 ? 200 : 200,
      headerAlign: 'center',
      align: 'right',
      field: 'CASH',
      accessor: 'CASH',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Issue Stock Cash Amount</div>,
    },
    {
      width:
        width <= 768 ? 180 : width <= 1024 ? 180 : width <= 1440 ? 180 : 180,
      headerAlign: 'center',
      align: 'right',
      field: 'BILL_CASH_AMOUNT',
      accessor: 'BILL_CASH_AMOUNT',
      renderCell: (p) => {
        return p.value !== 0 ? FormatNumber2(p.value) : '';
      },
      renderHeader: (params) => <div>Bill Cash Amount</div>,
    },
  ];

  return C;
};

export {
  StationReportColumns,
  StationDayEndReports,
  DeffrenceReportColumns,
  StationReportColumnsQntyWise,
};
