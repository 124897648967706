import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import GeniustextInput from '../../../utility/component/mastertext';
import {
  setAlertWithTitle,
  setIssueStockItem,
  setListStationMasterModalStatus,
  setModEmployeeMasterHelpStatus,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../../utility/Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import { SaveButton, UndoButton } from '../../../utility/component/buttons';
import GeniusDatagrid from '../../../utility/component/datagrid/geniusDatagrid';
import { selectPrefix, selectWidth } from '../../../utility/Redux/profile';
import { selectToken } from '../../../utility/Redux/security';
import ItemBox from '../itemBox';
import ModListStationMaster from '../../../utility/component/modals/modListStationMaster';
import ModEmployeeMasterHelp from '../../../utility/component/modals/modEmployeeMasterHelp';
import ModNumericKeyboard from '../../menu/dashboard/biiling/modal/modalNumericKeyBoard';
import { Close } from '@mui/icons-material';
import { LoadOrderDetials } from './loadOrder';
import OrderColumns from './columns';
import GetsectionList from './gettsectionList';
import GetItemList from '../orderBooking/getItemList';
import { SaveOrderBooking } from './saveBooking';
import { FormatNumber2 } from '../../../utility/component/tools';
import AlertModalTwoButton from '../../../utility/component/modals/alertWithTwoButton';
import PlaceHolderTextInput from '../../../utility/component/placeTextInput';
import { useFocus } from '../../../utility/hooks/hooks';
import MobileOrderColumns from './mobileColumns';
import SectionBillhelp from './comp';

const OrderBooking = () => {
  const dispatch = useDispatch();
  const [SID, setSid] = useState('');
  const [stationName, setStationName] = useState('');
  const [employee, setEmployee] = useState('');
  const [itemName, setItemName] = useState('');
  const [orderQnty, setOrderQnty] = useState('');
  const [remarks, setRemarks] = useState('');
  const windowWidth = useSelector(selectWidth);
  const [searchText, setSearchText] = useState('');
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const [sname, setSname] = useState('');
  const [sectionList, setSectionList] = useState([]);
  const [allData, setAllData] = useState([]);
  const [icode, setICode] = useState('');
  const [items, setItems] = useState([]);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [dateFocus, setDateFocus] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [isSectionDrawerOpen, setIsSectionDraweropen] = useState(false);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const columns = OrderColumns(
    windowWidth,
    prefix,
    SID,
    token,
    icode,
    dispatch,
    setOrderDetails,
    date,
    setAlertTwoButtonModal,
    alertTwoButtonModal
  );
  const mobileColumns = MobileOrderColumns(
    windowWidth,
    prefix,
    SID,
    token,
    icode,
    dispatch,
    setOrderDetails,
    date,
    setAlertTwoButtonModal,
    alertTwoButtonModal
  );
  const onChange = ({ id, field, value }) => {
    if (field === 'ORDER_QNTY') {
      setOrderQnty(value);
    }
  };

  const save = () => {
    if (SID) {
      if (employee) {
        if (icode) {
          if (orderQnty && orderQnty > 0) {
            const data = {
              table_prefix: prefix,
              SERIAL_NO: '',
              SID: SID,
              SNAME: stationName,
              DATE: moment(date).format('YYYY-MM-DD'),
              SECTION_NAME: sname,
              ICODE: icode,
              ITEMNAME: itemName,
              ORDER_QNTY: orderQnty,
              REMARKS: remarks,
              EMPLOYEE_NAME: employee,
            };
            SaveOrderBooking(
              data,
              token,
              dispatch,
              undo,
              setOrderDetails,
              prefix,
              date,
              SID
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Please enter order Quantity',
              })
            );
          }
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'ALert',
              msg: 'Please select Item',
            })
          );
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please select Employee Name',
          })
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please select any station first.',
        })
      );
    }
  };

  const undo = () => {
    setItemName('');
    setICode('');

    setDate(moment().format('YYYY-MM-DD'));
    setOrderQnty(0);
    setRemarks('');
  };

  useEffect(() => {
    GetsectionList(
      setSectionList,
      prefix,
      token,

      dispatch,
      sname,
      setItems,
      setAllData
    );
  }, []);
  useEffect(() => {
    if (SID) {
      LoadOrderDetials(setOrderDetails, prefix, token, date, SID, dispatch);
    }
  }, [SID, date]);
  const [searchRef, setSearchFocus] = useFocus(false);
  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const d = allData.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.ICODE.toString().includes(searchText);
        } else {
          return item.ITEMNAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setItems(d);
    } else {
      setItems(allData);
    }
  }, [searchText]);
  const firstWidth = useRef(null);
  return (
    <div className="flex p-1 h-full w-full text-md bg-[#ebf0f7]">
      <div
        style={{
          display: windowWidth > 1280 ? 'flex' : 'none',
        }}
        className="flex h-full w-full"
      >
        <div ref={firstWidth} className="h-full  flex flex-col   rounded-sm">
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="p-2 rounded-sm bg-[#f5f5f2]"
          >
            <div className="flex">
              <div style={{}}>
                <GeniustextInput
                  title={'Date'}
                  date={true}
                  width={
                    windowWidth <= 768
                      ? '210px'
                      : windowWidth <= 1024
                      ? '250px'
                      : '305px'
                  }
                  backgroundColor={'#f1efeb'}
                  fromDate={date}
                  fromDateFocus={dateFocus}
                  setFromDate={setDate}
                  setFromDateFocus={setDateFocus}
                />
              </div>

              <div className="flex ">
                <div className="ml-2">
                  <GeniustextInput
                    type="text"
                    title={'SID'}
                    search={() => {
                      dispatch(setListStationMasterModalStatus(true));
                    }}
                    backgroundColor={'#f1efeb'}
                    value={stationName}
                    secondText={{
                      value: SID,
                      backgroundColor: '#f7dda4',
                    }}
                    width={
                      windowWidth <= 768
                        ? '180px'
                        : windowWidth <= 1024
                        ? '250px'
                        : '240px'
                    }
                  />
                </div>
              </div>
              <div className="ml-2">
                <GeniustextInput
                  type="text"
                  title={'Employee Name'}
                  value={employee}
                  backgroundColor={'#f1efeb'}
                  width={
                    windowWidth <= 768
                      ? '160px'
                      : windowWidth <= 1024
                      ? '200px'
                      : '260px'
                  }
                  search={() => {
                    dispatch(setModEmployeeMasterHelpStatus(true));
                  }}
                />
              </div>
            </div>
            <div className="flex">
              <GeniustextInput
                type="text"
                titleBold={true}
                fontBold={600}
                backgroundColor={'#f1efeb'}
                title={'Item Name'}
                value={itemName}
                width={
                  windowWidth <= 768
                    ? '210px'
                    : windowWidth <= 1024
                    ? '250px'
                    : '305px'
                }
              />
              <div className="ml-2">
                <GeniustextInput
                  type="number"
                  title={'Order Quantity'}
                  titleBold={true}
                  backgroundColor={'#f1efeb'}
                  value={orderQnty}
                  onClick={() => {
                    dispatch(setnumericKeyboardStatus(true));
                    dispatch(setnumericKeyboardType('ORDER_QNTY'));
                    dispatch(
                      setIssueStockItem({
                        title: 'Order Qnty.',
                        value: orderQnty ? orderQnty : 0,
                        id: 1,
                      })
                    );
                  }}
                  onChange={(e) => setOrderQnty(e.target.value)}
                  width={
                    windowWidth <= 768
                      ? '180px'
                      : windowWidth <= 1024
                      ? '250px'
                      : '238px'
                  }
                />
              </div>
              <div className="ml-2">
                <GeniustextInput
                  type="text"
                  title={'Remarks'}
                  value={remarks}
                  backgroundColor={'#f1efeb'}
                  onChange={(e) => setRemarks(e.target.value)}
                  width={
                    windowWidth <= 768
                      ? '160px'
                      : windowWidth <= 1024
                      ? '200px'
                      : '261px'
                  }
                />
              </div>
            </div>

            <div className="flex justify-end">
              <SaveButton onClick={save} />
              <div>
                <UndoButton onClick={undo} />
              </div>
            </div>
          </div>
          <div className="  flex flex-col grow">
            <div
              style={{
                height: `calc(100% - ${36}px`,
              }}
              className=" mt-3"
            >
              <GeniusDatagrid
                list={orderDetails}
                rowID={(e) => e.SERIAL_NO}
                columns={columns}
              />
            </div>
            <div
              style={{
                border: '1px solid #b5b3b3',
                borderRight: '0px solid #b5b3b3',
              }}
              className="rounded-sm flex mt-3 text-sm"
            >
              {columns.map((d, i) => {
                return (
                  <div
                    style={{
                      width: d.width,
                      borderRight: '1px solid #b5b3b3',
                      borderRadius: d.field === 'EMPLOYEE_NAME' ? '7px' : '0px',
                    }}
                  >
                    {d.field === 'ITEMNAME' && (
                      <div className="w-full justify-end flex pr-3 p-2">
                        <div className="font-semibold pr-2 text-[#383535]">
                          {' '}
                          Total Item :
                        </div>{' '}
                        {orderDetails.length}
                      </div>
                    )}
                    {d.field === 'ORDER_QNTY' && (
                      <div className="w-full justify-end flex pr-3 p-2">
                        {FormatNumber2(
                          orderDetails.reduce(
                            (total, row) =>
                              Number(total) + Number(row.ORDER_QNTY),
                            0
                          )
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            width: firstWidth.current
              ? `calc(100% - ${firstWidth.current.clientWidth + 16}px)`
              : '60%',
          }}
          className="h-full ml-4 rounded-sm "
        >
          <div className="w-full h-full">
            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className=" w-full h-fit p-1 rounded-sm"
            >
              <input
                type="text"
                className="w-full bg-[#ebf0f7]"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{
                  outline: 'none',
                }}
                placeholder="Search"
              />
            </div>

            <div
              style={{
                border: '1px solid #b5b3b3',
              }}
              className="flex overflow-x-scroll w-full mt-2 rounded-sm p-2"
            >
              {sectionList.map((e, key) => {
                return (
                  <div
                    key={key}
                    className="flex"
                    style={{
                      height: '45px',
                      borderRadius: '7px',
                      marginRight: '8px',
                      textTransform: 'none',
                      backgroundColor: '#f5eb82',
                      fontSize: '15px',
                      color: '#4a4a4a',
                      width: 'auto',
                    }}
                  >
                    <button
                      onClick={() => {
                        setSname(e.SECTION_NAME);
                        GetItemList(
                          prefix,
                          sname,
                          token,
                          setItems,
                          setAllData,
                          dispatch
                        );
                      }}
                    >
                      <div className="whitespace-nowrap px-3">
                        {' '}
                        <div className="lowercase"> {e.SECTION_NAME} </div>
                      </div>
                    </button>
                    {e.SECTION_NAME === sname && (
                      <button
                        style={{
                          paddingRight: '5px',
                        }}
                        onClick={() => {
                          setSname('');
                          GetItemList(
                            prefix,
                            '',
                            token,
                            setItems,
                            setAllData,
                            dispatch
                          );
                        }}
                      >
                        <Close />
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
            <div
              style={{
                border: '1px solid   #b5b3b3',
                height: `calc(100% - 134px)`,
              }}
              className="mt-3  p-2 overflow-y-scroll rounded-sm "
            >
              <div className="grid gap-2 grid-cols-1 ll:grid-cols-2 2xl:grid-cols-4 rounded-sm  ">
                {items.map((i, index) => (
                  <ItemBox
                    item={i}
                    itemNameStyle={{
                      width: '100%',
                      height: '70px',
                      textTransform: 'none',
                      backgroundColor: '#fadb3f',
                      borderRadius: '7px',
                      color: '#4a4a4a',
                      padding: '0px',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setItemName(i.ITEMNAME);
                      setICode(i.ICODE);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: windowWidth > 1280 ? 'none' : 'flex',
          height: `calc(100% - 60px)`,
        }}
        className="flex  w-full "
      >
        <div className="p-1">
          <div className="flex items-center  border  p-1 shadow-sm  rounded-sm bg-[#f8f8fd]   ">
            <div className="mr-1 flex items-center "> Date: </div>{' '}
            <GeniustextInput
              fromDate={moment(date).format('YYYY-MM-DD')}
              type={'date'}
              onChange={(e) => {
                setDate(e.target.value);
              }}
              value={moment(date).format('YYYY-MM-DD')}
              hideHeader={true}
              hideError={true}
            />
          </div>
          <div
            style={{
              height: `calc(100% - 250px)`,
            }}
            className="mt-2"
          >
            <GeniusDatagrid
              list={orderDetails}
              rowID={(e) => e.SERIAL_NO}
              columns={mobileColumns}
            />
          </div>
          <div
            style={{
              width: '220px',
            }}
          >
            <div className="flex w-full  py-1">
              <div className="w-1/2">
                <PlaceHolderTextInput
                  type={'text'}
                  id={'SNAME'}
                  placeholder={'Station'}
                  value={stationName}
                  rightIcon={
                    <button
                      onClick={() => {
                        dispatch(setListStationMasterModalStatus(true));
                      }}
                    >
                      <i className="bi bi-search text-orange"></i>
                    </button>
                  }
                />
              </div>

              <div className="ml-1 w-1/2">
                <PlaceHolderTextInput
                  type={'text'}
                  id={'Employee'}
                  placeholder={'Employee'}
                  value={employee}
                  rightIcon={
                    <button
                      onClick={() => {
                        dispatch(setModEmployeeMasterHelpStatus(true));
                      }}
                    >
                      <i className="bi bi-search text-orange"></i>
                    </button>
                  }
                />
              </div>
            </div>
            <div className="flex w-full">
              <div className="w-1/2">
                <PlaceHolderTextInput
                  type={'text'}
                  id={'ITTEMNAME'}
                  placeholder={'Item Name'}
                  value={itemName}
                />
              </div>

              <div className="ml-1 w-1/2">
                <PlaceHolderTextInput
                  type={'text'}
                  id={'QNTY'}
                  readOnly={true}
                  value={orderQnty}
                  placeholder={'Order Qnty.'}
                  onClick={() => {
                    dispatch(setnumericKeyboardStatus(true));
                    dispatch(setnumericKeyboardType('ORDER_QNTY'));
                    dispatch(
                      setIssueStockItem({
                        title: 'Order Qnty.',
                        value: orderQnty ? orderQnty : 0,
                        id: 1,
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div className="mt-1">
              <PlaceHolderTextInput
                type={'text'}
                id={'REMARKS'}
                placeholder={'Remarks'}
                value={remarks}
                onchange={(e) => setRemarks(e.target.value)}
              />
            </div>
          </div>
          <div className="flex w-full justify-end mt-2">
            <SaveButton onClick={save} />
            <UndoButton onClick={undo} />
          </div>
        </div>

        <div
          style={{
            width: `calc(100% - 220px)`,
          }}
          className="flex    h-full  ml-1  "
        >
          <div className="w-full h-full ">
            <div
              style={{
                height: `calc(100% - 0px)`,
              }}
              className="flex w-full "
            >
              <div
                style={{
                  width: `calc(100% - 0px)`,
                }}
                className="h-full flex flex-col  "
              >
                <div className=" ">
                  {windowWidth <= 1280 && (
                    <SectionBillhelp
                      setIsSectionDraweropen={setIsSectionDraweropen}
                      isSectionDrawerOpen={isSectionDrawerOpen}
                      sectionList={sectionList}
                      sectionName={sname}
                      setSectionName={setSname}
                      SecClick={(e) => {
                        setIsSectionDraweropen(false);
                        setSname(e.SECTION_NAME);
                        GetItemList(
                          prefix,
                          sname,
                          token,
                          setItems,
                          setAllData,
                          dispatch
                        );
                      }}
                      onClick={() => {
                        setIsSectionDraweropen(false);
                        setSname('');
                        GetItemList(
                          prefix,
                          '',
                          token,
                          setItems,
                          setAllData,
                          dispatch
                        );
                      }}
                      // GetListItemMaster={''}
                      // setItemList={setItemList}
                    />
                  )}
                </div>
                <div className=" shadow-sm rounded-sm w-full">
                  <PlaceHolderTextInput
                    icon={
                      <i className="bi mr-2 bi-search text-[#b5b3b3]  flex  items-center"></i>
                    }
                    ref={searchRef}
                    value={searchText}
                    autoFocus={true}
                    type={'text'}
                    onchange={(e) => setSearchText(e.target.value)}
                    placeholder={'Search'}
                  />
                </div>

                <div
                  style={{
                    height: `calc(100% - 0px)`,
                  }}
                  className=" border rounded-sm p-2 shadow-sm flex flex-col mt-2 overflow-y-auto bg-[#f8f8fd]  pr-1"
                >
                  {items[0] && (
                    <div className="grid grid-cols-1   gap-2 t:grid-cols-2 l:grid-cols-3  ll:grid-cols-2    bg-[#f8f8fd]">
                      {items?.map((box, index) => {
                        return (
                          <ItemBox
                            item={box}
                            itemNameStyle={{
                              width: '100%',
                              height: '70px',
                              textTransform: 'none',
                              backgroundColor: '#fadb3f',
                              borderRadius: '7px',
                              color: '#4a4a4a',
                              padding: '0px',
                              fontSize: '12px',
                            }}
                            onClick={() => {
                              setItemName(box.ITEMNAME);
                              setICode(box.ICODE);
                            }}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModListStationMaster
        onRowClick={(p) => {
          setStationName(p.row.STATION_NAME);
          setSid(p.row.CODE);
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
      <ModEmployeeMasterHelp
        onRowClick={(p) => {
          setEmployee(p.row.employee_name);
          dispatch(setModEmployeeMasterHelpStatus(false));
        }}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ModNumericKeyboard onChange={onChange} mode={'dark'} />
    </div>
  );
};

export default OrderBooking;
