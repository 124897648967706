import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { selectToken } from '../../Redux/security';
import CryptoJS from 'crypto-js';

import { selectCompanyAddress, selectWidth } from '../../Redux/profile';
import 'react-simple-keyboard/build/css/index.css';
import {
  selectModCompanyHelpStatus,
  setAlertWithTitle,
  setModCompanyHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../../Redux/modal';
import MasterHelp from './masterHelp';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

const ModCompanyHelp = ({ onRowClick }) => {
  const dispatch = useDispatch();

  const [fullList, setFulllist] = useState([]);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const status = useSelector(selectModCompanyHelpStatus);
  const [rows, setRows] = useState([]);
  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      width: windowWidth <= 940 ? 200 : windowWidth <= 1280 ? 250 : 300,
      headerAlign: 'center',
      align: 'left',
      field: 'company_email',
      renderHeader: (params) => (
        <div className="text-[12px]">Company Email</div>
      ),
    },

    {
      width: windowWidth <= 940 ? 150 : windowWidth <= 1280 ? 200 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'company_name',

      renderHeader: (params) => <div className="text-[12px]">Company Name</div>,
    },
  ];

  const companyDetails = useSelector(selectCompanyAddress);
  const getMessageSetup = async () => {
    dispatch(setSpinnerLoading('Loading'));
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(companyDetails.email, secretPass);

    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    CallAPIPromise(
      '/api/users/companyHelp',
      {
        company_email: companyDetails.email,
      },
      hmacString,
      dispatch
    )
      .then((p) => {
        if (!p.data.error) {
          setRows(p.data.response);
          setFulllist(p.data.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'something went wrong',
            })
          );
        }

        // setloading(false)
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getMessageSetup();
    }
  }, [status]);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.whatsapp_number.toString().includes(searchQuery);
        } else {
          return item.company_email
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });
      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const handleClose = () => {
    dispatch(setModCompanyHelpStatus(false));
    setSearchQuery('');
  };

  return (
    <div>
      <MasterHelp
        status={status}
        onClose={() => {
          handleClose();
          setRows([]);
        }}
        style={style}
        title={'Company Master | Help'}
        columns={columns}
        list={rows}
        rowId={(p) => p.company_email}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          onRowClick(p);
          setSearchQuery('');
          setRows([]);
        }}
      />
    </div>
  );
};

export default ModCompanyHelp;
