import React from 'react';
import { useTable } from 'react-table';

const ThermalPrintTable = ({ data, columns }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <div>
        <table
          style={
            {
              // width: '100%',
              // height: '500px',
            }
          }
          {...getTableProps()}
        >
          <thead
          // style={{ height: '30px', width: '100%' }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    style={
                      columns.width
                        ? {
                            width: columns.width,
                            border: '1px solid black',
                            fontSize: '10px',
                          }
                        : {
                            flex: 1,
                            border: '1px solid black',
                          }
                    }
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            // style={{ width: '100%', overflow: 'hidden', overflowY: 'Scroll' }}
            {...getTableBodyProps()}
          >
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  style={
                    {
                      // height: '25px',
                      // width: '100%',
                      // pageBreakInside: 'avoid',
                      // pageBreakAfter: 'auto',
                    }
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    const cellValue = cell.render('Cell');

                    const isNumericValues =
                      cell.column.id === 'SALE_PRICE' ||
                      cell.column.id === 'IQNTY' ||
                      cell.column.id === 'ammount';

                    const isPrice =
                      cell.column.id === 'SALE_PRICE' ||
                      cell.column.id === 'ammount';

                    const cellContent =
                      cellValue.props.value === 0 ? '' : cellValue;

                    const rowWidth = cell.column.width;

                    const cellStyle = {
                      border: '1px dotted black',
                      fontSize: '12px',
                      textAlign: isNumericValues ? 'right' : 'left',
                      width: rowWidth,
                    };

                    return (
                      <td
                        className="pl-2 pr-3"
                        style={cellStyle}
                        {...cell.getCellProps()}
                      >
                        {cellContent}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ThermalPrintTable;
