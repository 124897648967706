import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { CompnySignup } from './auth/signup';
import ActivationAccount from './auth/activation';
import { ForgotPassword } from './auth/changePassword';
import { ResetEmail } from './auth/resetUserEmail';
import VerifyEmail from './auth/verifyEmail';
import Billing from './routeBilling/menu/dashboard/biiling/billing';
import Summary from './routeBilling/menu/saleSummary';
import Auth from './auth/auth';
import { Login } from './auth/Login';
import ProtectedRoute from './auth/util/protectedRoute';
import NotFound from './auth/notFound';
import Home from './routeBilling/home';
import DriverMaps from './routeBilling/menu/driverMaps';
import AdminDashboard from './routeBilling/menu/dashboard/adminDashboard';
import PartyWiseReport from './routeBilling/menu/partywiseReport';
import ItemWiseReport from './routeBilling/menu/itemWiseReport';
import ShopDashboard from './customerCP/shopDashboard';
import Dashboard from './routeBilling/menu/dashboard/dashboard';
import RouteAlert from './routeBilling/menu/routeAlert';
import PartyBalanceReport from './routeBilling/menu/partyBalanceReport2';
import CompanyMaster from './routeBilling/menu/companyMaster';
import UserMaster from './routeBilling/menu/userMaster';
import Masters from './master/masters';
import SectionMaster from './master/sectionMaster';
import DepartmentMaster from './master/departmentMaster';
import ItemMaster from './master/itemMaster';
import PartyMaster from './master/partyMaster';
import PartyRateMaster from './master/partyRatemaster';
import Reports from './reports/reports';
import SummaryList from './summary/summary';
import Updation from './updation/updation';
import Settings from './settings/settings';
import DayendSummary from './utility/component/modals/dayendSummary';
import IssueStock from './routeBilling/issueStock/issueStock';
import Manufactring from './routeBilling/manufacturing/manufactring';
import StationMaster from './master/stationMaster';
import EmployeeMaster from './master/emplowerMaster';
import StationBalanceReport from './reports/stationBalanceReport';
import ManufacturingSummary from './routeBilling/manufacturing/summary/summary';
import UpdatepartyItemRate from './customerCP/updatepartyItemrate';
import ManufacturingIndex from './routeBilling/manufacturing';
import ModalExpenseVoucher from './utility/component/modals/modExpVoucher';
import ModalCollectionVoucher from './utility/component/modals/modCollectionVoucher';
import UpdateItemRateStationWise from './master/updateItemRateStationWise';
import OrderBooking from './routeBilling/issueStock/orderBooking/orderBooking';
import ModPartyLedger from './utility/component/modals/modPartyLedger';
import GeniusDashboard from './routeBilling/admin/dashboard';
import StationDayEndreports from './reports/stationDayEndreports';
import MfgReport from './routeBilling/manufacturing/rmfgReport';
import MessageSetupMaster from './master/messageSetupMaster';
import ModuleMaster from './settings/moduleMaster';
import UserModuleMaster from './settings/userModuleMaster';
import SummaryDatagrid from './utility/component/datagrid/summaryDatagrid';
import PaymentReciptSummary from './summary/paymentRecieptSummary';
import StationItemMaster from './master/stationItemMAster';
import OrderSummary from './routeBilling/manufacturing/summary/orderSummary';
import ProfitReports from './routeBilling/menu/profitReport';
import UserControl from './settings/userControl';
import CheckToken from './checkToke';
import PromoMessangerMaster from './routeBilling/menu/promoMessangerMaster';
import SendPromoMsg from './routeBilling/menu/sendPromoMsg';
import ModGetOtp from './auth/modGetOtp';
import { off, ref } from 'firebase/database';
import database from './routeBilling/issueStock/firebase';
import { useSelector } from 'react-redux';
import { selectPrefix } from './utility/Redux/profile';
import DiffrentReport from './reports/defferenceReport';
import UserProfile from './profile';
import ItemWiseIssueReport from './routeBilling/menu/itemWiseIssueReport';
import UserScopesMaster from './settings/user_scopes_master';

const AppRoutes = ({ userType }) => {
  const [station_id, setStationId] = useState('');
  const [stationName, setStationNameState] = useState('');

  const authRoutes = [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'shopkeeper_login',
      element: <ModGetOtp />,
    },
    {
      path: 'signup',
      element: <CompnySignup />,
    },
    {
      path: 'reset_password_email',
      element: <ResetEmail />,
    },
  ];

  const privateRoutes = [
    {
      path: '/dashboard',
      element: userType === 'ADMIN' ? AdminDashboard : Dashboard,
      userType: 'allowed',
    },
    {
      path: '/department_master',
      element: DepartmentMaster,
      userType: 'allowed',
    },
    { path: '/item_master', element: ItemMaster, userType: 'allowed' },
    { path: '/party_master', element: PartyMaster, userType: 'allowed' },
    {
      path: '/party_rate_master',
      element: PartyRateMaster,
      userType: 'allowed',
    },
    {
      path: '/shopkeeper_price_list',
      element: ShopDashboard,
      userType: 'allowed',
    },
    {
      path: '/item_Wise_issue_report',
      element: ItemWiseIssueReport,
      userType: 'allowed',
    },
    { path: '/station_master', element: StationMaster, userType: 'allowed' },
    { path: '/Employee_Master', element: EmployeeMaster, userType: 'allowed' },
    {
      path: '/Update_Party_Item_Rate',
      element: UpdatepartyItemRate,
      userType: 'allowed',
    },
    {
      path: '/Update_Item_Rate_Station_Wise',
      element: UpdateItemRateStationWise,
      userType: 'allowed',
    },

    { path: '/Route_Alert_Report', element: RouteAlert, userType: 'allowed' },
    {
      path: '/Item_wise_Sale_Report',
      element: ItemWiseReport,
      userType: 'allowed',
    },
    { path: '/Profit_report', element: ProfitReports, userType: 'allowed' },
    {
      path: '/Party_Balance_Report',
      element: PartyBalanceReport,
      userType: 'allowed',
    },
    { path: '/Party_Ledger', element: ModPartyLedger, userType: 'allowed' },
    { path: '/salesMap', element: DriverMaps, userType: 'allowed' },
    {
      path: '/All_Stations_Balance_Report',
      element: StationBalanceReport,
      userType: 'allowed',
    },
    {
      path: '/Difference_report',
      element: DiffrentReport,
      userType: 'allowed',
    },
    {
      path: '/Station_day_end_reports',
      element: StationDayEndreports,
      userType: 'allowed',
    },
    {
      path: '/User_scope_master',
      element: UserScopesMaster,
      userType: 'allowed',
    },
    { path: '/Sale_Summary', element: Summary, userType: 'allowed' },
    { path: '/check_token', element: CheckToken, userType: 'allowed' },
    { path: '/day_end_summary', element: DayendSummary, userType: 'allowed' },
    {
      path: '/payment_reciept_summary',
      element: PaymentReciptSummary,
      userType: 'allowed',
    },
    {
      path: '/station_item_master',
      element: StationItemMaster,
      userType: 'allowed',
    },
    {
      path: '/Manufacturing_Summary',
      element: ManufacturingSummary,
      userType: 'allowed',
    },
    {
      path: '/Expense_Voucher',
      element: ModalExpenseVoucher,
      userType: 'allowed',
    },
    {
      path: '/Collection_Voucher',
      element: ModalCollectionVoucher,
      userType: 'allowed',
    },
    { path: '/section_master', element: SectionMaster, userType: 'allowed' },
    {
      path: '/collection_summary',
      element: SummaryDatagrid,
      userType: 'allowed',
    },
    { path: '/issue_stock', element: IssueStock, userType: 'allowed' },
    {
      path: '/company_master',
      element: CompanyMaster,
      userType: 'ADMIN MANAGER',
    },
    {
      path: '/user_master',
      element: UserMaster,
      userType: 'ADMIN MANAGER STORE',
    },
    { path: '/billing', element: Billing, userType: 'allowed' },
    { path: '/order_booking', element: OrderBooking, userType: 'allowed' },
    { path: '/Mfg_balance_report', element: MfgReport, userType: 'allowed' },
    { path: '/Order_summary', element: OrderSummary, userType: 'allowed' },
    { path: '/dashboard', element: AdminDashboard, userType: 'allowed' },
    { path: '/admin_dashboard', element: GeniusDashboard, userType: 'ADMIN' },
    { path: '/settings', element: Settings, userType: 'ADMIN , MANAGER STORE' },
    { path: '/user_controle', element: UserControl, userType: 'MANAGER' },
    {
      path: '/user_profile',
      element: UserProfile,
      userType: 'MANAGER USER ADMIN',
    },
    {
      path: '/promo_messanger_master',
      element: PromoMessangerMaster,
      userType: 'ADMIN',
    },
    { path: '/send_promo_message', element: SendPromoMsg, userType: 'ADMIN' },
    { path: '/masters', element: Masters, userType: 'allowed' },
    { path: '/reports', element: Reports, userType: 'allowed' },
    { path: '/updation', element: Updation, userType: 'allowed' },
    { path: '/summary', element: SummaryList, userType: 'allowed' },
    {
      path: '/Party_wise_Report',
      element: PartyWiseReport,
      userType: 'allowed',
    },
    { path: '/Module_master', element: ModuleMaster, userType: 'ADMIN' },
    {
      path: '/Message_Setup_Master',
      element: MessageSetupMaster,
      userType: 'ADMIN',
    },
    {
      path: '/User_module_master',
      element: UserModuleMaster,
      userType: 'ADMIN',
    },
    { path: '/Mfg', element: ManufacturingIndex, userType: 'allowed' },
    { path: '/Manufacturing', element: Manufactring, userType: 'allowed' },
  ];

  const otherRouter = [
    '/changeUserPassword',
    '/activateUser',
    '/emailVerified',
  ];

  const expires = localStorage.getItem('expiresAt');
  const date = new Date().getTime();
  const navigate = useNavigate();
  const location = useLocation();

  const UserTime = () => {
    if (expires) {
      if (expires < date) {
        navigate('/auth/login');
      }
    } else {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    const isOtherRoute = otherRouter.filter((r) => r === location.pathname);
    if (!isOtherRoute[0]) {
      UserTime();
    }
  }, []);

  const prefix = useSelector(selectPrefix);

  useEffect(() => {
    if (location.pathname !== '/issue_stock' && station_id) {
      const setMsgRef = ref(database, `${prefix}/station/${station_id}/msg`);
      off(setMsgRef);
      setStationId('');
      setStationNameState('');
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/auth" element={<Auth />}>
          {authRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route path="*" element={<NotFound />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        >
          {privateRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  route.userType.includes(userType) ||
                  route.userType === 'allowed' ? (
                    <ProtectedRoute>
                      {' '}
                      {route.path === '/issue_stock' ? (
                        <route.element
                          setStationId={setStationId}
                          station_id={station_id}
                          setStationNameState={setStationNameState}
                          stationName={stationName}
                        />
                      ) : (
                        <route.element />
                      )}
                    </ProtectedRoute>
                  ) : (
                    <NotFound />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/changeUserPassword" element={<ForgotPassword />} />
        <Route path="/activateUser" element={<ActivationAccount />} />
        <Route path="/emailVerified" element={<VerifyEmail />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
