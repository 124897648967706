import { useDispatch, useSelector } from 'react-redux';
import {
  IssuePrintButton,
  Issue_Undo_button,
  Issue_button,
} from '../../utility/component/buttons';
import { FormatNumber2 } from '../../utility/component/tools';
import { useEffect, useState } from 'react';
import IssueDayEndCalculation from './issueDayEndCalculation';
import {
  selectNumerickeyboardType,
  setIssueStockItem,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../utility/Redux/modal';
import ModNumericKeyboard from '../menu/dashboard/biiling/modal/modalNumericKeyBoard';
import CallAPI from '../../utility/functions/getData';
import { selectPrefix } from '../../utility/Redux/profile';
import moment from 'moment';
import { selectToken } from '../../utility/Redux/security';
import GeniustextInput from '../../utility/component/mastertext';
import { Collapse } from '@mui/material';

const TotalIssueStockFooter = (props) => {
  const {
    rows,
    stationId,
    getStationBalance,
    bracketLess,
    deiselQnty,
    stationLess,
    setBalance,
    startKm,
    endKM,
    prevBalance,
    balance,
    columns,
    setDailyExpenseStatus,
    cash,
    setCash,
    setDebitStatus,
    setlessStatus,
    handlePrint,
    expense,
    setExpense,
    less,
    fromDate,
    setLess,
    collection,
    debit,
    setExpenseStatus,
    setDebit,
    due,
    setDue,
    setCollection,
    onSaveIssueStock,
    handleUndo,
    setTotalDistance,
    totalDistance,
    setvehicalAvg,
    deiselRate,
    deiselAmt,
    setDeiselAmt,
    vehicalAvg,
  } = props;
  const [checked, setChecked] = useState(false);
  const [billingBTC, setBillingBTC] = useState('');
  const [billingCardAMT, setBillingCardAMT] = useState('');
  const [billingCashAMT, setBillingCashAMT] = useState('');
  const [billingDisqAmt, setBillingDisqAmt] = useState('');
  const [billingEpayment, setBillingEpayment] = useState('');
  const dispatch = useDispatch();
  const numerictype = useSelector(selectNumerickeyboardType);
  const totalIssue = rows.reduce(
    (total, row) => Number(total) + Number(row.IQNTY),
    0
  );

  const totalSqnty = rows.reduce(
    (total, row) => Number(total) + Number(row.SQNTY),
    0
  );
  const totalSAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.SAMOUNT),
    0
  );
  const totalIssueQnty = rows.reduce(
    (total, row) =>
      Number(total) + Number(row.PRV_BAL ? row.IQNTY + row.PRV_BAL : row.IQNTY),
    0
  );

  const TotalISSUEAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.IAMOUNT),
    0
  );
  const TotalRAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.SALE_PRICE * row.RQNTY),
    0
  );

  const totalRQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.RQNTY),
    0
  );

  const cellEdit = (e) => {
    if (e.field === 'CASH') {
      setCash(e.value);
    } else {
      if (e.field === 'LESS') {
        setLess(e.value);
      } else {
        if (e.field === 'DEBIT') {
          setDebit(e.value);
        } else {
          if (e.field === 'COLLECTION') {
            setCollection(e.value);
          } else {
            if (e.field === 'EXPENSE') {
              setExpense(e.value);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    let d = 0;
    // let dieselAmt = 0;
    let ToKm = endKM ? endKM : 0;
    let fromKm = startKm ? startKm : 0;
    let totalDis = ToKm - fromKm;
    let deisel = deiselRate ? deiselRate : 0;
    setTotalDistance(totalDis);
    let dieselAmount = deiselQnty * deisel;
    setDeiselAmt(dieselAmount);
    let vehicalAvrage = totalDis / deiselQnty;

    setvehicalAvg(vehicalAvrage);
    let debit1 = debit ? debit : 0;
    let collection1 = collection ? collection : 0;

    d =
      Number(TotalISSUEAmount) -
      Number(TotalRAmount) -
      (Number(expense) + Number(less));

    let net_debit = 0;
    let net_collollection = 0;
    // in case if debit>collecection
    if (debit1 > collection1) {
      net_debit = debit1 - collection1;
      d = d - net_debit;
    }

    // in case if collecection>debit
    if (collection1 > debit1) {
      net_collollection = collection1 - debit1;
      d = d + net_collollection;
    }

    setDue(d);
    setBalance(Number(cash) - Number(d));
  }, [
    cash,
    TotalRAmount,
    TotalISSUEAmount,
    collection,
    expense,
    endKM,
    startKm,
    less,
    debit,
    totalSAmount,
    deiselRate,
    deiselQnty,
  ]);

  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  useEffect(() => {
    if (checked && stationId) {
      const data = {
        table_prefix: prefix,
        FDATE: moment(fromDate).format('YYYY-MM-DD'),
        TDATE: moment(fromDate).format('YYYY-MM-DD'),
        SID: stationId,
      };
      CallAPI(
        '/api/issueStockRoute/stationPayMode',
        data,
        token,
        (p) => {
          if (!p.data.error) {
            if (p.data.response[0]) {
              setBillingBTC(p.data.response[0].BTC_AMOUNT);
              setBillingCardAMT(p.data.response[0].CARD_AMOUNT);
              setBillingCashAMT(p.data.response[0].CASH_AMOUNT);
              setBillingDisqAmt(p.data.response[0].DISQ_AMT);
              setBillingEpayment(p.data.response[0].EPAYMENT);
            }
          }
        },
        dispatch
      );
    }
  }, [checked]);

  return (
    <div className="text-sm mt-2 ">
      <div
        className=" flex  "
        style={{
          alignItems: 'center',
        }}
      >
        <div className="flex w-full">
          <div
            style={{
              width: columns[0].width + columns[1].width + columns[2].width,
              border: '1px solid #b5b3b3',
            }}
            className="rounded-tl-sm cursor-pointer"
          >
            <div className="flex w-full ">
              <div
                style={{
                  borderRight: '1px solid #b5b3b3',
                }}
                onClick={() => {
                  if (stationId) {
                    getStationBalance();
                  }
                }}
                data-tooltip-id="tip"
                data-tooltip-content="Get previous balance"
                className=" px-2 text-[#F20808] w-1/2 py-2 "
              >
                Prev. Bal.
              </div>
              <div className="flex text-[#F20808] grow justify-end items-center text-right pr-1">
                {FormatNumber2(prevBalance)}
              </div>
            </div>
          </div>

          <div>
            <div
              style={{
                borderBottom: '1px solid #b5b3b3',
                borderTop: '1px solid #b5b3b3',
                fontWeight: 550,
                width: columns[3].width,
              }}
              className="h-full text-[#5c5656] text-right flex items-center justify-end pr-1"
            >
              {' '}
              Total{' '}
            </div>
          </div>
          <div>
            <div
              style={{
                border: '1px solid #b5b3b3',

                width:
                  window.innerWidth <= 1280
                    ? columns[4].width
                    : columns[5].width,
              }}
              className=" px-1 py-2 text-right text-gray-600 h-full"
            >
              {totalIssue > 0 && FormatNumber2(totalIssue)}
            </div>
          </div>

          <div>
            <div
              style={{
                border: '1px solid #b5b3b3',
                borderLeft: '0px solid #b5b3b3',

                width: columns[5].width,
              }}
              className=" px-1 py-2 text-right text-gray-600"
            >
              {FormatNumber2(totalIssueQnty)}
            </div>
          </div>

          <div>
            <div
              style={{
                border: '1px solid #b5b3b3',
                borderLeft: '0px solid #b5b3b3',

                width: columns[6].width,
              }}
              className=" px-1 py-2 h-full text-right text-gray-600"
            >
              {FormatNumber2(TotalISSUEAmount)}
            </div>
          </div>

          {columns[6] && (
            <div>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  width: columns[7].width,
                }}
                className=" px-1 py-2 text-right h-full text-gray-600"
              >
                {totalSqnty > 0 && FormatNumber2(totalSqnty)}
              </div>
            </div>
          )}
          {columns[6] && (
            <div>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  width: columns[8].width,
                }}
                className=" px-1 py-2 text-right h-full text-gray-600"
              >
                {totalSAmount ? FormatNumber2(totalSAmount) : ''}
              </div>
            </div>
          )}
          {columns[6] && (
            <div>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  width: columns[9].width,
                }}
                className=" px-1 py-2 h-full text-right text-gray-600"
              >
                {totalRQnty > 0 && FormatNumber2(totalRQnty)}
              </div>
            </div>
          )}
          {columns[6] && (
            <div>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  width: columns[10].width,
                }}
                className=" px-1 py-2 h-full text-right text-gray-600"
              >
                {TotalRAmount > 0 && FormatNumber2(TotalRAmount)}
              </div>
            </div>
          )}

          <div className="flex grow">
            <div
              style={{
                border: '1px solid #b5b3b3',
                borderLeft: '0px solid #b5b3b3',
              }}
              className=" px-1 py-2 rounded-tr-sm w-full"
            ></div>
          </div>
        </div>
      </div>
      {columns[6] && (
        <div className="flex  w-full   ">
          <div className=" w-full flex">
            <div
              style={{
                width: columns[0].width + columns[1].width + columns[2].width,
                border: '1px solid #b5b3b3',
                borderTop: '0px solid #b5b3b3',
              }}
            >
              <div className="flex w-full ">
                <div
                  style={{
                    fontWeight: 550,
                    borderRight: '1px solid #b5b3b3',
                    width:
                      (columns[0].width + columns[1].width + columns[2].width) /
                      2,
                  }}
                  className="py-2 px-2  text-[#5c5656]  "
                >
                  Cash
                </div>
                <div
                  style={{
                    width:
                      (columns[0].width + columns[1].width + columns[2].width) /
                      2,
                  }}
                  className=" flex    items-center"
                >
                  <div
                    onDoubleClick={() => {
                      if (stationId) {
                        dispatch(
                          setIssueStockItem({
                            title: 'CASH',
                            value: cash ? cash : 0,
                            id: '1',
                          })
                        );
                        dispatch(setnumericKeyboardType('CASH'));
                        dispatch(setnumericKeyboardStatus(true));
                      }
                    }}
                    className="w-full text-right outline-none pr-1"
                  >
                    {' '}
                    {/* {FormatNumber2(cash)}{' '} */}
                    <input
                      type="number"
                      value={cash}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'ArrowUp' ||
                          event.key === 'ArrowDown'
                        ) {
                          event.preventDefault();
                        }
                      }}
                      style={{
                        outline: 'none',
                        textAlign: 'right',
                      }}
                      className="w-full"
                      onChange={(e) => {
                        if (stationId) {
                          setCash(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex grow justify-start ">
              <div
                style={{
                  width:
                    columns[3].width +
                    columns[4].width +
                    columns[5].width +
                    columns[6].width,

                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  borderRight: '0px solid #b5b3b3',
                  borderTop: '0px solid #b5b3b3',
                }}
                className=" flex "
              >
                <button
                  onDoubleClick={() => {
                    if (prefix === 'stc_' || prefix === 'go_') {
                      if (stationId) {
                        setDailyExpenseStatus(true);
                      }
                    }
                  }}
                  style={{
                    width: columns[3].width,
                    fontWeight: 550,
                    backgroundColor: 'lightgray',
                    backgroundImage:
                      'radial-gradient(white, lightgray 1px, transparent 1px, transparent 1px)',
                    backgroundSize: '4px 4px',
                    // Ensure the background covers the entire viewport height
                  }}
                  className="py-2 px-1 w-1/2 text-end text-[#5c5656] bg-[#e2e0e0]"
                >
                  Expense
                </button>
                <div
                  style={{
                    width: columns[4].width,
                    borderLeft: '1px solid #b5b3b3',
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="    flex items-center"
                >
                  <div
                    onDoubleClick={() => {
                      if (stationId) {
                        dispatch(
                          setIssueStockItem({
                            title: 'EXPENSE',
                            value: expense ? expense : 0,
                            id: '1',
                          })
                        );
                        dispatch(setnumericKeyboardType('EXPENSE'));
                        dispatch(setnumericKeyboardStatus(true));
                      }
                    }}
                    className="w-full   outline-none pr-2  text-gray-700 font-semibold"
                  >
                    {' '}
                    <input
                      type="number"
                      value={expense}
                      style={{
                        outline: 'none',
                        textAlign: 'right',
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'ArrowUp' ||
                          event.key === 'ArrowDown'
                        ) {
                          event.preventDefault();
                        }
                      }}
                      className="w-full"
                      onChange={(e) => {
                        if (stationId) {
                          setExpense(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <button
                  style={{
                    width: columns[5].width,
                    borderRight: '1px solid #b5b3b3',
                    fontWeight: 550,
                    backgroundColor: 'lightgray',
                    backgroundImage:
                      'radial-gradient(white, lightgray 1px, transparent 1px, transparent 1px)',
                    backgroundSize: '4px 4px',
                  }}
                  onDoubleClick={() => {
                    if (prefix === 'stc_' || prefix === 'go_') {
                      if (stationId) {
                        setDebitStatus(true);
                      }
                    }
                  }}
                  className="py-2 px-1 text-end text-[#5c5656] "
                >
                  Debit
                </button>
                <div
                  style={{
                    borderRight: '1px solid #b5b3b3',
                    width: columns[6].width,
                  }}
                  className="  overflow-hidden  flex items-center"
                >
                  <div
                    onDoubleClick={() => {
                      if (stationId) {
                        dispatch(
                          setIssueStockItem({
                            title: 'DEBIT',
                            value: debit ? debit : 0,
                            id: '1',
                          })
                        );
                        dispatch(setnumericKeyboardType('DEBIT'));
                        dispatch(setnumericKeyboardStatus(true));
                      }
                    }}
                    className="w-full text-right outline-none pr-1"
                  >
                    {' '}
                    <input
                      type="number"
                      value={debit}
                      style={{
                        outline: 'none',
                        textAlign: 'right',
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'ArrowUp' ||
                          event.key === 'ArrowDown'
                        ) {
                          event.preventDefault();
                        }
                      }}
                      className="w-full"
                      onChange={(e) => {
                        if (stationId) {
                          setDebit(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  borderRight: '0px solid #b5b3b3',

                  borderTop: '0px solid #b5b3b3',
                }}
                className=" flex grow h-full "
              >
                <div className="flex w-full h-full  ">
                  <div
                    style={{
                      borderRight: '1px solid #b5b3b3',
                      fontWeight: 550,
                      width:
                        columns[7].width + columns[8].width + columns[9].width,
                    }}
                    className="py-1 px-1 flex items-center justify-end pr-3 "
                  >
                    Balance
                  </div>
                  <div
                    style={{
                      borderRight: '1px solid #b5b3b3',
                      fontWeight: 550,
                    }}
                    className="py-1 px-1 pr-3 flex items-center grow justify-end "
                  >
                    {FormatNumber2(balance)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex  w-full h-full ">
        <div className=" flex w-full">
          <div>
            <div
              style={{
                width: columns[0].width + columns[1].width + columns[2].width,
                border: '1px solid #b5b3b3',
                borderTop: '0px solid #b5b3b3',
              }}
              className="rounded-bl-sm overflow-hidden"
            >
              <div className="flex w-full ">
                <button
                  onDoubleClick={() => {
                    if (prefix === 'go_' || prefix === 'stc_') {
                      if (stationId) {
                        setlessStatus(true);
                      }
                    }
                  }}
                  style={{
                    fontWeight: 550,
                    borderRight: '1px solid #b5b3b3',
                    backgroundColor: 'lightgray',
                    backgroundImage:
                      'radial-gradient(white, lightgray 1px, transparent 1px, transparent 1px)',
                    backgroundSize: '4px 4px',
                    width:
                      (columns[0].width + columns[1].width + columns[2].width) /
                      2,
                  }}
                  className="py-2 px-2  text-[#5c5656] flex justify-start "
                >
                  Less {stationLess ? `(${stationLess})` : ''}{' '}
                  {bracketLess ? `(${bracketLess})` : ''}
                </button>
                <div
                  style={{
                    width:
                      (columns[0].width + columns[1].width + columns[2].width) /
                      2,
                  }}
                  className="  overflow-hidden  flex items-center"
                >
                  <div
                    onDoubleClick={() => {
                      if (prefix === 'go_' || prefix === 'stc_') {
                        if (stationId) {
                          dispatch(
                            setIssueStockItem({
                              title: 'LESS',
                              value: less ? less : 0,
                              id: '1',
                            })
                          );
                          dispatch(setnumericKeyboardType('LESS'));
                          dispatch(setnumericKeyboardStatus(true));
                        }
                      }
                    }}
                    className="w-full text-right outline-none pr-1"
                  >
                    {' '}
                    <input
                      type="number"
                      value={less}
                      style={{
                        outline: 'none',
                        textAlign: 'right',
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'ArrowUp' ||
                          event.key === 'ArrowDown'
                        ) {
                          event.preventDefault();
                        }
                      }}
                      className="w-full"
                      onChange={(e) => {
                        if (stationId) {
                          setLess(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {columns[6] && (
            <div className="flex grow justify-start h-full ">
              <div
                style={{
                  width:
                    columns[2].width +
                    columns[3].width +
                    columns[4].width +
                    columns[5].width +
                    columns[6].width,

                  borderTop: '0px solid #b5b3b3',
                  borderBottom: '1px solid #b5b3b3',
                }}
                className=" flex h-full "
              >
                <button
                  onDoubleClick={() => {
                    if (prefix === 'go_' || prefix === 'stc_') {
                      if (stationId) {
                        setExpenseStatus(true);
                      }
                    }
                  }}
                  style={{
                    fontWeight: 550,
                    width: columns[3].width,
                    backgroundColor: 'lightgray',
                    backgroundImage:
                      'radial-gradient(white, lightgray 1px, transparent 1px, transparent 1px)',
                    backgroundSize: '4px 4px',
                    // Ensure the background covers the entire viewport height
                  }}
                  className="py-1 px-1  justify-end flex items-center text-[#5c5656] "
                >
                  Collection
                </button>
                <div
                  style={{
                    borderRight: '1px solid #b5b3b3',
                    borderLeft: '1px solid #b5b3b3',

                    width: columns[4].width,
                  }}
                  className="   flex items-center"
                >
                  <div
                    onDoubleClick={() => {
                      if (stationId) {
                        dispatch(
                          setIssueStockItem({
                            title: 'COLLECTION',
                            value: collection ? collection : 0,
                            id: '1',
                          })
                        );
                        dispatch(setnumericKeyboardType('COLLECTION'));
                        dispatch(setnumericKeyboardStatus(true));
                      }
                    }}
                    className="w-full  text-gray-700 font-semibold text-right outline-none pr-1"
                  >
                    {' '}
                    <input
                      type="number"
                      value={collection}
                      style={{
                        outline: 'none',
                        textAlign: 'right',
                      }}
                      onKeyDown={(event) => {
                        if (
                          event.key === 'ArrowUp' ||
                          event.key === 'ArrowDown'
                        ) {
                          event.preventDefault();
                        }
                      }}
                      className="w-full"
                      onChange={(e) => {
                        if (stationId) {
                          setCollection(e.target.value);
                        }
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    width: columns[5].width,
                    fontWeight: 550,
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="py-2 px-1  text-end text-[#5c5656] "
                >
                  Due
                </div>
                <div
                  style={{
                    width: columns[6].width,
                    borderRight: '1px solid #b5b3b3',
                  }}
                  className="  overflow-hidden  flex items-center justify-end pr-1"
                >
                  {FormatNumber2(due)}
                </div>
              </div>{' '}
              <div
                style={{
                  borderBottom: '1px solid #b5b3b3',
                }}
                className=" flex grow  rounded-br-sm overflow-hidden justify-end"
              >
                <div className="flex">
                  <div className="h-full pr-[1px]">
                    <Issue_button onClick={onSaveIssueStock} />
                  </div>
                  <div className="h-full px-[1px]">
                    <Issue_Undo_button onClick={handleUndo} />
                  </div>{' '}
                  <div
                    style={{
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="flex  h-full rounded-br-sm pl-[1px] "
                  >
                    <IssuePrintButton onClick={handlePrint} />

                    {numerictype === 'CASH' && (
                      <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
                    )}
                    {numerictype === 'LESS' && (
                      <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
                    )}
                    {numerictype === 'EXPENSE' && (
                      <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
                    )}
                    {numerictype === 'COLLECTION' && (
                      <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
                    )}
                    {numerictype === 'DEBIT' && (
                      <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <IssueDayEndCalculation
        collection={collection}
        debit={debit}
        totalDistance={totalDistance}
        vehicalAvg={vehicalAvg}
        deiselAmt={deiselAmt}
        checked={checked}
        setChecked={setChecked}
        stationId={stationId}
      />

      <Collapse in={checked} timeout="auto" unmountOnExit>
        <div className="flex rounded-sm mt-[2px] font-semibold  py-2">
          <div className="flex items-center">
            {checked && (
              <div className=" flex">
                <GeniustextInput
                  type="text"
                  title={''}
                  hideHeader={true}
                  hideError={true}
                  backgroundColor={'#f1efeb'}
                  value={billingBTC}
                  secondText={{
                    value: 'BTC',
                    backgroundColor: '#f7dda4',
                    width: '75px',
                  }}
                  width={'180px'}
                  align={'right'}
                />
                <div className=" flex ml-3">
                  <GeniustextInput
                    type="text"
                    title={''}
                    hideHeader={true}
                    hideError={true}
                    backgroundColor={'#f1efeb'}
                    value={billingCardAMT}
                    secondText={{
                      value: 'Card',
                      backgroundColor: '#f7dda4',
                      width: '75px',
                    }}
                    width={'180px'}
                    align={'right'}
                  />
                </div>
                <div className=" flex ml-3">
                  <GeniustextInput
                    type="text"
                    title={''}
                    hideHeader={true}
                    hideError={true}
                    backgroundColor={'#f1efeb'}
                    value={billingCashAMT}
                    secondText={{
                      value: 'Cash',
                      backgroundColor: '#f7dda4',
                      width: '75px',
                    }}
                    width={'180px'}
                    align={'right'}
                  />
                </div>
                <div className=" flex ml-3">
                  <GeniustextInput
                    type="text"
                    title={''}
                    hideHeader={true}
                    hideError={true}
                    backgroundColor={'#f1efeb'}
                    value={billingDisqAmt}
                    secondText={{
                      value: 'Discount',
                      backgroundColor: '#f7dda4',
                      width: '75px',
                    }}
                    width={'180px'}
                    align={'right'}
                  />
                </div>
                <div className=" flex ml-3">
                  <GeniustextInput
                    type="text"
                    title={''}
                    hideHeader={true}
                    hideError={true}
                    backgroundColor={'#f1efeb'}
                    value={billingEpayment}
                    secondText={{
                      value: 'ePayment',
                      backgroundColor: '#f7dda4',
                      width: '75px',
                    }}
                    width={'180px'}
                    align={'right'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};
export default TotalIssueStockFooter;
