import React, { useRef, useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import { GetDifferenceReport } from './function';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setStationID,
  setStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { DeffrenceReportColumns } from './columns';
import GeniusReportsDate from '../utility/component/reportDate';
import {
  setAlertWithTitle,
  setListStationMasterModalStatus,
} from '../utility/Redux/modal';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import GeniusTableReports from '../utility/component/geniusComponents/geniusTableforReports';
import ReactToPrint from 'react-to-print';
import GeniusTable from '../utility/component/datagrid/geniusTableReports';
import { DiffrenceFooter } from './diffrenceFooter';
import IsLocalData from '../utility/functions/ceckLocalData';

const DiffrentReport = () => {
  const fromDate = useSelector(selectreportFromDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const toDate = useSelector(selectreportToDate);
  const [toDateFocus, setToDateFocus] = useState(false);
  const ReduxStation_id = useSelector(selectStationId);
  const ReduxStationName = useSelector(selectStationName);
  const userType = useSelector(selectUserType);
  const [list, setList] = useState([]);
  const printRef = useRef();
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);

  const okButton = () => {
    if (ReduxStation_id) {
      GetDifferenceReport(
        prefix,
        dispatch,
        token,
        setList,
        fromDate,
        toDate,
        ReduxStation_id,
        ReduxStationName
      );
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please select station first',
        })
      );
    }
  };

  const columns = DeffrenceReportColumns(windowWidth);

  useEffect(() => {
    const elseFunc = () => {
      if (userType !== 'USER') {
        dispatch(setStationID(''));
        dispatch(setStationName(''));
      }
    };

    const callback = (details, data) => {
      setList(data);
      dispatch(
        setreportsFromDate(moment(details[0].from_date).format('YYYY-MM-DD'))
      );
      dispatch(
        setreportsTodate(moment(details[0].to_date).format('YYYY-MM-DD'))
      );
      dispatch(setStationID(details[0].SID));
      dispatch(setStationName(details[0].SNAME));
    };

    IsLocalData('Difference_report', callback, elseFunc);
  }, []);

  const extraLength = list.length > 13 ? 20 : 0;

  const filterBgColor = (row) => {
    const d = list.filter((o) => {
      if (o.INVNO === row.original.INVNO) {
        if (o.BILL_CASH_AMOUNT > 0 && o.CASH > 0) {
          if (o.BILL_CASH_AMOUNT !== o.CASH) {
            return true;
          } else {
            return false;
          }
        } else {
          if (o.BTC_AMOUNT > 0 && o.DEBIT > 0) {
            if (o.BTC_AMOUNT !== o.DEBIT) {
              return true;
            } else {
              return false;
            }
          }
          return false;
        }
      } else {
        return false;
      }
    });
    console.log(d);

    return d;
  };

  const filterStyle = {
    bgColor: 'white',
    text: 'red',
  };

  return (
    <div className="h-full w-full p-2 text-sm">
      {window.innerWidth <= 500 ? (
        <>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="p-1  py-3 rounded-t-sm  flex items-center "
          >
            <div className="px-1 ">From</div>

            <GeniusReportsDate
              date={fromDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1 pl-2 ">To</div>
            <GeniusReportsDate
              date={toDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>
          <div style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}>
            {userType === 'USER' ? null : (
              <div className="flex  text-[#4B4545] p-2  ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div className="flex items-center ">
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Station ID
                    </span>
                  </div>
                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex text-[#a6a2a2]  pl-2 ml-2  "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={ReduxStation_id ? ReduxStation_id : 'All'}
                      style={{
                        // width: '20%',
                        fontSize: 12,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-6"
                    />

                    <div
                      style={{
                        borderLeft: '1px dotted #a6a2a2 ',
                      }}
                      className="px-2 p-1 w-48 whitespace-nowrap print:hidden"
                    >
                      {ReduxStationName ? `${ReduxStationName}` : null}
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={okButton}
                      className="flex items-center rounded-sm ml-2"
                      style={{
                        backgroundColor: '#c1c1c1',
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: 'black',
                      }}
                    >
                      <div className="flex items-center text-sm">Ok</div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            border: '1px solid #b5b3b3',
            width:
              columns[0].width +
              columns[1].width +
              columns[2].width +
              columns[3].width +
              columns[4].width +
              columns[5].width +
              extraLength,
          }}
          className=" pl-2 rounded-sm flex items-center "
        >
          <div className=" rounded-sm  flex items-center ">
            <div className="px-1 text-[13px]">From</div>

            <GeniusReportsDate
              date={fromDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="mx-4 ">To</div>
            <GeniusReportsDate
              date={toDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>

          <div
            style={{
              borderLeft: '1px solid #b5b3b3',
            }}
            className="flex px-4  ml-5 text-[#4B4545] p-3  "
          >
            <div
              style={{ fontSize: 13 }}
              className=" flex whitespace-normal  justify-center items-center  "
            >
              <div className="flex items-center ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER' || userType === 'ADMIN') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  className="hover:cursor-pointer"
                >
                  <i
                    className="bi bi-search"
                    style={{ color: '#f44336', fontSize: 15 }}
                  ></i>
                </div>

                <span
                  style={{ fontSize: 12, fontWeight: 600 }}
                  className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                >
                  {' '}
                  Station ID{' '}
                </span>
              </div>

              <div
                style={{
                  border: '1px dotted #a6a2a2 ',
                  borderRadius: '3px',
                }}
                className="flex text-[#a6a2a2]  pl-2 ml-2  "
              >
                <input
                  size={'small'}
                  placeholder="All"
                  value={ReduxStation_id ? ReduxStation_id : 'All'}
                  style={{
                    fontSize: 12,
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    backgroundColor: 'transparent',
                  }}
                  readOnly
                  className=" outline-0 w-12 "
                />

                <div
                  style={{
                    borderLeft: '1px dotted #a6a2a2 ',
                  }}
                  className="px-2 p-1 whitespace-nowrap print:hidden"
                >
                  {ReduxStationName ? `${ReduxStationName}` : null}
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={okButton}
                  className="flex items-center rounded-sm ml-2"
                  style={{
                    backgroundColor: '#c1c1c1',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: 'black',
                  }}
                >
                  <div className="flex items-center text-sm">Ok</div>
                </button>
                {list[0] && (
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button
                          style={{
                            width: 'auto',
                            borderRadius: '7px',
                            height: 'auto',
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 4,
                            paddingBottom: 4,
                            marginLeft: 7,
                            backgroundColor: '#bad4f9',
                            color: 'black',
                          }}
                        >
                          <div className="text-sm">Print</div>
                        </button>
                      );
                    }}
                    content={() => printRef.current}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={
          windowWidth > 768
            ? {
                height: `calc(100% - 220px)`,

                width:
                  columns[0].width +
                  columns[1].width +
                  columns[2].width +
                  columns[3].width +
                  columns[4].width +
                  columns[5].width +
                  extraLength,
              }
            : {
                height: `calc(100% - 220px)`,
              }
        }
        className="mt-2 w-full "
      >
        <GeniusTable
          columns={columns}
          data={list}
          filterStyle={filterStyle}
          filterBgColor={filterBgColor}
          rowBgColor={list.filter((o) => {
            if (o.BILL_CASH_AMOUNT > 0 && o.CASH > 0) {
              if (o.BILL_CASH_AMOUNT !== o.CASH) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          })}
          hideExcel={true}
          footer={<DiffrenceFooter rows={list} columns={columns} />}
        />
      </div>

      <div
        style={
          windowWidth > 768
            ? {
                border: '0px solid #b5b3b3',

                width:
                  columns[0].width +
                  columns[1].width +
                  columns[2].width +
                  columns[3].width +
                  columns[4].width +
                  columns[5].width +
                  extraLength,
              }
            : {
                border: '0px solid #b5b3b3',
              }
        }
        className="mt-2 flex justify-end"
      >
        <div
          ref={printRef}
          style={{ width: '100%', display: 'block', visibility: 'hidden' }}
        >
          <GeniusTableReports
            stationId={ReduxStation_id}
            date={fromDate}
            date1={toDate}
            columns={columns}
            data={list}
            headerWidth={'80px'}
            title={'Difference Report'}
          />
        </div>
      </div>

      <ModListStationMaster
        onRowClick={(p) => {
          setList([]);
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
    </div>
  );
};

export default DiffrentReport;
