import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setALertStatus,
  setAlertMsg,
  setBillingAccountMasterModalStatus,
} from '../../../../utility/Redux/modal';
import { toProperCase } from '../../../../utility/component/format';
import { selectPrefix } from '../../../../utility/Redux/profile';
import { DownloadFile } from '../../../../utility/functions/firebase';
import { useState } from 'react';

const ItemButton = ({
  index,
  accNumber,
  salePriceLoading,
  orderList,
  addItem,
  box,
  getSalePrice,
}) => {
  const dispatch = useDispatch();
  const [firbaseImg, setImg] = useState('');
  const prefix = useSelector(selectPrefix);

  const icode = box.ICODE;
  const path = `rootbilling/items/${prefix + icode}`;
  DownloadFile(path)
    .then((e) => {
      if (!e.includes('FirebaseError')) {
        setImg(e);
      } else {
        setImg('');
      }
    })
    .catch((e) => {
      // setCapturedImage('');
      setImg('');
    });
  return (
    <Button
      key={index}
      variant={'contained'}
      style={{
        textTransform: 'none',
        letterSpacing: '1px',
        backgroundColor: '#fadb3f',
      }}
    >
      <div
        onClick={() => {
          if (accNumber) {
            if (!salePriceLoading) {
              const itemExists = orderList.some(
                (item) => item.ICODE === box.ICODE
              );

              if (itemExists) {
                const index = orderList.findIndex(
                  (item) => item.ICODE === box.ICODE
                );
                if (orderList[index].BQNTY > 0) {
                  return addItem(box.ICODE, orderList[index].QNTY);
                } else {
                  dispatch(setAlertMsg('insufficient balance'));
                  dispatch(setALertStatus(true));
                }
              } else {
                if (box.BQNTY > 0) {
                  getSalePrice(box);
                } else {
                  dispatch(setAlertMsg('insufficient balance'));
                  dispatch(setALertStatus(true));
                }
              }
            }
          } else {
            dispatch(setBillingAccountMasterModalStatus(true));
          }
        }}
        style={{
          width: '100%',
        }}
        className={`    bg-[#fadb3f]  `}
      >
        <div className="  flex flex-col  justify-between  ">
          <div className="   w-full flex flex-col grow  ">
            <div className="flex  ">
              <div
                style={
                  window.innerWidth <= 768
                    ? { fontSize: 12 }
                    : {
                        fontSize: 15,
                        fontWeight: 500,
                      }
                }
                className=" text-black "
              >
                {toProperCase(box.INAME)}
              </div>
              <div
                className="pl-1 flex items-center"
                style={{
                  fontSize: '12px',
                }}
              ></div>
            </div>
          </div>
          <div>{!box.REGIONAL_INAME ? box.REGIONAL_INAME : ''}</div>

          <div className="flex justify-between items-center  ">
            <div
              style={{
                fontSize: '11px',
                color: 'gray',
              }}
            >
              {box.BQNTY}
            </div>

            {firbaseImg && (
              <div>
                {' '}
                <img
                  alt=""
                  src={firbaseImg}
                  style={{
                    height: '30px',
                  }}
                />{' '}
              </div>
            )}
          </div>
        </div>
      </div>
    </Button>
  );
};

export default ItemButton;
