import CryptoJS from 'crypto-js';
import { setdefaultProfileSetting } from '../Redux/profile';
import CallAPI from '../functions/getData';
import { setAlertWithTitle } from '../Redux/modal';
import { handleSignout } from './modals/logout';

const secretPass = process.env.REACT_APP_SECRET_KEY;
const userTypeEncrypted = localStorage.getItem('userType');

const getUserType = () => {
  if (userTypeEncrypted) {
    const bytes = CryptoJS.AES.decrypt(userTypeEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var userType = data;

      return userType;
    }
  }

  return null;
};

const userType = getUserType();

export const getDefaultProfileSetting = (
  dispatch,
  email,
  token,
  prefix,
  setTwoButtonAlert,
  navigate,
  userCode,
  isFieebaseSocketOn,
  statusField
) => {
  const data = {
    table_prefix: prefix,
    code: userCode,
  };

  CallAPI(
    '/api/userSettings/userDefaultSetting',
    data,
    token,
    (res) => {
      if (!res.data.error) {
        if (res.data.response[0]) {
          dispatch(
            setdefaultProfileSetting({
              ...res.data.response[0],
            })
          );
        } else {
          setTwoButtonAlert((p) => {
            return {
              ...p,
              status: true,
              title: 'Alert',
              msg: 'Kindly contact your manager to update you default settings.',
              button1: 'ok',

              button1Click: () => {
                if (userType === 'MANAGER') {
                  setAlertWithTitle((p) => {
                    return {
                      ...p,
                      status: false,
                    };
                  });
                  navigate('/userDefaultSetting');
                }
                handleSignout(
                  dispatch,
                  navigate,
                  prefix,
                  userCode,
                  isFieebaseSocketOn,
                  statusField
                );
              },
            };
          });
        }
      }
    },
    dispatch
  );
};
