import React, { useEffect, useState } from 'react';
import profile from '../utility/images/routebillingLogo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { selectDefultProfileSetting } from '../utility/Redux/profile';
import moment from 'moment';

import CustomeTwoButtonAlert from '../utility/component/modals/twoButtonAlert';
import { setCustomeTwoButtomAlert } from '../utility/Redux/modal';
import HandleIndexedDB from '../utility/functions/indexDbDatabase';
import { toProperCase } from '../utility/component/format';
// Shared Tailwind CSS classes
const shadowClasses = 'shadow-lg';
const bgWhiteClasses = 'bg-white';
const p4Classes = 'p-4';
const flex1Classes = 'flex-1';

const roundedClasses = 'rounded-[20px]';

const UserProfile = () => {
  const [userData, setUserData] = useState('');
  const [endDate, setEndDate] = useState();
  useEffect(() => {
    const databaseName = 'userDetails';
    const storeName = 'data';

    HandleIndexedDB({
      databaseName,
      storeName,
      callback: (data) => {
        if (data[0]) {
          setUserData(data[0]);
          const endDate = moment(data[0].end_date).diff(
            moment(moment().format('YYYY-MM-DD')),
            'days'
          );
          setEndDate(endDate);
        }
      },
    });
  }, []);
  const defaultSettings = useSelector(selectDefultProfileSetting);
  const dispatch = useDispatch();
  console.log(defaultSettings);
  return (
    <div className={`flex p-6 w-full h-full bg-gray-100`}>
      <div
        className={`  ${bgWhiteClasses} ${p4Classes} ${roundedClasses} ${shadowClasses} w-fit`}
      >
        <div className="border-b mb-5">
          <img src={profile} alt="User Profile" className={'mb-4  w-fit'} />
        </div>

        <h2 className={`text-[19px] font-semibold }`}>Profile</h2>
        <div className={'text-[16px] font-segoe mt-3'}>
          {userData?.user_name}
        </div>
        <div className={''}>{userData?.user_mobile}</div>
        <div className={''}>{userData?.user_email}</div>

        <button
          onClick={() => {
            dispatch(setCustomeTwoButtomAlert(true));
          }}
          className="mt-10 bg-yellow-400 w-full py-1 px-4 rounded-sm shadow-md"
        >
          Logout
        </button>
      </div>
      <div className="flex-1 ml-6">
        <div
          className={`${flex1Classes} ${bgWhiteClasses} ${p4Classes} ${roundedClasses} ${shadowClasses} `}
        >
          <div className={``}>
            <div className={'text-[18px] font-semibold'}>Permissions</div>

            <div className="mt-3">
              <Permision
                title={'Can give discount on billing'}
                value={defaultSettings?.allow_discount}
              />
              <Permision
                title={'After billing auto send sms or whatsapp'}
                value={defaultSettings?.auto_send_bill}
              />
              <Permision
                title={'Carry previous balance in issue stock'}
                value={defaultSettings?.carry_previous_balance}
              />
              <Permision
                title={'Check shop location on billing'}
                value={defaultSettings?.check_bill_location}
              />
              <Permision
                title={'Check stock in billing'}
                value={defaultSettings?.check_stock_in_bill}
              />
              <Permision
                title={'Can send bill whatsapp or sms on random numbers'}
                value={defaultSettings?.enable_customer_mobile}
              />
              <Permision
                title={'Can change dashboard date'}
                value={defaultSettings?.enable_user_dashboard_date}
              />
              <Permision
                title={'Can send sms on billing'}
                value={defaultSettings?.send_sms}
              />
              <Permision
                title={'Can send whatsapp on billing'}
                value={defaultSettings?.send_whatsapp}
              />
              <Permision
                title={'Can see today issue qnty and rates'}
                value={defaultSettings?.show_today_issued}
              />
            </div>
          </div>
        </div>

        <div
          className={`${flex1Classes} mt-6 ${bgWhiteClasses} ${p4Classes} ${roundedClasses} ${shadowClasses} `}
        >
          <div className={``}>
            <div className={'text-[18px] font-semibold'}>Other Details</div>

            <div className="mt-3">
              <div className="flex items-center">
                The license will expire on{' '}
                <label className="ml-1 c font-semibold text-red-400">
                  {moment(userData?.end_date).format('DD MMM , YYYY')}
                </label>{' '}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end ">
          <p className="mt-3  text-gray-600">
            For any issue create a ticket{' '}
            <a
              href="https://geniusoffice.freshdesk.com/support/tickets/new"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 underline"
            >
              here
            </a>
            .
          </p>
        </div>
      </div>
      <CustomeTwoButtonAlert />
    </div>
  );
};

const Permision = ({ title, value }) => {
  return (
    <div className="flex items-center mb-2">
      <div className="  w-[400px] m">{title}</div>:
      <div className={`ml-3 ${'text-black '} `}>
        {<input type="checkbox" checked={value === 'YES'} />}
      </div>
      <div className={`ml-3 ${'text-black w-[30px]'} `}>
        {toProperCase(value)}
      </div>
    </div>
  );
};

export default UserProfile;
