import { CallAPIPromise } from '../../routeBilling/menu/dashboard/biiling/modal/comman';
import { setAlertWithTitle, setSpinnerLoading } from '../Redux/modal';
import axios from 'axios';

const SendOtp = (MobileNumber, randomNumber, dispatch) => {
  dispatch(setSpinnerLoading('Sending OTP'));
  CallAPIPromise(
    `/api/cpusers/sendOTP`,

    {
      user_mobile: MobileNumber,
      OTP: randomNumber,
    },
    '',
    dispatch
  )
    .then(async function (response) {
      if (response.status === 200) {
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Something went wrong',
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);

      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Something went wrong',
        })
      );
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export default SendOtp;
