import { useDispatch, useSelector } from 'react-redux';
import {
  SelectDepartmentMasterHelpStatus,
  setDepartmentMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';

import MasterHelp from '../utility/component/modals/masterHelp';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';

const DepartmentMasterHelp = ({ setDeptName }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const status = useSelector(SelectDepartmentMasterHelpStatus);

  const [rows, setRows] = useState([]);
  const [fullList, setFullList] = useState([]);
  const token = useSelector(selectToken);
  const width = useSelector(selectWidth);

  const columns = [
    {
      width:
        width <= 768 ? 60 : width <= 1024 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      field: 'DEPARTMENT_NAME',
      renderHeader: (params) => <div>Department Name</div>,
      width:
        width <= 768 ? 230 : width <= 1024 ? 230 : width <= 1440 ? 200 : 300,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  const dispatch = useDispatch();

  const prefix = useSelector(selectPrefix);

  const getData = async () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPIPromise(
      '/api/issueMasterRoute/listDepartmentMaster',
      {
        table_prefix: prefix,
        DEPARTMENT_NAME: '',
      },
      token,
      dispatch
    )
      .then((p) => {
        setRows(p.data.response);
        setFullList(p.data.response);
      })
      .catch((error) => {
        if (error.response.data.name === 'TokenExpiredError') {
          console.log(error.response);

          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const handleClose = () => {
    dispatch(setDepartmentMasterHelpStatus(false));
  };

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.DEPARTMENT_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  return (
    <>
      <MasterHelp
        status={status}
        style={style}
        onClose={() => {
          handleClose();
          setRows([]);
        }}
        title={'Department Master | Help'}
        columns={columns}
        list={rows}
        rowId={(r) => r.CODE}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          setDeptName(p.row.DEPARTMENT_NAME);
          dispatch(setDepartmentMasterHelpStatus(false));
          setSearchQuery('');
          setRows([]);
        }}
      />
    </>
  );
};

export default DepartmentMasterHelp;
