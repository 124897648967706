import React from 'react';

import ItemButton from './itemButton';
const ItemBox = ({
  itemboxLoading,
  Lottie,
  accNumber,
  spinner,
  filterdItemList,
  salePriceLoading,
  orderList,
  addItem,
  getSalePrice,
}) => {
  return (
    <div
      style={{
        border: ' 1px solid #bab5ad',
        borderRadius: window.innerWidth <= 768 ? '3px' : '3px',
        borderTopRightRadius: window.innerWidth >= 768 && '0px',
        borderTopLeftRadius: window.innerWidth >= 768 && '0px',
      }}
      className=" flex h-full  overflow-y-scroll  "
    >
      <div className="w-full">
        {itemboxLoading ? (
          <div className=" w-full h-full  flex justify-center items-center">
            <div className="w-10 h-10">
              <Lottie
                animationData={spinner}
                loop={true}
                style={{ fontSize: 15 }}
              />
            </div>
          </div>
        ) : (
          <div className=" p-2  w-full grid grid-cols-1 md:grid-cols-1 l:grid-cols-2 d:grid-cols-3 gap-1  ">
            {filterdItemList?.map((box, index) => {
              return (
                <ItemButton
                  index={index}
                  accNumber={accNumber}
                  salePriceLoading={salePriceLoading}
                  orderList={orderList}
                  addItem={addItem}
                  box={box}
                  getSalePrice={getSalePrice}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemBox;
