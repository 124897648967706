import React from 'react';
import MapWithMultyMarkers from '../../utility/component/multyMarker';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix } from '../../utility/Redux/profile';
import axios from 'axios';
import { setSessionExpired } from '../../utility/Redux/modal';
import { selectToken } from '../../utility/Redux/security';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { CalendarMonth } from '@mui/icons-material';
import { parseISO } from 'date-fns';
import GeniusSelectDate from '../../utility/component/selectDate';
import { CallAPIPromise } from './dashboard/biiling/modal/comman';

const DriverMaps = () => {
  const dispatch = useDispatch();
  const tokan = useSelector(selectToken);
  const [station, setStation] = useState([]);
  const [stationName, setStationName] = useState('');
  const [dateFocus, SetDateFocus] = useState(false);
  const [selectedStation, setSelectedStation] = useState('');
  const [locationList, setLocationList] = useState([]);
  const prefix = useSelector(selectPrefix);
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const columns = React.useMemo(
    () => [
      {
        field: 'CODE',
        type: 'string',
        width: 80,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Code'}</strong>,
      },
      {
        field: 'STATION_NAME',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Station'}</strong>,
      },
    ],
    []
  );

  const StationList = () => {
    CallAPIPromise(
      `/api/masters/listStationMaster`,
      {
        table_prefix: prefix,
        CODE: '',
      },
      tokan,
      dispatch
    )
      .then((res) => {
        setStation(res.data.response);
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  const getStationSalesLocation = () => {
    CallAPIPromise(
      `/api/reports/saleSummary`,
      {
        table_prefix: prefix,
        from_date: date,
        to_date: date,
        sid: selectedStation,
        account_no: '',
      },
      tokan,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              sid: selectedStation,
              sName: stationName,
            });
          }
          setLocationList(rowList);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  useEffect(() => {
    StationList();
  }, []);
  useEffect(() => {
    if (selectedStation) {
      getStationSalesLocation();
    }
  }, [selectedStation, date]);

  return (
    <div className="flex flex-col w-full h-full  p-4 overflow-y-scroll">
      <div className="flex items-center border border-[#b5b3b3] py-[10px] px-4 mb-3 rounded-sm">
        <div style={{ fontSize: 15 }} className="flex  w-1/4 items-center ">
          <label className="whitespace-nowrap text-[13px] ">From </label>
          <div className=" ml-2">
            <GeniusSelectDate
              date={date}
              setDate={setDate}
              SetDateFocus={SetDateFocus}
              dateFocus={dateFocus}
            />
          </div>
        </div>
        <div
          style={{ fontSize: 14 }}
          className="ml-3  hidden t:flex items-center"
        >
          <label>Station Name: </label>
          <div className="text-orange ml-1">{stationName}</div>
        </div>
      </div>
      <div className="flex w-full grow max-sm:block">
        <div className="flex w-1/4 t:h-full mr-4 max-sm:w-full h-[30%]">
          <DataGrid
            hideFooterPagination
            hideFooter
            hideFooterSelectedRowCount
            headerHeight={38}
            onRowClick={(params) => {
              setSelectedStation(params.row.CODE);
              setStationName(params.row.STATION_NAME);
            }}
            sx={
              window.innerWidth < 770
                ? {
                    backgroundColor: 'white',

                    fontSize: 9,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                    },
                  }
                : {
                    backgroundColor: 'white',

                    fontSize: 13,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                    },
                  }
            }
            rowHeight={33}
            columns={columns}
            rows={station}
            getRowId={(e) => e.CODE}
            pageSize={100}
            // rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        </div>

        <div
          style={{ fontSize: 14 }}
          className="flex t:hidden border border-[#b5b3b3] w-full my-3 p-2 rounded"
        >
          <label>Station Name: </label>
          <div className="text-orange ml-1">{stationName}</div>
        </div>

        <div className="w-full t:w-3/4 t:h-full h-[60%]">
          <MapWithMultyMarkers locations={locationList} />
        </div>
      </div>
    </div>
  );
};

export default DriverMaps;
