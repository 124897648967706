const deleteWholeDatabase = (database) => {
  const deleteData = () => {
    const request = indexedDB.deleteDatabase(database);

    request.onsuccess = function () {
      console.log(`Database "${database}" deleted successfully`);
    };

    request.onerror = function (event) {
      console.error(
        `Error deleting database "${database}":`,
        event.target.error
      );
    };
  };

  deleteData();
};

export default deleteWholeDatabase;
