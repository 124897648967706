import moment from 'moment';
import React from 'react';

const ManfSumColumns = (width) => {
  const columns = [
    {
      width:
        width <= 1024 ? 150 : width <= 1280 ? 150 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      accessor: 'EMPLOYEE_NAME',
      field: 'EMPLOYEE_NAME',
      headerName: 'Employee Name',
      renderHeader: (params) => <div>Employee Name</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 100 : width <= 1440 ? 120 : 130,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_QNTY',
      field: 'MFG_QNTY',
      headerName: 'MFG. Qnty.',
      renderHeader: (params) => <div>Mfg. Qnty.</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'DMG_QNTY',
      field: 'DMG_QNTY',

      renderHeader: (params) => <div> Damage Qnty.</div>,
    },
  ];
  return columns;
};

const OrderSumColumns = (width) => {
  const columns = [
    {
      width:
        width <= 1024 ? 150 : width <= 1280 ? 150 : width <= 1440 ? 150 : 110,
      headerAlign: 'center',
      align: 'left',
      accessor: 'DATE',
      field: 'DATE',
      headerName: 'Date',
      renderCell: (params) => moment(params.value).format('YYYY-MM-DD'),
      renderHeader: (params) => <div>Date</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 100 : width <= 1440 ? 120 : 130,
      headerAlign: 'center',
      align: 'left',
      accessor: 'EMPLOYEE_NAME',
      field: 'EMPLOYEE_NAME',
      headerName: 'Employee Name',
      renderHeader: (params) => <div>Employee Name</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 100 : width <= 1440 ? 100 : 80,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ICODE',
      field: 'ICODE',

      renderHeader: (params) => <div> Icode</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ITEMNAME',
      field: 'ITEMNAME',

      renderHeader: (params) => <div> Item Name</div>,
    },

    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 120 : width <= 1440 ? 130 : 130,
      headerAlign: 'center',
      align: 'right',
      accessor: 'ORDER_QNTY',
      field: 'ORDER_QNTY',

      renderHeader: (params) => <div> Order quantity</div>,
    },
    {
      width:
        width <= 1024 ? 100 : width <= 1280 ? 100 : width <= 1440 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      accessor: 'SID',
      field: 'SID',

      renderHeader: (params) => <div> SID</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'left',
      accessor: 'SNAME',
      field: 'SNAME',

      renderHeader: (params) => <div> Station Name</div>,
    },
  ];
  return columns;
};
const ManfSecColumns = (width) => {
  const columns = [
    {
      width:
        width <= 1024 ? 150 : width <= 1280 ? 150 : width <= 1440 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',
      accessor: 'SECTION_NAME',
      field: 'SECTION_NAME',
      headerName: 'Section Name',
      renderHeader: (params) => <div>Section Name</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 100 : width <= 1440 ? 120 : 130,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_QNTY',
      field: 'MFG_QNTY',
      headerName: 'MFG. Qnty.',
      renderHeader: (params) => <div>Mfg. Qnty.</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'DMG_QNTY',
      field: 'DMG_QNTY',

      renderHeader: (params) => <div className="mr-4"> Damage Qnty.</div>,
    },
  ];
  return columns;
};
const ManfItemColumns = (width) => {
  const columns = [
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 100 : width <= 1440 ? 70 : 100,
      headerAlign: 'center',
      align: 'center',
      accessor: 'ICODE',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      flex: 1,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ITEMNAME',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width <= 1024 ? 130 : width <= 1280 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_QNTY',
      field: 'MFG_QNTY',

      renderHeader: (params) => <div> Mfg. Qnty.</div>,
    },
    {
      width:
        width <= 1024 ? 130 : width <= 1280 ? 150 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'right',
      accessor: 'DMG_QNTY',
      field: 'DMG_QNTY',

      renderHeader: (params) => <div> Damage Qnty.</div>,
    },
  ];
  return columns;
};

const MfgSumaryColumns = (width) => {
  const columns = [
    {
      width: width <= 1024 ? 80 : width <= 1280 ? 80 : width <= 1440 ? 70 : 100,
      headerAlign: 'center',
      align: 'center',
      accessor: 'SERIAL_NO',
      field: 'SERIAL_NO',
      headerName: 'Serial',
      renderHeader: (params) => <div>Serial</div>,
    },
    {
      width: width <= 1024 ? 70 : width <= 1280 ? 80 : width <= 1440 ? 120 : 80,
      headerAlign: 'center',
      align: 'center',
      accessor: 'ICODE',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1280 ? 180 : width <= 1440 ? 100 : 250,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ITEMNAME',
      field: 'ITEMNAME',

      renderHeader: (params) => <div> Item Name</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 100 : width <= 1440 ? 100 : 200,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_QNTY',
      field: 'MFG_QNTY',

      renderHeader: (params) => <div> Mfg. Qnty.</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_TIME',
      field: 'MFG_TIME',

      renderHeader: (params) => <div> Mfg. Time</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1280 ? 150 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'EMPLOYEE_NAME',
      field: 'EMPLOYEE_NAME',

      renderHeader: (params) => <div>Mfg. By</div>,
    },
  ];
  return columns;
};

export {
  ManfSumColumns,
  ManfSecColumns,
  MfgSumaryColumns,
  ManfItemColumns,
  OrderSumColumns,
};
