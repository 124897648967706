import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../Redux/security';
import GeniustextInput from '../mastertext';
import { setAlertWithTitle, setSpinnerLoading } from '../../Redux/modal';
import {
  selectPrefix,
  selectUserType,
  setStationID,
} from '../../Redux/profile';
import ModUserHelp from './modUserHelp';
import AlertModalTwoButton from './alertWithTwoButton';
import { SaveButton, UndoButton } from '../buttons';
import CallAPI from '../../functions/getData';

const SystemAdmin = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userhelpStatus, setuserhelpStatus] = useState(false);
  const [defaultSettingEmail, setDefaultSettingEmail] = useState('');
  const [defaultSettingStationId, setDefaultSettingStationId] = useState('');
  const [isMobileActive, SetMobileActive] = useState(false);
  const [isBillLocationActive, setBillLocationActive] = useState(false);
  const [isDashboardDateActive, setDashboardDateActive] = useState(false);
  const [isCarryPreviosBalActive, setCArryPrevsBalActive] = useState(false);
  const [isAllowDiscount, setAllowDiscount] = useState(false);
  const [isAutoBillSend, setAutoBillSend] = useState(false);
  const [showTodayIssue, setShowTodayIssue] = useState(false);
  const [sendWhatsapp, setSendWhatsapp] = useState(false);
  const [sendSms, setSendSms] = useState(false);
  const [loginOtp, setLoginOTP] = useState(false);
  const [valid_for_days, setValidForDays] = useState(1);
  const [WhatsappCrdeits, setWhatsappCredits] = useState('');
  const [SmsCredits, setSmsCrdeits] = useState('');
  const [userMobile, setUserMobile] = useState('');
  const [userCode, setUserCode] = useState('');
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [usertype, setUserType] = useState('USER');
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const saveSystemDefaultSetting = () => {
    dispatch(setSpinnerLoading('Saving'));
    const data = {
      table_prefix: prefix,
      user_email: defaultSettingEmail,
      user_type: usertype,
      code: userCode,
      enable_customer_mobile: isMobileActive ? 'YES' : 'NO',
      enable_user_dashboard_date: isDashboardDateActive ? 'YES' : 'NO',
      check_bill_location: isBillLocationActive ? 'YES' : 'NO',
      station_id: defaultSettingStationId,
      allow_discount: isAllowDiscount ? 'YES' : 'NO',
      auto_send_bill: isAutoBillSend ? 'YES' : 'NO',
      show_today_issued: showTodayIssue ? 'YES' : 'NO',
      carry_previous_balance: isCarryPreviosBalActive ? 'YES' : 'NO',
      send_sms: sendSms ? 'YES' : 'NO',
      send_whatsapp: sendWhatsapp ? 'YES' : 'NO',
      sms_credits: SmsCredits ? Number(SmsCredits) : 0,
      whatsapp_credits: WhatsappCrdeits ? Number(WhatsappCrdeits) : 0,
      user_mobile: !defaultSettingEmail ? userMobile : '',
      valid_for_days: valid_for_days,
      login_otp: loginOtp ? 'YES' : 'NO',
    };
    CallAPI(
      '/api/userSettings/changeUserDefaultSetting',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          dispatch(setSpinnerLoading('Saved Successfully'));
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Data updated',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something went wrong',
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
        back();
      }
    );
  };

  const back = () => {
    setUserType('USER');
    dispatch(setStationID(''));
    SetMobileActive(false);
    setBillLocationActive(false);
    setDashboardDateActive(false);
    setCArryPrevsBalActive(false);
    setAllowDiscount(false);
    dispatch(setStationID(''));
    setAutoBillSend(false);
    setShowTodayIssue(false);
    setDefaultSettingEmail('');
    setDefaultSettingStationId('');
    setSendSms(false);
    setLoginOTP(false);
    setValidForDays(1);
    setSendWhatsapp(false);
    setSmsCrdeits('');
    setWhatsappCredits('');
    setUserMobile('');
  };

  const getUserDefaultSetting = () => {
    const data = {
      code: userCode,
      table_prefix: prefix,
    };
    console.log(data);
    CallAPI(
      '/api/userSettings/userDefaultSetting',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            setUserType(res.data.response[0].user_type);
            dispatch(setStationID(res.data.response[0].station_id));
            SetMobileActive(
              res.data.response[0].enable_customer_mobile === 'YES'
                ? true
                : false
            );
            setSendSms(res.data.response[0].send_sms === 'YES' ? true : false);
            setSendWhatsapp(
              res.data.response[0].send_whatsapp === 'YES' ? true : false
            );
            setLoginOTP(
              res.data.response[0].login_otp === 'YES' ? true : false
            );
            setValidForDays(res.data.response[0].valid_for_days);
            setDashboardDateActive(
              res.data.response[0].enable_user_dashboard_date === 'YES'
                ? true
                : false
            );
            setBillLocationActive(
              res.data.response[0].check_bill_location === 'YES' ? true : false
            );
            setCArryPrevsBalActive(
              res.data.response[0].carry_previous_balance === 'YES'
                ? true
                : false
            );
            setWhatsappCredits(res.data.response[0].whatsapp_credits);
            setSmsCrdeits(res.data.response[0].sms_credits);

            setAllowDiscount(
              res.data.response[0].allow_discount === 'YES' ? true : false
            );
            setAutoBillSend(
              res.data.response[0].auto_send_bill === 'YES' ? true : false
            );

            setShowTodayIssue(
              res.data.response[0].show_today_issued === 'YES' ? true : false
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'No data',
                msg: 'No data found',
              })
            );
          }
        }
      },
      dispatch
    );
  };

  useEffect(() => {
    if (defaultSettingEmail || userMobile) {
      getUserDefaultSetting();
    }
  }, [defaultSettingEmail, userMobile]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const actualUserType = useSelector(selectUserType);
  return (
    <div className="flex-grow  bg-white border-brown px-5 max-sm:px-2">
      <div className="flex mt-4">
        <div>
          <GeniustextInput
            title={'User Email'}
            width={windowWidth > 764 ? '250px' : '150px'}
            type={'text'}
            search={() => setuserhelpStatus(true)}
            value={defaultSettingEmail}
          />
        </div>
        <div className="ml-3">
          <GeniustextInput
            title={'User Mobile'}
            width={windowWidth > 764 ? '250px' : '150px'}
            type={'number'}
            value={userMobile}
          />
        </div>
      </div>

      <div className="flex ">
        <div>
          <GeniustextInput
            title={'User Type'}
            width={windowWidth > 764 ? '180px' : '150px'}
            option={
              actualUserType === 'ADMIN'
                ? [
                    {
                      name: 'USER',
                      value: 'USER',
                    },
                    {
                      name: 'MANAGER',
                      value: 'MANAGER',
                    },
                    {
                      name: 'STORE',
                      value: 'STORE',
                    },
                    {
                      name: 'ADMIN',
                      value: 'ADMIN',
                    },
                  ]
                : [
                    {
                      name: 'USER',
                      value: 'USER',
                    },
                    {
                      name: 'MANAGER',
                      value: 'MANAGER',
                    },
                    {
                      name: 'STORE',
                      value: 'STORE',
                    },
                  ]
            }
            value={usertype}
            onChange={(e) => setUserType(e.target.value)}
          />
        </div>
        <div className="ml-3">
          <GeniustextInput
            title={'OTP Valid Days'}
            width={windowWidth > 764 ? '250px' : '150px'}
            type={'number'}
            value={valid_for_days}
            onChange={(e) => {
              setValidForDays(e.target.value);
            }}
          />
        </div>
      </div>

      <div className=" w-full max-sm:h-[220px] max-sm:overflow-y-scroll max-sm:rounded-sm border border-[#B5B3B3] l:border-0">
        <div className="flex w-full max-sm:block">
          <div className="flex w-1/2 max-sm:w-full">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={isMobileActive ? 'green' : 'red'}
                checked={isMobileActive}
                onChange={() => SetMobileActive(!isMobileActive)}
                color={isMobileActive ? 'success' : 'error'}
              />
            </div>
            <div className="flex items-center mt-1">Customer Mobile</div>
          </div>

          <div className="flex w-1/2 max-sm:w-full ">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={isBillLocationActive ? 'green' : 'red'}
                checked={isBillLocationActive}
                onChange={() => setBillLocationActive(!isBillLocationActive)}
                color={isBillLocationActive ? 'success' : 'error'}
              />
            </div>
            <div className="flex items-center mt-1">Check Bill Location</div>
          </div>
        </div>

        <div className="flex w-full max-sm:block">
          <div className="flex w-1/2 max-sm:w-full">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={isDashboardDateActive ? 'green' : 'red'}
                checked={isDashboardDateActive}
                onChange={() => setDashboardDateActive(!isDashboardDateActive)}
                color={isDashboardDateActive ? 'success' : 'error'}
              />
            </div>
            <div className="flex items-center mt-1">User Dashboard Date</div>
          </div>

          <div className="flex w-1/2 max-sm:w-full">
            <div className="flex items-center ">
              <GeniustextInput
                checkBox={true}
                hideError={true}
                backgroundColor={isCarryPreviosBalActive ? 'green' : 'red'}
                checked={isCarryPreviosBalActive}
                onChange={() =>
                  setCArryPrevsBalActive(!isCarryPreviosBalActive)
                }
                color={isCarryPreviosBalActive ? 'success' : 'error'}
              />
              <div className="flex items-center mt-1">
                Carry Previous Balance
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full max-sm:block">
          <div className="flex w-1/2 max-sm:w-full">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={isAllowDiscount ? 'green' : 'red'}
                checked={isAllowDiscount}
                onChange={() => setAllowDiscount(!isAllowDiscount)}
                color={isAllowDiscount ? 'success' : 'error'}
              />
            </div>
            <div className="flex items-center mt-1">Allow Discount</div>
          </div>

          <div className="flex w-1/2 max-sm:w-full">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={isAutoBillSend ? 'green' : 'red'}
                checked={isAutoBillSend}
                color={isAutoBillSend ? 'success' : 'error'}
                onChange={() => setAutoBillSend((prev) => !prev)}
              />
            </div>
            <div className="flex items-center mt-1">Auto Bill Send</div>
          </div>
        </div>

        <div className="flex w-full max-sm:block">
          <div className="flex w-1/2 max-sm:w-full items-center">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={showTodayIssue ? 'green' : 'red'}
                checked={showTodayIssue}
                color={showTodayIssue ? 'success' : 'error'}
                onChange={() => setShowTodayIssue((prev) => !prev)}
              />
            </div>
            <div className="flex items-center mt-1">Show Today Issue</div>
          </div>
          <div className="flex w-1/2 max-sm:w-full items-center">
            <div className="flex items-center ">
              <GeniustextInput
                //   width={windowWidth > 764 ? '180px' : '150px'}
                checkBox={true}
                hideError={true}
                backgroundColor={loginOtp ? 'green' : 'red'}
                checked={loginOtp}
                color={loginOtp ? 'success' : 'error'}
                onChange={() => setLoginOTP((prev) => !prev)}
              />
            </div>
            <div className="flex items-center mt-1">Login OTP</div>
          </div>
        </div>
        {usertype === 'ADMIN' && (
          <div className="flex w-full max-sm:block">
            <div className=" w-1/2 max-sm:w-full">
              <div className="w-full flex">
                {' '}
                <div className="flex items-center ">
                  <GeniustextInput
                    //   width={windowWidth > 764 ? '180px' : '150px'}
                    checkBox={true}
                    hideError={true}
                    backgroundColor={sendWhatsapp ? 'green' : 'red'}
                    checked={sendWhatsapp}
                    color={sendWhatsapp ? 'success' : 'error'}
                    onChange={() => setSendWhatsapp((prev) => !prev)}
                  />
                </div>
                <div className="flex items-center mt-1">Send Whatsapp</div>
              </div>
              <div className="pl-2 mt-1">
                <input
                  type="number"
                  style={{
                    outline: 'none',
                    border: '1px solid #cbc9c9',
                  }}
                  className="rounded-sm py-1 pl-8"
                  placeholder="Whatsapp Credits"
                  value={WhatsappCrdeits}
                  onChange={(e) => setWhatsappCredits(e.target.value)}
                />
              </div>
            </div>
            <div className=" w-1/2 max-sm:w-full">
              <div className="w-full flex">
                <div className="flex items-center ">
                  <GeniustextInput
                    //   width={windowWidth > 764 ? '180px' : '150px'}
                    checkBox={true}
                    hideError={true}
                    backgroundColor={sendSms ? 'green' : 'red'}
                    checked={sendSms}
                    color={sendSms ? 'success' : 'error'}
                    onChange={() => setSendSms((prev) => !prev)}
                  />
                </div>
                <div className="flex items-center mt-1">Send SMS</div>
              </div>

              <div className="pl-2 mt-1">
                <input
                  type="number"
                  style={{
                    outline: 'none',
                    border: '1px solid #cbc9c9',
                  }}
                  className="rounded-sm py-1 pl-8"
                  placeholder="SMS Credits"
                  value={SmsCredits}
                  onChange={(e) => setSmsCrdeits(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex w-full justify-end  py-5">
        <SaveButton
          onClick={() => {
            if (defaultSettingStationId) {
              setAlertTwoButtonModal({
                ...alertTwoButtonModal,
                status: true,
                title: 'Save',
                msg: 'Do you want to save this data?',
                button1: 'Yes',
                button2: 'No',

                button1Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                  saveSystemDefaultSetting();
                },
                button2Click: () => {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: false,
                  });
                },
              });
            }
          }}
        />
        <UndoButton onClick={back} />
      </div>

      <ModUserHelp
        status={userhelpStatus}
        onRowClick={(p) => {
          setDefaultSettingEmail(p.row.USER_EMAIL);
          setDefaultSettingStationId(p.row.STATION_ID);
          setuserhelpStatus(false);
          setUserMobile(p.row.USER_MOBILE);
          setUserCode(p.row.CODE);
        }}
        onClose={() => {
          setuserhelpStatus(false);
        }}
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default SystemAdmin;
