import React, { useEffect, useRef, useState } from 'react';
import ModalHeaderStyle from '../../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import GeniustextInput from '../../utility/component/mastertext';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../../utility/component/buttons';
import { Country, State, City } from 'country-state-city';
import { selectWidth } from '../../utility/Redux/profile';
import { PromoMasterSave } from './func';
import { selectToken } from '../../utility/Redux/security';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import PromoMessagehelp from '../../help/promoMessangerHelp';
import { toProperCase } from '../../utility/component/format';
import CallAPI from '../../utility/functions/getData';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const PromoMessangerMaster = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = Country.getAllCountries();
  const [selectedCountry, setSelectedCountry] = useState('India');
  const [selectedState, setSelectedState] = useState('Punjab');
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [city, setCity] = useState('Jalandhar');
  const [allList, setAllCustomerList] = useState([]);
  const products = [
    {
      name: 'genius office',
      value: 'genius office',
    },
    {
      name: 'genius office CA',
      value: 'genius office CA',
    },
    {
      name: 'routeBilling',
      value: 'routeBilling',
    },
    {
      name: 'Tailoringx',
      value: 'Tailoringx',
    },
    {
      name: 'Retailx',
      value: 'Retailx',
    },
    {
      name: 'Restaurant',
      value: 'Restaurant',
    },
    {
      name: 'Hotel',
      value: 'Hotel',
    },
    {
      name: 'Salon',
      value: 'Salon',
    },
    {
      name: 'Agent',
      value: 'Agent',
    },
    {
      name: 'Other',
      value: 'Other',
    },
  ];
  const templates = [
    {
      name: 'copy_tailoring_pormotional',
      value: 'HX6597ca9d13e27f236380da9caf315db3',
      message: `Welcome to Tailoringx, the ultimate measurement app designed to revolutionize the way tailors and garment professionals manage their client's measurements and orders.`,
    },
    {
      name: 'routebilling_pormotional',
      value: 'HXecb4db1b2a1c87fb787685979d299c3c',
      message: `Simplify Your Wholesale Operations!
      Discover the power of routeBilling for effortless inventory management and streamlined order processing. Take control of your business processes and optimize efficiency starting today! `,
    },
    // {
    //   name: 'software_update_reminder',
    //   value: 'HXe54c2710c371be598121b53317d73c4a',
    //   message: ``,
    // },
    // {
    //   name: 'software_updation_success',
    //   value: 'HX359984a0837fec25278d230feb4e65',
    //   message: `Please Update Software Changes Done. Call Back or Create Ticket (https://geniusoffice.freshdesk.com/support/home)  or call on 8558951962 if you have any query.`,
    // },
  ];
  const [promoMasterhelpStatus, setpromoMessagehelpStatus] = useState(false);
  const [data, setData] = useState({
    CODE: '',
    NAME: '',
    COMPANY_NAME: '',
    CONTACT_PERSON: '',
    MOBILE: '',
    MSG_MOBILE: '',
    EMAIL: '',
    WEBSITE: '',
    ADDRESS: '',
    COUNTRY: '',
    STATE: '',
    CITY: '',
    PIN_CODE: '',
    PRODUCT_NAME: products[0].value,
    SEND_MESSAGE: 'Yes',
    BUSINESS_DETAILS: '',
    REMARKS: '',
    REFERED_BY: '',
    DEFAULT_MESSAGE: templates[0].value,
  });
  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };
  const windowWidth = useSelector(selectWidth);
  const token = useSelector(selectToken);
  useEffect(() => {
    if (selectedCountry) {
      const sCountry = countries.filter(
        (country) => country.name === selectedCountry
      );
      if (sCountry[0]) {
        setStatelist(State.getStatesOfCountry(sCountry[0].isoCode));
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const sState = stateList.filter((state) => state.name === selectedState);
      if (sState[0]) {
        setCitylist(
          City.getCitiesOfState(sState[0].countryCode, sState[0].isoCode)
        );
      }
    }
  }, [selectedState, stateList]);
  const formRef = useRef(null);

  useEffect(() => {
    // Add a keydown event listener to each input and select field
    const inputFields = formRef.current.querySelectorAll('input, select');

    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 3) {
            inputFields[5].focus();
          }
          if (index === 16) {
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  useEffect(() => {
    setData((o) => {
      return {
        ...o,
        COUNTRY: selectedCountry,
        STATE: selectedState,
        CITY: city,
      };
    });
  }, [selectedCountry, selectedState, city]);
  const getCustomerData = () => {
    dispatch(setSpinnerLoading('Loading'));
    const data = {
      CODE: '',
    };
    CallAPI(
      'api/PromoMessageMaster/listPromoMsgMaster',
      data,
      token,
      (res) => {
        if (!res.data.error) {
          setAllCustomerList(res.data.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'something went wrong',
            })
          );
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };
  useEffect(() => {
    getCustomerData();
  }, []);
  return (
    <div
      ref={formRef}
      className="w-full text-sm h-full flex justify-center bg-[#f5f5f7] p-8 overflow-y-scroll"
    >
      <div className="text-sm  rounded-sm  h-full">
        <div className="bg-white   ">
          <ModalHeaderStyle
            header={'Promo Messenger Master'}
            onClose={() => {
              navigate('/');
            }}
          />
        </div>

        <div
          style={{
            height: `calc(100% - 100px)`,
          }}
          className="    bg-white px-5 pt-4  flex flex-col  "
        >
          <div className="flex">
            <div>
              <div className="text-[15px] font-semibold ">Customer Details</div>
              <div className=" mt-2">
                <GeniustextInput
                  type="text"
                  id={'NAME'}
                  search={() => {
                    setpromoMessagehelpStatus(true);
                  }}
                  title={'Customer Name'}
                  backgroundColor={'#f1efeb'}
                  width={'290px'}
                  max={100}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        NAME: toProperCase(e.target.value),
                      };
                    });
                  }}
                  value={data.NAME}
                />
                <GeniustextInput
                  title={'Product Name'}
                  backgroundColor={'#f1efeb'}
                  width={'290px'}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        PRODUCT_NAME: e.target.value,
                      };
                    });

                    const isMobileExits = allList.filter(
                      (r) =>
                        r.MSG_MOBILE === data.MSG_MOBILE &&
                        r.PRODUCT_NAME === e.target.value
                    );
                    if (isMobileExits[0]) {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Whatsapp Number is already exist in this product.',
                        })
                      );
                      setData((old) => {
                        return {
                          ...old,
                          MSG_MOBILE: '',
                        };
                      });
                    }
                  }}
                  value={data.PRODUCT_NAME}
                  option={products}
                />
                <GeniustextInput
                  type="text"
                  max={100}
                  title={'Company Name'}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        COMPANY_NAME: toProperCase(e.target.value),
                      };
                    });
                  }}
                  id={'COMPANY_NAME'}
                  value={data.COMPANY_NAME}
                  backgroundColor={'#f1efeb'}
                  width={'290px'}
                  align={'left'}
                />

                <GeniustextInput
                  type="text"
                  id={'CONTACT_PERSON'}
                  max={100}
                  title={'Contact Person'}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        CONTACT_PERSON: toProperCase(e.target.value),
                      };
                    });
                  }}
                  value={data.CONTACT_PERSON}
                  backgroundColor={'#f1efeb'}
                  width={'290px'}
                />
                <div className="flex ">
                  <GeniustextInput
                    type="number"
                    error={'mobile'}
                    id={'MOBILE'}
                    max={15}
                    hideError={true}
                    title={'Mobile No.'}
                    onChange={(e) => {
                      setData((old) => {
                        return {
                          ...old,
                          MOBILE: e.target.value,
                        };
                      });
                    }}
                    value={data.MOBILE}
                    backgroundColor={'#f1efeb'}
                    width={'176px'}
                    align={'left'}
                  />
                  <div className="ml-3">
                    <GeniustextInput
                      title={'Send Message'}
                      backgroundColor={'#f1efeb'}
                      width={'100px'}
                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            SEND_MESSAGE: e.target.value,
                          };
                        });
                      }}
                      value={data.SEND_MESSAGE}
                      option={[
                        {
                          name: 'Yes',
                          value: 'Yes',
                        },
                        {
                          name: 'No',
                          value: 'No',
                        },
                      ]}
                    />
                  </div>
                </div>

                <div className="flex ">
                  <div className="flex-1]">
                    <div>Message Mobile No.</div>
                    <PhoneInput
                      country={'in'} // Default country
                      containerStyle={{
                        backgroundColor: 'pink',
                        width: '200px',
                        marginTop: '5px',
                      }}
                      inputStyle={{
                        width: '290px',
                        backgroundColor: '#f1efeb',
                        height: '40px',
                      }}
                      onChange={(e) => {
                        const mobile = e.includes('+') ? e : `+${e}`;
                        if (data.PRODUCT_NAME) {
                          const isMobileExits = allList.filter(
                            (r) =>
                              r.MSG_MOBILE === e &&
                              r.PRODUCT_NAME === data.PRODUCT_NAME
                          );
                          if (!isMobileExits[0]) {
                            setData((old) => {
                              return {
                                ...old,
                                MSG_MOBILE: mobile,
                              };
                            });
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Number already exist',
                              })
                            );
                          }
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'Please select Product Name',
                            })
                          );
                        }
                      }}
                      value={data.MSG_MOBILE}
                      enableSearch={true} // Enable search for countries
                      disableSearchIcon={true} // Optional: disable the search icon
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="ml-4">
              <div className="text-[15px] font-semibold ">
                Customer Contact Details
              </div>
              <div className="mt-2">
                <GeniustextInput
                  type="text"
                  title={'Email'}
                  error={'email'}
                  max={100}
                  id={'EMAIL'}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        EMAIL: e.target.value,
                      };
                    });
                  }}
                  value={data.EMAIL}
                  backgroundColor={'#f1efeb'}
                  width={'290px'}
                />
                <GeniustextInput
                  type="text"
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        WEBSITE: e.target.value,
                      };
                    });
                  }}
                  max={30}
                  id={'WEBSITE'}
                  value={data.WEBSITE}
                  title={'Website'}
                  backgroundColor={'#f1efeb'}
                  width={'290px'}
                />

                <GeniustextInput
                  type="text"
                  title={'Address'}
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        ADDRESS: e.target.value,
                      };
                    });
                  }}
                  max={100}
                  id={'ADDRESS'}
                  value={data.ADDRESS}
                  backgroundColor={'#f1efeb'}
                  width={'408px'}
                  align={'left'}
                />
                <div className="flex">
                  <GeniustextInput
                    title={'Country'}
                    country={true}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                    option={countries}
                    backgroundColor={'#f1efeb'}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'State'}
                      country={true}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                      value={selectedState}
                      backgroundColor={'#f1efeb'}
                      option={stateList}
                      onChange={handleStateChange}
                    />
                  </div>
                </div>
                <div className="flex">
                  <GeniustextInput
                    title={'City'}
                    country={true}
                    width={windowWidth >= 768 ? '200px' : '150px'}
                    value={city}
                    backgroundColor={'#f1efeb'}
                    onChange={(e) => {
                      setCity(e.target.value);
                    }}
                    option={cityList}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      type="text"
                      onChange={(e) => {
                        setData((old) => {
                          return {
                            ...old,
                            PIN_CODE: e.target.value,
                          };
                        });
                      }}
                      max={15}
                      value={data.PIN_CODE}
                      width={windowWidth >= 768 ? '200px' : '150px'}
                      title={'Pin Code'}
                      id={'PIN_CODE'}
                      backgroundColor={'#f1efeb'}
                    />
                  </div>
                </div>
                <div className="flex">
                  <GeniustextInput
                    title={'Bussines Details'}
                    id={'BUSINESS_DETAILS'}
                    width={'408px'}
                    max={100}
                    value={data.BUSINESS_DETAILS}
                    type={'text'}
                    backgroundColor={'#f1efeb'}
                    onChange={(e) => {
                      setData((old) => {
                        return {
                          ...old,
                          BUSINESS_DETAILS: toProperCase(e.target.value),
                        };
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex ">
            <div>
              <GeniustextInput
                id={'REMARKS'}
                width={'288px'}
                textArea={true}
                title={'Remarks'}
                value={data.REMARKS}
                onChange={(e) => {
                  setData((old) => {
                    return {
                      ...old,
                      REMARKS: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className=" ml-5 ">
              <div className="flex ">
                <GeniustextInput
                  type="text"
                  onChange={(e) => {
                    setData((old) => {
                      return {
                        ...old,
                        REFERED_BY: e.target.value,
                      };
                    });
                  }}
                  max={15}
                  value={data.REFERED_BY}
                  width={windowWidth >= 768 ? '200px' : '150px'}
                  title={'Referred by'}
                  id={'REFERED_BY'}
                  backgroundColor={'#f1efeb'}
                />
              </div>
              <div className="flex h-fit ">
                {data.CODE ? (
                  <ModifyButton
                    onClick={() => {
                      if (data.NAME) {
                        if (data.COMPANY_NAME) {
                          if (data.CONTACT_PERSON) {
                            if (data.MOBILE) {
                              if (data.MSG_MOBILE) {
                                if (data.PIN_CODE) {
                                  PromoMasterSave(
                                    data,
                                    token,
                                    dispatch,
                                    setData,
                                    products,
                                    getCustomerData,
                                    formRef
                                  );
                                } else {
                                  const inputFields =
                                    formRef.current.querySelectorAll(
                                      'input, select'
                                    );

                                  inputFields.forEach((field) => {
                                    if (field.id === 'PIN_CODE') {
                                      field.focus();
                                    }
                                  });

                                  dispatch(
                                    setAlertWithTitle({
                                      title: 'Alert',
                                      msg: 'Please enter pincode',
                                    })
                                  );
                                }
                              } else {
                                const inputFields =
                                  formRef.current.querySelectorAll(
                                    'input, select'
                                  );
                                inputFields.forEach((field) => {
                                  if (field.id === 'MSG_MOBILE') {
                                    field.focus();
                                  }
                                });
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert',
                                    msg: 'Please enter message mobile',
                                  })
                                );
                              }
                            } else {
                              const inputFields =
                                formRef.current.querySelectorAll(
                                  'input, select'
                                );
                              inputFields.forEach((field) => {
                                if (field.id === 'MOBILE') {
                                  field.focus();
                                }
                              });
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Alert',
                                  msg: 'Please enter mobile',
                                })
                              );
                            }
                          } else {
                            const inputFields =
                              formRef.current.querySelectorAll('input, select');
                            inputFields.forEach((field) => {
                              if (field.id === 'CONTACT_PERSON') {
                                field.focus();
                              }
                            });
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Please enter contact person',
                              })
                            );
                          }
                        } else {
                          const inputFields =
                            formRef.current.querySelectorAll('input, select');
                          inputFields.forEach((field) => {
                            if (field.id === 'COMPANY_NAME') {
                              field.focus();
                            }
                          });
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'Please enter Company Name',
                            })
                          );
                        }
                      } else {
                        const inputFields =
                          formRef.current.querySelectorAll('input, select');
                        inputFields.forEach((field) => {
                          if (field.id === 'NAME') {
                            field.focus();
                          }
                        });
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: 'Please enter customer name',
                          })
                        );
                      }
                    }}
                  />
                ) : (
                  <SaveButton
                    onClick={() => {
                      if (data.PRODUCT_NAME) {
                        if (data.NAME) {
                          if (data.COMPANY_NAME) {
                            if (data.CONTACT_PERSON) {
                              if (data.MOBILE) {
                                if (data.MSG_MOBILE) {
                                  if (data.PIN_CODE) {
                                    PromoMasterSave(
                                      data,
                                      token,
                                      dispatch,
                                      setData,
                                      products,
                                      getCustomerData,
                                      formRef
                                    );
                                  } else {
                                    const inputFields =
                                      formRef.current.querySelectorAll(
                                        'input, select'
                                      );

                                    inputFields.forEach((field) => {
                                      if (field.id === 'PIN_CODE') {
                                        field.focus();
                                      }
                                    });

                                    dispatch(
                                      setAlertWithTitle({
                                        title: 'Alert',
                                        msg: 'Please enter Pincode',
                                      })
                                    );
                                  }
                                } else {
                                  const inputFields =
                                    formRef.current.querySelectorAll(
                                      'input, select'
                                    );
                                  inputFields.forEach((field) => {
                                    if (field.id === 'MSG_MOBILE') {
                                      field.focus();
                                    }
                                  });
                                  dispatch(
                                    setAlertWithTitle({
                                      title: 'Alert',
                                      msg: 'Please enter Message Mobile No.',
                                    })
                                  );
                                }
                              } else {
                                const inputFields =
                                  formRef.current.querySelectorAll(
                                    'input, select'
                                  );
                                inputFields.forEach((field) => {
                                  if (field.id === 'MOBILE') {
                                    field.focus();
                                  }
                                });
                                dispatch(
                                  setAlertWithTitle({
                                    title: 'Alert',
                                    msg: 'Please enter Mobile',
                                  })
                                );
                              }
                            } else {
                              const inputFields =
                                formRef.current.querySelectorAll(
                                  'input, select'
                                );
                              inputFields.forEach((field) => {
                                if (field.id === 'CONTACT_PERSON') {
                                  field.focus();
                                }
                              });
                              dispatch(
                                setAlertWithTitle({
                                  title: 'Alert',
                                  msg: 'Please enter Contact Person',
                                })
                              );
                            }
                          } else {
                            const inputFields =
                              formRef.current.querySelectorAll('input, select');
                            inputFields.forEach((field) => {
                              if (field.id === 'COMPANY_NAME') {
                                field.focus();
                              }
                            });
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Please enter Company Name',
                              })
                            );
                          }
                        } else {
                          const inputFields =
                            formRef.current.querySelectorAll('input, select');
                          inputFields.forEach((field) => {
                            if (field.id === 'NAME') {
                              field.focus();
                            }
                          });
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'Please enter Customer Name',
                            })
                          );
                        }
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: 'Please select Product Name',
                          })
                        );
                      }
                    }}
                  />
                )}
                <UndoButton
                  onClick={() => {
                    setData({
                      CODE: '',
                      NAME: '',
                      COMPANY_NAME: '',
                      CONTACT_PERSON: '',
                      MOBILE: '',
                      MSG_MOBILE: '',
                      EMAIL: '',
                      WEBSITE: '',
                      ADDRESS: '',
                      COUNTRY: '',
                      STATE: '',
                      CITY: '',
                      PIN_CODE: '',
                      PRODUCT_NAME: products[0].value,
                      SEND_MESSAGE: 'Yes',
                    });
                  }}
                />
              </div>
            </div>
          </div>

          <PromoMessagehelp
            handleClose={() => {
              setpromoMessagehelpStatus(false);
            }}
            onRowClick={(e) => {
              const item = e.row;
              setData({
                CODE: item.CODE,
                NAME: item.NAME,
                COMPANY_NAME: item.COMPANY_NAME,
                CONTACT_PERSON: item.CONTACT_PERSON,
                MOBILE: item.MOBILE,
                MSG_MOBILE: item.MSG_MOBILE,
                EMAIL: item.EMAIL,
                WEBSITE: item.WEBSITE,
                ADDRESS: item.ADDRESS,
                COUNTRY: item.COUNTRY,
                STATE: item.STATE,
                CITY: item.CITY,
                PIN_CODE: item.PIN_CODE,
                PRODUCT_NAME: item.PRODUCT_NAME,
                SEND_MESSAGE: item.SEND_MESSAGE,
                BUSINESS_DETAILS: item.BUSINESS_DETAILS,
                REMARKS: item.REMARKS,
                REFERED_BY: item.REFERED_BY,
                DEFAULT_MESSAGE: item.DEFAULT_MESSAGE,
              });
              setpromoMessagehelpStatus(false);
            }}
            status={promoMasterhelpStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default PromoMessangerMaster;
