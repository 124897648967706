import React from 'react';

import Modal from '@mui/material/Modal';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import PartyWiseDiscount from './partyWiseDiscount';
import { useState } from 'react';
import ModalHeaderStyle from '../styles/modalHeader';
import ModalBody from '../styles/modalBody';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 24,
};

export default function ModPartyWiseDisount({
  setStatus,
  status,
  stationId,
  stationName,
  setLess,
  date,
}) {
  const [list, setlist] = useState([]);

  const handleClose = () => {
    setStatus(false);
    setLess(
      list.reduce((total, row) => Number(total) + Number(row.DISCOUNT), 0)
    );
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={'rounded-lg overflow-hidden '}>
          <div className="w-full flex flex-col bg-white h-full ">
            <ModalHeaderStyle
              header={'Party Wise Discount'}
              onClose={handleClose}
            />

            <div className="flex-grow  bg-white    border-brown">
              <ModalBody>
                <PartyWiseDiscount
                  stationId={stationId}
                  stationName={stationName}
                  setStatus={setStatus}
                  setLess={setLess}
                  list={list}
                  setlist={setlist}
                  date={date}
                />
              </ModalBody>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
