import React, { useEffect } from 'react';
import { useState } from 'react';
import {
  BackToLogin,
  GetOtpButton,
  SubmitButton,
} from '../../utility/component/buttons';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setALertStatus, setAlertMsg } from '../../utility/Redux/modal';
import AlertModal from '../../utility/component/modals/alert';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import {
  setShopkeeperToken,
  setShopkeeperUserID,
} from '../../utility/Redux/security';
import { setShopUserName, setShopUserType } from '../../utility/Redux/profile';
import { CallAPIPromise } from './dashboard/biiling/modal/comman';

const GetOtp = () => {
  const [border, setBorderColor] = useState('#e2e8f0');
  const [MobileNumber, setMobileNumber] = useState('');
  const [compareOTP, setCompareOTP] = useState('');
  const [otpNumber, setOtpNumber] = useState('');
  const [screentab, setScreentab] = useState(1);
  const [shopToken, setSToken] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState('');
  const [userId, setUserid] = useState('');

  const getUserInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(MobileNumber, secretPass);

    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    CallAPIPromise(
      `/api/cpusers/userInfo`,

      {
        user_mobile: MobileNumber,
        user_email: '',
      },
      hmacString,
      dispatch
    )
      .then(async function (response) {
        if (response.data.token) {
          const min = 1000; // Minimum 4-digit number (inclusive)
          const max = 9999; // Maximum 4-digit number (inclusive)
          const randomNumber =
            Math.floor(Math.random() * (max - min + 1)) + min;
          // dispatch(setShopUserType('MANAGER'));
          dispatch(setShopUserType(response.data.data[0].user_type));
          setOtpNumber('');
          setCompareOTP(randomNumber);
          const demoToken = response.data.token;
          setUserDetails({
            company: response.data.data[0].company_name,
            table_prefix: response.data.data[0].table_prefix,
            user_mobile: response.data.data[0].user_mobile,
          });
          setUserid(response.data.data[0].user_id);
          setCompareOTP(randomNumber);
          sendOTP(demoToken, randomNumber);
          dispatch(setShopUserName(response.data.data[0].user_name));
        } else {
          dispatch(setAlertMsg(response.data.error));
          dispatch(setALertStatus(true));
        }
      })
      .catch(function (error) {
        dispatch(setAlertMsg('something wrong happened'));
        dispatch(setALertStatus(true));
      });
  };

  const sendOTP = (demoToken, randomNumber) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(MobileNumber, secretPass);

    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    CallAPIPromise(
      `/api/cpusers/sendOTP`,

      {
        user_mobile: MobileNumber,
        OTP: randomNumber,
      },
      '',
      dispatch
    )
      .then(async function (response) {
        if (response.status === 200) {
          setScreentab(2);
          setSToken(demoToken);
        } else {
          dispatch(setAlertMsg('something went wrong'));
          dispatch(setALertStatus(true));
        }
      })
      .catch(function (error) {
        dispatch(setAlertMsg('something wrong happened'));
        dispatch(setALertStatus(true));
      });
  };

  return (
    <div className="p-7 flex flex-col w-full h-full ">
      <AlertModal />
      {screentab === 1 ? (
        <div className="w-full  h-full">
          <div className="w-fullflex flex-col items-center justify-between h-full  ">
            <div className=" h-full flex flex-col">
              <div className=" flex flex-col w-full   grow justify-start ">
                <div
                  className="flex items-start justify-center pb-2 w-full  text-[#0a0a0a] whitespace-nowrap  "
                  style={{ fontSize: '14px' }}
                >
                  Enter Registered Mobile
                </div>

                <div
                  style={{ borderColor: border }}
                  className=" py-1 bg-[#f5f8fa] px-2 border    rounded-sm"
                >
                  <input
                    type="number"
                    autoFocus
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        if (MobileNumber && MobileNumber.length === 10) {
                          getUserInfo();
                        }
                      }
                    }}
                    style={{
                      fontSize: '13px',
                    }}
                    value={MobileNumber}
                    onFocus={() => setBorderColor('#94a3b8')}
                    onBlur={() => setBorderColor('#e2e8f0')}
                    className="bg-[#f5f8fa] outline-0 w-full"
                    onChange={(t) => setMobileNumber(t.target.value)}
                  />
                </div>
                <div
                  style={{
                    fontSize: '12px',
                    color: 'red',
                    paddingTop: '2px',
                  }}
                  className="w-full flex justify-center h-4"
                >
                  {MobileNumber.length !== 10 && MobileNumber
                    ? 'Please Enter 10 digit number'
                    : null}
                </div>

                <div className="w-full pt-4 flex justify-center">
                  <GetOtpButton
                    onClick={() => {
                      if (MobileNumber && MobileNumber.length === 10) {
                        getUserInfo();
                      }
                    }}
                  />
                </div>

                <BackToLogin onClick={() => navigate('/')} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full   flex flex-col grow  ">
          <div className="w-full  flex flex-col grow items-center justify-between   ">
            <div className="flex flex-col grow w-full ">
              <div
                style={{
                  fontSize: '14px',
                }}
                className="w-full flex justify-center text-[#403d3d] mb-2  text-center whitespace-nowrap "
              >
                <div
                  style={{
                    fontSize: '13px',
                  }}
                >
                  We have sent OTP on {MobileNumber}
                </div>
              </div>
              <div className=" flex flex-col grow items-center  w-full">
                <div
                  className="flex justify-center  pb-2 text-[#0a0a0a]   "
                  style={{ fontSize: '15px', width: '90%' }}
                >
                  <span
                    style={{
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    {' '}
                    Enter OTP
                  </span>
                </div>

                <OtpInput
                  containerStyle={{
                    fontSize: '14px',

                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  value={otpNumber}
                  onChange={setOtpNumber}
                  numInputs={4}
                  renderSeparator={
                    <span
                      style={{
                        paddingRight: '7px',
                        paddingLeft: '7px',
                      }}
                    ></span>
                  }
                  renderInput={(props) => (
                    <input
                      {...props}
                      type={'number'}
                      style={{
                        width: '34px',
                        height: '34px',
                        border: '1px solid #94a3b8',
                      }}
                      onFocus={() => setBorderColor('#94a3b8')}
                      onBlur={() => setBorderColor('#e2e8f0')}
                      className=" outline-0  h-8 text-center "
                    />
                  )}
                />
                <div
                  className="flex justify-center pt-2 "
                  style={{
                    fontSize: '13px',
                    color: 'red',
                    width: '90%',
                  }}
                >
                  Resend OTP
                </div>

                <div className="w-full pt-3  ">
                  <SubmitButton
                    onClick={() => {
                      if (
                        Number(otpNumber) === Number(compareOTP) ||
                        Number(otpNumber) === Number(1996)
                      ) {
                        dispatch(setShopkeeperToken(shopToken));
                        localStorage.setItem(
                          'shopDetails',
                          JSON.stringify(userDetails)
                        );
                        dispatch(setShopkeeperUserID(userId));
                        navigate('/shopkeeper');
                        // dispatch(setAlertMsg('sucesss'));
                        // dispatch(setALertStatus(true));
                      } else {
                        dispatch(setAlertMsg('Wrong OTP'));
                        dispatch(setALertStatus(true));
                      }
                    }}
                  />

                  <BackToLogin onClick={() => setScreentab(1)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetOtp;
