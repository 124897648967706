import React from 'react'
import { useNavigate } from 'react-router-dom'
import Lottie from "lottie-react";
import loadLottie from '../utility/json/not_found.json'

const NotFound = () => {
    const move = useNavigate()
  return (
    <div className='h-screen w-screen flex justify-center items-center'>
    <Lottie onClick={()=>move('/auth/login')} animationData={loadLottie} loop={true} style={{height:'autok',width:window.innerWidth}} />
    </div>
  )
}

export default NotFound
