import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Add, Close, Remove } from '@mui/icons-material';
import { OkNumericButton } from '../../utility/component/buttons';

import { Button } from '@mui/material';
import {
  selectIssueItem,
  selectNumerickeyboardType,
  setnumericKeyboardStatus,
} from '../../utility/Redux/modal';
import { useEffect } from 'react';
import { selectPrefix } from '../../utility/Redux/profile';
import { selectToken } from '../../utility/Redux/security';

const GeniusKeyboard = ({ onChange, editType, onSave }) => {
  const defaultQnty = [
    '7',
    '8',
    '9',
    '4',
    '5',
    '6',
    '1',
    '2',
    '3',
    '.',
    '0',
    'Clear',
  ];
  const numericType = useSelector(selectNumerickeyboardType);
  const issueItemDetails = useSelector(selectIssueItem);
  const token = useSelector(selectToken);
  const [text, setText] = useState('0');
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);

  useEffect(() => {
    if (numericType === 'IQNTY') {
      setText(issueItemDetails.IQNTY);
    } else {
      if (numericType === 'RQNTY') {
        setText(issueItemDetails.RQNTY);
      } else {
        if (numericType === 'SALE_PRICE') {
          setText(issueItemDetails.SALE_PRICE);
        }
      }
    }
  }, []);

  return (
    <div className=" bg-white ">
      <div className="flex w-full justify-end">
        <button
          onClick={() => {
            dispatch(setnumericKeyboardStatus(false));
          }}
          style={{
            borderLeft: '1px solid black',
            borderBottom: '1px solid black',
          }}
          className="rounded-bl-sm "
        >
          <Close
            style={{
              color: 'white',
            }}
          />
        </button>
      </div>
      <div className="w-full flex justify-center items-center text-red-500 font-semibold">
        {numericType === 'IQNTY' ||
        numericType === 'RQNTY' ||
        numericType === 'SALE_PRICE'
          ? issueItemDetails.ITEMNAME
          : null}
      </div>

      <div className=" flex w-full justify-center px-5 py-3">
        <div className="flex items-center w-full  rounded-sm  ">
          <div className=" items-center px-2  rounded-sm   hover:cursor-pointer font-semibold">
            <Remove
              fontSize="medium"
              color={'secondary'}
              onClick={() => {
                setText((p) => {
                  if (Number(p) > 0) {
                    const t = Number(p) - Number(1);
                    return t.toString();
                  } else {
                    return p.toString();
                  }
                });
              }}
            />
          </div>
          <Button
            style={{
              width: '100%',
              backgroundColor: '#fefefe',
              boxShadow: '0px 0px 1px #5A5858',
              padding: '0px',
            }}
          >
            {' '}
            <div className=" px-2 py-1 text-[20px] justify-center flex w-full   text-center text-black">
              {text}{' '}
            </div>
          </Button>
          <div className=" border-slate-200 px-2 rounded-sm  hover:cursor-pointer">
            <Add
              fontSize="medium"
              color={'secondary'}
              onClick={() => {
                setText((p) => {
                  const t = Number(p) + Number(1);
                  return t.toString();
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3 px-5  ">
        {defaultQnty.map((item, index) => {
          return (
            <div key={index} className="w-full">
              {item !== 'Clear' && (
                <Button
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    width: '100%',
                    padding: '0px',
                  }}
                  onClick={() => {
                    setText((p) => {
                      if (p === '0') {
                        return item;
                      } else {
                        return p + item;
                      }
                    });
                  }}
                >
                  <div className=" px-10 py-1  justify-between flex w-full text-center">
                    <div className="text-center  w-full text-[23px]">
                      {' '}
                      {item}{' '}
                    </div>
                  </div>
                </Button>
              )}
              {item === 'Clear' && (
                <Button
                  style={{
                    backgroundColor: 'white',
                    boxShadow: '0px 0px 1px #5A5858',
                    height: '100%',

                    padding: '0px',
                    textTransform: 'none',
                    textDecoration: 'none',
                  }}
                  onClick={() => {
                    setText((p) => {
                      if (p !== '0') {
                        if (p.toString().length === 1) {
                          return '0';
                        } else {
                          return p.toString().slice(0, -1);
                        }
                      } else {
                        return '0';
                      }
                    });
                  }}
                >
                  <div className=" px-10 py-1  justify-between flex w-full text-center">
                    <div className="text-center  w-full text-[18px] text-red-500">
                      {' '}
                      {item}{' '}
                    </div>
                  </div>
                </Button>
              )}
            </div>
          );
        })}
      </div>
      <div className="w-full flex justify-center py-5 px-5">
        <OkNumericButton
          onClick={() => {
            dispatch(setnumericKeyboardStatus(false));
            onChange({
              field: editType,
              value: text,
              id: issueItemDetails.ICODE,
            });
          }}
        />
      </div>
    </div>
  );
};

export default GeniusKeyboard;
