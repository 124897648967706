import React from 'react';
import { FormatNumber2 } from '../../../../utility/component/tools';
import {
  SelectBillingDiscount,
  setBillingDiscount,
  setBillingNetAmount,
} from '../../../../utility/Redux/tableOrder';
import { setALertStatus, setAlertMsg } from '../../../../utility/Redux/modal';
import { useSelector } from 'react-redux';

export const BillingCalculation = ({
  TotalQnty,
  totalAmount,
  defaultProfile,
  dispatch,
  NetAmount,
  prevBal,
}) => {
  const selectDiscountedAmount = useSelector(SelectBillingDiscount);
  return (
    <div>
      <div
        style={
          window.innerWidth <= 764
            ? {
                fontSize: '11px',
              }
            : {
                fontSize: '12px',
              }
        }
        className="flex w-full "
      >
        <div
          style={{
            borderTop: '1px dotted #bab5ad',

            borderLeft: '1px dotted #bab5ad',

            width: '25%',
          }}
          className="p-1"
        >
          Qnty
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',

            borderLeft: '1px dotted #bab5ad',

            width: '25%',
          }}
          className="p-1"
        >
          {TotalQnty}
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',

            borderLeft: '1px dotted #bab5ad',

            flex: 1,
            textAlign: 'right',
          }}
          className="p-1"
        >
          Total
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',
            borderRight: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',
            width: '25%',
            textAlign: 'right',
          }}
          className="p-1"
        >
          {FormatNumber2(totalAmount)}
        </div>
      </div>

      <div
        style={
          window.innerWidth <= 764
            ? {
                fontSize: '11px',
              }
            : {
                fontSize: '12px',
              }
        }
        className="flex w-full"
      >
        <div
          style={{
            borderTop: '1px dotted #bab5ad',
            borderBottom: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',
            width: '25%',
            color: 'orangered',

            borderBottomLeftRadius: '3px',
          }}
          className="p-1  font-semibold"
        >
          Discount
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',
            borderBottom: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',

            width: '25%',
          }}
          className="p-1"
        >
          <input
            type={'number'}
            className="outline-0 w-full"
            readOnly={defaultProfile?.allow_discount === 'NO' ? true : false}
            onChange={(t) => {
              if (t.target.value <= totalAmount) {
                dispatch(setBillingDiscount(t.target.value));
                dispatch(setBillingNetAmount(totalAmount - t.target.value));
              } else {
                dispatch(
                  setAlertMsg(
                    'Discount amount cannot be greater than the total amount.'
                  )
                );
                dispatch(setALertStatus(true));
              }
            }}
            value={selectDiscountedAmount}
          />
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',
            borderBottom: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',

            flex: 1,

            textAlign: 'right',
          }}
          className="p-1 hidden md:flex md:flex-col"
        >
          Prev. Balance
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',
            borderBottom: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',
            flex: 1,
            textAlign: 'right',
          }}
          className="p-1 md:hidden whitespace-nowrap "
        >
          Prv. Bal.
        </div>

        <div
          style={{
            borderTop: '1px dotted #bab5ad',
            borderRight: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',
            borderBottom: '1px dotted #bab5ad',
            width: '25%',

            textAlign: 'right',
          }}
          className="p-1"
        >
          {prevBal}
        </div>
      </div>

      <div
        style={
          window.innerWidth <= 764
            ? {
                fontSize: '11px',
              }
            : {
                fontSize: '12px',
              }
        }
        className="flex "
      >
        <div
          style={{
            width: '25%',
          }}
          className="p-1"
        ></div>

        <div
          style={{
            width: '25%',

            borderBottomRightRadius: '3px',
          }}
          className="p-1"
        >
          {'  '}
        </div>
        <div
          style={{
            flex: 1,
            textAlign: 'right',

            borderBottom: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',
            fontWeight: 'bold',
            borderBottomLeftRadius: '3px',
          }}
          className="p-1 hidden md:flex md:flex-col whitespace-nowrap"
        >
          Net Amount
        </div>

        <div
          style={{
            borderBottom: '1px dotted #bab5ad',
            borderLeft: '1px dotted #bab5ad',

            flex: 1,
            textAlign: 'right',

            fontWeight: 'bold',
            borderBottomLeftRadius: '3px',
          }}
          className="p-1 md:hidden whitespace-nowrap"
        >
          Net Amt.
        </div>

        <div
          style={{
            borderBottom: '1px dotted #bab5ad',

            borderLeft: '1px dotted #bab5ad',
            borderRight: '1px dotted #bab5ad',
            fontWeight: 'bold',
            borderBottomRightRadius: '4px',
            width: '25%',
            textAlign: 'right',
          }}
          className="p-1"
        >
          {FormatNumber2(Math.round(NetAmount))}
        </div>
      </div>
    </div>
  );
};
