import React from 'react';
import './thermalprint.css';
import ThermalPrintTable from './thermalPrintTable';

const PrintIssueMobileReport = React.forwardRef((props, ref) => {
  const { data, stationId, stationName, date, mobile, truckNo, helperName } =
    props;

  const columns = [
    {
      width: 20,
      accessor: 'ICODE',
      Header: 'Code',
    },
    {
      width: 80,
      accessor: 'ITEMNAME',
      Header: 'Item Name',
    },
    {
      width: 30,
      accessor: 'SALE_PRICE',
      Header: 'Rate',
      Cell: ({ value }) => {
        const formattedValue = Number(value).toFixed(2);
        return <div>{formattedValue}</div>;
      },
    },
    {
      width: 30,
      accessor: '',
      Header: 'Issue',
    },
    {
      width: 30,
      accessor: 'ammount',
      Header: 'Amount',
      Cell: ({ value }) => {
        const formattedValue = Number(value).toFixed(2);
        return <div>{formattedValue}</div>;
      },
    },
  ];

  const companyName = localStorage.getItem('company');

  return (
    <div ref={ref}>
      <div
        id="print-header1"
        // style={{ width: "100%" }}
      >
        <div>
          <p
            className="flex justify-center items-center font-semibold"
            style={{ fontSize: '20px' }}
          >
            {companyName}
          </p>
        </div>

        <div className="flex justify-center items-center">
          <div className="font-semibold mx-1">Date</div> :{' '}
          <div className="mx-1">{date}</div>
        </div>

        <div className="flex justify-center items-center">
          <div className="font-semibold mx-1">Station ID</div> :{' '}
          <div className="mx-1">{stationId}</div> ,{' '}
          <div className="mx-1">{stationName}</div>
        </div>

        {mobile ? (
          <div className="flex justify-center items-center">
            <div className="font-semibold mx-1">Mobile</div> :{' '}
            <div className="mx-1">{mobile}</div>
          </div>
        ) : null}

        {truckNo ? (
          <div className="flex justify-center items-center">
            <div className="font-semibold mx-1">Truck No.</div> :{' '}
            <div className="mx-1">{truckNo}</div>
          </div>
        ) : null}

        {helperName ? (
          <div className="flex justify-center items-center">
            <div className="font-semibold mx-1">Helper Name</div> :{' '}
            <div className=" mx-1">{helperName}</div>
          </div>
        ) : null}
      </div>

      <div
        id="print-component1"
        className="flex justify-center items-center"
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <ThermalPrintTable data={data} columns={columns} />
      </div>
    </div>
  );
});

export default PrintIssueMobileReport;
