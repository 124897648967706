import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useTable } from 'react-table';
import { Pagination } from '@mui/material';
import ExcelExport from './excelComomenent';
const GeniusTableReports = ({
  onRowDoubleClick,
  columns,
  data,
  footer,
  onCellDoubleClick,
  rowBgColor,
  hideRightMargin,
  hideExcel,
  filterBgColor,
  filterStyle,
  cellStyle,
}) => {
  console.log(rowBgColor);
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 100; // Number of items per page
  const startIndex = pageIndex * pageSize;
  const endIndex = (pageIndex + 1) * pageSize;
  const l = data.length / 100;
  const length = Math.floor(l);
  const paginatedData = data.slice(startIndex, endIndex);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: paginatedData });
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);

  const handleDiv1Scroll = () => {
    const scrollTop = div2Ref.current.scrollLeft;
    div1Ref.current.scrollLeft = scrollTop;
    div3Ref.current.scrollLeft = scrollTop;
  };

  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: 'white' }}>
      <div
        ref={div1Ref}
        style={{
          overflowX: 'auto',
          scrollbarWidth: 'none',
          WebkitOverflowScrolling: 'touch',
        }}
      >
        {headerGroups.map((headerGroup) => (
          <tr
            style={{ fontSize: '14px' }}
            className="bg-[#0d0d0d] text-white "
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map((column, index) => (
              <td
                {...column.getHeaderProps()}
                className="whitespace-nowrap  text-center py-2 "
                style={
                  columns[index].width
                    ? {
                        minWidth:
                          index === headerGroup.headers.length - 1
                            ? columns[index].width
                            : columns[index].width,
                        borderRight: '1px solid #888c89',
                      }
                    : {
                        flex: columns[index].flex,
                        fontWeight: 500,
                        borderRight: '1px solid #888c89',
                      }
                }
              >
                {columns[index].renderHeader().props.children}
              </td>
            ))}
          </tr>
        ))}
      </div>

      <div
        style={{
          height: `calc(100% -  ${footer ? 130 : 40}px)`,
        }}
        ref={div3Ref}
        className="    overflow-y-auto overflow-x-hidden  "
      >
        <table
          {...getTableProps()}
          ref={div3Ref}
          className=" border border-t-0"
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                style={{ fontSize: '14px', height: '0px' }}
                className="bg-[#0d0d0d] text-white "
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => (
                  <td
                    {...column.getHeaderProps()}
                    className="whitespace-nowrap  text-center  "
                    style={
                      columns[index].width
                        ? {
                            minWidth:
                              index === headerGroup.headers.length - 1
                                ? columns[index].width
                                : columns[index].width,
                            borderRight: '1px solid #888c89',
                            height: '0px',
                          }
                        : {
                            flex: columns[index].flex,
                            fontWeight: 500,
                            borderRight: '1px solid #888c89',
                            height: '0px',
                          }
                    }
                  ></td>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              const bg = rowBgColor
                ? rowBgColor[0]
                  ? filterBgColor
                    ? filterBgColor(row)
                    : rowBgColor.filter((e) => e.INVNO === row.values.INVNO)
                  : []
                : [];
              const bgColor = bg[0]
                ? filterStyle
                  ? filterStyle
                  : { bgColor: '#F2D2D2', text: 'black' }
                : { bgColor: 'white', text: 'black' };

              prepareRow(row);
              return (
                <tr
                  className="hover:cursor-pointer"
                  onDoubleClick={
                    onRowDoubleClick ? () => onRowDoubleClick(row) : null
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, i) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          borderRight: '1px dashed #b5b3b3',

                          borderTop:
                            rowIndex === 0
                              ? '0 dashed #b5b3b3'
                              : '1px dashed #b5b3b3',
                          borderBottom: '1px dashed #b5b3b3',
                          maxWidth: cell.column.width,
                          fontSize: '14px',
                        }}
                        onDoubleClick={() => {
                          if (onCellDoubleClick) {
                            onCellDoubleClick({
                              ...cell,
                              row: {
                                ...cell.row,
                                values: data[rowIndex],
                              },
                            });
                          }
                        }}
                      >
                        <div
                          style={{
                            textAlign: cell.render('Cell').props.cell.column
                              .align
                              ? cell.render('Cell').props.cell.column.align
                              : 'left',
                            backgroundColor: cellStyle
                              ? cellStyle.col ===
                                cell.render('Cell').props.cell.column.field
                                ? cellStyle.bgColor
                                : bgColor.bgColor
                              : bgColor.bgColor,
                            color: bgColor.text,
                          }}
                          className="whitespace-nowrap p-2 h-8 overflow-hidden"
                        >
                          <div
                            style={{
                              marginRight: hideRightMargin
                                ? 0
                                : i === row.cells.length - 1
                                ? '15px'
                                : 0,
                            }}
                          >
                            {cell.render('Cell').props.cell.column.type ===
                            'date'
                              ? moment(
                                  cell.render('Cell').props.cell.value
                                ).format('YYYY-MM-DD')
                              : cell.render('Cell').props.cell.value ===
                                  '0.00' ||
                                cell.render('Cell').props.cell.value === 0 ||
                                cell.render('Cell').props.cell.value === '0'
                              ? ' '
                              : cell.column.renderCell
                              ? cell.column.renderCell(
                                  cell.render('Cell').props.cell
                                )
                              : cell.render('Cell')}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="flex justify-end py-2">
        <Pagination
          onChange={(e, v) => {
            setPageIndex(v);
          }}
          count={length}
          shape="rounded"
        />
      </div>

      <div
        onScroll={handleDiv1Scroll}
        className="w-full bg"
        style={{
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
        ref={div2Ref}
      >
        {footer ? footer : ''}
      </div>
      {!hideExcel && (
        <div className="flex justify-end p-1 mt-2">
          <ExcelExport columns={columns} data={data} />
        </div>
      )}
    </div>
  );
};

export default GeniusTableReports;
