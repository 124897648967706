import React, { useState } from 'react';
import GeniusSelectDate from '../../../utility/component/selectDate';
import moment from 'moment';
import GeniusDatagrid from '../../../utility/component/datagrid/geniusDatagrid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../../../utility/Redux/profile';
import { selectToken } from '../../../utility/Redux/security';
import { GetEmployeeSummary } from './functions';
import {
  ManfSumColumns,
  ManfSecColumns,
  MfgSumaryColumns,
  ManfItemColumns,
} from './columns';
import { GetSecSummary } from './getMfgSecSummary';
import { GetMfgSummary } from './getMfgSummary';
import { GetItemSummary } from './getItemSumary';
import { Close } from '@mui/icons-material';
import GeniusReportsDate from '../../../utility/component/reportDate';

const ManufacturingSummary = () => {
  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDateFocus, setToDateFocus] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [fromDate2, setFromDate2] = useState(moment().format('YYYY-MM-DD'));
  const [fromDateFocus2, setFromDateFocus2] = useState(false);
  const [toDate2, setToDate2] = useState(moment().format('YYYY-MM-DD'));
  const [toDateFocus2, setToDateFocus2] = useState(false);
  const [sectionName, setSectionName] = useState('');
  const [employeeList, setEmployeelist] = useState([]);
  const [seclist, setSectlist] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [mfgSummary, setMfgSummary] = useState([]);

  const windowWidth = useSelector(selectWidth);

  const columns = ManfSumColumns(windowWidth);
  const secColumns = ManfSecColumns(windowWidth);
  const summaryColumns = MfgSumaryColumns(windowWidth);
  const ItemColumns = ManfItemColumns(windowWidth);
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const getEmployeeData = () => {
    GetEmployeeSummary(
      setEmployeelist,
      prefix,
      token,
      dispatch,
      fromDate,
      toDate
    ).then(() => {
      GetMfgSummary(
        setMfgSummary,
        prefix,
        token,
        dispatch,
        fromDate,
        toDate,
        ''
      );
    });
  };
  const getSecData = () => {
    GetSecSummary(
      setSectlist,
      prefix,
      token,
      dispatch,
      fromDate2,
      toDate2
    ).then(() => {
      GetItemSummary(
        setItemList,
        prefix,
        token,
        dispatch,
        fromDate2,
        toDate2,
        ''
      );
    });
  };

  return (
    <div className="w-full   bg-gray-50 h-full  p-3 ">
      <div className="h-full  p-2 text-sm ">
        <div
          style={{
            border: '1px solid #b5b3b3',
            width:
              columns.reduce((acc, row) => acc + Number(row.width), 0) +
              summaryColumns.reduce((acc, row) => acc + Number(row.width), 0) +
              14,
          }}
          className="p-1 py-3 rounded-sm flex items-center "
        >
          <div className="px-1 font-semibold">From :</div>

          <GeniusReportsDate
            date={fromDate}
            onChange={(d) => {
              setFromDate(moment(d).format('YYYY-MM-DD'));
              setFromDateFocus(false);
            }}
            setDate={setFromDate}
            dateFocus={fromDateFocus}
            SetDateFocus={setFromDateFocus}
          />
          <div className="px-1 pl-2 font-semibold">To :</div>
          <GeniusReportsDate
            date={toDate}
            setDate={setToDate}
            dateFocus={toDateFocus}
            onChange={(d) => {
              setToDate(moment(d).format('YYYY-MM-DD'));
              setToDateFocus(false);
            }}
            SetDateFocus={setToDateFocus}
          />
          <button
            className="flex items-center rounded-sm ml-2"
            onClick={getEmployeeData}
            style={{
              backgroundColor: '#c1c1c1',
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 4,
              paddingBottom: 4,
              color: 'black',
            }}
          >
            <div className="flex items-center text-sm">Ok</div>
          </button>
          {employeeName && (
            <div className="flex items-center">
              <div className="ml-2 font-semibold"> Employee Name :</div>
              <div className="ml-2 flex items-center ">
                {employeeName && (
                  <>
                    <div
                      style={{
                        border: '1px solid #b5b3b3',
                      }}
                      className="rounded-sm p-1 min-w-10 flex items-center  w-fit"
                    >
                      {' '}
                      <div>{employeeName ? employeeName : ''}</div>
                      <Close
                        onClick={() => {
                          setEmployeeName('');
                          GetMfgSummary(
                            setMfgSummary,
                            prefix,
                            token,
                            dispatch,
                            fromDate,
                            toDate,
                            ''
                          );
                        }}
                        fontSize={'small'}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            height: `calc(100% - 57px)`,
          }}
          className="mt-2 flex flex-col w-full  "
        >
          <div className="flex h-1/2 w-full ">
            <div
              style={{
                width: columns[0].width + columns[1].width + columns[2].width,
              }}
              className="flex h-full "
            >
              <GeniusDatagrid
                list={employeeList}
                rowID={(r) => r.id}
                columns={columns}
                onDoubleClick={(p) => {
                  setEmployeeName(p.row.EMPLOYEE_NAME);
                  GetMfgSummary(
                    setMfgSummary,
                    prefix,
                    token,
                    dispatch,
                    fromDate,
                    toDate,
                    p.row.EMPLOYEE_NAME
                  );
                }}
              />
            </div>
            <div
              style={{
                width:
                  summaryColumns[0].width +
                  summaryColumns[1].width +
                  summaryColumns[2].width +
                  summaryColumns[3].width +
                  summaryColumns[4].width +
                  summaryColumns[5].width +
                  10,
              }}
              className="flex h-full ml-2 "
            >
              <GeniusDatagrid
                list={mfgSummary}
                rowID={(r) => r.id}
                columns={summaryColumns}
              />
            </div>
          </div>

          <div className="w-full py-2">
            <div className="font-semibold pb-2">
              Manufactring - Item Wise Summary
            </div>
            <div
              style={{
                border: '1px solid #b5b3b3',
                width:
                  columns.reduce((acc, row) => acc + Number(row.width), 0) +
                  summaryColumns.reduce(
                    (acc, row) => acc + Number(row.width) + 2,
                    0
                  ) +
                  10,
              }}
              className="p-1 py-3 rounded-sm flex items-center "
            >
              <div className="px-1 font-semibold">From :</div>

              <GeniusReportsDate
                date={fromDate2}
                onChange={(d) => {
                  setFromDate2(moment(d).format('YYYY-MM-DD'));
                  setFromDateFocus2(false);
                }}
                dateFocus={fromDateFocus2}
                SetDateFocus={setFromDateFocus2}
              />
              <div className="px-1 pl-2 font-semibold">To :</div>
              <GeniusReportsDate
                date={toDate2}
                onChange={(d) => {
                  setToDate2(moment(d).format('YYYY-MM-DD'));
                  setToDateFocus2(false);
                }}
                dateFocus={toDateFocus2}
                SetDateFocus={setToDateFocus2}
              />
              <button
                className="flex items-center rounded-sm ml-2"
                onClick={getSecData}
                style={{
                  backgroundColor: '#c1c1c1',
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: 'black',
                }}
              >
                <div className="flex items-center text-sm">Ok</div>
              </button>
              {sectionName && (
                <div className="flex items-center">
                  <div className="ml-2 font-semibold"> Section Name :</div>
                  <div className="ml-2 flex items-center">
                    {sectionName && (
                      <>
                        <div
                          style={{
                            border: '1px solid #b5b3b3',
                          }}
                          className="rounded-sm  flex items-center p-1 min-w-10 w-fit"
                        >
                          {' '}
                          <div>{sectionName ? sectionName : ''}</div>
                          <Close
                            onClick={() => {
                              setSectionName('');
                              GetItemSummary(
                                setItemList,
                                prefix,
                                token,
                                dispatch,
                                fromDate2,
                                toDate2,
                                ''
                              );
                            }}
                            fontSize={'small'}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="flex h-1/2 w-full mt-2">
            <div
              style={{
                width: columns[0].width + columns[1].width + columns[2].width,
              }}
              className="flex h-full"
            >
              <GeniusDatagrid
                list={seclist}
                rowID={(r) => r.id}
                columns={secColumns}
                dataGridHeader={true}
                onDoubleClick={(p) => {
                  setSectionName(p.row.SECTION_NAME);
                  GetItemSummary(
                    setItemList,
                    prefix,
                    token,
                    dispatch,
                    fromDate2,
                    toDate2,
                    p.row.SECTION_NAME
                  );
                }}
              />
            </div>
            <div
              style={{
                width:
                  summaryColumns[0].width +
                  summaryColumns[1].width +
                  summaryColumns[2].width +
                  summaryColumns[3].width +
                  summaryColumns[4].width +
                  summaryColumns[4].width +
                  14,
              }}
              className="flex  h-full ml-2"
            >
              <GeniusDatagrid
                list={itemList}
                rowID={(r) => r.ICODE}
                columns={ItemColumns}
                dataGridHeader={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturingSummary;
