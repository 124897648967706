import { FormatNumber2 } from '../tools';
const width = (window.innerWidth * 1) / 100;

export const DayEndHeaderMobile = (props) => {
  return (
    <div className="  bg-[#f5f0e8]   ">
      <div
        style={{
          borderBottom: '1px solid #ded9d9',
        }}
        className="w-full flex   "
      >
        <div
          style={{}}
          className="flex flex-1 py-1 pl-2 justify-center text-red-600 "
        ></div>
        <div
          style={{
            width: width * 15,

            fontSize: 11,
          }}
          className="flex justify-center py-1 bg-[#e5f1e3] text-[#223D31] "
        >
          Collection
        </div>

        <div
          className="flex justify-center py-1 bg-[#fdfaf6]"
          style={{
            width: width * 15,
            fontWeight: 500,
            fontSize: 11,
          }}
        >
          Expense
        </div>
      </div>

      <div className=" w-full flex   ">
        <div
          className="  text-black  flex justify-center items-center py-[6px]"
          style={{ flex: 1, borderRight: '1px solid #ded9d9' }}
        >
          {'Description'}
        </div>
        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 15 }}
          className="  text-black    flex justify-center items-center "
        >
          <div className="pl-2">{'Date'}</div>
        </div>
        <div
          style={{
            borderRight: '1px solid #ded9d9',

            width: width * 15,
          }}
          className=" flex  text-black    items-center justify-center  "
        >
          <div>{'Pay Mode'}</div>
        </div>
        <div
          style={{
            borderRight: '1px solid #ded9d9',

            width: width * 15,
          }}
          className=" flex  text-black  bg-[#e5f1e3]  items-center justify-center  "
        >
          <div>{'Total Sale'}</div>
        </div>{' '}
        <div
          style={{
            borderRight: '1px solid #ded9d9',

            width: width * 15,
          }}
          className=" flex  text-black     bg-[#fdfaf6] items-center justify-center  "
        >
          <div>{'Expense'}</div>
        </div>{' '}
      </div>
    </div>
  );
};
