const SaveButton = {
  width: '60%',
  backgroundColor: '#c1ea9f',
  borderRadius: '3px',
  paddingTop: '4px',
  paddingBottom: '4px',
  font: 'bold',
  height: '45px',
  fontSize: '15px',
};

const UndoButton = {
  width: '40%',
  backgroundColor: '#616161',
  borderRadius: '3px',
  paddingTop: '4px',
  paddingBottom: '4px',
  font: 'bold',
  height: '45px',
  fontSize: '15px',
  marginLeft: '3px',
  color: 'white',
};

const sectionStyle = {
  width: '100%',
  height: '48px',
  marginTop: '4px',
  borderRadius: '7px',
  textTransform: 'none',
  backgroundColor: '#f5eb82',
  fontSize: '12px',
  marginBottom: '4px',
  color: '#4a4a4a',
  boxShadow: '1px 1px 1px gray',
};

const sectionStyleTab = {
  // width: '80%',
  height: '45px',
  borderRadius: '7px',
  marginRight: '8px',
  textTransform: 'none',
  backgroundColor: '#f5eb82',
  fontSize: '15px',
  color: '#4a4a4a',
};

const sectionStyleMobile = {
  width: '100%',
  height: '32px',
  marginTop: '5px',
  borderRadius: '7px',
  // backgroundColor: '#fadb3f',
  backgroundColor: '#f5eb82',
  fontSize: '9px',
  color: '#4a4a4a',
};

const itemNameStyle = {
  width: '100%',
  height: '70px',
  textTransform: 'none',
  backgroundColor: '#fadb3f',
  borderRadius: '7px',
  color: '#4a4a4a',
  padding: '0px',
  fontSize: '12px',
};

const itemNameStyleMobile = {
  // backgroundColor: '#f5eb82',
  backgroundColor: '#fadb3f',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '32px',
  marginTop: '5px',
  borderRadius: '7px',
  fontSize: '9px',
  color: '#4a4a4a',
};

export {
  SaveButton,
  UndoButton,
  sectionStyle,
  sectionStyleMobile,
  itemNameStyle,
  itemNameStyleMobile,
  sectionStyleTab,
};
