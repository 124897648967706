import React from 'react';
import { isTokenExpired } from './utility/functions/checkToken';
import { useSelector } from 'react-redux';
import { selectToken } from './utility/Redux/security';

const CheckToken = () => {
  const token = useSelector(selectToken);
  isTokenExpired(token);
  return <div>CheckToke</div>;
};

export default CheckToken;
