import React from 'react';
import Modal from '@mui/material/Modal';

import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertMsg,
  setALertStatus,
  setBillSattalmentModalStatus,
  setConfirmSaveBillstatus,
  setSessionExpired,
  setSpinnerLoading,
  setviewBillModalStatus,
  slectConfirmSaveBillStatus,
} from '../../Redux/modal';
import { Button } from '@mui/material';
import logo from '../../images/ico.png';
import {
  clearOrderBillingList,
  SelectBillingDiscount,
  SelectBillSettlementBalance,
  SelectBillSettlementDetails,
  SelectOrdersList,
  setBillingDiscount,
  setBillSettlementDetails,
  setCartItems,
  setViewBillInvoiceId,
} from '../../Redux/tableOrder';
import moment from 'moment';
import {
  selectAccountDetails,
  selectLatitude,
  selectLongitude,
  selectPrefix,
  selectStationId,
  selectStationName,
  setBillingAccountDetails,
} from '../../Redux/profile';
import axios from 'axios';
import { selectToken } from '../../Redux/security';
import { useNavigate } from 'react-router-dom';
import { autoCollectionSaveInBilling } from '../../constant';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

export default function SaveALertBillSettlement({ collectionPaymode }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const status = useSelector(slectConfirmSaveBillStatus);
  const stationId = useSelector(selectStationId);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const handleClose = () => {
    dispatch(setConfirmSaveBillstatus(false));
  };

  const billingItemsList = useSelector(SelectOrdersList);

  const selectDiscountedAmount = useSelector(SelectBillingDiscount);

  const BillSettelmentBalance = useSelector(SelectBillSettlementBalance);
  const billSettelmentDetails = useSelector(SelectBillSettlementDetails);

  const accDetails = useSelector(selectAccountDetails);

  const stationName = useSelector(selectStationName);
  const latitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);

  const NetAmount = Math.round(
    billingItemsList?.reduce((acc, curr) => acc + curr.AMOUNT, 0) -
      selectDiscountedAmount
  );
  const totalAmount =
    billingItemsList?.reduce((acc, curr) => acc + curr.AMOUNT, 0) -
    selectDiscountedAmount;

  const saveVoucher = (response, invo) => {
    dispatch(setSpinnerLoading('Saving voucher'));
    const data = {
      table_prefix: prefix,
      cart_item: [
        {
          VOUCHER_NO: '',

          DATE: moment().format('YYYY-MM-DD : h.mm'),
          PAY_MODE: collectionPaymode,
          PREV_BAl: '',
          CARD_NO: '',
          BANK_NAME: '',
          EPAYMENT_BANK: '',
          ACCOUNT_NO: accDetails.accNumber,
          NAME: accDetails.accName,
          ADDRESS: accDetails.accAddress,
          MOBILE: accDetails.accMobile,
          AMOUNT_RECEIVED: billSettelmentDetails.TOTAL_COLLECTION,
          AMOUNT_PAID: 0,
          AMOUNT_DESC: `Amount Paid by ${collectionPaymode}`,
          INVNO: '0',
          SID: stationId,
          LATITUDE: latitude ? latitude : '38.8951',
          LONGITUDE: longitude ? longitude : '-77.0364',
          APPROVED: 'NO',
          SNAME: stationName,
        },
      ],
    };

    CallAPIPromise('/api/voucherRoute/saveVoucher', data, token, dispatch)
      .then((res) => {
        console.log('voucher saved');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        dispatch(
          setBillSettlementDetails({
            cash: '',
            card: '',
            cardNumber: '',
            coupanAmount: '',
            coupanNumber: '',
            epayment: '',
            epaymentAmount: '',
            btc: '',
            btcName: '',
            disc: '',
            btcMobile: '',
            btcAccNumber: '0',
            btcAddress: '',
            TOTAL_COLLECTION: 0,
          })
        );
        dispatch(clearOrderBillingList());
        navigate('/');
        dispatch(
          setBillingAccountDetails({
            accNumber: '',
            accMobile: '',
            accName: '',
          })
        );
        dispatch(setBillSattalmentModalStatus(false));
        dispatch(setBillingDiscount(''));

        if (response.data.result.affectedRows === 1) {
          dispatch(setviewBillModalStatus(true));
          dispatch(setViewBillInvoiceId(invo));
        } else {
          alert(response.data.error);
          navigate('/');
        }
      });
  };

  const cartItems = billingItemsList.map((data) => {
    const tax1Amt = (data.AMOUNT * data.TAX1_RATE) / 100;
    const tax2Amt = (data.AMOUNT * data.TAX2_RATE) / 100;
    const tax3Amt = (data.AMOUNT * data.TAX3_RATE) / 100;
    return {
      INCL_EXCL: '',
      DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
      BILL_MODE: '',
      BILL_TYPE: '',
      BILL_STATUS: 'Settled',
      ACCOUNT_NO: accDetails.accNumber,
      NAME: accDetails.accName,
      ADDRESS: accDetails.accAddress,
      MOBILE: accDetails.accMobile,
      TAX_NUMBER: '',
      CUSTOMER_CODE: '0',
      CUSTOMER_NAME: '',
      CUSTOMER_ADDRESS: '',
      CUSTOMER_MOBILE: '',
      ICODE: data.ICODE,
      ITEMNAME: data.ITEMNAME,
      PACKING: '',
      UNIT: '',
      SALE_PRICE: data.SALE_PRICE,
      QNTY: data.QNTY,
      AMOUNT: data.AMOUNT,
      DISQ_RT: '0',
      DISQ_AMT: selectDiscountedAmount ? selectDiscountedAmount : 0.0,
      HSN_CODE: data.HSN_CODE,
      TAX1_RATE: data.TAX1_RATE ? data.TAX1_RATE : 0,
      TAX1_AMT: tax1Amt,
      TAX2_RATE: data.TAX2_RATE ? data.TAX2_RATE : 0,
      TAX2_AMT: tax2Amt,
      TAX3_RATE: data.TAX3_RATE ? data.TAX2_RATE : 0,
      TAX3_AMT: tax3Amt,
      BASIC_RATE: data.SALE_PRICE,
      BASIC_AMT: data.AMOUNT,

      TOTAL: Number(totalAmount) + Number(selectDiscountedAmount),
      FREIGHT: '0',
      REMARKS: billSettelmentDetails.disc ? billSettelmentDetails.disc : '',
      ROUND_OFF: '0',
      NET_AMOUNT: NetAmount,
      SALES_MAN: '',
      VEHICLE_NO: '',
      DRIVER_NAME: '',
      DRIVER_MOBILE: '',
      USER: '',
      CESS_RATE: data.CESS_RATE,
      CESS_AMOUNT: '0',
      ORDER_NO: '0',
      CASH_AMOUNT: billSettelmentDetails.cash
        ? billSettelmentDetails.cash
        : 0.0,
      CHEQUE_AMOUNT: '0',
      BANK_NAME: '',
      EPAYMENT: billSettelmentDetails.epaymentAmount
        ? billSettelmentDetails.epaymentAmount
        : 0.0,
      EPAYMENT_BANK: billSettelmentDetails.epayment
        ? billSettelmentDetails.epayment
        : 'None',
      CARD_AMOUNT: billSettelmentDetails.card
        ? billSettelmentDetails.card
        : 0.0,
      CARD_NO: billSettelmentDetails.cardNumber
        ? billSettelmentDetails.cardNumber
        : 0.0,
      COUPON_AMOUNT: billSettelmentDetails.coupanAmount
        ? billSettelmentDetails.coupanAmount
        : 0.0,
      COUPON_NO: billSettelmentDetails.coupanNumber
        ? billSettelmentDetails.coupanNumber
        : 0.0,
      BTC_AMOUNT: billSettelmentDetails.btc ? billSettelmentDetails.btc : 0.0,
      SID: stationId,
      SNAME: stationName,
      id: '55555',
      // id: Math.floor(Math.random() * 789654),
      LATITUDE: latitude ? latitude : '',
      LONGITUDE: longitude ? longitude : '',
    };
  });

  const saveBillSettment_API = (invo) => {
    dispatch(setSpinnerLoading('Saving bill settlement'));
    const CollectionAmt = billSettelmentDetails.TOTAL_COLLECTION;
    const cashAmt = billSettelmentDetails.cash
      ? billSettelmentDetails.cash
      : 0.0;
    const ePaymentAmt = billSettelmentDetails.epaymentAmount
      ? billSettelmentDetails.epaymentAmount
      : 0.0;
    const cardAmt = billSettelmentDetails.card
      ? billSettelmentDetails.card
      : 0.0;
    const data = {
      table_prefix: prefix,
      INVNO: invo,
      DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
      ACCOUNT_NO: accDetails.accNumber,
      NAME: accDetails.accName,
      SID: stationId,
      BILL_STATUS: 'Settled',
      SNAME: stationName,
      CASH_AMOUNT: cashAmt,
      EPAYMENT: ePaymentAmt,
      EPAYMENT_BANK: billSettelmentDetails.epayment
        ? billSettelmentDetails.epayment
        : 'None',
      CARD_AMOUNT: cardAmt,
      CARD_NO: billSettelmentDetails.cardNumber
        ? billSettelmentDetails.cardNumber
        : 0.0,
      COUPON_AMOUNT: billSettelmentDetails.coupanAmount
        ? billSettelmentDetails.coupanAmount
        : 0.0,
      COUPON_NO: billSettelmentDetails.coupanNumber
        ? billSettelmentDetails.coupanNumber
        : 0.0,
      BTC_AMOUNT: billSettelmentDetails.btc ? billSettelmentDetails.btc : 0.0,
      DISQ_AMT: selectDiscountedAmount ? selectDiscountedAmount : 0.0,
      TAX1_RATE: '0',
      TAX1_AMT: '0',
      TAX2_RATE: '0',
      TAX2_AMT: '0',
      TAX3_RATE: '0',
      TAX3_AMT: '0',
      TOTAL_COLLECTION: CollectionAmt,
    };
    console.log(data);
    // const extraCollection = CollectionAmt - (cashAmt + ePaymentAmt + cardAmt);
    CallAPIPromise(`/api/billing/saveBillSettlement`, data, token, dispatch)
      .then(function (response) {
        if (
          billSettelmentDetails.TOTAL_COLLECTION &&
          billSettelmentDetails.TOTAL_COLLECTION > 0
        ) {
          saveVoucher(response, invo);
        } else {
          dispatch(
            setBillSettlementDetails({
              cash: '',
              card: '',
              cardNumber: '',
              coupanAmount: '',
              coupanNumber: '',
              epayment: '',
              epaymentAmount: '',
              btc: '',
              btcName: '',
              disc: '',
              btcMobile: '',
              btcAccNumber: '0',
              btcAddress: '',
              TOTAL_COLLECTION: 0,
            })
          );
          dispatch(clearOrderBillingList());
          navigate('/');
          dispatch(
            setBillingAccountDetails({
              accNumber: '',
              accMobile: '',
              accName: '',
            })
          );
          dispatch(setBillSattalmentModalStatus(false));
          dispatch(setBillingDiscount(''));

          if (response.data.result.affectedRows === 1) {
            dispatch(setviewBillModalStatus(true));
            dispatch(setViewBillInvoiceId(invo));
          } else {
            alert(response.data.error);
            navigate('/');
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        dispatch(
          setBillSettlementDetails({
            cash: '',
            card: '',
            cardNumber: '',
            coupanAmount: '',
            coupanNumber: '',
            epayment: '',
            epaymentAmount: '',
            btc: '',
            btcName: '',
            disc: '',
            btcMobile: '',
            btcAccNumber: '0',
            btcAddress: '',
            TOTAL_COLLECTION: 0,
          })
        );
        dispatch(clearOrderBillingList());
        navigate('/');
        dispatch(
          setBillingAccountDetails({
            accNumber: '',
            accMobile: '',
            accName: '',
          })
        );
        dispatch(setBillSattalmentModalStatus(false));
        dispatch(setBillingDiscount(''));
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg('Something wrong'));
          dispatch(setALertStatus(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const saveBillSettelment = () => {
    dispatch(setConfirmSaveBillstatus(false));

    dispatch(setCartItems(cartItems));
    if (BillSettelmentBalance === 0) {
      if (
        (billSettelmentDetails.card && !billSettelmentDetails.cardNumber) ||
        (billSettelmentDetails.coupanAmount &&
          !billSettelmentDetails.coupanNumber) ||
        (billSettelmentDetails.epaymentAmount &&
          !billSettelmentDetails.epayment)
      ) {
        dispatch(
          setAlertMsg(
            'Please fill in both fields of payment to complete your transaction.'
          )
        );
        dispatch(setALertStatus(true));
      } else {
        if (BillSettelmentBalance === 0) {
          const duplicateIds = cartItems.filter(
            (obj, index, self) =>
              index !== self.findIndex((o) => o.ICODE === obj.ICODE)
          );

          if (duplicateIds.length > 0) {
            dispatch(clearOrderBillingList());
            dispatch(setAlertMsg('Dupclicate items in list'));
            dispatch(setALertStatus(true));
          } else {
            dispatch(setBillSattalmentModalStatus(false));
            dispatch(setSpinnerLoading('Saving bill'));
            CallAPIPromise(
              `/api/billing/saveBill`,
              {
                table_prefix: prefix,
                cart_item: cartItems,
              },

              token,
              dispatch
            )
              .then((res) => {
                if (res.status === 200) {
                  if (res.data.affectedRows === cartItems.length) {
                    saveBillSettment_API(res.data.INVNO);
                  } else {
                    dispatch(setAlertMsg('Some rows are not saved'));
                    dispatch(setALertStatus(true));
                    dispatch(setAlertMsg(res.data.statusText));
                    dispatch(setALertStatus(true));
                    dispatch(
                      setBillSettlementDetails({
                        cash: '',
                        card: '',
                        cardNumber: '',
                        coupanAmount: '',
                        coupanNumber: '',
                        epayment: '',
                        epaymentAmount: '',
                        btc: '',
                        btcName: '',
                        disc: '',
                        btcMobile: '',
                        btcAccNumber: '0',
                        btcAddress: '',
                        TOTAL_COLLECTION: 0,
                      })
                    );
                    dispatch(clearOrderBillingList());
                    navigate('/');
                    dispatch(
                      setBillingAccountDetails({
                        accNumber: '',
                        accMobile: '',
                        accName: '',
                      })
                    );
                    dispatch(setBillSattalmentModalStatus(false));
                    dispatch(setBillingDiscount(''));
                  }
                } else {
                  dispatch(setAlertMsg(res.data.statusText));
                  dispatch(setALertStatus(true));
                  dispatch(
                    setBillSettlementDetails({
                      cash: '',
                      card: '',
                      cardNumber: '',
                      coupanAmount: '',
                      coupanNumber: '',
                      epayment: '',
                      epaymentAmount: '',
                      btc: '',
                      btcName: '',
                      disc: '',
                      btcMobile: '',
                      btcAccNumber: '0',
                      btcAddress: '',
                      TOTAL_COLLECTION: 0,
                    })
                  );
                  dispatch(clearOrderBillingList());
                  navigate('/');
                  dispatch(
                    setBillingAccountDetails({
                      accNumber: '',
                      accMobile: '',
                      accName: '',
                    })
                  );
                  dispatch(setBillSattalmentModalStatus(false));
                  dispatch(setBillingDiscount(''));
                }
              })
              .catch((e) => {
                console.log(e);
                dispatch(
                  setBillingAccountDetails({
                    accNumber: '',
                    accMobile: '',
                    accName: '',
                  })
                );
                dispatch(setSpinnerLoading(false));
                dispatch(setBillSattalmentModalStatus(false));
                navigate('/');

                if (e.response.data.name === 'TokenExpiredError') {
                  dispatch(setSessionExpired(true));
                } else {
                  dispatch(setAlertMsg('Something wrong'));
                  dispatch(setALertStatus(true));
                }

                dispatch(
                  setBillSettlementDetails({
                    cash: '',
                    card: '',
                    cardNumber: '',
                    coupanAmount: '',
                    coupanNumber: '',
                    epayment: '',
                    epaymentAmount: '',
                    btc: '',
                    btcName: '',
                    disc: '',
                    btcMobile: '',
                    btcAccNumber: '0',
                    btcAddress: '',
                    TOTAL_COLLECTION: 0,
                  })
                );
                dispatch(clearOrderBillingList());
              });
          }
        }
      }
    } else {
      dispatch(setAlertMsg('Your bill settlement has not been cleared.'));
      dispatch(setALertStatus('true'));
    }
  };

  return (
    <div className="bg-white">
      <Modal
        open={status}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="d:w-4/12 ml:w-10/12 ms:w-11/12 l:w-5/12 bg-brown overflow-hidden rounded-lg t:w-4/12 p:w-5/6 justify-center  "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 pt-5 items-center ">
            <div className="flex  w-full px-5 pb-6  items-center ">
              <img src={logo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown text-md ">
                  Do you want to save this bill?
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                <Button
                  size={'small'}
                  onClick={saveBillSettelment}
                  style={{
                    backgroundColor: '#A8CF45',
                    color: 'black',
                  }}
                  variant="contained"
                >
                  <div className="px-5">Yes</div>{' '}
                </Button>
              </div>
              <div className="flex justify-end pb-3  bg-white pr-3 ">
                <Button
                  size={'small'}
                  onClick={handleClose}
                  style={{
                    backgroundColor: '#f1f5f9',
                    color: 'black',
                  }}
                  variant="contained"
                >
                  <div className="px-2">No </div>{' '}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
