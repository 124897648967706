import React, { useEffect, useRef, useState } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import { UserModuleMasterColumns } from './columns';
import { SaveUserModuleMaster, UserModuleMasetrHelp } from './function';
import { useDispatch, useSelector } from 'react-redux';
import { selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import ModUserHelp from '../utility/component/modals/modUserHelp';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { Button } from '@mui/material';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import ModulMasterHelp from '../master/moduleMasterHelp';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';

const UserModuleMaster = () => {
  const [userModuleList, setUserModuleList] = useState([]);
  const [userCompany, setUserCompany] = useState('');
  const [userConpanyEmail, setUserConpanyEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');
  const token = useSelector(selectToken);
  const [userhelpStatus, setUserHelpStatus] = useState(false);
  const [tabName, settabName] = useState('');
  const navigate = useNavigate();
  const [moduleName, setModulName] = useState('');
  const [moduleMasterHelpStatus, setModulMasterHelpStatus] = useState(false);
  const [allow, setAllow] = useState('YES');
  const dispatch = useDispatch();
  const width = [useSelector(selectWidth)];
  const userModuleColumns = UserModuleMasterColumns(
    width,
    setUserModuleList,
    token,
    dispatch
  );
  useEffect(() => {
    if (userId) {
      dispatch(setSpinnerLoading('Loading...'));
      UserModuleMasetrHelp(
        userId,
        token,
        (e) => {
          if (!e.data.error) {
            const data = e.data.response;

            setUserModuleList(data);
          }
        },
        dispatch,
        () => {
          dispatch(setSpinnerLoading(false));
        },
        (e) => console.log(e)
      );
    }
  }, [userId]);

  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7] p-4 items-center  ">
      <div className="text-sm  rounded-sm  h-full">
        <div className="bg-white  rounded-t-sm ">
          <ModalHeaderStyle
            header={'User Module Master'}
            onClose={() => {
              navigate('/settings');
            }}
          />
        </div>
        <div
          style={{
            height: `calc(100% - 55px)`,
          }}
          className="      flex flex-col  "
        >
          <div className="flex bg-white justify-center pt-4 ">
            <div>
              <div className="flex">
                <GeniustextInput
                  title={'User ID'}
                  secondText={{
                    value: userId,
                    backgroundColor: '#f7dda4',
                  }}
                  value={userName}
                  search={() => {
                    setUserHelpStatus(true);
                  }}
                  width={'300px'}
                  type={'text'}
                  titleBold={600}
                  onChange={(p) => {
                    setUserId(p.target.value);
                  }}
                />
                <div className="ml-2 flex">
                  <GeniustextInput
                    title={'Tab Name'}
                    type={'text'}
                    search={() => {
                      setModulMasterHelpStatus(true);
                    }}
                    width={'180px'}
                    value={tabName}
                  />
                  <div className="ml-2">
                    <GeniustextInput
                      title={'Module Name'}
                      type={'text'}
                      width={'180px'}
                      value={moduleName}
                    />
                  </div>
                </div>
              </div>
              <div className="flex">
                <GeniustextInput
                  title={'User Company Email'}
                  value={userConpanyEmail}
                  width={'300px'}
                  titleBold={600}
                  type={'text'}
                  onChange={(p) => {
                    setUserConpanyEmail(p.target.value);
                  }}
                />
                <div className="ml-2 flex">
                  <GeniustextInput
                    title={'User Company'}
                    value={userCompany}
                    width={'180px'}
                    type={'text'}
                    titleBold={600}
                    onChange={(p) => {
                      setUserCompany(p.target.value);
                    }}
                  />
                  <div className="ml-2 flex">
                    <GeniustextInput
                      title={'Allow'}
                      value={allow}
                      width={'180px'}
                      option={[
                        {
                          name: 'YES',
                          value: 'YES',
                        },
                        {
                          name: 'NO',
                          value: 'NO',
                        },
                      ]}
                      onChange={(p) => {
                        setAllow(p.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: `calc(100% - 240px)`,
            }}
            className=" bg-white p-4"
          >
            <GeniusDatagrid
              columns={userModuleColumns}
              list={userModuleList}
              rowID={(r) => r.ID}
            />
          </div>
          <div className="flex bg-white p-2 rounded-b-sm   justify-end">
            <div>
              <Button
                // ref={updateRef}
                onClick={() => {
                  const isDuplicate = userModuleList.filter(
                    (f) => f.MODULE_NAME === moduleName
                  );
                  if (
                    userCompany &&
                    userConpanyEmail &&
                    tabName &&
                    moduleName
                  ) {
                    if (!isDuplicate[0]) {
                      const data = {
                        ID: '',
                        USER_ID: userId,
                        TAB_NAME: tabName,
                        MODULE_NAME: moduleName,
                        ALLOW_YN: allow,
                      };
                      dispatch(setSpinnerLoading('Saving'));
                      SaveUserModuleMaster(
                        data,
                        token,
                        (e) => {
                          console.log(e);
                          if (e.status === 200) {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: e.data.message,
                              })
                            );
                            setModulName('');
                            settabName('');
                            UserModuleMasetrHelp(
                              userId,
                              token,
                              (e) => {
                                if (!e.data.error) {
                                  const data = e.data.response;

                                  setUserModuleList(data);
                                }
                              },
                              dispatch,
                              () => {},
                              (e) => console.log(e)
                            );
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Error',
                                msg: 'Something went wrong',
                              })
                            );
                          }
                        },
                        dispatch,
                        () => {
                          dispatch(setSpinnerLoading(false));
                        },
                        (e) => console.log(e)
                      );
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Already in list',
                        })
                      );
                    }
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Please fil all details',
                      })
                    );
                  }
                }}
                variant="contained"
                style={{
                  textTransform: 'none',
                  letterSpacing: '1px',
                  backgroundColor: '#a8cf45',
                  width: 110,
                }}
              >
                <div className="text-black px-4 font-sans">Save </div>
              </Button>
            </div>
            <div className="ml-2">
              <Button
                // ref={updateRef}
                variant="contained"
                style={{
                  textTransform: 'none',
                  letterSpacing: '1px',
                  backgroundColor: '#383535',
                  width: 110,
                }}
              >
                <div className="text-white font-sans px-4  ">Undo </div>
              </Button>
            </div>
          </div>
          <ModUserHelp
            status={userhelpStatus}
            onRowClick={(p) => {
              setUserId(p.row.CODE);
              setUserName(p.row.USER_NAME);
              setUserHelpStatus(false);
              setUserCompany(p.row.COMPANY_NAME);
              setUserConpanyEmail(p.row.COMPANY_EMAIL);
              settabName('');
              setModulName('');
              setUserModuleList([]);
            }}
            onClose={() => {
              setUserHelpStatus(false);
            }}
          />
          <ModulMasterHelp
            status={moduleMasterHelpStatus}
            onRowClick={(e) => {
              setModulName(e.row.MODULE_NAME);
              settabName(e.row.TAB_NAME);
              setModulMasterHelpStatus(false);
            }}
            setStatus={setModulMasterHelpStatus}
          />
        </div>
      </div>
    </div>
  );
};

export default UserModuleMaster;
