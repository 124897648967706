import React from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectBillSettlement,
  setBillSattalmentModalStatus,
} from '../../../../../utility/Redux/modal';
import SettleBill from './settleBill';
import { setBillSettlementDetails } from '../../../../../utility/Redux/tableOrder';
import SettleBillMoblie from './settleBillMoblie';
import MasterHeaderStyle from '../../../../../utility/component/styles/masterHearStyle';
import ModalHeaderStyle from '../../../../../utility/component/styles/modalHeader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function BillSettelmentModal() {
  const dispatch = useDispatch();
  const BillSettelmentStatus = useSelector(SelectBillSettlement);

  const handleClose = () => {
    dispatch(setBillSattalmentModalStatus(false));
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: ' ',
        TOTAL_COLLECTION: 0,
      })
    );
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={BillSettelmentStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth <= 760 ? styleMobile : style}
          className={'rounded-sm overflow-hidden '}
        >
          <div className="w-full  ">
            <ModalHeaderStyle
              header={'Bill Settlement'}
              onClose={handleClose}
            />

            <div className="flex-grow h-full bg-white   ">
              {window.innerWidth <= 760 ? <SettleBillMoblie /> : <SettleBill />}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
