import React, { useEffect, useState } from 'react';
import { useSelect } from '../utility/hooks/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import CallAPI from '../utility/functions/getData';
import GeniustextInput from '../utility/component/mastertext';
import {
  setListStationMasterModalStatus,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { PartyColumns } from './partyColumns';

const PartyList = ({
  stationId,
  stationName,
  fullList,
  setFullList,
  parties,
  setParties,
  setStationId,
  setStationName,
}) => {
  const prefix = useSelector(selectPrefix);
  const windowWidth = useSelector(selectWidth);
  const [searchText, setSearchText] = useState('');

  const columns = PartyColumns(windowWidth);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();
  const getAccountData = () => {
    dispatch(setSpinnerLoading('Loading'));
    CallAPI(
      `/api/masters/listAccountMaster`,
      {
        // table_prefix: prefix,
        table_prefix: prefix,
        mobile: '',
        name: '',
        sid: stationId,
      },
      token,
      (e) => {
        if (!e.data.error) {
          setParties(e.data.response);
          setFullList(e.data.response);
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  useEffect(() => {
    if (!fullList[0]) {
      getAccountData();
    }
  }, [stationId]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchText);
        } else {
          return item.NAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setParties(searchItems);
    } else {
      setParties(fullList);
    }
  }, [searchText]);
  return (
    <div
      style={{
        width: windowWidth > 764 ? '810px' : '355px',
      }}
      className="py-2  h-full  "
    >
      <div className="mt-3">
        <GeniustextInput
          titleAlign={'left'}
          type="text"
          title={'Select Station'}
          reset={() => {
            setStationId('');
            setStationName('');
            setFullList([]);
            setParties([]);
          }}
          iconAlign={'right'}
          search={() => {
            setParties([]);
            setFullList([]);
            dispatch(setListStationMasterModalStatus(true));
          }}
          hideError={true}
          backgroundColor={'#f1efeb'}
          value={stationName}
          secondText={{
            value: stationId,
            backgroundColor: '#f7dda4',
            width: '75px',
          }}
          width={'250px'}
          align={'left'}
        />
      </div>

      <div
        style={{
          height: '360px',
        }}
        className="mt-3"
      >
        <GeniusDatagrid
          rowID={(r) => r.ACCOUNT_NO}
          list={parties}
          columns={columns}
          dataGridHeader={true}
          showPagination={true}
        />
      </div>
      <div
        style={{
          width: '100%',
          border: '1px solid #D2D2D7',
        }}
        className="rounded-sm p-1 mt-3 flex items-center justify-between pl-2"
      >
        {' '}
        <div className=" ">
          <input
            type="text"
            value={searchText}
            className="w-full"
            style={{
              outline: 'none ',
            }}
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div className="text-sm pr-3 text-gray-500">
          Records : {parties.length}
        </div>
      </div>
    </div>
  );
};

export default PartyList;
