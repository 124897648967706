import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { SelectSection } from "./refresh";

export const Masters = createSlice({
  name: "masters",
  initialState: {
     list: {
      section: [],
    },
    expense: {
     
   }
   
  },

  reducers: {
      setSectionList(state, action) {
      state.list.section = action.payload;
    },
  },
});

export default Masters.reducer;
export const {
 setSectionList
} = Masters.actions;

export const SelectSectionList = (state) => state.masters.list.section;