import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../Redux/modal';
import APIErrorBug from '../component/geniusComponents/error';

const CallAPI = (path, data, token, then, dispatch, final, c) => {
  axios
    .post(path, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((t) => {
      then(t);
      if (t?.data?.error) {
        console.log('err');
        dispatch(setSpinnerLoading(false));
        // APIErrorBug(t.config.data, t.config.url, t.data.error, token, dispatch);
      }
    })
    .catch((error) => {
      if (c) {
        c(error);
      }
      dispatch(setSpinnerLoading(false));
      console.log(error);
      // APIErrorBug(
      //   error.config.data,
      //   error.config.url,
      //   error.message,
      //   token,
      //   dispatch
      // );
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        if (error.response.data?.message) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: error.response.data?.message,
            })
          );
          return;
        }
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something went wrong',
          })
        );
      }
    })
    .finally(final ? final : () => {});
};

export default CallAPI;
