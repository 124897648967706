import { FormatNumber2 } from '../tools';
const width = (window.innerWidth * 1) / 100;
export const PartyLedgerFooter = (props) => {
  const { rows, columns } = props;

  const totalDueAmount = rows.reduce(
    (total, row) =>
      Number(total) + Number(row.BTC_AMOUNT.toLocaleString().replace(',', '')),
    0
  );

  const TotalReceivedAmount = rows.reduce(
    (total, row) =>
      Number(total) +
      Number(row.AMOUNT_RECEIVED.toLocaleString().replace(',', '')),
    0
  );
  console.log(rows);
  return (
    <div style={{ width: '100%' }} className="  bg-[#e6e6e3] flex ">
      <div className=" bg-[#e6e6e3] flex w-full text-[14px]">
        {' '}
        {columns.map((item, index) => {
          const header = item.field;
          return (
            <div
              key={index}
              className="  text-black flex justify-end pr-2  items-center border-r  py-2 border-slate-300 "
              style={item.flex ? { flex: item.flex } : { width: item.width }}
            >
              {header === 'DATE(DATE)' ? null : null}
              {header === 'INVNO' ? null : null}
              {header === 'VOUCHER_NO' ? null : null}
              {header === 'ACCOUNT_NO' ? null : null}
              {header === 'NAME' ? 'Total' : null}
              {header === 'DESC_NAME' ? null : null}
              {header === 'BTC_AMOUNT'
                ? totalDueAmount
                  ? FormatNumber2(totalDueAmount)
                  : null
                : null}
              {header === 'AMOUNT_RECEIVED'
                ? TotalReceivedAmount
                  ? FormatNumber2(TotalReceivedAmount)
                  : null
                : null}
              {header === 'APPROVED' ? null : null}
            </div>
          );
        })}{' '}
      </div>

      {/* <div className="   hidden print:flex-col justify-end items-end ">
        <div className="flex">
          Total Due Amount :{' '}
          {totalDueAmount ? FormatNumber2(totalDueAmount) : 0.0}
        </div>

        <div className="flex">
          Total Recieved Amount :{' '}
          {TotalReceivedAmount ? FormatNumber2(TotalReceivedAmount) : 0.0}
        </div>
      </div> */}
    </div>
  );
};
