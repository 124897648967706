import React from 'react';

import Modal from '@mui/material/Modal';

import DailyExpense from './dailyExpense';
import ModalHeaderStyle from '../styles/modalHeader';
import ModalBody from '../styles/modalBody';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  boxShadow: 24,
};

export default function ModDailyExpense({
  setStatus,
  status,
  stationId,
  date,
  setExpense,
  stationName,
}) {
  const handleClose = () => {
    setStatus(false);
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={status}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={'rounded-lg overflow-hidden '}>
          <div className="w-full flex flex-col bg-white h-full ">
            <div className="flex">
              <ModalHeaderStyle
                header={'Daily Expense'}
                onClose={handleClose}
              />
            </div>

            <div className="flex-grow  bg-white    border-brown">
              <ModalBody>
                <DailyExpense
                  stationId={stationId}
                  stationName={stationName}
                  setExpense={setExpense}
                  handleClose={handleClose}
                  date={date}
                />
              </ModalBody>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
