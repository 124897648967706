import React, { useRef, useState } from 'react';
import moment from 'moment';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { useEffect } from 'react';
import { GetStationDayEndReport } from './function';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setStationID,
  setStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import { StationDayEndReports } from './columns';
import { FormatNumber2 } from '../utility/component/tools';
import GeniusReportsDate from '../utility/component/reportDate';
import {
  setAlertWithTitle,
  setListStationMasterModalStatus,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import GeniusTableReports from '../utility/component/geniusComponents/geniusTableforReports';
import ReactToPrint from 'react-to-print';
import IsLocalData from '../utility/functions/ceckLocalData';
import CallAPI from '../utility/functions/getData';

const StationDayEndreports = () => {
  const fromDate = useSelector(selectreportFromDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const toDate = useSelector(selectreportToDate);
  const [toDateFocus, setToDateFocus] = useState(false);
  const ReduxStation_id = useSelector(selectStationId);
  const ReduxStationName = useSelector(selectStationName);
  const userType = useSelector(selectUserType);
  const [list, setList] = useState([]);
  const printRef = useRef();
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);
  const [prevBal, setPrevBal] = useState(0);
  // useEffect;
  const okButton = () => {
    if (ReduxStation_id) {
      GetStationDayEndReport(
        prefix,
        dispatch,
        token,
        setList,
        fromDate,
        toDate,
        ReduxStation_id,
        ReduxStationName
      );
      getPrevBal();
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please select station first',
        })
      );
    }
  };

  const columns = StationDayEndReports(windowWidth);

  const totalCash = list.reduce(
    (total, row) => Number(total) + Number(row.CASH),
    0
  );
  const totalCollection = list.reduce(
    (total, row) => Number(total) + Number(row.COLLECTION),
    0
  );
  const totalDebit = list.reduce(
    (total, row) => Number(total) + Number(row.DEBIT),
    0
  );

  const totalBalcence = list.reduce(
    (total, row) => Number(total) + Number(row.BALANCE),
    0
  );

  const totalDUE = list.reduce(
    (total, row) => Number(total) + Number(row.DUE),
    0
  );

  const totalExpense = list.reduce(
    (total, row) => Number(total) + Number(row.EXPENSE),
    0
  );

  const totalLess = list.reduce(
    (total, row) => Number(total) + Number(row.LESS),
    0
  );

  const totalIqnty = list.reduce(
    (total, row) => Number(total) + Number(row.IQNTY),
    0
  );

  const totalRQnty = list.reduce(
    (total, row) => Number(total) + Number(row.RQNTY),
    0
  );

  const totalIAmount = list.reduce(
    (total, row) => Number(total) + Number(row.IAMOUNT),
    0
  );

  const totalRAmount = list.reduce(
    (total, row) => Number(total) + Number(row.RAMOUNT),
    0
  );
  const totalSqnty = list.reduce(
    (total, row) => Number(total) + Number(row.SQNTY),
    0
  );
  const totalSAmount = list.reduce(
    (total, row) => Number(total) + Number(row.SAMOUNT),
    0
  );

  useEffect(() => {
    const elseFunc = () => {
      if (userType !== 'USER') {
        dispatch(setStationID(''));
        dispatch(setStationName(''));
      }
    };

    const callback = (details, data) => {
      setList(data);
      dispatch(setStationID(details[0].SID));
      dispatch(setreportsFromDate(details[0].FDATE));
      dispatch(setreportsTodate(details[0].TDATE));
      dispatch(setStationName(details[0].SNAME));
      getPrevBal(details[0].SID, details[0].FDATE);
    };
    IsLocalData('Station_day_end_reports', callback, elseFunc);
  }, []);
  const getPrevBal = (stationId, from) => {
    const data = {
      table_prefix: prefix,
      SID: stationId ? stationId : ReduxStation_id,
      FDATE: from ? from : fromDate,
    };
    CallAPI(
      'api/issueStockRoute/stationCurrentBalance',
      data,
      token,
      (p) => {
        if (!p.data.error) {
          console.log(p);
          const data = p.data.response[0];
          const d = data.OP_DEBIT + data.PRV_DEBIT;
          const c = data.OP_CREDIT + data.PRV_CREDIT;
          const prvBal = d - c;
          setPrevBal(prvBal);
        }
      },
      dispatch,
      () => {
        dispatch(setSpinnerLoading(false));
      }
    );
  };

  const p = prevBal + totalDebit;
  return (
    <div className="h-full w-full p-2 text-sm">
      {window.innerWidth <= 500 ? (
        <>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="p-1  py-3 rounded-t-sm  flex items-center "
          >
            <div className="px-1 ">From</div>

            <GeniusReportsDate
              date={fromDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1 pl-2 ">To</div>
            <GeniusReportsDate
              date={toDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>
          <div style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}>
            {userType === 'USER' ? null : (
              <div className="flex  text-[#4B4545] p-2  ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div className="flex items-center ">
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Station ID
                    </span>
                  </div>
                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex text-[#a6a2a2]  pl-2 ml-2  "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={ReduxStation_id ? ReduxStation_id : 'All'}
                      style={{
                        // width: '20%',
                        fontSize: 12,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-6"
                    />

                    <div
                      style={{
                        borderLeft: '1px dotted #a6a2a2 ',
                      }}
                      className="px-2 p-1 w-48 whitespace-nowrap print:hidden"
                    >
                      {ReduxStationName ? `${ReduxStationName}` : null}
                    </div>
                  </div>
                  <div>
                    <button
                      onClick={okButton}
                      className="flex items-center rounded-sm ml-2"
                      style={{
                        backgroundColor: '#c1c1c1',
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: 'black',
                      }}
                    >
                      <div className="flex items-center text-sm">Ok</div>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          style={{
            border: '1px solid #b5b3b3',
            width:
              columns[0].width +
              columns[1].width +
              columns[2].width +
              columns[3].width +
              columns[4].width +
              columns[5].width +
              columns[6].width +
              columns[7].width +
              columns[8].width +
              columns[9].width +
              columns[10].width +
              20,
          }}
          className=" pl-2 rounded-sm flex items-center "
        >
          <div className=" rounded-sm  flex items-center ">
            <div className="px-1 text-[13px]">From</div>

            <GeniusReportsDate
              date={fromDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="mx-4 ">To</div>
            <GeniusReportsDate
              date={toDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>

          <div
            style={{
              borderLeft: '1px solid #b5b3b3',
            }}
            className="flex px-4  ml-5 text-[#4B4545] p-3  "
          >
            <div
              style={{ fontSize: 13 }}
              className=" flex whitespace-normal  justify-center items-center  "
            >
              <div className="flex items-center ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER' || userType === 'ADMIN') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  className="hover:cursor-pointer"
                >
                  <i
                    className="bi bi-search"
                    style={{ color: '#f44336', fontSize: 15 }}
                  ></i>
                </div>

                <span
                  style={{ fontSize: 12, fontWeight: 600 }}
                  className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                >
                  {' '}
                  Station ID{' '}
                </span>
              </div>

              <div
                style={{
                  border: '1px dotted #a6a2a2 ',
                  borderRadius: '3px',
                }}
                className="flex text-[#a6a2a2]  pl-2 ml-2  "
              >
                <input
                  size={'small'}
                  placeholder="All"
                  value={ReduxStation_id ? ReduxStation_id : 'All'}
                  style={{
                    fontSize: 12,
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    backgroundColor: 'transparent',
                  }}
                  readOnly
                  className=" outline-0 w-12 "
                />

                <div
                  style={{
                    borderLeft: '1px dotted #a6a2a2 ',
                  }}
                  className="px-2 p-1 whitespace-nowrap print:hidden"
                >
                  {ReduxStationName ? `${ReduxStationName}` : null}
                </div>
              </div>
              <div className="flex">
                <button
                  onClick={okButton}
                  className="flex items-center rounded-sm ml-2"
                  style={{
                    backgroundColor: '#c1c1c1',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: 'black',
                  }}
                >
                  <div className="flex items-center text-sm">Ok</div>
                </button>
                {list[0] && (
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button
                          style={{
                            width: 'auto',
                            borderRadius: '7px',
                            height: 'auto',
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 4,
                            paddingBottom: 4,
                            backgroundColor: '#bad4f9',
                            color: 'black',
                            marginLeft: 7,
                          }}
                        >
                          <div className="text-sm">Print</div>
                        </button>
                      );
                    }}
                    content={() => printRef.current}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        style={
          windowWidth > 768
            ? {
                border: '1px solid #b5b3b3',

                width:
                  columns[0].width +
                  columns[1].width +
                  columns[2].width +
                  columns[3].width +
                  columns[4].width +
                  columns[5].width +
                  columns[6].width +
                  columns[7].width +
                  columns[8].width +
                  columns[9].width +
                  columns[10].width +
                  20,
              }
            : {
                border: '1px solid #b5b3b3',
                overflowX: 'auto',
                width: window.innerWidth - 70,
              }
        }
        className="flex rounded-sm items-center mt-[8px] "
      >
        <div className="flex t:w-1/4 border-r min-w-[150px]  border-[#b5b3b3] p-2 ">
          <div className="font-semibold">Prev. Balance :</div>
          <div className="pl-3">{FormatNumber2(prevBal)}</div>
        </div>
        <div className="flex t:w-1/4 border-r min-w-[150px]  border-[#b5b3b3] p-2 ">
          <div className="font-semibold">Total Debit :</div>
          <div className="pl-3">{FormatNumber2(totalDebit)}</div>
        </div>

        <div className="flex t:w-1/4 p-2  border-r min-w-[185px] border-[#b5b3b3]">
          <div className="font-semibold">Total Collection :</div>
          <div className="pl-3">{FormatNumber2(totalCollection)}</div>
        </div>

        <div className="flex t:w-1/4 p-2 min-w-[160px] ">
          <div className="font-semibold">Balance :</div>
          <div className="pl-3">
            {FormatNumber2(p - totalCollection)}
            {/* {FormatNumber2(prevBal + totalCollection - totalDebit)} */}
          </div>
        </div>
      </div>

      <div
        style={
          windowWidth > 768
            ? {
                height: `calc(100% - 220px)`,

                width:
                  columns[0].width +
                  columns[1].width +
                  columns[2].width +
                  columns[3].width +
                  columns[4].width +
                  columns[5].width +
                  columns[6].width +
                  columns[7].width +
                  columns[8].width +
                  columns[9].width +
                  columns[10].width +
                  20,
              }
            : {
                height: `calc(100% - 220px)`,
              }
        }
        className="mt-2 w-full "
      >
        <GeniusDatagrid
          list={list}
          rowID={(r) => r.FNO}
          columns={columns}
          dataGridHeader={true}
          showPagination={true}
        />
      </div>
      {list[0] && (
        <div
          style={
            windowWidth > 768
              ? {
                  border: '1px solid #b5b3b3',

                  width:
                    columns[0].width +
                    columns[1].width +
                    columns[2].width +
                    columns[3].width +
                    columns[4].width +
                    columns[5].width +
                    columns[6].width +
                    columns[7].width +
                    columns[8].width +
                    columns[9].width +
                    columns[10].width +
                    20,
                }
              : {
                  border: '1px solid #b5b3b3',
                }
          }
          className=" flex rounded-sm mt-[8px] w-full "
        >
          <div className="w-full flex overflow-x-scroll  ">
            {columns.map((i) => {
              return (
                <div
                  style={{
                    minWidth: `${i.width}px`,
                    borderRight:
                      i.field === 'BALANCE'
                        ? '0px solid #b5b3b3'
                        : '1px solid #b5b3b3',
                    textAlign: i.align,
                    paddingRight: i.field === 'BALANCE' ? '8px' : '4px',
                  }}
                  className="p-1"
                >
                  {i.field === 'CASH' && FormatNumber2(totalCash)}
                  {i.field === 'COLLECTION' && FormatNumber2(totalCollection)}
                  {i.field === 'DEBIT' && FormatNumber2(totalDebit)}
                  {i.field === 'DUE' && totalDUE > 0
                    ? FormatNumber2(totalDUE)
                    : ''}
                  {i.field === 'EXPENSE' && FormatNumber2(totalExpense)}
                  {i.field === 'LESS' && FormatNumber2(totalLess)}{' '}
                  {i.field === 'IQNTY' && FormatNumber2(totalIqnty)}
                  {i.field === 'RQNTY' && FormatNumber2(totalRQnty)}{' '}
                  {i.field === 'RAMOUNT' && FormatNumber2(totalRAmount)}
                  {i.field === 'IAMOUNT' && FormatNumber2(totalIAmount)}
                  {i.field === 'SQNTY' && FormatNumber2(totalSqnty)}
                  {i.field === 'SAMOUNT' && FormatNumber2(totalSAmount)}
                  {i.field === 'BALANCE' && FormatNumber2(totalBalcence)}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div
        style={
          windowWidth > 768
            ? {
                border: '0px solid #b5b3b3',

                width:
                  columns[0].width +
                  columns[1].width +
                  columns[2].width +
                  columns[3].width +
                  columns[4].width +
                  columns[5].width +
                  columns[6].width +
                  columns[7].width +
                  columns[8].width +
                  columns[9].width +
                  columns[10].width +
                  20,
              }
            : {
                border: '0px solid #b5b3b3',
              }
        }
        className="mt-2 flex justify-end"
      >
        <div
          ref={printRef}
          style={{ width: '100%', display: 'block', visibility: 'hidden' }}
        >
          <GeniusTableReports
            stationId={ReduxStation_id}
            date={fromDate}
            date1={toDate}
            columns={columns}
            data={list}
            headerWidth={'80px'}
            header={
              <div
                id={'print-header2'}
                className="flex border rounded-sm items-center  w-full px-4 "
              >
                <div className="flex w-1/3 border  border-[#b5b3b3] p-2 ">
                  <div className="font-semibold">Total Debit :</div>
                  <div className="pl-3">{FormatNumber2(totalDebit)}</div>
                </div>

                <div className="flex w-1/3 p-2 border-l-0  border border-[#b5b3b3]">
                  <div className="font-semibold">Total Collection :</div>
                  <div className="pl-3">{FormatNumber2(totalCollection)}</div>
                </div>

                <div className="flex w-1/3 p-2 border border-l-0 border-[#b5b3b3] ">
                  <div className="font-semibold ">Balance :</div>
                  <div className="pl-3">
                    {FormatNumber2(totalDebit - totalCollection)}
                  </div>
                </div>
              </div>
            }
            title={'Station Day End Reports'}
          />
        </div>
      </div>

      <ModListStationMaster
        onRowClick={(p) => {
          setList([]);
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
    </div>
  );
};

export default StationDayEndreports;
