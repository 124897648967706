import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const CustomSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    top: 'auto',
    bottom: 20,
    right: 15,
    height: '82%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    zIndex: theme.zIndex.drawer - 5, // Decrease the z-index
  },
}));

function SwipeableEdgeDrawer({ data, status, setStatus, selectedOption }) {
  const toggleDrawer = (newOpen) => () => {
    setStatus(newOpen);
  };

  return (
    <div className="h-full rounded-t-sm ">
      {!status && selectedOption === 'option2' && (
        <button
          onClick={() => {
            if (selectedOption === 'option2') {
              setStatus(true);
            }
          }}
          style={{
            top: 'auto',
            bottom: 20,
            right: 15,
            height: '78%',
            boxSizing: 'border-box',
            overflow: 'hidden',
            backgroundColor: 'transparent',
          }}
          className="rounded-r-sm overflow-hidden rounded-t-sm "
        >
          <div className="h-full bg-slate-100  flex items-center">
            <div
              style={{
                writingMode: 'vertical-rl',
                transform: 'scale(-1)',
                textOrientation: 'mixed',
              }}
              className="text-sm"
            >
              Section - Help
            </div>
          </div>
        </button>
      )}
      <div className="h-full ">
        <CustomSwipeableDrawer
          anchor="right"
          open={status}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
        >
          {data}
        </CustomSwipeableDrawer>
      </div>
    </div>
  );
}

export default SwipeableEdgeDrawer;
