import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
} from '../../../../../utility/Redux/modal';

export const CallAPIPromise = (path, data, token, dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(path, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((t) => {
          resolve(t);
        })
        .catch((error) => {
          // dispatch(setSpinnerLoading(false));
          console.log(error);

          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          } else {
            if (error.response.data?.message) {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: error.response.data?.message,
                })
              );
              return;
            }
            reject(error);
          }
        });
    } catch {
      reject('Try Catch');
    }
  });
};
