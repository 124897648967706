import moment from 'moment';
import { FormatNumber2 } from '../component/tools';
const width = window.innerWidth / 100;

export const columns = [
  {
    field: 'desc_name',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Description'}</div>,
  },
  {
    field: 'date',
    type: 'string',

    width: width * 10,
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params.value) {
        return moment(params.value).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Date'}</div>,
  },
  {
    field: 'pay_mode',
    type: 'string',
    width: width * 9,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Pay Mode'}</div>,
  },
  {
    field: 'voucher_no',
    type: 'string',
    width: width * 6,
    align: 'right',

    align: 'right',
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Voucher No.'}</div>,
  },

  {
    field: 'cash_amount',
    type: 'string',
    width: width * 8,
    align: 'right',
    cellClassName: 'super-app-theme--collection',
    renderCell: (params) => {
      if (params.value) {
        return <div> {FormatNumber2(params.value)} </div>;
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Cash Amt.'}</div>,
  },
  {
    field: 'card_amount',
    type: 'string',
    cellClassName: 'super-app-theme--collection',
    width: width * 8,
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Card Amt.'}</div>,
  },
  {
    field: 'btc_amount',
    type: 'string',
    width: width * 8,
    cellClassName: 'super-app-theme--collection',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'BTC Amt.'}</div>,
  },
  {
    field: 'collection_amt',
    type: 'string',
    cellClassName: 'super-app-theme--collection',
    width: width * 8,
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Collection'}</div>,
  },
  {
    field: 'cheque_amount',
    type: 'string',
    width: width * 8,
    align: 'right',
    cellClassName: 'super-app-theme--collection',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Cheque Amt.'}</div>,
  },
  {
    field: 'epayment',
    type: 'string',
    cellClassName: 'super-app-theme--collection',
    width: width * 8,
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Epayment'}</div>,
  },
  {
    field: 'discount',
    type: 'string',
    width: width * 6,
    cellClassName: 'super-app-theme--expense',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Discount'}</div>,
  },

  {
    field: 'amount_paid',
    type: 'string',
    width: width * 6,
    align: 'right',
    cellClassName: 'super-app-theme--expense',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Amount Paid'}</div>,
  },
];

export const Mobilecolumns = [
  {
    field: 'desc_name',
    type: 'string',
    flex: 1,
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Description'}</div>,
  },
  {
    field: 'date',
    type: 'string',

    width: width * 15,
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params.value) {
        return moment(params.value).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Date'}</div>,
  },
  {
    field: 'pay_mode',
    type: 'string',
    width: width * 15,
    align: 'center',
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Pay Mode'}</div>,
  },

  {
    field: 'Total',
    type: 'string',
    width: width * 15,
    cellClassName: 'super-app-theme--expense',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Discount'}</div>,
  },

  {
    field: 'Expense',
    type: 'string',
    width: width * 15,
    align: 'right',
    cellClassName: 'super-app-theme--expense',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => <div>{'Amount Paid'}</div>,
  },
];
