import { FormatNumber2, FormatNumber3 } from '../component/tools';

const width = (window.innerWidth * 1) / 100;
export const DashboardColumns = (defaultProfile) => {
  const columns = [
    {
      field: 'ICODE',
      type: 'string',
      width: width * 4,
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
          {' '}
          {'Code'}{' '}
        </strong>
      ),
    },
    {
      field: 'INAME',
      type: 'string',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
          {' '}
          Item Name{' '}
        </strong>
      ),
    },
    {
      field: 'PRV_BAL',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 9,
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      align: 'right',

      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-16 leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Prev. Balance
        </strong>
      ),
    },
    {
      field: 'IQNTY',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 9,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (defaultProfile.show_today_issued === 'YES') {
          if (params.value) {
            return FormatNumber3(params.value);
          } else {
            return '';
          }
        } else {
          if (params.value) {
            return '***';
          } else {
            return '';
          }
        }
      },
      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-10 leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Issued Qnty
        </strong>
      ),
    },

    {
      field: 'IAMOUNT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 9,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (defaultProfile.show_today_issued === 'YES') {
          if (params.value) {
            return FormatNumber2(params.value);
          } else {
            return '';
          }
        } else {
          if (params.value) {
            return '***';
          } else {
            return '';
          }
        }
      },
      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-20 leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Issued Amount
        </strong>
      ),
    },

    {
      field: 'SQNTY',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 9,

      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => (
        <strong
          className="text-[#555a66]  w-16 leading-4 whitespace-normal "
          style={{ fontSize: 13 }}
        >
          {' '}
          Sales Qnty
        </strong>
      ),
    },
    {
      field: 'SAMOUNT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 9,
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      align: 'right',

      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-16 leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          {' '}
          Sales Amount
        </strong>
      ),
    },

    {
      field: 'BQNTY',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 9,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return params.value;
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      renderCell: (params) => {
        if (defaultProfile.show_today_issued === 'YES') {
          const b = FormatNumber3(
            params.row.PRV_BAL + params.row.IQNTY - params.row.SQNTY
          );
          return b;
        } else {
          return '***';
        }
      },
      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-16 leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          Balance Qnty
        </strong>
      ),
    },
    {
      field: 'BAMOUNT',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      align: 'right',
      headerAlign: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        if (defaultProfile.show_today_issued === 'YES') {
          const value =
            (params.row.PRV_BAL + params.row.IQNTY - params.row.SQNTY) *
            params.row.SALE_PRICE;

          if (value) {
            return <div className="pr-4">{FormatNumber2(value)} </div>;
          } else {
            return '';
          }
        } else {
          const value =
            (params.row.PRV_BAL + params.row.IQNTY - params.row.SQNTY) *
            params.row.SALE_PRICE;

          if (value) {
            return <div className="pr-4">***</div>;
          } else {
            return '';
          }
        }
      },

      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-16 leading-4 whitespace-normal"
          style={{ fontSize: 13 }}
        >
          Balance Amount
        </strong>
      ),
    },
  ];

  return columns;
};

export const DashboardMobileColumns = (defaultProfile) => {
  const columnsMobileView = [
    {
      field: 'INAME',
      type: 'string',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <strong className="text-[#555a66]" style={{ fontSize: 10 }}>
          {' '}
          Item{' '}
        </strong>
      ),
    },
    {
      field: 'PRV_BAL',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 18,
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      align: 'right',

      renderHeader: (params) => (
        <strong
          className="text-[#555a66] w-16 leading-4 whitespace-normal"
          style={{ fontSize: 10 }}
        >
          {' '}
          Prev. Balance
        </strong>
      ),
    },
    {
      field: 'IQNTY',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 20,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (defaultProfile.show_today_issued === 'YES') {
          if (params.value) {
            return FormatNumber3(params.value);
          } else {
            return '';
          }
        } else {
          if (params.value) {
            return '***';
          } else {
            return '';
          }
        }
      },
      renderHeader: (params) => (
        <strong className="text-[#555a66] " style={{ fontSize: 10 }}>
          {' '}
          Issued
        </strong>
      ),
    },

    {
      field: 'SQNTY',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 17,

      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      renderHeader: (params) => (
        <strong className="text-[#555a66]   " style={{ fontSize: 10 }}>
          {' '}
          Sales Qnty
        </strong>
      ),
    },

    {
      field: 'BQNTY',
      type: 'number',
      headerClassName: 'super-app-theme--header',
      width: width * 17,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <strong className="text-[#555a66] " style={{ fontSize: 10 }}>
          Balance Qnty
        </strong>
      ),
    },
  ];
  return columnsMobileView;
};
