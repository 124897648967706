import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
var defaultValue3 = new Date();

const lastDate = localStorage.getItem('lastDate');

var defaultValue2 = moment(defaultValue3).format('YYYY-MM-DD');

export const RefreshApi = createSlice({
  name: 'refresh',
  initialState: {
    payMode: {
      status: 0,
    },
    outLet: {
      status: 0,
    },
    newLoginRequest: false,
    itemGroup: {
      status: 0,
    },
    section: {
      status: 0,
    },
    restaurantApp: {
      status: 0,
    },
    fastFood: {
      status: 0,
    },
    reportType: '',

    date: {
      dataDate: lastDate ? lastDate : defaultValue2,
    },
    firstDate: moment().format('YYYY-MM-DD'),
    secondDate: moment().format('YYYY-MM-DD'),
  },
  reducers: {
    setDate(state, action) {
      state.date.dataDate = action.payload;
      localStorage.setItem('lastDate', action.payload);
    },
    setReportType(state, action) {
      state.reportType = action.payload;
    },
    setFirstDate(state, action) {
      state.firstDate = action.payload;
    },
    setSecondDate(state, action) {
      state.secondDate = action.payload;
    },
    setnewLoginRequest(state, action) {
      state.newLoginRequest = !state.newLoginRequest;
    },
    setpaymodStatus(state, action) {
      state.payMode.status = state.payMode.status + 1;
    },
    setOutletStatus(state, action) {
      state.outLet.status = state.outLet.status + 1;
    },
    setItemGroupStatus(state, action) {
      state.itemGroup.status = state.itemGroup.status + 1;
    },
    setSectionStatus(state, action) {
      state.section.status = state.section.status + 1;
    },
    setResturantStatus(state, action) {
      state.restaurantApp.status = state.restaurantApp.status + 1;
    },
    setFastFoodStatus(state, action) {
      state.fastFood.status = state.fastFood.status + 1;
    },
  },
});

export default RefreshApi.reducer;

export const {
  setSectionStatus,
  setFirstDate,
  setSecondDate,
  setOutletStatus,
  setnewLoginRequest,
  setFastFoodStatus,
  setReportType,
  setItemGroupStatus,

  setResturantStatus,
  setpaymodStatus,
  setDate,
} = RefreshApi.actions;

export const SelectPaymodeStatus = (state) => state.refresh.payMode.status;
export const SelectItemGroupStatus = (state) => state.refresh.itemGroup.status;
export const SelectResturantStatus = (state) =>
  state.refresh.restaurantApp.status;
export const SelectFastFoodStatus = (state) => state.refresh.fastFood.status;
export const SelectDate = (state) => state.refresh.date.dataDate;

export const SelectOutlet = (state) => state.refresh.outLet.status;
export const SelectSection = (state) => state.refresh.section.status;
export const selectLoginRequestRefresh = (state) =>
  state.refresh.newLoginRequest;
export const selectFirstDate = (state) => state.refresh.firstDate;
export const selectSecondDate = (state) => state.refresh.secondDate;
export const selectReportType = (state) => state.refresh.reportType;
