import React from 'react';

import Modal from '@mui/material/Modal';

import logo from '../utility/images/WGLOGO.png';

import GetOtp from '../routeBilling/menu/getOtp';

export default function ModGetOtp() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    boxShadow: 24,
    borderRadius: '3px',
  };

  const styleMobile = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: '2px solid #000',
    boxShadow: 24,
    borderRadius: '5px',
  };
  return (
    <div className=" items-center  flex w-screen bg-[#383535]  h-screen justify-center ">
      <Modal open={true}>
        <div
          style={window.innerWidth > 768 ? style : styleMobile}
          className="w-auto 4k:w-4/12 4k:h-4/6 pt-7 pb-1 h-auto   bg-white    flex flex-col items-center"
        >
          <div>
            <div
              style={{
                marginTop: -60,
                flexDirection: 'row',
                justifyContent: 'center',
                height: 90,
                width: 94,
                backgroundColor: 'white',
                borderRadius: 100,
              }}
            >
              <img src={logo} alt="" />
            </div>
            <div
              style={{
                fontSize: 20,
              }}
              className=" mt-4 font-medium text-[#121111] text-center"
            >
              Login
            </div>
          </div>
          <div
            style={{
              borderTop: '1px solid #bab3b3',
              height: '1px',
              width: '100%',
              marginTop: '16px',
            }}
          />

          <div className=" w-full h-full ">
            <GetOtp />
          </div>
        </div>
      </Modal>
    </div>
  );
}
