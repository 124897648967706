import moment from 'moment';

import React from 'react';
import { useSelector } from 'react-redux';

import { amountToWords2 } from '../../../../../utility/component/amountinWords2';
import { toProperCase } from '../../../../../utility/component/format';
import { FormatNumber2 } from '../../../../../utility/component/tools';

import {
  selectAccountDetails,
  selectCompanyAddress,
  selectStationName,
} from '../../../../../utility/Redux/profile';

import {
  SelectBillSettlementDetails,
  selectCartItems,
  selectCurrentInvoiceBillNumber,
} from '../../../../../utility/Redux/tableOrder';

const BillDesign = React.forwardRef((props, ref) => {
  const total = props?.total;
  const items = props?.billItems;

  const bill = props?.billing;
  const currentInvoice = useSelector(selectCurrentInvoiceBillNumber);
  const stationName = useSelector(selectStationName);
  const accDetails = useSelector(selectAccountDetails);
  const { accName, accAddress } = accDetails;
  const billDetails = useSelector(SelectBillSettlementDetails);
  const { btc, card, cash, coupanAmount, epaymentAmount } = billDetails;
  const comapnyAddress = useSelector(selectCompanyAddress);

  const cartItems = useSelector(selectCartItems);

  const { address, city, email, mobile, state, companyName, companyWebsite } =
    comapnyAddress;
  // const subtotal = items?.reduce((acc, item) => acc + item.rate * item.qty, 0);

  return (
    <div
      // style={{ width: "122px", height: "auto" }}
      style={{ height: 'auto' }}
      className=" bg-white  p-4"
      ref={ref}
    >
      <div className="w-full">
        <div className="w-full flex justify-center h-auto ">
          <img
            style={{ width: '40%', height: 'auto' }}
            src={require('../../../../../utility/images/smrat.png')}
            className={'w-full h-auto'}
          />
        </div>
        <div
          style={{ fontSize: 14 }}
          className="w-full flex justify-center font-semibold"
        >
          {companyName}
        </div>
        <div style={{ fontSize: 12 }} className="w-full flex justify-center ">
          {address},{city}
        </div>
        <div style={{ fontSize: 12 }} className="w-full flex justify-center ">
          {state} , {mobile}
        </div>
        <div style={{ fontSize: 12 }} className="flex">
          {' '}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {email}
          </div>{' '}
        </div>
        <div className="flex">
          {' '}
          <div
            style={{ fontSize: 12 }}
            className="w-full flex justify-center pl-2"
          >
            {companyWebsite}
          </div>{' '}
        </div>

        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1   border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-black "
        ></div>
        <div
          style={{ fontSize: 12 }}
          className="w-full text-sm mt-2 mb-2 font-semibold flex items-center justify-center"
        >
          {' '}
          <div>TAX INVOICE</div>
        </div>

        <div className="w-full flex justify-between">
          <div className="w-full">
            <div
              style={{ fontSize: 12 }}
              className="w-full  flex justify-between"
            >
              <div>Bill No. : {currentInvoice}</div>
              <div style={{ fontSize: 12 }}>
                {' '}
                Date:{moment(cartItems[0].DATE).format('YYYY-MM-DD : h.mm A')}
              </div>
            </div>
            <div
              style={{ borderTopWidth: '1px' }}
              className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-black "
            ></div>
            <div
              style={{ fontSize: 14 }}
              className="w-full  flex justify-between mt-2"
            ></div>
            <div style={{ fontSize: 12 }}>Route Name : {stationName}</div>
            <div style={{ fontSize: 12 }} className={'mt-1'}>
              Customer Name: {accName}{' '}
            </div>
            <div style={{ fontSize: 12 }} className={'mb-2'}>
              Address: {accAddress}
            </div>
          </div>
        </div>

        <table className="w-full">
          <thead
            style={{ borderTopWidth: '1px', borderBottomWidth: '1px' }}
            className="w-full  border-black h-6"
          >
            <tr className="w-full   ">
              <th
                style={{ fontSize: 12, borderRightWidth: '1px', width: '14%' }}
                className=" whitespace-nowrap  border-black "
              >
                <div>Item Name</div>
              </th>
              <th
                style={{ fontSize: 12, borderRightWidth: '1px', width: '20%' }}
                className="   border-black"
              >
                <div> Rate</div>
              </th>
              <th
                style={{ fontSize: 12, borderRightWidth: '1px', width: '15%' }}
                className="  border-black "
              >
                <div> Qnty</div>
              </th>
              <th style={{ fontSize: 12, width: '25%' }}>
                <div> Amount</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item, index) => {
              return (
                <tr key={index}>
                  <td
                    className=" border-black border-dotted"
                    style={{ fontSize: 12, borderRightWidth: '1px' }}
                  >
                    {item.ITEMNAME}
                  </td>
                  <td
                    className=" pr-2  border-dotted border-black text-right "
                    style={{ fontSize: 12, borderRightWidth: '1px' }}
                  >
                    {FormatNumber2(item.SALE_PRICE)}
                  </td>
                  <td
                    className=" pr-2 border-dotted text-right  border-black "
                    style={{ fontSize: 12, borderRightWidth: '1px' }}
                  >
                    {item.QNTY}
                  </td>
                  <td className="text-right pr-2 " style={{ fontSize: 12 }}>
                    {FormatNumber2(item.SALE_PRICE * item.QNTY)}
                  </td>
                </tr>
              );
            })}

            <tr
              style={{ borderTopWidth: '1px', borderBottomWidth: '1px' }}
              className=" border-black "
            >
              <td
                colSpan="3"
                className=" border-black font-semibold border-dotted pr-2"
                style={{
                  textAlign: 'right',
                  fontSize: 12,
                  borderRightWidth: '1px',
                }}
              >
                PLEASE PAY
              </td>
              <td
                className="pr-2 font-semibold border-black"
                style={{ fontSize: 12, textAlign: 'right' }}
              >
                {FormatNumber2(total)}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          style={{
            fontSize: 12,
          }}
          className={'w-full flex justify-center mt-1'}
        >
          {toProperCase(amountToWords2(total)) + ' ' + 'Only'}
        </div>
        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-1 border-black "
        ></div>
        <div
          style={{
            fontSize: 12,
          }}
          className={'w-full flex font-semibold justify-center'}
        >
          Amount Received
        </div>

        <div className="flex w-full justify-center items-center ">
          {cash ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              Cash Amount : {cash}{' '}
            </div>
          ) : null}

          {card ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {cash ? <div className="px-2"> | </div> : null}
              Card Amount : {card}{' '}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center">
          {btc ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              BTC Amount : {btc}{' '}
            </div>
          ) : null}
          {btc && epaymentAmount ? <div className="px-2">|</div> : null}

          {epaymentAmount ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              ePayment Amount : {epaymentAmount}{' '}
            </div>
          ) : null}
        </div>

        <div className="w-full flex justify-center items-center ">
          {coupanAmount ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              Coupon Amount : {coupanAmount}{' '}
            </div>
          ) : null}
          {coupanAmount && coupanAmount ? <div className="px-2">|</div> : null}
          {coupanAmount ? (
            <div
              style={{
                fontSize: 12,
              }}
              className={' flex justify-center'}
            >
              {' '}
              Cheque Amount : {coupanAmount}{' '}
            </div>
          ) : null}
        </div>

        <div
          style={{ borderTopWidth: '1px' }}
          className="w-full h-1  border-dotted border-r-0 bordere-l-0 border-b-0 border-l-0 border mt-2 border-black "
        ></div>

        <div
          style={{
            fontSize: 11,
          }}
          className={' flex w-full justify-center mt-1 text-center'}
        >
          All Disputes Subjected to Jalandhar Jurisdiction.
        </div>

        <div
          style={{
            fontSize: 11,
          }}
          className={' flex justify-center'}
        >
          Powered by : www.geniusoffice.com
        </div>
      </div>
    </div>
  );
});

export default BillDesign;
