import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCollectionAccountDetails,
  setALertStatus,
  setAlertMsg,
  setCollectionAccountDetails,
  setConfirmSaveVoucher,
  setModPartyMasterHelpStatus,
  setVocherModalStatus,
} from '../../Redux/modal';
import ModalHeaderStyle from '../styles/modalHeader';
import { selectToken } from '../../Redux/security';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
} from '../../Redux/profile';
import moment from 'moment';
import { ExitButton, GeniusOfficeButton, SaveButton } from '../buttons';
import { useFocus } from '../../hooks/hooks';
import axios from 'axios';
import GeniustextInput from '../mastertext';
import { useNavigate } from 'react-router-dom';
import CollPrintView from '../collPrintView';
import ColVoucPrintModal from './modColVouPrint';
import ModConfirmSaveVoucher from './modconfirmSaveVoucher';
import ModPartyMasterHelp from '../../../master/partyMasterHelp';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

const ModalCollectionVoucher = () => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const stationId = useSelector(selectStationId);
  const accDetails = useSelector(selectCollectionAccountDetails);
  const [disableSaveButton, setDisableSAveButton] = useState(true);

  const [paymode, setPaymode] = useState('CASH');
  const [latitude, setLatitute] = useState('');
  const [longitude, setlongitude] = useState('');
  const [amtFocus, setAmtFocus] = useFocus();
  const [descFocus, setDescFocus] = useFocus();
  const componentRef = useRef(null);
  const StationName = useSelector(selectStationName);
  const [data, setData] = useState({
    VOUCHER_NO: '',

    DATE: moment().format('YYYY-MM-DD : h.mm'),
    PAY_MODE: paymode,
    PREV_BAl: '',
    CARD_NO: '',
    BANK_NAME: '',
    EPAYMENT_BANK: '',
    ACCOUNT_NO: accDetails.accNumber,
    NAME: accDetails.accName,
    ADDRESS: accDetails.accAddress,
    MOBILE: accDetails.accMobile,
    AMOUNT_RECEIVED: '0',
    AMOUNT_PAID: '0',
    AMOUNT_DESC: '',
    INVNO: '0',
    SID: stationId,
    LATITUDE: latitude ? latitude : '38.8951',
    LONGITUDE: longitude ? longitude : '-77.0364',
    APPROVED: 'NO',
    SNAME: StationName,
  });

  const clearData = () => {
    setData({
      VOUCHER_NO: '',

      DATE: moment().format('YYYY-MM-DD : h.mm'),
      PAY_MODE: paymode,
      PREV_BAl: '',
      CARD_NO: '',
      BANK_NAME: '',
      EPAYMENT_BANK: '',
      ACCOUNT_NO: accDetails.accNumber,
      NAME: accDetails.accName,
      ADDRESS: accDetails.accAddress,
      MOBILE: accDetails.accMobile,
      AMOUNT_RECEIVED: '0',
      AMOUNT_PAID: '0',
      AMOUNT_DESC: '',
      INVNO: '0',
      SID: stationId,
      LATITUDE: latitude ? latitude : '38.8951',
      LONGITUDE: longitude ? longitude : '-77.0364',
      APPROVED: 'NO',
      SNAME: StationName,
    });
  };

  useEffect(() => {
    dispatch(setModPartyMasterHelpStatus(true));
  }, []);

  const handleClose = () => {
    dispatch(setVocherModalStatus(false));
    navigate('/updation');
    dispatch(
      setCollectionAccountDetails({
        accountDetails: {
          accName: '',
          accAddress: '',
          accMobile: '',
          accNumber: '',
          stationId: '',
        },
      })
    );
  };

  const handleSave = () => {
    if (!disableSaveButton) {
      if (paymode) {
        if ((paymode === 'CARD') & !data.CARD_NO) {
          dispatch(setAlertMsg('Card number can not be empty'));
          dispatch(setALertStatus(true));
        } else {
          if ((paymode === 'CHEQUE') & !data.BANK_NAME) {
            dispatch(setAlertMsg('Bank name can not be empty'));
            dispatch(setALertStatus(true));
          } else {
            if ((paymode === 'EPAYMENT') & !data.EPAYMENT_BANK) {
              dispatch(setAlertMsg('ePayment bank can not be empty'));
              dispatch(setALertStatus(true));
            } else {
              if (data.AMOUNT_RECEIVED > 0) {
                if (data.AMOUNT_DESC) {
                  dispatch(setConfirmSaveVoucher(true));
                } else {
                  dispatch(setAlertMsg('Please enter the description.'));
                  dispatch(setALertStatus(true));
                  setDescFocus();
                }
              } else {
                setAmtFocus();
                dispatch(setAlertMsg('Please enter amount received.'));
                dispatch(setALertStatus(true));
              }
            }
          }
        }
      } else {
        dispatch(setAlertMsg('Please select any payment mode.'));
        dispatch(setALertStatus(true));
      }
    } else {
      dispatch(setALertStatus(true));
      dispatch(setAlertMsg('Something wrong with previous balance.'));
    }
  };

  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitute(position.coords.latitude);
        setlongitude(position.coords.longitude);
      },
      (error) => {
        if (isProduction) {
          dispatch(
            setAlertMsg(
              'To continue using this app, please enable location services.'
            )
          );
          dispatch(setALertStatus(true));
          handleClose();
          dispatch(setModPartyMasterHelpStatus(false));
        }
        console.log(error);
      }
    );
  }, []);

  const getPreviusBalance = () => {
    CallAPIPromise(
      '/api/reports/partyBalance',
      {
        table_prefix: prefix,
        ACCOUNT_NO: accDetails.accNumber,
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          const list = res.data.response;
          const prevBal =
            list[0].OP_DEBIT +
            list[0].DEBIT -
            (list[0].OP_CREDIT + list[0].CREDIT);
          setData({
            VOUCHER_NO: '',
            table_prefix: prefix,
            DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
            PAY_MODE: paymode,
            CARD_NO: '',
            BANK_NAME: '',
            EPAYMENT_BANK: '',
            ACCOUNT_NO: accDetails.accNumber,
            NAME: accDetails.accName,
            ADDRESS: accDetails.accAddress,
            MOBILE: accDetails.accMobile,
            AMOUNT_RECEIVED: 0,
            AMOUNT_PAID: '0',
            AMOUNT_DESC: '',
            INVNO: '0',
            SID: stationId,
            LATITUDE: latitude ? latitude : '38.8951',
            LONGITUDE: longitude ? longitude : '-77.0364',
            PREV_BAl: prevBal,
            APPROVED: 'NO',
            SNAME: StationName,
          });

          if (prevBal >= 0) {
            setDisableSAveButton(false);
          }
          if (prevBal < 0) {
            setDisableSAveButton(false);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (accDetails.accNumber) {
      getPreviusBalance();
    }
  }, [accDetails]);

  const windowWidth = useSelector(selectWidth);

  const navigate = useNavigate();
  const userType = useSelector(selectUserType);
  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7] overflow-y-scroll py-5 ">
      <div style={{ borderRadius: '12px' }} className=" h-full">
        <div ref={headerRef}>
          <ModalHeaderStyle
            header={'Collection Voucher'}
            onClose={() => {
              navigate('/updation');
            }}
          />
        </div>

        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="flex flex-col  "
        >
          <div
            style={{
              height: 'calc(100% -80px)',
            }}
            className="  py-3 px-5 justify-center p-3  pt-5 bg-white "
          >
            <div className="flex">
              <div className=" l:hidden flex flex-row l:flex-col px-2 justify-center">
                <div>
                  <div className="mb-2 l:mb-0">
                    <GeniusOfficeButton
                      checked={paymode === 'CASH' ? true : false}
                      title={'Cash Amt.'}
                      onClick={() => {
                        setPaymode('CASH');
                        setData({
                          ...data,
                          AMOUNT_DESC: 'CASH RECEIVED',
                        });
                      }}
                      color={'#ffffff'}
                    />
                  </div>

                  <div className="mb-2 l:mb-0">
                    <GeniusOfficeButton
                      checked={paymode === 'CARD' ? true : false}
                      title={'Card Amt.'}
                      onClick={() => {
                        setPaymode('CARD');
                        setData({
                          ...data,
                          AMOUNT_DESC: 'CARD PAYMENT RECEIVED',
                        });
                      }}
                      color={'#ffffff'}
                    />
                  </div>
                </div>
                <div>
                  <div className="mb-2 l:mb-0">
                    <GeniusOfficeButton
                      checked={paymode === 'CHEQUE' ? true : false}
                      title={'Cheque Amt.'}
                      onClick={() => {
                        setPaymode('CHEQUE');
                        setData({
                          ...data,
                          AMOUNT_DESC: 'CHEQUE PAYMENT RECEIVED',
                        });
                      }}
                      color={'#ffffff'}
                    />
                  </div>

                  <div className="mb-2 l:mb-0">
                    <GeniusOfficeButton
                      checked={paymode === 'EPAYMENT' ? true : false}
                      title={'Epayment'}
                      onClick={() => {
                        setPaymode('EPAYMENT');
                        setData({
                          ...data,
                          AMOUNT_DESC: 'EPAYMENT RECEIVED',
                        });
                      }}
                      color={'#ffffff'}
                    />
                  </div>
                </div>
              </div>

              <div className=" hidden l:flex l:flex-row  px-2 justify-center">
                <div className="mb-2 l:mb-0">
                  <GeniusOfficeButton
                    checked={paymode === 'CASH' ? true : false}
                    title={'Cash Amt.'}
                    onClick={() => {
                      setPaymode('CASH');
                      setData({
                        ...data,
                        AMOUNT_DESC: 'CASH RECEIVED',
                      });
                    }}
                    color={'#ffffff'}
                  />
                </div>

                <div className="mb-2 l:mb-0">
                  <GeniusOfficeButton
                    checked={paymode === 'CARD' ? true : false}
                    title={'Card Amt.'}
                    onClick={() => {
                      setPaymode('CARD');
                      setData({
                        ...data,
                        AMOUNT_DESC: 'CARD PAYMENT RECEIVED',
                      });
                    }}
                    color={'#ffffff'}
                  />
                </div>

                <div className="mb-2 l:mb-0">
                  <GeniusOfficeButton
                    checked={paymode === 'CHEQUE' ? true : false}
                    title={'Cheque Amt.'}
                    onClick={() => {
                      setPaymode('CHEQUE');
                      setData({
                        ...data,
                        AMOUNT_DESC: 'CHEQUE PAYMENT RECEIVED',
                      });
                    }}
                    color={'#ffffff'}
                  />
                </div>

                <div className="mb-2 l:mb-0">
                  <GeniusOfficeButton
                    checked={paymode === 'EPAYMENT' ? true : false}
                    title={'ePayment'}
                    onClick={() => {
                      setPaymode('EPAYMENT');
                      setData({
                        ...data,
                        AMOUNT_DESC: 'EPAYMENT RECEIVED',
                      });
                    }}
                    color={'#ffffff'}
                  />
                </div>
              </div>
            </div>

            <div className="flex mt-4">
              <GeniustextInput
                title={'Voucher Number'}
                width={windowWidth > 764 ? '150px' : '150px'}
                type={'number'}
                value={data.voucher}
              />
              <div className="ml-2">
                <GeniustextInput
                  title={'Date'}
                  width={windowWidth > 764 ? '150px' : '150px'}
                  type={'text'}
                  value={moment().format('YYYY-MM-DD')}
                />
              </div>
            </div>

            <div className="flex">
              <GeniustextInput
                title={'Party Name'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                value={data.NAME}
              />
              <div className="ml-2">
                <GeniustextInput
                  title={'Previous Balance'}
                  width={windowWidth > 764 ? '150px' : '150px'}
                  type={'number'}
                  value={data.PREV_BAl}
                />
              </div>
            </div>

            <div className="flex">
              <GeniustextInput
                title={'Address'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                value={data.ADDRESS}
              />
              <div className="ml-2">
                <GeniustextInput
                  title={'Amount Received'}
                  width={windowWidth > 764 ? '150px' : '150px'}
                  type={'number'}
                  value={data.AMOUNT_RECEIVED}
                  onChange={(t) => {
                    setData({
                      ...data,
                      AMOUNT_RECEIVED: t.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex">
              <GeniustextInput
                title={'Mobile'}
                width={windowWidth > 764 ? '300px' : '300px'}
                type={'number'}
                value={data.MOBILE}
              />
            </div>

            <div className="flex">
              <GeniustextInput
                title={'Description'}
                width={windowWidth > 764 ? '450px' : '300px'}
                type={'text'}
                ref={descFocus}
                value={data.AMOUNT_DESC}
                onChange={(t) => {
                  setData({
                    ...data,
                    AMOUNT_DESC: t.target.value,
                  });
                }}
              />
            </div>

            {paymode === 'CASH' || paymode === '' ? null : (
              <div>
                <GeniustextInput
                  title={
                    paymode === 'CARD'
                      ? 'Card Number'
                      : paymode === 'EPAYMENT'
                      ? 'ePayment Bank'
                      : paymode === 'CHEQUE'
                      ? 'Bank Name'
                      : 'Default Title'
                  }
                  width={windowWidth > 764 ? '450px' : '150px'}
                  type={'text'}
                  value={
                    paymode === 'CARD'
                      ? data.CARD_NO
                      : paymode === 'EPAYMENT'
                      ? data.EPAYMENT_BANK
                      : paymode === 'CHEQUE'
                      ? data.BANK_NAME
                      : ''
                  }
                  onChange={(t) => {
                    if (paymode === 'CARD') {
                      setData({
                        ...data,
                        CARD_NO: t.target.value,
                      });
                    }

                    if (paymode === 'CHEQUE') {
                      setData({
                        ...data,
                        BANK_NAME: t.target.value,
                      });
                    }

                    if (paymode === 'EPAYMENT') {
                      setData({
                        ...data,
                        EPAYMENT_BANK: t.target.value,
                      });
                    }
                  }}
                />
              </div>
            )}
          </div>

          <div className="hidden print:flex">
            <CollPrintView ref={componentRef} data={data} paymode={paymode} />
          </div>
          <div className="w-full ">
            <ColVoucPrintModal data={data} paymode={paymode} />
            <ModConfirmSaveVoucher data={data} clearData={clearData} />
            <ModPartyMasterHelp
              SID={userType === 'USER' ? stationId : ''}
              onRowClick={(params) => {
                const name = params.row.NAME;
                const mobile = params.row.MOBILE;
                const accNumber = params.row.ACCOUNT_NO;
                const address = params.row.ADDRESS;
                const stationId = params.row.SID;
                const stationName = params.row.SNAME;
                dispatch(
                  setCollectionAccountDetails({
                    accName: name,
                    accAddress: address,
                    accMobile: mobile,
                    accNumber: accNumber,
                    stationId: stationId,
                    sName: stationName,
                  })
                );
                dispatch(setModPartyMasterHelpStatus(false));
              }}
              onClose={() => {
                dispatch(setModPartyMasterHelpStatus(true));
                navigate('/reports');
              }}
            />
          </div>

          <div
            style={{
              borderTop: '1px solid #D2D2D7',
            }}
            className="flex justify-end bg-white py-5 rounded-b-sm  px-4"
          >
            <SaveButton
              onClick={() => {
                handleSave();
              }}
            />
            <ExitButton onClick={handleClose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalCollectionVoucher;
