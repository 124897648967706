import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectListStationMasterModalStatus,
  setListStationMasterModalStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../../Redux/modal';
import MasterHelp from './masterHelp';
import { selectToken } from '../../Redux/security';
import { selectPrefix, selectWidth } from '../../Redux/profile';
import axios from 'axios';
import { CallAPIPromise } from '../../../routeBilling/menu/dashboard/biiling/modal/comman';

export default function ModListStationMaster({ onRowClick }) {
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [fullList, setFullList] = useState([]);
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectListStationMasterModalStatus);

  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: '85%',
    backgroundColor: 'white',
    padding: '2px',
    borderRadius: '8px',
    overflow: 'hidden',
  };
  const width = useSelector(selectWidth);
  const columns = React.useMemo(
    () => [
      {
        field: 'CODE',
        type: 'string',
        width:
          width <= 768 ? 80 : width <= 1024 ? 150 : width <= 1440 ? 100 : 100,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Station Id'}</strong>,
      },
      {
        field: 'STATION_NAME',
        type: 'number',
        width:
          width <= 768 ? 120 : width <= 1024 ? 300 : width <= 1440 ? 300 : 330,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <strong>{'Station Name'}</strong>,
      },
    ],
    []
  );

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getListStationMaster = () => {
    dispatch(setSpinnerLoading('Loading Station'));
    CallAPIPromise(
      `/api/masters/listStationMaster`,
      {
        table_prefix: prefix,
        CODE: '',
      },
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setFullList(data);
          setList(data);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.STATION_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setList(searchItems);
    } else {
      setList(fullList);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (ModalStatus) {
      getListStationMaster();
    }
  }, [ModalStatus]);
  return (
    <>
      <MasterHelp
        status={ModalStatus}
        style={style}
        onClose={() => {
          dispatch(setListStationMasterModalStatus(false));
          setList([]);
        }}
        title={'Station Master | Help'}
        columns={columns}
        list={list}
        rowId={(p) => p.CODE}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          onRowClick(p);
          setList([]);
        }}
      />
    </>
  );
}
