import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import CryptoJS from 'crypto-js';
import { child, get, set, ref } from 'firebase/database';
import database from '../routeBilling/issueStock/firebase';
import deleteWholeDatabase from '../utility/functions/deleteWholeDatabase';
import HandleIndexedDB from '../utility/functions/indexDbDatabase';
import {
  setCompany,
  setCompanyAddress,
  setCurrency,
  setdevloper,
  setDevloperMode,
  setemail,
  setfirebaseStatusField,
  setPrefix,
  setreportsFromDate,
  setreportsTodate,
  setStationID,
  setStationName,
  setUserCode,
  setUserName,
  setUserType,
} from '../utility/Redux/profile';
import {
  setExpireAt,
  setLogin,
  setLoginData,
  setOtpMobile,
  setOtpStatus,
  setRandomOtp,
  setToken,
  setUserData,
} from '../utility/Redux/security';
import moment from 'moment';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';
const secretPass = process.env.REACT_APP_SECRET_KEY;

export const loadUserInfo = (email, mobile, hmacString, dispatch) => {
  return new Promise((resolve, reject) => {
    CallAPIPromise(
      '/api/users/userInfo',
      {
        user_email: email ? email : '',
        user_mobile: mobile ? mobile : '',
      },
      hmacString,
      dispatch
    )
      .then((e) => {
        if (!e.data.error) {
          if (e?.data?.response[0]) {
            if (e?.data?.response[0].email_verified === 'N') {
              reject(
                'Your account email needs to be verified. Please check your inbox for a verification link'
              );
            } else {
              if (Number(e?.data?.response[0].valid_user) !== 1) {
                reject(
                  'Thank you for registering. Your account is currently being processed. You will be able to login once your account has been activated.'
                );
              } else {
                resolve(e?.data?.response[0]);
              }
            }
          } else {
            reject('User not found');
          }
        } else {
          reject('Something went wrong');
        }
      })
      .catch(() => {
        reject('Something went wrong');
      });
  });
};
export const verifyCredintails = (
  email,
  mobile,
  password,
  userData,
  hmacString,
  dispatch
) => {
  return new Promise((resolve, reject) => {
    const data = {
      user_email: email ? email : '',
      user_mobile: mobile ? mobile : '',
      user_password: password,
      code: userData.code,
    };
    CallAPIPromise('/api/users/login', data, hmacString, dispatch)
      .then((response) => {
        if (response !== null) {
          if (response.data.error) {
            reject(response.data.error);

            dispatch(setSpinnerLoading(false));
          } else {
            resolve(response);
          }
        } else {
          reject('Something went wrong');
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error) {
          reject(e?.response?.data?.error);
        } else {
          reject('Something went wrong');
        }
      });
  });
};

const encryptEmail = (email) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(email),
    secretPass
  ).toString();

  localStorage.setItem('email', data);
};
const encryptData = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    secretPass
  ).toString();

  localStorage.setItem('prefix', data);
};

const encryptUserType = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    secretPass
  ).toString();

  localStorage.setItem('userType', data);
};

const encryptDevMode = (text) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    secretPass
  ).toString();

  localStorage.setItem('dev', data);
};

const storeRememberData = async (email, passwrd) => {
  const e = CryptoJS.AES.encrypt(JSON.stringify(email), secretPass).toString();
  const p = CryptoJS.AES.encrypt(
    JSON.stringify(passwrd),
    secretPass
  ).toString();

  localStorage.setItem(
    'rememberd',
    JSON.stringify({
      email: e,
      password: p,
    })
  );
};
export function isSubdomain(url) {
  const hostname = new URL(url).hostname;

  const parts = hostname.split('.');
  const filteredParts = parts.filter((part) => part.toLowerCase() !== 'www');
  if (filteredParts.length >= 2 && filteredParts[0].toLowerCase() !== 'www') {
    // Check if there are at least 3 parts, and the first part is not 'www'
    return filteredParts.slice(0, -2).join('.');
  } else {
    return null;
  }
}

const getVaildOTPDays = (code, prefix, token, dispatch) => {
  return new Promise((resolve, reject) => {
    const data = {
      code: code,
      table_prefix: prefix.replace(/_.+_/, '_'),
    };
    CallAPIPromise(
      `/api/userSettings/userDefaultSetting`,
      data,
      token,
      dispatch
    )
      .then((res) => {
        if (!res.data.error) {
          if (res.data.response[0]) {
            resolve(res.data.response);
          } else {
            resolve([
              {
                login_otp: 'NO',
                valid_for_days: 1,
              },
            ]);
          }
        } else {
          resolve([
            {
              login_otp: 'NO',
              valid_for_days: 1,
            },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
        resolve([
          {
            login_otp: 'No',
            valid_for_days: 1,
          },
        ]);
      });
  });
};
function getRandomNumber() {
  const min = 1000;
  const max = 9999;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
export function runLoginFunc(
  userEmail,
  userMobile,
  hmacString,
  isFirebaseSocket,
  dispatch,
  password,
  remember,
  move
) {
  loadUserInfo(userEmail, userMobile, hmacString, dispatch)
    .then((userData) => {
      if (isFirebaseSocket) {
        dispatch(setSpinnerLoading('Checking connection'));
        verifyCredintails(
          userEmail,
          userMobile,
          password,
          userData,
          hmacString,
          dispatch
        )
          .then((response) => {
            const p = response.data.data[0].table_prefix;
            const w = p.split('_');
            const firstPart = w[0];
            dispatch(setSpinnerLoading('Authentication Successful'));
            if (
              firstPart === isSubdomain(window.location.href) ||
              window.location.host.includes('localhost') ||
              window.location.host.includes('192.168') ||
              userData.sub_domain === 'NO' ||
              (response.data.data[0].sub_domain === 'NO' &&
                !isSubdomain(window.location.href))
            ) {
              const expiresAt = new Date().getTime() + 60 * 60000;
              if (
                userData.user_type !== 'MANAGER' &&
                userData.user_type !== 'ADMIN'
              ) {
                checkFirebaseLoginManager(
                  userData.table_prefix,
                  userData.code,
                  dispatch,
                  expiresAt,
                  'status'
                )
                  .then(() => {
                    verifyLoginByOTP(
                      response,
                      userData,
                      expiresAt,
                      dispatch,
                      remember,
                      password,
                      move
                    );
                  })
                  .catch((e) => {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Allready Connected',
                      })
                    );
                  });
              } else {
                checkFirebaseLoginManager(
                  userData.table_prefix,
                  userData.code,
                  dispatch,
                  expiresAt,
                  'status'
                )
                  .then(() => {
                    verifyLoginByOTP(
                      response,
                      userData,
                      expiresAt,
                      dispatch,
                      remember,
                      password,
                      move
                    );
                  })
                  .catch((e) => {
                    checkFirebaseLoginManager(
                      userData.table_prefix,
                      userData.code,
                      dispatch,
                      expiresAt,
                      'status2'
                    )
                      .then(() => {
                        verifyLoginByOTP(
                          response,
                          userData,
                          expiresAt,
                          dispatch,
                          remember,
                          password,
                          move
                        );
                      })
                      .catch(() => {
                        checkFirebaseLoginManager(
                          userData.table_prefix,
                          userData.code,
                          dispatch,
                          expiresAt,
                          'status3'
                        )
                          .then(() => {
                            verifyLoginByOTP(
                              response,
                              userData,
                              expiresAt,
                              dispatch,
                              remember,
                              password,
                              move
                            );
                          })
                          .catch(() => {
                            dispatch(setSpinnerLoading(false));
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Allready Connected',
                              })
                            );
                          });
                      });
                  });
              }
            } else {
              dispatch(setSpinnerLoading(false));
              dispatch(
                setAlertWithTitle({
                  title: 'Error',
                  msg: `Subdomain not matched`,
                })
              );
            }
          })
          .catch((err) => {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: err,
              })
            );
          });
      }
    })
    .catch((err) => {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: err,
        })
      );
    });
}

const SendOtp = (
  MobileNumber,
  prefix,
  randomNumber,
  email,
  dispatch,
  token
) => {
  return new Promise((resolve, reject) => {
    dispatch(setSpinnerLoading('Sending OTP'));
    CallAPIPromise(
      `/api/sendOTP/sendOTP`,

      {
        table_prefix: prefix,
        user_email: email,
        mobile: MobileNumber,
        otp: randomNumber,
      },
      token,
      dispatch
    )
      .then(async function (response) {
        if (response.status === 200) {
          resolve();
        } else {
          reject();
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Something went wrong',
            })
          );
        }
      })
      .catch(function (error) {
        reject();
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Something went wrong',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  });
};

const verifyLoginByOTP = (
  response,
  userData,
  expiresAt,
  dispatch,
  remember,
  password,
  move
) => {
  getVaildOTPDays(
    userData.code,
    userData.table_prefix,
    response.data.token,
    dispatch
  ).then((d) => {
    if (d[0]) {
      const isOtpRequired = d[0]?.login_otp === 'YES';
      if (isOtpRequired) {
        const randomeNumber = getRandomNumber();

        SendOtp(
          userData.company_mobile,
          userData.table_prefix,
          randomeNumber,
          userData.user_email,
          dispatch,
          response.data.token
        ).then(() => {});
        console.log(response);
        dispatch(setOtpStatus(true));
        dispatch(setRandomOtp(randomeNumber));
        dispatch(setOtpMobile(userData.company_mobile));
        dispatch(setUserData(userData));
        dispatch(
          setLoginData({
            ...response.data.data[0],
            token: response.data.token,
          })
        );
        dispatch(setExpireAt(expiresAt));
        dispatch(setSpinnerLoading(false));
      } else {
        moveHome(
          response,
          userData,
          expiresAt,
          dispatch,
          remember,
          password,
          move
        );
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Something went wrong',
        })
      );
    }
  });
};
const checkFirebaseLoginManager = (
  prefix,
  userCode,
  dispatch,
  expiresAt,
  statusField
) => {
  return new Promise((resolve, reject) => {
    const connected = ref(database, `${prefix}/user/${userCode}`);
    const setConnectMsgRef = ref(
      database,
      `${prefix}/user/${userCode}/${statusField}/msg`
    );
    const setConnectRef = ref(
      database,
      `${prefix}/user/${userCode}/${statusField}/connection`
    );

    // Dispatch initial state
    dispatch(setfirebaseStatusField(statusField));

    // Set the connection message
    set(setConnectMsgRef, `isConnected`);

    // Proceed to check rejection status
    checkIsRejected(connected, statusField, dispatch)
      .then(() => {
        set(setConnectRef, `c-${expiresAt}`);
        set(setConnectMsgRef, `connected`);

        dispatch(setfirebaseStatusField(statusField));
        // If checkIsRejected resolves, resolve the outer promise
        resolve();
      })
      .catch((error) => {
        console.log(error);
        // If checkIsRejected rejects, reject the outer promise with the error
        reject(error);
      });
  });
};

const checkIsRejected = (connectedRef, statusField, dispatch) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      get(child(connectedRef, `/${statusField}/msg`))
        .then((msg) => {
          if (msg.exists()) {
            const data = msg.val();
            if (!data.includes('rejected')) {
              resolve(); // Successfully resolved
            } else {
              reject(new Error('Connection rejected')); // Rejected with an error
            }
          } else {
            reject(new Error('No message found')); // Rejected due to missing message
          }
        })
        .catch((err) => {
          console.log(err);

          reject(err); // Handle any errors from the Firebase get function
        });
    }, 2000);
  });
};

export const moveHome = (
  response,
  userDetail,
  experiDate,
  dispatch,
  remember,
  password,
  move
) => {
  dispatch(setSpinnerLoading(false));
  dispatch(setUserCode(userDetail.code));

  encryptEmail(userDetail.user_email);
  const databaseName = 'userDetails';
  const storeName = 'data';
  if (userDetail) {
    deleteWholeDatabase(databaseName);

    const dataToSave = [
      {
        start_date: userDetail.start_date,
        end_date: userDetail.end_date,
        currency_name: userDetail.currency_name,
        currency_symbol: userDetail.currency_symbol,
        user_email: userDetail.user_email,
        ICODE: 1,
        address: userDetail.address,
        city: userDetail.city,
        company_email: userDetail.company_email,
        company_name: userDetail.company_name,
        company_website: userDetail.company_website,
        user_mobile: userDetail.user_mobile,
        user_name: userDetail.user_name,
      },
    ];

    HandleIndexedDB({
      databaseName,
      storeName,
      dataToSave,
    });
  }

  localStorage.setItem('expiresAt', experiDate);

  dispatch(setToken(response.data.token));

  dispatch(setLogin(true));
  dispatch(setUserName(response.data.data[0].user_name));
  dispatch(setCompany(response.data.data[0].company_name));
  dispatch(setPrefix(response.data.data[0].table_prefix));
  dispatch(setStationName(response.data.data[0].station_name));

  localStorage.setItem(
    'companyDetails',
    JSON.stringify({
      address: userDetail.address,
      city: userDetail.city,
      state: userDetail.state,
      mobile: userDetail.company_mobile,
      email: userDetail.company_email,
      companyName: userDetail.company_name,
      companyWebsite: userDetail.company_website,
      start_date: userDetail.start_date,
      end_date: userDetail.end_date,
    })
  );

  dispatch(
    setCompanyAddress({
      address: userDetail.address,
      city: userDetail.city,
      state: userDetail.state,
      mobile: userDetail.company_mobile,
      email: userDetail.company_email,
      companyName: userDetail.company_name,
      companyWebsite: userDetail.company_website,
    })
  );

  encryptData(response.data.data[0].table_prefix);
  encryptUserType(response.data.data[0].user_type);
  localStorage.setItem('company', response.data.data[0].company_name);
  localStorage.setItem('userName', response.data.data[0].user_name);
  if (response.data.data[0].user_type === 'MANAGER') {
    localStorage.setItem('stationId', '');
  } else {
    localStorage.setItem('stationId', response.data.data[0].station_id);
  }

  if (response.data.data[0].user_type === 'MANAGER') {
    localStorage.setItem('stationName', '');
  } else {
    localStorage.setItem('stationName', response.data.data[0].station_name);
  }

  dispatch(setUserType(response.data.data[0].user_type));
  if (response.data.data[0].user_type === 'MANAGER') {
    dispatch(setStationName(''));
  } else {
    dispatch(setStationName(response.data.data[0].station_name));
  }
  localStorage.removeItem('lastDate');
  if (remember) {
    storeRememberData(userDetail.user_email, password);
  } else {
    localStorage.removeItem('rememberd');
  }
  if (response.data.data[0].user_type === 'MANAGER') {
    dispatch(setStationID(''));
  } else {
    dispatch(setStationID(response.data.data[0].station_id));
  }

  localStorage.setItem('fromDate', moment().format('YYYY-MM-DD'));
  localStorage.setItem('toDate', moment().format('YYYY-MM-DD'));
  dispatch(setreportsFromDate(moment().format('YYYY-MM-DD')));
  dispatch(setreportsTodate(moment().format('YYYY-MM-DD')));
  localStorage.setItem('tknTime', new Date().getTime() + 60 * 60000);

  move('/', {
    state: {
      sid: response.data.data[0].station_id,
      email: userDetail.user_email,
      start_date: userDetail.start_date,
      end_date: userDetail.end_date,
      currency: userDetail.currency_symbol,
    },
  });
  dispatch(setemail(userDetail.user_email));
  dispatch(setCurrency(userDetail.currency_symbol));
  dispatch(setSessionExpired(false));
};
