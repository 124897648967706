import axios from 'axios';
import moment from 'moment';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import { LoadOrderDetials } from './loadOrder';
import { CallAPIPromise } from '../../menu/dashboard/biiling/modal/comman';

export const SaveOrderBooking = async (
  data,
  token,
  dispatch,
  undo,
  setOrderDetails,
  prefix,
  date,
  SID
) => {
  dispatch(setSpinnerLoading('Saving..'));
  CallAPIPromise('/api/orderBooking/saveOrder', data, token, dispatch)
    .then((res) => {
      if (res.status === 200) {
        undo();
        LoadOrderDetials(setOrderDetails, prefix, token, date, SID, dispatch);
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
