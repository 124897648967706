import React from 'react';
import { GetSectionName } from './getSection';
import { GetItemList } from './getItemList';

const MasterFunction = (
  setSectionList,
  prefix,
  token,
  dispatch,
  setItemList,
  sname,
  setFullItemList
) => {
  if (sname) {
    GetItemList(setItemList, prefix, token, dispatch, sname, setFullItemList);
  } else {
    GetItemList(setItemList, prefix, token, dispatch, sname, setFullItemList);
    GetSectionName(setSectionList, prefix, token, dispatch);
  }

  return <div>MasterFunction</div>;
};

export default MasterFunction;
