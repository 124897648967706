import { FormatNumber2, FormatNumber3 } from './tools';
export const TotalFooter = (props) => {
  const { rows, columns } = props;

  const TotalPrevBalance = rows.reduce(
    (total, row) => Number(total) + Number(row.PRV_BAL),
    0
  );

  const TotalIssuesdQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.IQNTY),
    0
  );

  const TotalSQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.SQNTY),
    0
  );
  const TotalSAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.SAMOUNT),
    0
  );

  const TotalBalQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.BQNTY),
    0
  );

  const TotalBalAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.BAMOUNT),
    0
  );

  return (
    <div
      className="flex bg-[#e6e6e3]   items-center pl-2  d:pl-0  "
      style={{
        display: 'flex',

        alignItems: 'center',
      }}
    >
      {columns.map((p) => {
        return (
          <div
            key={p.field}
            style={
              p.flex
                ? {
                    flex: p.flex,
                    textAlign: p.align ? p.align : 'left',
                    paddingLeft: '2px',
                    borderRight: '1px solid #cfcdc8',
                    paddingRight: '10px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }
                : {
                    width: p.width,
                    textAlign: p.align ? p.align : 'left',
                    paddingLeft: '2px',
                    borderRight: '1px solid #cfcdc8',
                    paddingRight: '10px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }
            }
          >
            <div>
              {p.field === 'ICODE' && ''}
              {p.field === 'INAME' && ''}
              {p.field === 'PRV_BAL' && FormatNumber2(TotalPrevBalance)}
              {p.field === 'IQNTY' && FormatNumber3(TotalIssuesdQnty)}
              {p.field === 'IAMOUNT' && ''}
              {p.field === 'SQNTY' && FormatNumber3(TotalSQnty)}
              {p.field === 'SAMOUNT' && FormatNumber2(TotalSAmount)}
              {p.field === 'BQNTY' && FormatNumber3(TotalBalQnty)}
              {p.field === 'BAMOUNT' && FormatNumber2(TotalBalAmount)}
            </div>
          </div>
        );
      })}
    </div>
  );
};
