import axios from 'axios';
import moment from 'moment';

import { setSectionList } from '../Redux/masters';
import { setSessionExpired, setSpinnerLoading } from '../Redux/modal';
import { CallAPIPromise } from '../../routeBilling/menu/dashboard/biiling/modal/comman';

const GetAllSection = (dispatch, token, sectionId, prefix) => {
  let list = [];
  dispatch(setSpinnerLoading('Loading section list'));
  CallAPIPromise(
    `/api/itemsToSaleRoute/sectionToSale`,
    {
      table_prefix: prefix,
      sid: sectionId,
      // from_date: "2023-04-03",
      from_date: moment().format('YYYY-MM-DD'),
    },
    token,
    dispatch
  )
    .then((res) => {
      const data = res.data.response;

      if (!res.data.error) {
        for (let i = 0; i < data.length; i++) {
          list.push({
            key: i,
            Name: data[i].SECTION_NAME,
            Printer: data[i].PRINTER_NAME,
            id: i,
          });
        }

        dispatch(setSectionList(list));
      }
    })
    .catch((e) => {
      console.log(e);
      if (e.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });

  return list;
};

export default GetAllSection;
