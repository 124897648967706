import React from 'react';

const FormatPhoneNumber = (number) => {
  const cleanedNumber = number.replace(/\D/g, '');
  const formattedNumber = cleanedNumber.replace(
    /^(\d{2})(\d{4})(\d{4})$/,
    '+91 $1 $2 $3'
  );

  return formattedNumber;
};

export default FormatPhoneNumber;
