import React, { useRef, useState } from 'react';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import GeniustextInput from '../utility/component/mastertext';
import { useDispatch, useSelector } from 'react-redux';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

import {
  setAlertWithTitle,
  setItemMasterHelpStatus,
  setItemMasterModalStatus,
  setListStationMasterModalStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { ModifyButton, UndoButton } from '../utility/component/buttons';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import ItemMasterHelp from './iteemMasterHelp';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { CallAPIPromise } from '../routeBilling/menu/dashboard/biiling/modal/comman';

const UpdateItemRateStationWise = () => {
  const headerRef = useRef();
  const navigate = useNavigate();
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const dispatch = useDispatch();
  const [stationId, setStationID] = useState(false);
  const [stationName, setStationName] = useState('');
  const [itemName, setItemName] = useState('');
  const [mrp, setMrp] = useState('');
  const [icode, setIcode] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const prifix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const windowSize = useSelector(selectWidth);
  const updatItemRate = () => {
    const data = {
      table_prefix: prifix,
      ICODE: icode,
      SID: stationId,
      SALE_PRICE: salePrice,
      MRP: mrp,
    };
    dispatch(setSpinnerLoading('Updating item rate'));
    CallAPIPromise(
      `/api/issueMasterRoute/updateItemRateToRoute`,
      data,
      token,
      dispatch
    )
      .then(async function (response) {
        if (response.status === 200) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: 'Data updated successfully',
              lottie: 'success',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error 112',
              msg: 'Something went wrong',
            })
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(error);
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Something went wrong.',
            lottie: 'reject',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        undo();
      });
  };

  const undo = () => {
    setItemName('');
    setIcode('');
    setStationID('');
    setStationName('');
    setSalePrice('');
    setMrp('');
  };

  return (
    <div className="w-full flex justify-center  bg-gray-50 h-full  p-3">
      <div className="text-sm  rounded-sm  h-full overflow-hidden">
        <div ref={headerRef} className="bg-white   ">
          <ModalHeaderStyle
            header={'Update Item Rate - Station Parties'}
            onClose={() => {
              navigate('/masters');
            }}
          />
          <div
            style={{
              height: headerRef.current
                ? `calc(100% - ${headerRef.current.clientHeight}px)`
                : '90%',
            }}
            className="      flex flex-col  "
          >
            <div className="  py-3 px-5 justify-center p-3  pt-5 bg-white    ">
              <div className="t:flex  justify-between  ">
                <GeniustextInput
                  search={() => {
                    dispatch(setListStationMasterModalStatus(true));
                  }}
                  value={stationId}
                  type={'number'}
                  title={'SID'}
                  width={'280px'}
                  autofocus={true}
                  error={false}
                />
                <div className="flex justify-between t:ml-16 ">
                  <GeniustextInput
                    width={'280px'}
                    type={'text'}
                    title={'SID Name'}
                    value={stationName}
                    error={false}
                  />
                </div>
              </div>

              <div className="t:flex justify-between ">
                <GeniustextInput
                  search={() => {
                    dispatch(setItemMasterHelpStatus(true));
                  }}
                  value={itemName}
                  type={'text'}
                  title={'Item Name'}
                  titleBold={true}
                  width={'280px'}
                  error={false}
                />
                <div className="flex justify-between t:ml-16 ">
                  <GeniustextInput
                    width={'280px'}
                    type={'number'}
                    title={'MRP'}
                    value={mrp}
                    onChange={(e) => setMrp(e.target.value)}
                    error={false}
                  />
                </div>
              </div>
              <div className="t:flex justify-between">
                <div className="flex justify-between">
                  <GeniustextInput
                    width={'280px'}
                    type={'number'}
                    titleBold={true}
                    title={'Sale Price'}
                    value={salePrice}
                    onChange={(e) => setSalePrice(e.target.value)}
                    error={false}
                  />
                </div>
                <div className="t:ml-16 mt-[26px] flex justify-end items-center">
                  <ModifyButton
                    onClick={() => {
                      if (stationId) {
                        if (icode) {
                          if (salePrice > 0 && salePrice) {
                            setAlertTwoButtonModal({
                              ...alertTwoButtonModal,
                              status: true,
                              title: 'Alert',
                              msg: 'Are you sure you want to update the sale price',
                              button1: 'Yes',
                              button2: 'Cancel',

                              button1Click: () => {
                                updatItemRate();

                                setAlertTwoButtonModal({
                                  ...alertTwoButtonModal,
                                  status: false,
                                });
                              },
                              button2Click: () => {
                                setAlertTwoButtonModal({
                                  ...alertTwoButtonModal,
                                  status: false,
                                });
                              },
                            });
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Please enter Sale Price',
                              })
                            );
                          }
                        } else {
                          dispatch(
                            setAlertWithTitle({
                              title: 'Alert',
                              msg: 'Please select any Item for continue.',
                            })
                          );
                        }
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: 'Please select any Station ',
                          })
                        );
                      }
                    }}
                  />
                  <UndoButton onClick={undo} />
                </div>
              </div>
              <div className="text-gray-400 mt-5 flex">
                <div>
                  <TipsAndUpdatesIcon
                    fontSize={'small'}
                    sx={{
                      color: '#fdb608',
                    }}
                  />
                </div>
                <div className="ml-2">Update MRP & Sale Price to Station</div>
              </div>
            </div>
          </div>
        </div>
        <ModListStationMaster
          onRowClick={(p) => {
            setStationID(p.row.CODE);
            setStationName(p.row.STATION_NAME);
            dispatch(setListStationMasterModalStatus(false));
          }}
        />
        <ItemMasterHelp
          onRowClick={(p) => {
            setItemName(p.row.ITEMNAME);
            setMrp(p.row.SALE_PRICE);
            setIcode(p.row.ICODE);
            dispatch(setItemMasterHelpStatus(false));
          }}
        />
        <AlertModalTwoButton
          details={alertTwoButtonModal}
          setAlertTwoButtonModal={setAlertTwoButtonModal}
        />
      </div>
    </div>
  );
};

export default UpdateItemRateStationWise;
