const width = (window.innerWidth * 1) / 100;

export const DayEndHeader = (props) => {
  return (
    <div className="  bg-[#f5f0e8]   ">
      <div
        style={{
          borderBottom: '1px solid #ded9d9',
        }}
        className="w-full flex   "
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: 17,
          }}
          className="flex flex-1 py-1 pl-2 justify-center text-red-600 "
        ></div>
        <div
          style={{
            width: width * 48,
            fontSize: 17,
          }}
          className="flex justify-center py-1 bg-[#e5f1e3] text-[#4d4848] font-bold "
        >
          Collection & Sale
        </div>

        <div
          className="flex justify-center py-1 bg-[#fdfaf6] text-[#4d4848] font-bold"
          style={{
            width: width * 12,
            fontSize: 17,
          }}
        >
          Expense
        </div>
      </div>

      <div className="p:flex w-full hidden  ">
        <div
          className="  text-[#4d4848] font-[700]       flex justify-center items-center py-[6px]"
          style={{ flex: 1, borderRight: '1px solid #ded9d9' }}
        >
          {'Description'}
        </div>

        <div
          style={{ borderRight: '1px solid #ded9d9', width: width * 10 }}
          className="  text-[#4d4848] font-[700]    flex justify-center items-center "
        >
          <div className="pl-2">{'Date'}</div>
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 9,
          }}
          className=" flex  text-[#4d4848] font-[700]         items-center justify-center  "
        >
          <div>{'Pay Mode'}</div>

          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4  bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            fontSize: 13,
            width: width * 6,
            borderRight: '1px solid #ded9d9',
          }}
          className=" text-[#4d4848] font-[700]        flex   items-center  "
        >
          <div className="  text-[#1b1c1f] flex grow  justify-center  ">
            Voucher
          </div>

          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            borderBottom: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 8,
          }}
          className="hidden md:flex  bg-[#e5f1e3] text-[#4d4848] font-[700] items-center border-l  justify-center    "
        >
          <div>Cash Amt.</div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            fontSize: 13,
            borderBottom: '1px solid #ded9d9',
            width: width * 8,
          }}
          className=" bg-[#e5f1e3] hidden  text-[#4d4848] font-[700]  md:flex justify-center w-full items-center   text-right    "
        >
          <div>Card Amt.</div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            fontSize: 13,
            borderBottom: '1px solid #ded9d9',
            width: width * 8,
          }}
          className=" bg-[#e5f1e3] hidden    md:flex justify-center w-full items-center   text-right   "
        >
          <div className="  bg-[#e5f1e3] text-[#4d4848] font-[700]">
            BTC Amt.
          </div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>
        <div
          style={{
            borderRight: '1px solid #ded9d9',
            borderBottom: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 8,
          }}
          className="hidden bg-[#e5f1e3] md:flex text-[#4d4848] font-[700] items-center   justify-center   "
        >
          <div>Collection</div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            borderBottom: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 8,
          }}
          className=" bg-[#e5f1e3] hidden  text-[#4d4848] font-[700]  md:flex justify-center w-full items-center   text-right   "
        >
          <div>Cheque Amt.</div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            borderBottom: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 8,
          }}
          className=" bg-[#e5f1e3] hidden  text-[#4d4848] font-[700]  md:flex justify-center w-full items-center   text-right  "
        >
          <div>ePayment</div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            borderRight: '1px solid #ded9d9',
            borderBottom: '1px solid #ded9d9',
            fontSize: 13,
            width: width * 6,
          }}
          className=" hidden bg-[#fdfaf6] text-[#4d4848] font-[700]  md:flex justify-center w-full items-center   text-right   border-b-[#a6a2a2] border-r-[#a6a2a2]"
        >
          <div>Discount</div>
          {/* <div
            style={{ width: '1px' }}
            className=" py-4 h-4 bg-slate-300"
          ></div> */}
        </div>

        <div
          style={{
            fontSize: 13,
            borderBottom: '1px solid #ded9d9',
            width: width * 6,
          }}
          className=" hidden  bg-[#fdfaf6] text-[#4d4848] font-[700]  md:flex justify-center w-full items-center   text-right   border-b-[#a6a2a2] "
        >
          <div>Amount Paid</div>
        </div>
      </div>
    </div>
  );
};
