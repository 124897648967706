import React, { useEffect } from 'react';
import { useState } from 'react';

import { useRef } from 'react';
import uniqid from 'uniqid';
import CompanyInfo from './companyINFO';
import { useDispatch, useSelector } from 'react-redux';

import CryptoJS from 'crypto-js';
import axios from 'axios';
import {
  selectCompanyAddress,
  selectEmail,
  selectPrefix,
} from '../../utility/Redux/profile';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import { DownloadFile, UploadFile } from '../../utility/functions/firebase';
import moment from 'moment';
import { Country, State, City } from 'country-state-city';
import { CallAPIPromise } from './dashboard/biiling/modal/comman';

const widths = ((window.innerWidth * window.innerHeight) / 100) * 0.0001;

const rowHeaderWidths = window.innerWidth <= 1280 ? widths * 200 : widths * 80;
const profilePicWidths = window.innerWidth <= 1024 ? widths * 80 : widths * 80;

const CompanyMaster = () => {
  const [rowHeaderWidth, setRowHeaderWidth] = useState(rowHeaderWidths);
  const [selectedFile, setSelectedFile] = useState('');
  const [webcamStatus, setWebcamStatus] = useState('');
  const [profilePicWidth, setProfilePicWidth] = useState(profilePicWidths);
  const [firebaseUrl, setFirebaseUrl] = useState('');
  const webcamRef = useRef();
  const [userEmail, setUserEmail] = useState(useSelector(selectEmail));
  const [capturedImage, setCapturedImage] = useState();
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [no_of_users, setNumerOfUser] = useState('');
  const [activeUsers, setActiveusers] = useState('');
  const [companyDetails, setCompanyDetails] = useState(
    useSelector(selectCompanyAddress)
  );
  const [path, setPath] = useState('');
  const [companyInfo, setCompanyInfo] = useState({
    user_email: '',
    user_password: '',
    user_name: '',
    user_type: '',
    user_status: '',
    company_email: '',
    company_name: '',
    company_mobile: '',
    company_website: '',
    company_logo: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin_code: '',
    currency_name: '',
    currency_symbol: '',
    regd_no1: '',
    regd_no2: '',
    regd_no3: '',
    disputes: '',
  });
  const dispatch = useDispatch();
  const countries = Country.getAllCountries();

  const minProfileicWidth =
    window.innerWidth < 768
      ? '120px'
      : window.innerWidth <= 1024
      ? '120px'
      : '250px';

  const handleDrop = (acceptedFiles) => {
    const editedFiles = acceptedFiles.map((file) => {
      const editedName = ` ${uniqid()}-${file.name}`;
      return new File([file], editedName, { type: file.type });
    });

    setSelectedFile(editedFiles[0]);
  };

  const prefix = useSelector(selectPrefix);
  const phoneRegex = /^[6-9]\d{9}$/;

  const CompanyUserInfo = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const email = companyDetails.email;
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      company_email: companyDetails.email,
      user_email: phoneRegex.test(userEmail) ? '' : userEmail,
      user_mobile: phoneRegex.test(userEmail) ? userEmail : '',
    };
    dispatch(setSpinnerLoading('Loading'));
    CallAPIPromise(`/api/users/userCompanyInfo`, data, hmacString, dispatch)
      .then(async function (response) {
        setCompanyInfo(response.data[0]);
        setNumerOfUser(response.data[0].no_of_users);
        setActiveusers(response.data[0].TOTAL_USERS);
        const b = `rootbilling/companyProfile/${prefix + userEmail}`;

        DownloadFile(b)
          .then((e) => {
            if (!e.includes('FirebaseError')) {
              setSelectedFile('');
              setCapturedImage('');
              setFirebaseUrl(e);
              setPath(b);
            } else {
              setFirebaseUrl('');
              setSelectedFile('');
              setCapturedImage('');
              setPath('');
            }
          })
          .catch(() => {
            console.log('clear customer catch');
            setFirebaseUrl('');
            if (typeof setSelectedFile === 'function') {
              setSelectedFile('');
              setCapturedImage('');
            }
          });
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    CompanyUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails]);
  const UpdateCompanyDetailsAPI = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
    const email = companyInfo.company_email;
    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const data = {
      company_email: companyInfo.company_email,
      company_mobile: companyInfo.company_mobile,
      company_website: companyInfo.company_website,
      address: companyInfo.address,
      city: companyInfo.city,
      state: companyInfo.state,
      country: companyInfo.country,
      pin_code: companyInfo.pin_code,

      currency_name: companyInfo.currency_name,
      currency_symbol: companyInfo.currency_symbol,
      regd_no1: companyInfo.regd_no1,
      regd_no2: companyInfo.regd_no2,
      regd_no3: companyInfo.regd_no3,
      disputes: companyInfo.disputes,
      company_logo: '',
      start_date: moment(companyInfo.start_date).format('YYYY-MM-DD'),
      end_date: moment(companyInfo.end_date).format('YYYY-MM-DD'),
      table_prefix: companyInfo.table_prefix,
      no_of_users: no_of_users,
    };

    CallAPIPromise(
      `/api/users/updateCompanyInfo`,

      data,
      hmacString,
      dispatch
    )
      .then(async function async(response) {
        if (response.status === 200) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: response.data.message,
            })
          );
          if (capturedImage || selectedFile) {
            const name = `${prefix + userEmail}`;
            const path = 'rootbilling/companyProfile/';
            const camera = capturedImage
              ? await fetch(capturedImage).then((response) => response.blob())
              : '';
            const file = selectedFile ? selectedFile : camera;
            UploadFile(file, path, name);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (companyInfo.country) {
      const selectedCountry = countries.filter(
        (country) => country.name === companyInfo.country
      );
      if (selectedCountry[0]) {
        setStatelist(State.getStatesOfCountry(selectedCountry[0].isoCode));
      }
    }
  }, [companyInfo.country]);

  useEffect(() => {
    if (companyInfo.state && stateList[0]) {
      const selectedstate = stateList.filter(
        (state) => state.name === companyInfo.state
      );
      if (selectedstate[0]) {
        setCitylist(
          City.getCitiesOfState(
            selectedstate[0].countryCode,
            selectedstate[0].isoCode
          )
        );
      }
    }
  }, [companyInfo.state, , stateList]);

  return (
    <div className="w-full h-full flex flex-col text-sm   ">
      <div className="h-full  ">
        <CompanyInfo
          rowHeaderWidth={rowHeaderWidth}
          minProfileicWidth={minProfileicWidth}
          customerFirebasePic={firebaseUrl}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          webcamStatus={webcamStatus}
          setWebcamStatus={setWebcamStatus}
          profilePicWidth={profilePicWidth}
          webcamRef={webcamRef}
          capturedImage={capturedImage}
          setCapturedImage={setCapturedImage}
          handleDrop={handleDrop}
          countries={countries}
          stateList={stateList}
          cityList={cityList}
          companyInfo={companyInfo}
          firebaseUrl={firebaseUrl}
          setCompanyInfo={setCompanyInfo}
          UpdateCompanyDetailsAPI={UpdateCompanyDetailsAPI}
          setCompanyDetails={setCompanyDetails}
          setUserEmail={setUserEmail}
          no_of_users={no_of_users}
          setNumerOfUser={setNumerOfUser}
          path={path}
          setFirebaseUrl={setFirebaseUrl}
          activeUsers={activeUsers}
          setActiveusers={setActiveusers}
        />
      </div>
    </div>
  );
};

export default CompanyMaster;
