import CallAPI from '../../utility/functions/getData';
import moment from 'moment';
import { setSpinnerLoading } from '../../utility/Redux/modal';
import HandleIndexedDB from '../../utility/functions/indexDbDatabase';

export const GetPendingOrder = (data, token, then, dispatch, f) => {
  CallAPI('/api/orderBooking/loadPendingOrder', data, token, then, dispatch, f);
};

export const ChangeItemRate = (
  sid,
  icode,
  salePrice,
  prefix,
  token,
  dispatch
) => {
  const data = {
    SID: sid,
    ICODE: icode,
    SALE_PRICE: salePrice,
    table_prefix: prefix,
  };
  CallAPI(
    'api/issueStockRoute/changeRateInIssue',
    data,
    token,
    (e) => {
      console.log(e);
    },
    dispatch
  );
};

export const CellEdit = (
  e,
  loadBulkIssueList,

  setCartItemUpdate,
  fno,
  date,
  helperName,
  truckNo,
  stationId,
  stationName,
  iTotal,
  rTotal,
  startKm,
  endKM,
  cash,
  less,
  expense,
  collection,
  debit,
  userdefaultSetting,
  issueCartIQNTY,
  setCartItemInsert,
  setRows,
  dispatch,
  setQuantityEditStatus,
  rows,
  issueCartRQNTY,
  setAlertMsg,

  prefix,
  setALertStatus,
  token,
  setStartKm,
  setEndKm,
  setAlertWithTitle,
  changeRate,
  userId,
  setAlertTwoButtonModal
) => {
  const val = e.value;
  const v = val ? val : 0;
  if (!isNaN(v)) {
    if (e.field === 'IQNTY') {
      if (v >= 0) {
        const item = loadBulkIssueList.filter((r) => r.ICODE === e.id);

        if (fno) {
          if (item[0].FNO && item[0].FNO > 0) {
            setCartItemUpdate((p) => {
              const f = p.filter((r) => r.ICODE === e.id);
              const w = p.filter((r) => r.ICODE !== e.id);
              if (f[0]) {
                // if (f[0].SQNTY <= Number(v)) {
                return [
                  ...w,
                  issueCartIQNTY(
                    Number(v),
                    fno,
                    moment(date).format('YYYY-MM-DD'),
                    helperName,
                    truckNo,
                    stationId,
                    stationName,
                    f[0].ICODE,
                    f[0].ITEMNAME,
                    f[0].SECTION_NAME,
                    f[0].SALE_PRICE,
                    f[0].PRV_BAL,

                    f[0].RQNTY,
                    f[0].RAMOUNT,
                    iTotal,
                    rTotal,
                    startKm,
                    endKM,
                    f[0].REMARKS,
                    cash,
                    less,
                    expense,
                    collection,
                    debit,
                    f[0].SQNTY,
                    f[0].SAMOUNT,
                    userdefaultSetting,
                    f[0].TAX1_RATE,
                    f[0].TAX2_RATE,
                    f[0].TAX3_RATE
                  ),
                ];
              } else {
                return [
                  ...p,
                  issueCartIQNTY(
                    Number(v),
                    fno,
                    moment(date).format('YYYY-MM-DD'),
                    helperName,
                    truckNo,
                    stationId,
                    stationName,
                    item[0].ICODE,
                    item[0].ITEMNAME,
                    item[0].SECTION_NAME,
                    item[0].SALE_PRICE,
                    item[0].PRV_BAL,

                    item[0].RQNTY,
                    item[0].RAMOUNT,
                    iTotal,
                    rTotal,
                    startKm,
                    endKM,
                    item[0].REMARKS,
                    cash,
                    less,
                    expense,
                    collection,
                    debit,
                    item[0].SQNTY,
                    item[0].SAMOUNT,
                    userdefaultSetting,
                    item[0].TAX1_RATE,
                    item[0].TAX2_RATE,
                    item[0].TAX3_RATE
                  ),
                ];
              }
            });
          } else {
            setCartItemInsert((c) => {
              const f = c.filter((r) => r.ICODE === e.id);
              const w = c.filter((r) => r.ICODE !== e.id);
              if (f[0]) {
                return [
                  ...w,
                  issueCartIQNTY(
                    Number(v),
                    fno,
                    moment(date).format('YYYY-MM-DD'),
                    helperName,
                    truckNo,
                    stationId,
                    stationName,
                    f[0].ICODE,
                    f[0].ITEMNAME,
                    f[0].SECTION_NAME,
                    f[0].SALE_PRICE,
                    f[0].PRV_BAL ? f[0].PRV_BAL : 0,

                    f[0].RQNTY,
                    f[0].RAMOUNT,
                    iTotal,
                    rTotal,
                    startKm,
                    endKM,
                    f[0].REMARKS,
                    cash,
                    less,
                    expense,
                    collection,
                    debit,
                    f[0].SQNTY,
                    f[0].SAMOUNT,
                    userdefaultSetting,
                    f[0].TAX1_RATE,
                    f[0].TAX2_RATE,
                    f[0].TAX3_RATE
                  ),
                ];
              } else {
                return [
                  ...c,
                  issueCartIQNTY(
                    Number(v),
                    fno,
                    moment(date).format('YYYY-MM-DD'),
                    helperName,
                    truckNo,
                    stationId,
                    stationName,
                    item[0].ICODE,
                    item[0].ITEMNAME,
                    item[0].SECTION_NAME,
                    item[0].SALE_PRICE,
                    item[0].PRV_BAL ? item[0].PRV_BAL : 0,

                    item[0].RQNTY,
                    item[0].RAMOUNT,
                    iTotal,
                    rTotal,
                    startKm,
                    endKM,
                    '',
                    cash,
                    less,
                    expense,
                    collection,
                    debit,
                    item[0].SQNTY,
                    item[0].SAMOUNT,
                    userdefaultSetting,
                    item[0].TAX1_RATE,
                    item[0].TAX2_RATE,
                    item[0].TAX3_RATE
                  ),
                ];
              }
            });
          }
        }

        setRows((prevRows) => {
          const data = prevRows.map((row) =>
            row.ICODE === e.id
              ? issueCartIQNTY(
                  Number(v),
                  fno,
                  moment(date).format('YYYY-MM-DD'),
                  helperName,
                  truckNo,
                  stationId,
                  stationName,
                  row.ICODE,
                  row.ITEMNAME,
                  row.SECTION_NAME,
                  row.SALE_PRICE,
                  row.PRV_BAL ? row.PRV_BAL : 0,

                  row.RQNTY,
                  row.RAMOUNT,
                  iTotal,
                  rTotal,
                  startKm,
                  endKM,
                  row.REMARKS,
                  cash,
                  less,
                  expense,
                  collection,
                  debit,
                  row.SQNTY,
                  row.SAMOUNT,
                  userdefaultSetting,
                  row.TAX1_RATE,
                  row.TAX2_RATE,
                  row.TAX3_RATE
                )
              : row
          );

          if (!fno) {
            const databaseName = 'SaveIssueCart';
            const storeName = `${userId}-${stationId}-${stationName}-${moment().format(
              'YYYY-MM-DD'
            )}`;
            const dataToSave = data;
            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
            });
          }

          return data;
        });
      }
    } else {
      if (e.field === 'RQNTY') {
        const isExistItem = rows.some((i) => i.ICODE === e.id);

        if (isExistItem) {
          if (v >= 0) {
            const item = loadBulkIssueList.filter((r) => r.ICODE === e.id);

            if (fno) {
              if (item[0].FNO && item[0].FNO > 0) {
                setCartItemUpdate((p) => {
                  const f = p.filter((r) => r.ICODE === e.id);
                  const w = p.filter((r) => r.ICODE !== e.id);

                  if (f[0]) {
                    return [
                      ...w,
                      issueCartRQNTY(
                        f[0].IQNTY,
                        fno,
                        moment(date).format('YYYY-MM-DD'),
                        helperName,
                        truckNo,
                        stationId,
                        stationName,
                        f[0].ICODE,
                        f[0].ITEMNAME,
                        f[0].SECTION_NAME,
                        f[0].SALE_PRICE,
                        f[0].PRV_BAL ? f[0].PRV_BAL : 0,

                        Number(v),
                        f[0].RAMOUNT,
                        iTotal,
                        rTotal,
                        startKm,
                        endKM,
                        f[0].REMARKS,
                        cash,
                        less,
                        expense,
                        collection,
                        debit,
                        f[0].SQNTY,
                        f[0].SAMOUNT,
                        userdefaultSetting,
                        f[0].TAX1_RATE,
                        f[0].TAX2_RATE,
                        f[0].TAX3_RATE
                      ),
                    ];
                  } else {
                    return [
                      ...p,
                      issueCartRQNTY(
                        item[0].IQNTY,
                        fno,
                        moment(date).format('YYYY-MM-DD'),
                        helperName,
                        truckNo,
                        stationId,
                        stationName,
                        item[0].ICODE,
                        item[0].ITEMNAME,
                        item[0].SECTION_NAME,
                        item[0].SALE_PRICE,
                        item[0].PRV_BAL ? item[0].PRV_BAL : 0,

                        Number(v),
                        item[0].RAMOUNT,
                        iTotal,
                        rTotal,
                        startKm,
                        endKM,
                        item[0].REMARKS,
                        cash,
                        less,
                        expense,
                        collection,
                        debit,
                        item[0].SQNTY,
                        item[0].SAMOUNT,
                        userdefaultSetting,
                        item[0].TAX1_RATE,
                        item[0].TAX2_RATE,
                        item[0].TAX3_RATE
                      ),
                    ];
                  }
                });
              } else {
                setCartItemInsert((i) => {
                  const f = i.filter((r) => r.ICODE === e.id);
                  const w = i.filter((r) => r.ICODE !== e.id);
                  if (f[0]) {
                    return [
                      ...w,
                      issueCartRQNTY(
                        f[0].IQNTY,
                        fno,
                        moment(date).format('YYYY-MM-DD'),
                        helperName,
                        truckNo,
                        stationId,
                        stationName,
                        f[0].ICODE,
                        f[0].ITEMNAME,
                        f[0].SECTION_NAME,
                        f[0].SALE_PRICE,
                        f[0].PRV_BAL ? f[0].PRV_BAL : 0,
                        Number(v),

                        f[0].RAMOUNT,
                        iTotal,
                        rTotal,
                        startKm,
                        endKM,
                        f[0].REMARKS,
                        cash,
                        less,
                        expense,
                        collection,
                        debit,
                        f[0].SQNTY,
                        f[0].SAMOUNT,
                        userdefaultSetting,
                        f[0].TAX1_RATE,
                        f[0].TAX2_RATE,
                        f[0].TAX3_RATE
                      ),
                    ];
                  } else {
                    return [
                      ...i,
                      issueCartRQNTY(
                        item[0].IQNTY,
                        fno,
                        moment(date).format('YYYY-MM-DD'),
                        helperName,
                        truckNo,
                        stationId,
                        stationName,
                        item[0].ICODE,
                        item[0].ITEMNAME,
                        item[0].SECTION_NAME,
                        item[0].SALE_PRICE,
                        item[0].PRV_BAL ? item[0].PRV_BAL : 0,

                        Number(v),
                        item[0].RAMOUNT,
                        iTotal,
                        rTotal,
                        startKm,
                        endKM,
                        '',
                        cash,
                        less,
                        expense,
                        collection,
                        debit,
                        item[0].SQNTY,
                        item[0].SAMOUNT,
                        userdefaultSetting,
                        item[0].TAX1_RATE,
                        item[0].TAX2_RATE,
                        item[0].TAX3_RATE
                      ),
                    ];
                  }
                });
              }
            }

            setRows((prevRows) => {
              const data = prevRows.map((row) =>
                row.ICODE === e.id
                  ? issueCartRQNTY(
                      row.IQNTY,
                      '',
                      moment(date).format('YYYY-MM-DD'),
                      helperName,
                      truckNo,
                      stationId,
                      stationName,
                      row.ICODE,
                      row.ITEMNAME,
                      row.SECTION_NAME,
                      row.SALE_PRICE,
                      row.PRV_BAL ? row.PRV_BAL : 0,

                      Number(v),
                      Number(v) * row.SALE_PRICE,
                      iTotal,
                      rTotal,
                      startKm,
                      endKM,
                      '',
                      cash,
                      less,
                      expense,
                      collection,
                      debit,
                      row.SQNTY,
                      row.SAMOUNT,
                      userdefaultSetting,
                      row.TAX1_RATE,
                      row.TAX2_RATE,
                      row.TAX3_RATE
                    )
                  : row
              );
              if (!fno) {
                const databaseName = 'SaveIssueCart';
                const storeName = `${userId}-${stationId}-${stationName}-${moment().format(
                  'YYYY-MM-DD'
                )}`;
                const dataToSave = data;
                HandleIndexedDB({
                  databaseName,
                  storeName,
                  dataToSave,
                });
              }

              return data;
            });
          }
        } else {
          dispatch(setAlertMsg('Please Select Qunatity'));
          dispatch(setALertStatus(true));
        }
      } else {
        if (e.field === 'SALE_PRICE') {
          const isExistItem = rows.some((i) => i.ICODE === e.id);

          if (isExistItem) {
            if (v >= 0) {
              const item = loadBulkIssueList.filter((r) => r.ICODE === e.id);

              if (fno) {
                if (item[0].FNO && item[0].FNO > 0) {
                  setCartItemUpdate((p) => {
                    const f = p.filter((r) => r.ICODE === e.id);
                    const w = p.filter((r) => r.ICODE !== e.id);

                    if (f[0]) {
                      return [
                        ...w,
                        issueCartIQNTY(
                          f[0].IQNTY,
                          fno,
                          moment(date).format('YYYY-MM-DD'),
                          helperName,
                          truckNo,
                          stationId,
                          stationName,
                          f[0].ICODE,
                          f[0].ITEMNAME,
                          f[0].SECTION_NAME,
                          Number(v),
                          f[0].PRV_BAL ? f[0].PRV_BAL : 0,

                          f[0].RQNTY,
                          f[0].RAMOUNT,
                          iTotal,
                          rTotal,
                          startKm,
                          endKM,
                          f[0].REMARKS,
                          cash,
                          less,
                          expense,
                          collection,
                          debit,
                          f[0].SQNTY,
                          f[0].SAMOUNT,
                          userdefaultSetting,
                          f[0].TAX1_RATE,
                          f[0].TAX2_RATE,
                          f[0].TAX3_RATE
                        ),
                      ];
                    } else {
                      return [
                        ...p,
                        issueCartIQNTY(
                          item[0].IQNTY,
                          fno,
                          moment(date).format('YYYY-MM-DD'),
                          helperName,
                          truckNo,
                          stationId,
                          stationName,
                          item[0].ICODE,
                          item[0].ITEMNAME,
                          item[0].SECTION_NAME,
                          Number(v),
                          item[0].PRV_BAL ? item[0].PRV_BAL : 0,

                          item[0].RQNTY,

                          item[0].RAMOUNT,
                          iTotal,
                          rTotal,
                          startKm,
                          endKM,
                          item[0].REMARKS,
                          cash,
                          less,
                          expense,
                          collection,
                          debit,
                          item[0].SQNTY,
                          item[0].SAMOUNT,
                          userdefaultSetting,
                          item[0].TAX1_RATE,
                          item[0].TAX2_RATE,
                          item[0].TAX3_RATE
                        ),
                      ];
                    }
                  });
                } else {
                  setCartItemInsert((i) => {
                    const f = i.filter((r) => r.ICODE === e.id);
                    const w = i.filter((r) => r.ICODE !== e.id);
                    if (f[0]) {
                      return [
                        ...w,
                        issueCartIQNTY(
                          f[0].IQNTY,
                          fno,
                          moment(date).format('YYYY-MM-DD'),
                          helperName,
                          truckNo,
                          stationId,
                          stationName,
                          f[0].ICODE,
                          f[0].ITEMNAME,
                          f[0].SECTION_NAME,
                          Number(v),
                          f[0].PRV_BAL ? f[0].PRV_BAL : 0,
                          f[0].RQNTY,

                          f[0].RAMOUNT,
                          iTotal,
                          rTotal,
                          startKm,
                          endKM,
                          f[0].REMARKS,
                          cash,
                          less,
                          expense,
                          collection,
                          debit,
                          f[0].SQNTY,
                          f[0].SAMOUNT,
                          userdefaultSetting,
                          f[0].TAX1_RATE,
                          f[0].TAX2_RATE,
                          f[0].TAX3_RATE
                        ),
                      ];
                    } else {
                      return [
                        ...i,
                        issueCartIQNTY(
                          item[0].IQNTY,
                          fno,
                          moment(date).format('YYYY-MM-DD'),
                          helperName,
                          truckNo,
                          stationId,
                          stationName,
                          item[0].ICODE,
                          item[0].ITEMNAME,
                          item[0].SECTION_NAME,
                          Number(v),
                          item[0].PRV_BAL ? item[0].PRV_BAL : 0,

                          item[0].RQNTY,
                          item[0].RAMOUNT,
                          iTotal,
                          rTotal,
                          startKm,
                          endKM,
                          '',
                          cash,
                          less,
                          expense,
                          collection,
                          debit,
                          item[0].SQNTY,
                          item[0].SAMOUNT,
                          userdefaultSetting,
                          item[0].TAX1_RATE,
                          item[0].TAX2_RATE,
                          item[0].TAX3_RATE
                        ),
                      ];
                    }
                  });
                }
              }

              setRows((prevRows) => {
                const data = prevRows.map((row) =>
                  row.ICODE === e.id
                    ? issueCartIQNTY(
                        row.IQNTY,
                        '',
                        moment(date).format('YYYY-MM-DD'),
                        helperName,
                        truckNo,
                        stationId,
                        stationName,
                        row.ICODE,
                        row.ITEMNAME,
                        row.SECTION_NAME,
                        Number(v),
                        row.PRV_BAL ? row.PRV_BAL : 0,

                        row.RQNTY,
                        row.RQNTY * Number(v),
                        iTotal,
                        rTotal,
                        startKm,
                        endKM,
                        '',
                        cash,
                        less,
                        expense,
                        collection,
                        debit,
                        row.SQNTY,
                        row.SAMOUNT,
                        userdefaultSetting,
                        row.TAX1_RATE,
                        row.TAX2_RATE,
                        row.TAX3_RATE
                      )
                    : row
                );

                if (!fno) {
                  const databaseName = 'SaveIssueCart';
                  const storeName = `${userId}-${stationId}-${stationName}-${moment().format(
                    'YYYY-MM-DD'
                  )}`;
                  const dataToSave = data;
                  HandleIndexedDB({
                    databaseName,
                    storeName,
                    dataToSave,
                  });
                }
                return data;
              });

              if (
                moment(date).format('YYYY-MM-DD') ===
                moment().format('YYYY-MM-DD')
              ) {
                setAlertTwoButtonModal({
                  title: 'Alert',
                  msg: 'Do You want to change Rate in master?',
                  status: true,
                  setStatus: '',
                  button1: 'Yes',
                  button1Click: () => {
                    setAlertTwoButtonModal((e) => {
                      return {
                        ...e,
                        status: false,
                      };
                    });
                    changeRate(
                      stationId,
                      e.id,
                      e.value,
                      prefix,
                      token,
                      dispatch
                    );
                  },
                  button2: 'No',
                  button2Click: () => {
                    setAlertTwoButtonModal((e) => {
                      return {
                        ...e,
                        status: false,
                      };
                    });
                  },
                });
              }
            }
          } else {
            dispatch(setAlertMsg('Please Select Qunatity'));
            dispatch(setALertStatus(true));
          }
        } else {
          if (e.field === 'START_KM') {
            setStartKm(v);
          } else {
            if (e.field === 'END_KM') {
              setEndKm(v);
            }
          }
        }
      }
    }
  } else {
    dispatch(
      setAlertWithTitle({
        title: 'Error',
        msg: 'Only Number Allowed',
      })
    );
  }
};

export const GetSectionName = async (
  prefix,
  token,
  setData,
  setIsLoading,
  getItemsList,
  dispatch,
  setItems,
  setAllData
) => {
  dispatch(setSpinnerLoading('Loading section list'));
  CallAPI(
    '/api/issueMasterRoute/listSectionMaster',
    {
      table_prefix: prefix,
      SECTION_NAME: '',
    },
    token,
    (p) => {
      setData(p.data.response);
      setIsLoading(false);
    },
    dispatch
  );
};

export const GetItemsList = async (
  sname,
  dispatch,
  prefix,
  setItems,
  setIsLoading,
  setAllData,
  token
) => {
  dispatch(setSpinnerLoading('Loading item list'));
  CallAPI(
    '/api/issueMasterRoute/listItemMaster',
    {
      table_prefix: prefix,
      sname: sname,
    },
    token,
    (p) => {
      setItems(p.data.response);
      setIsLoading(false);
      if (sname === '') {
        setAllData(p.data.response);
        setIsLoading(false);
      }
    },
    dispatch,
    () => {
      dispatch(setSpinnerLoading(false));
    }
  );
};
