import React, {  useState } from 'react'
import {PhoneCompnySignup } from './phoneSignup';
import { DesktopCompnySignup } from './desktopSignup';
// import { DesktopCompnySignup } from './testSignup';
export const CompnySignup = () => {
  const [deviceWidth,setdeviceWidth] = useState(window.innerWidth)

  window.onresize = ()=> setdeviceWidth(window.innerWidth)
  return (
    <div>
    { deviceWidth <= 600 ?  <PhoneCompnySignup/>:<DesktopCompnySignup/>}
    </div>

  )



}


  
  ;
