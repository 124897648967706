import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Lottie from 'lottie-react';
import sucess from '../../json/success2.json';
import reject from '../../json/rejected.json';
import {
  setAlertWithTitle,
  setSpinnerLoading,
  setviewBillModalStatus,
} from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/icon.svg';
import { AlertOkButton, CancelButton } from '../buttons';
import { Button } from '@mui/material';
import CallAPI from '../../functions/getData';
import { selectPrefix } from '../../Redux/profile';
import { selectToken } from '../../Redux/security';

export default function ActionRemarks({
  remarksStatus,
  setRemarksStatus,
  SubmitButton,
  remarks,
  setRemarks,
  question,
}) {
  const dispatch = useDispatch();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    // border: '1px solid white',
    boxShadow: 24,
    borderRadius: '7px',
    outline: 'none',
  };

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  return (
    <div>
      <Modal open={remarksStatus}>
        <div style={style} className=" w-full min-w-[280px] max-w-[320px]">
          <div className={'h-full w-full bg-white  rounded-sm  p-4'}>
            <div className=" flex justify-center w-full rounded-sm">
              <div
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: -30,

                  borderRadius: 100,
                }}
                className="bg-white p-[2px]"
              >
                <img
                  src={logo}
                  alt=""
                  style={{
                    width: '50px',
                    height: 'auto',
                  }}
                />
              </div>
            </div>

            <div className="w-full justify-center text-center text-[#1c1a1a] flex mt-2 font-semibold items-top">
              {question}
            </div>
            <div
              style={{
                fontSize: '13px',
              }}
              className="w-full justify-center border border-[#b5b3b3] rounded-sm overflow-hidden text-center text-[#1c1a1a] flex mt-2 "
            >
              <textarea
                value={remarks}
                autoFocus
                rows={4}
                maxLength={100}
                placeholder="Remarks"
                className="outline-none w-full p-2"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </div>

            <div className="w-full flex justify-end mt-3">
              {SubmitButton}

              <div className="w-auto mr-1">
                {' '}
                <CancelButton
                  onClick={() => {
                    setRemarksStatus(false);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
