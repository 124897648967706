import React from 'react';
import HandleIndexedDB from './indexDbDatabase';

const dataToLocal = (database, data, details) => {
  const databaseName = database;
  const storeName = 'data';

  const dataToSave = data;
  HandleIndexedDB({
    databaseName,
    storeName,
    dataToSave,
    callback: () => {
      const storeName = 'Details';
      const dataToSave = details;
      HandleIndexedDB({
        databaseName,
        storeName,
        dataToSave,
      });
    },
  });
};

export default dataToLocal;
