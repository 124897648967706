export function amountToWords2(amount) {
  const numberWords = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tensWords = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  if (amount < 20) {
    return numberWords[amount];
  }

  if (amount < 100) {
    const tens = tensWords[Math.floor(amount / 10)];
    const ones = numberWords[amount % 10];
    const separator = amount % 10 !== 0 ? "-" : "";
    return tens + separator + ones;
  }

  if (amount < 1000) {
    const hundreds = numberWords[Math.floor(amount / 100)];
    const tens =
      amount % 100 !== 0 ? " and " + amountToWords2(amount % 100) : "";
    return hundreds + " hundred" + tens;
  }

  if (amount < 1000000) {
    const thousands = amountToWords2(Math.floor(amount / 1000));
    const hundreds =
      amount % 1000 !== 0 ? " " + amountToWords2(amount % 1000) : "";
    return thousands + " thousand" + hundreds;
  }

  if (amount < 1000000000) {
    const millions = amountToWords2(Math.floor(amount / 1000000));
    const thousands =
      amount % 1000000 !== 0 ? " " + amountToWords2(amount % 1000000) : "";
    return millions + " million" + thousands;
  }

  return "number too large";
}
