import { FormatNumber2, FormatNumber3 } from './tools';
export const AdminTotalFooter = (props) => {
  const { rows, columns } = props;

  const totalIssuedQnty = rows.reduce((total, row) => total + row.IQNTY, 0);

  const totalIssuedAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.IAMOUNT),
    0
  );

  const TotalReturnAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.RAMOUNT),
    0
  );

  const TotalSaleQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.SQNTY),
    0
  );
  const TotalSaleAmount = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT),
    0
  );

  const TotalBalQnty = rows.reduce(
    (total, row) => Number(total) + Number(row.Balqnty),
    0
  );

  const TotalBalAmt = rows.reduce(
    (total, row) => Number(total) + Number(row.Bal_Amt),
    0
  );

  const TotalCashAMount = rows.reduce(
    (total, row) => total + row.CASH_AMOUNT,
    0
  );
  const TotalCardAmount = rows.reduce(
    (total, row) => total + row.CARD_AMOUNT,
    0
  );
  const TotalEpaymentAmount = rows.reduce(
    (total, row) => total + row.EPAYMENT,
    0
  );

  const TotalChequeAmt = rows.reduce(
    (total, row) => total + row.CHEQUE_AMOUNT,
    0
  );

  const TotalBtcAmt = rows.reduce((total, row) => total + row.BTC_AMOUNT, 0);

  const TotalDiscountAmt = rows.reduce((total, row) => total + row.DISQ_AMT, 0);

  return (
    <div
      className=" flex bg-[#e6e6e3]   pt-1  "
      style={{
        display: 'flex',

        alignItems: 'center',
      }}
    >
      {columns.map((p) => {
        return (
          <div
            key={p.field}
            style={
              p.flex
                ? {
                    flex: p.flex,
                    textAlign: p.align ? p.align : 'left',
                    paddingLeft: '2px',
                    borderRight: '1px solid #cfcdc8',
                    paddingRight: '10px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }
                : {
                    width: p.width,
                    textAlign: p.align ? p.align : 'left',
                    paddingLeft: '2px',
                    borderRight: '1px solid #cfcdc8',
                    paddingRight: '10px',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    flexDirection: 'row',

                    alignItems: 'center',
                  }
            }
          >
            {p.field === 'SID' && '  '}
            {p.field === 'SNAME' && '  '}
            {p.field === 'IQNTY' && FormatNumber3(totalIssuedQnty)}
            {p.field === 'IAMOUNT' && FormatNumber2(totalIssuedAmount)}
            {p.field === 'SQNTY' && FormatNumber3(TotalSaleQnty)}
            {p.field === 'AMOUNT' && FormatNumber2(TotalSaleAmount)}
            {p.field === 'Balqnty' && FormatNumber3(TotalBalQnty)}
            {p.field === 'Bal_Amt' && FormatNumber2(TotalBalAmt)}
            {p.field === 'CASH_AMOUNT' && FormatNumber2(TotalCashAMount)}
            {p.field === 'CARD_AMOUNT' && FormatNumber2(TotalCardAmount)}
            {p.field === 'EPAYMENT' && FormatNumber2(TotalEpaymentAmount)}
            {p.field === 'CHEQUE_AMOUNT' && FormatNumber2(TotalChequeAmt)}
            {p.field === 'BTC_AMOUNT' && FormatNumber2(TotalBtcAmt)}
            {p.field === 'DISQ_AMT' && FormatNumber2(TotalDiscountAmt)}
          </div>
        );
      })}
    </div>
  );
};
