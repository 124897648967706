import { FormatNumber2, FormatNumber3 } from '../../../utility/component/tools';
export const ItemWiseIssueFooter = (props) => {
  const { rows, columns } = props;

  const totalSaleQnty = rows.reduce((total, row) => total + row.SQNTY, 0);
  const totalRQnty = rows.reduce((total, row) => total + row.RQNTY, 0);
  const totalIQnty = rows.reduce((total, row) => total + row.IQNTY, 0);

  const totalSAMT = rows.reduce(
    (total, row) => Number(total) + Number(row.SAMOUNT),
    0
  );
  const totalIAMT = rows.reduce(
    (total, row) => Number(total) + Number(row.IAMOUNT),
    0
  );
  const totalRAMT = rows.reduce(
    (total, row) => Number(total) + Number(row.RAMOUNT),
    0
  );
  return (
    <div id={'print-Fotter'} className="  bg-[#e6e6e3] flex  text-sm ">
      {columns.map((item, index) => {
        const header = item.field;
        return (
          <div
            key={index}
            className="  text-black flex justify-end pr-2 items-center border-r py-2 border-slate-300"
            style={item.flex ? { flex: item.flex } : { width: item.width }}
          >
            {header === 'DESC_NAME' ? 'Total' : null}
            {header === 'SQNTY'
              ? totalSaleQnty > 0
                ? FormatNumber3(totalSaleQnty)
                : null
              : null}
            {header === 'RQNTY'
              ? totalRQnty > 0
                ? FormatNumber3(totalRQnty)
                : null
              : null}
            {header === 'IQNTY'
              ? totalIQnty > 0
                ? FormatNumber3(totalIQnty)
                : null
              : null}

            {header === 'SAMOUNT'
              ? totalSAMT > 0
                ? FormatNumber2(totalSAMT)
                : null
              : null}

            {header === 'IAMOUNT'
              ? totalIAMT > 0
                ? FormatNumber2(totalIAMT)
                : null
              : null}
            {header === 'RAMOUNT'
              ? totalRAMT > 0
                ? FormatNumber2(totalRAMT)
                : null
              : null}
          </div>
        );
      })}
    </div>
  );
};
