import React from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSaleviewBillModalStatus,
  setviewBillModalStatus,
} from '../../Redux/modal';
import ViewBill from '../viewBill';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
};

const styleMobile = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  border: '2px solid #000',
  boxShadow: 24,
};

export default function ViewBillModal() {
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectSaleviewBillModalStatus);
  const handleClose = () => {
    dispatch(setviewBillModalStatus(false));
  };

  return (
    <div className="bg-white ">
      <Modal
        open={ModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth < 770 ? styleMobile : style}
          className={'rounded-lg overflow-hidden '}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className=" flex flex-col grow  bg-white w-full">
              <div className="h-full w-full">
                <ViewBill />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
