import React, { useEffect, useState } from 'react';

import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectSystemAdminModal,
  setSystemAdminModalStatus,
} from '../../Redux/security';
import { setStationID } from '../../Redux/profile';
import ModalHeaderStyle from '../styles/modalHeader';
import ModalBody from '../styles/modalBody';
import SystemAdmin from './systemAdmin';

export default function ModSystemAdmin() {
  const [WindowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectSystemAdminModal);
  const handleClose = () => {
    dispatch(setSystemAdminModalStatus(false));
    dispatch(setStationID(''));
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    height: 'auto',
    border: '2px solid #000',
    boxShadow: 24,
  };

  useEffect(() => {
    // Event listener callback function
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener when the component mounts
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Modal
        open={ModalStatus}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style} className={'rounded-lg overflow-hidden '}>
          <div className="w-full flex flex-col bg-white h-full ">
            <ModalHeaderStyle
              header={'System Default Settings'}
              onClose={handleClose}
            />

            <ModalBody>
              {/* <SystemAdmin /> */}
              <SystemAdmin />
            </ModalBody>
          </div>
        </div>
      </Modal>
    </div>
  );
}
