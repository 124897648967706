import React from "react";

import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";


import { BarLoader } from "react-spinners";
import UserStatus from "./userStatus";
import { selectLoginRequestModatStatus, selectStatus, setLoginRequestsModalStatus } from "../../Redux/modal";
import LoginRequest from "./loginRequest";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  height: "85%",
  border: "2px solid #000",
  boxShadow: 24,
};

const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "80%",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function ModalLoginRequest() {
  const dispatch = useDispatch();
  const ModalStatus = useSelector(selectLoginRequestModatStatus);
  const loading = useSelector(selectStatus);
 


  const handleClose = () => {
      dispatch(setLoginRequestsModalStatus(false))
      
  };

  return (
    <div className="bg-white">
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={ModalStatus}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={window.innerWidth <770 ? styleMobile : style}
          className={"rounded-lg overflow-hidden "}
        >
          <div className="w-full flex flex-col bg-white h-full ">
            <div className="flex w-full bg-brown items-center">
              <img
                src={require("../../../utility/images/logo3.png")}
                className="w-36 md:w-48 h-auto"
              />
              <div
                style={{ width: "1px", height: "100%" }}
                className=" bg-orange"
              />
              <div className="flex  grow justify-between items-center text-white bg-brown px-3 font-medium ">
              Login(s) Requests
                <CancelPresentationIcon
                  onClick={handleClose}
                  color="orange"
                  fontSize="medium"
                />
              </div>
            </div>

            <div className="flex-grow h-full bg-white    border-brown">
           
             <LoginRequest />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
