import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDefultProfileSetting, selectUserType } from '../Redux/profile';
import { parseISO } from 'date-fns';
import { SelectDate, setDate } from '../Redux/refresh';
import DatePicker from 'react-datepicker';
export const FromToDateDashBoard = ({ refresh, getIssuedData }) => {
  const dispatch = useDispatch();
  const checkDateData = useSelector(SelectDate);
  const [firstdateFocus, setfirstDateFocus] = useState('');
  const userType = useSelector(selectUserType);
  const defaultProfile = useSelector(selectDefultProfileSetting);

  return (
    <div style={{ borderRadius: '3px', overflow: 'hidden' }} className=" flex ">
      <div
        style={{
          border: '1px solid #b5b3b3',
          borderTopLeftRadius: '7px',
          borderBottomLeftRadius: '7px',
        }}
        className="flex items-center  justify-center "
      >
        <div className=" flex items-center p-2  px-4  ">
          <div style={{ fontSize: 14 }} className="pr-2 text-[#1b1919]">
            Issued Material Report
          </div>

          <div
            style={{ borderRadius: '7px' }}
            className="flex border border-[#4B4545] overflow-hidden ml-2 text-[#4B4545]  hover:cursor-pointer"
          >
            <div
              style={{
                padding: '2px',
                borderRight: '1px solid #a6a2a2  ',
              }}
              className="bg-[#f1efeb] "
            >
              <DatePicker
                onFocus={() => setfirstDateFocus(true)}
                onClickOutside={() => setfirstDateFocus(false)}
                onSelect={() => setfirstDateFocus(false)}
                onInputClick={() => setfirstDateFocus(true)}
                open={
                  userType === 'USER'
                    ? defaultProfile?.enable_user_dashboard_date === 'YES'
                      ? firstdateFocus
                      : false
                    : firstdateFocus
                }
                readOnly={true}
                className=" bg-transparent text-sm   w-36 outline-0 "
                dateFormat=" d MMMM , yyyy"
                selected={parseISO(checkDateData)}
                onChange={(date) => {
                  dispatch(setDate(moment(date).format('YYYY-MM-DD')));
                  getIssuedData(moment(date).format('YYYY-MM-DD'));
                }}
              />
            </div>
            <div className=" items-center flex justify-start px-2 bg-[#f7dda4] ">
              <i
                onClick={() => {
                  if (
                    userType === 'USER'
                      ? defaultProfile?.enable_user_dashboard_date === 'YES'
                      : true
                  ) {
                    setfirstDateFocus(true);
                  }
                }}
                className="bi bi-calendar2-minus"
              ></i>
            </div>
          </div>
          <button
            onClick={refresh}
            style={{
              fontSize: 14,
              fontWeight: '500px',
            }}
            data-tooltip-id="tip"
            data-tooltip-content="Refresh"
            className="pl-2"
          >
            <i className="bi text-[20px] text-orange bi-arrow-clockwise"></i>
          </button>
        </div>
      </div>
      <div
        style={{
          border: '1px dotted #a6a2a2',
          borderLeft: '0px dotted #a6a2a2',
          borderRight: '0px dotted #a6a2a2',
          borderTopRightRadius: '7px',
          borderBottomRightRadius: '7px',
        }}
        className="grow "
      >
        <div
          style={{
            borderRight: '1px dotted #a6a2a2',
            borderTopRightRadius: '7px',
            borderBottomRightRadius: '7px',
          }}
          className="flex w-full h-full "
        >
          <div className="flex grow h-full   ">
            <div className="w-full h-full">
              <div
                className="w-full h-1/2 print:h-full flex items-center"
                style={{
                  fontSize: 12,
                  color: 'red',
                }}
              >
                <span className="pl-4 font-semibold "></span>
              </div>
              <div
                style={{
                  borderTop: '1px dotted #a6a2a2',
                  color: '#f2666f',
                  fontSize: 12,
                }}
                className="h-1/2 flex items-center print:hidden"
              >
                <span className="pl-4 font-semibold  "></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
