import { SaveButton, UndoButton } from '../../utility/component/buttons';
import { FormatNumber2 } from '../../utility/component/tools';
const width = (window.innerWidth * 1) / 100;

const PartyWiseFooter = (props) => {
  const {
    rows,
    columns,

    handleSave,
    handleUndo,
  } = props;
  const TotalAmountRecieved = rows.reduce(
    (total, row) => Number(total) + Number(row.AMOUNT_RECEIVED),
    0
  );

  return (
    <div className="text-sm mt-2 ">
      <div
        className=" flex  "
        style={{
          alignItems: 'center',
        }}
      >
        <div className="flex w-full">
          <div
            style={{
              width: columns[0].width + columns[1].width + columns[2].width,
              border: '1px solid #f3f3f3',
            }}
            className="rounded-tl-sm"
          >
            <div className="flex w-full justify-between items-center pr-3">
              <div>
                <SaveButton onClick={handleSave} />
                <UndoButton onClick={handleUndo} />
              </div>
              <div className="font-semibold">Total</div>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #f3f3f3',
              borderLeft: '0px solid #f3f3f3',
            }}
            className="flex-1 flex justify-end items-center pr-3"
          >
            {FormatNumber2(TotalAmountRecieved)}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PartyWiseFooter;
