import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const List = createSlice({
  name: 'list',
  initialState: {
    issuedSummery: [],
    paymodeSaleReport: [],
    accountMasterDatagrid: [],
    saleSummary: [],
    ExpenseVoucher: [],
    routeAlertReport: [],
    stationList: [],
  },
  reducers: {
    setIssuedSummeryList(state, action) {
      state.issuedSummery = action.payload;
    },
    setStationList(state, action) {
      state.stationList = action.payload;
    },
    setRouteAlertReport(state, action) {
      state.routeAlertReport = action.payload;
    },
    setExpenseVoucherList(state, action) {
      state.ExpenseVoucher = action.payload;
    },

    setSaleSummaryList(state, action) {
      state.saleSummary = action.payload;
    },
    setAccountDatagridList(state, action) {
      state.accountMasterDatagrid = action.payload;
    },
    setPaymodeSaleReport(state, action) {
      state.paymodeSaleReport = action.payload;
    },
  },
});

export default List.reducer;

export const {
  setIssuedSummeryList,
  setAccountDatagridList,
  setPaymodeSaleReport,
  setSaleSummaryList,
  setRouteAlertReport,
  setExpenseVoucherList,
  setStationList,
} = List.actions;

export const selectIssuesList = (state) => state.list.issuedSummery;
export const selectStationList = (state) => state.list.stationList;
export const selectPaymodeReport = (state) => state.list.paymodeSaleReport;
export const selectAccountDatagridList = (state) =>
  state.list.accountMasterDatagrid;
export const selectSaleSummaryList = (state) => state.list.saleSummary;
export const selectExpenseVoucherList = (state) => state.list.ExpenseVoucher;

export const selectRouteAlertReport = (state) => state.list.routeAlertReport;
