import moment from 'moment';
import React from 'react';

const ManufacturingColumns = (width) => {
  const columns = [
    {
      width:
        width <= 1024 ? 80 : width <= 1024 ? 100 : width <= 1440 ? 70 : 100,
      headerAlign: 'center',
      align: 'center',
      accessor: 'ICODE',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width:
        width <= 1024 ? 120 : width <= 1024 ? 100 : width <= 1440 ? 120 : 220,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ITEMNAME',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1024 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_QNTY',
      field: 'MFG_QNTY',

      renderHeader: (params) => <div>Mfg Qnty</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1024 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'right',
      accessor: 'DMG_QNTY',
      field: 'DMG_QNTY',

      renderHeader: (params) => <div>Damage</div>,
    },
    {
      width:
        width <= 1024 ? 100 : width <= 1024 ? 100 : width <= 1440 ? 100 : 180,
      headerAlign: 'center',
      align: 'left',
      accessor: 'REMARKS',
      field: 'REMARKS',
      headerName: 'Remarks',

      renderHeader: (params) => <div>Remarks</div>,
    },

    {
      width:
        width <= 1024 ? 80 : width <= 1024 ? 100 : width <= 1440 ? 100 : 150,
      headerAlign: 'center',
      align: 'center',
      accessor: 'DATE',
      field: 'DATE',
      headerName: 'Time',
      renderCell: (params) => (
        <div>{moment(params.value).format('YYYY-MM-DD')}</div>
      ),
      renderHeader: (params) => <div>Time</div>,
    },
    {
      width:
        width <= 1024 ? 80 : width <= 1024 ? 100 : width <= 1440 ? 70 : 100,
      headerAlign: 'center',
      align: 'left',
      accessor: 'EMPLOYEE_NAME',
      field: 'EMPLOYEE_NAME',

      renderHeader: (params) => <div>Mfg. By</div>,
    },
  ];
  return columns;
};
const ManufacturingMobColumns = (width) => {
  const columns = [
    {
      width: 100,
      headerAlign: 'center',
      align: 'left',
      accessor: 'ITEMNAME',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width: 70,
      headerAlign: 'center',
      align: 'right',
      accessor: 'MFG_QNTY',
      field: 'MFG_QNTY',

      renderHeader: (params) => <div>Mfg Qnty</div>,
    },
    {
      width: 60,
      headerAlign: 'center',
      align: 'right',
      accessor: 'DMG_QNTY',
      field: 'DMG_QNTY',

      renderHeader: (params) => <div>Damage</div>,
    },
  ];
  return columns;
};
export { ManufacturingMobColumns, ManufacturingColumns };
