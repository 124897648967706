import React, { useState } from 'react';

const PlaceHolderTextInput = ({
  onKeyDown,
  onClick,
  ref,
  id,
  type,
  placeholder,
  value,
  onchange,
  icon,
  onDoubleClick,
  rightIcon,
  borderColor,
  readOnly,
  autoFocus,
}) => {
  const [border, setBorder] = useState('#A09E9E');
  const [bgcolor, setBgcolor] = useState('white');
  return (
    <div
      style={{
        border: borderColor
          ? `1px solid ${borderColor}`
          : `1px solid ${border}`,
        backgroundColor: bgcolor,
      }}
      className="p-2 rounded-sm w-full text-sm flex items-center  "
    >
      {icon && icon}
      <input
        onClick={onClick ? onClick : () => console.log('click')}
        autoFocus={autoFocus ? true : false}
        id={id ? id : ''}
        onDoubleClick={onDoubleClick}
        onBlur={() => {
          setBorder('#A09E9E');
          setBgcolor('white');
        }}
        onFocus={() => {
          setBorder('#43bff7');
          setBgcolor('white');
        }}
        ref={ref}
        className="outline-none  bg-transparent w-full flex items-center"
        onKeyDown={
          onKeyDown
            ? (e) => {
                onKeyDown(e);
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                }
              }
            : (e) => {
                if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                  e.preventDefault();
                }
              }
        }
        readOnly={readOnly ? true : false}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onchange}
      />
      {rightIcon && rightIcon}
    </div>
  );
};

export default PlaceHolderTextInput;
