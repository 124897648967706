import { Button } from '@mui/material';
import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react';
import database from '../issueStock/firebase';

const DashboadBox = ({
  i,
  e,
  setCompanyEmail,
  setCompanyInfoHelpStatus,
  setUserEmail,
}) => {
  const [liveUserCount, setLiveUserCount] = useState(0);
  const getLiveUser = async (tb) => {
    try {
      const connectedRef = ref(database, `${tb}/users`);
      onValue(connectedRef, (sn) => {
        if (sn.exists()) {
          const data = sn.val();
          const live = Object.values(data).filter((item) =>
            item.status.includes('c')
          );
          const dataArray = Object.keys(data).map((key) => ({
            id: `${key}`,
            status: data[key].status,
          }));
          setLiveUserCount(live.length);
        }
      });
    } catch (error) {
      console.error('Error fetching live user data:', error);
    }
  };

  useEffect(() => {
    // Function to get live user count
    const fetchLiveUserCount = async () => {
      const text = e.company_name;
      const words = text.split(' ');

      const result =
        words.reduce((acc, word) => {
          if (word) {
            acc += word[0];
          }
          return acc;
        }, '') + '_';
      try {
        getLiveUser(result.toLowerCase());
      } catch (error) {
        console.error('Error fetching live user count:', error);
      }
    };

    // Call the function
    fetchLiveUserCount();
  }, []);
  return (
    <Button
      key={i}
      onClick={() => {
        setCompanyEmail(e.company_email);
        setUserEmail(e.user_email);
        setCompanyInfoHelpStatus(true);
      }}
      variant={'contained'}
      style={{
        textTransform: 'none',
        backgroundColor: 'transparent',
        padding: '0px',
        borderRadius: '7px',
        overflow: 'hidden',
      }}
    >
      <div className="bg-[#e9e9e5] py-3 w-full whitespace-nowrap text-[#302d2d] px-3 ">
        <div className="w-full flex justify-start font-semibold text-brown">
          <i className="bi bi-briefcase-fill flex items-center mr-2 text-brown"></i>{' '}
          {e.company_name}
        </div>
        <div className="w-full flex justify-between">
          {' '}
          <div className="w-1/2  flex justify-start">
            <div>
              <i className="bi bi-people-fill mr-2 text-[#06521c80] "></i>
              {liveUserCount}
            </div>
          </div>
          <div>
            <i className="bi bi-people-fill mr-2 text-[#b4b4b1] "></i>
            {e.tusers}
          </div>
        </div>
      </div>
    </Button>
  );
};

export default DashboadBox;
