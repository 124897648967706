import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import CallAPI from '../../utility/functions/getData';

const PromoMasterSave = (
  data,
  token,
  dispatch,
  setData,
  products,
  getCustomerData,
  formRef
) => {
  console.log(data);
  if (
    (data.SEND_MESSAGE === 'No' && data.REMARKS) ||
    data.SEND_MESSAGE === 'Yes'
  ) {
    dispatch(setSpinnerLoading('Saving'));
    CallAPI(
      '/api/PromoMessageMaster/SavePromoMsgMaster',
      data,
      token,
      (res) => {
        if (res.status === 200) {
          dispatch(
            setAlertWithTitle({
              title: 'Success',
              msg: 'Data saved successfully',
              lottie: 'success',
            })
          );
          setData({
            CODE: '',
            NAME: '',
            COMPANY_NAME: '',
            CONTACT_PERSON: '',
            MOBILE: '',
            MSG_MOBILE: '',
            EMAIL: '',
            WEBSITE: '',
            ADDRESS: '',
            COUNTRY: '',
            STATE: '',
            CITY: '',
            PIN_CODE: '',
            PRODUCT_NAME: products[0].value,
            SEND_MESSAGE: 'Yes',
            BUSINESS_DETAILS: '',
            REMARKS: '',
            REFERED_BY: '',
            DEFAULT_MESSAGE: '',
          });
          getCustomerData();
        }
      },
      dispatch
    );
  } else {
    const inputFields = formRef.current.querySelectorAll(
      'input, select,textarea'
    );

    inputFields.forEach((field) => {
      if (field.id === 'REMARKS') {
        field.focus();
      }
    });
    dispatch(
      setAlertWithTitle({
        title: 'Alert',
        msg: 'Please write remarks.',
      })
    );
  }
};
export { PromoMasterSave };
