import React from 'react';
import { FormatNumber2 } from '../../utility/component/tools';
import { useDispatch } from 'react-redux';
import { setAlertWithTitle } from '../../utility/Redux/modal';

const IssueDayEndCalculation = ({
  debit,
  collection,
  totalDistance,
  vehicalAvg,
  deiselAmt,
  checked,
  stationId,
  setChecked,
  hideBillingTotal,
}) => {
  let netCollection = 0;
  let netDebit = 0;
  if (collection > debit) {
    netCollection = collection - debit;
  }
  
  if (debit > collection) {
    netDebit = debit - collection;
  }

  const dispatch = useDispatch();

  return (
    <div
      style={{
        border: '1px solid #b5b3b3',
      }}
      className="flex rounded-sm mt-[2px] font-semibold pl-2 py-2 justify-between"
    >
      <div className="flex">
        {' '}
        <div>{netDebit > 0 ? 'Net Debit : ' : 'Net Collection : '}</div>
        <div className="pl-1 font-[500]">
          {netDebit > 0
            ? FormatNumber2(netDebit)
            : FormatNumber2(netCollection)}
        </div>
        <div className="ml-10">{'Total Distance :'}</div>
        <div className="pl-1 font-[500]">{totalDistance}</div>
        <div className="ml-10">{'Vehicle Average :'}</div>
        <div className="pl-1 font-[500]">{vehicalAvg ? vehicalAvg : 0}</div>
        <div className="ml-10 ">{'Diesel Amount :'}</div>
        <div className="pl-1 font-[500]">{deiselAmt}</div>
      </div>

      {!hideBillingTotal && (
        <div className="flex  mr-3">
          <input
            checked={checked}
            type={'checkbox'}
            onChange={(e) => {
              if (stationId) {
                setChecked(e.target.checked);
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'Please select station id first',
                  })
                );
              }
            }}
          />
          <div className="ml-2">Billing Total</div>
        </div>
      )}
    </div>
  );
};

export default IssueDayEndCalculation;
