import moment from 'moment';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import axios from 'axios';
import { CallAPIPromise } from '../menu/dashboard/biiling/modal/comman';

const GetMfgBalReport = (
  prefix,
  dispatch,
  token,
  setList,
  fromDate,
  toDate
) => {
  dispatch(setSpinnerLoading('Loading'));
  const data = {
    table_prefix: prefix,
    FDATE: moment(fromDate).format('YYYY-MM-DD'),
    TDATE: moment(toDate).format('YYYY-MM-DD'),
  };
  CallAPIPromise(
    `/api/saveInHouseMfg/mfgBalanceReport`,
    data,

    token,
    dispatch
  )
    .then(async function (response) {
      if (!response.data.error) {
        setList(response.data.response);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error ',
            msg: 'Something Went Wrong!',
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Something Went Wrong!',
        })
      );
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export { GetMfgBalReport };
